export const RuleValidations = {
  maxLength1000: 1000,
  maxLength4096: 4096,
  maxLength1024: 1024,
  maxLength255: 255,
  maxLength16: 16,
  maxLength17: 17,
  maxLength32: 32,
  maxLength64: 64,
  minNumber1: 1,
  maxNumber10000: 10000,
  maxNumber99999999: 99999999,
  maxNumberCar99999: 99999,
  latinAndJapanCharacter: "^[0-9a-zA-Zぁ-んァ-ン一-龥ｧ-ﾝﾞﾟー 　]+$",
  phoneNumber: '^0[0-9]+$',
  emailPattern: '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  zip: '^[0-9]{3}-[0-9]{4}$',
  extractNumberInString: /\d+/,
  getNumberInString: /[^0-9]/g,
  passwordPattern: '^(?=.*[A-Z])(?=.*[a-z])(?=.*[\\d])([A-Za-z\\d]{8,32})$',
  multipleEmailSeparateByComma: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}(,[\w-.]+@([\w-]+\.)+[\w-]{2,4})*$/,
  validateImportFile: '^.+\.(csv)$',
  regexDataCsv:/(".*?"|[^",]+(?:\s+[^",]+)*)(?=\s*,|\s*$)/g,
  removeDoubleQuoteString: /['"\r]+/g,
  removeAllDoubleQuoteString: /"/g,
  regexEntryNo: /\d{4}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([01]\d|2[0-3])([0-5]\d)([0-5]\d)\d{3}/g,
  regexDateString: /\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])\s([01]\d|2[0-3]):([0-5]\d):([0-5]\d)/g,
  regexOptionText: /^[A-Za-z.\/]+/g,
  regexSingleAndDoubleQuote: /["']/g,
  regexSingleQuote: /\'/g,
  regexDoubleQuote: /\"/g,
  regexFirstLetter: /(?:^\w|[A-Z]|\b\w|\s+)/g,
  regexOnlySpace: /\s/g,
  regexEmail: /^[\w-\+.]+@([\w-]+\.)+[\w-]{2,4}$/g,
  regexFloatNumber: /^[+-]?([0-9]*[.])?[0-9]+/g,
  regexFile: <any>{
    image: {
      mime: /[\/.](jpg|jpeg|png)$/i,
      maxFileSize: 10, // unit is MB
      mimeTypesString: 'jpg, jpeg, png'
    },
    movie: {
      mime: /[\/.](mp4|quicktime|mpg)$/i,
      maxFileSize: 320, // unit is MB
      mimeTypesString: 'mp4, mov, mpg'
    },
    sound: {
      mime: /[\/.](mpeg|aac)$/i,
      maxFileSize: 10, // unit is MB,
      maxFileSizePluginWhisper: 25,
      mimeTypesString: 'mp3, aac'
    },
    file: {
      mime: /.*/,
      maxFileSize: 512, // unit is MB
      mimeTypesString: ''
    }
  },
  REGEX_LINK: /^(ftp|http|https):\/\/[^ "]+$/
}

export const DEFAULT_ALERT_TYPE = {
  success: 'success',
  error: 'danger'
}

export const SEARCH_FIRST_CHARACTER_WITH_SPACE = /\w\S*/g
