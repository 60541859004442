<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3>{{ "form.createFormTitle" | translate }}</h3>
    </c-col>
    <c-card class="card-no-border account-card">
      <form class="form-create" [formGroup]="formCreate" novalidate>
        <div class="mb-3">
          <c-col class="mb-1">
            {{ "form.inputLabel.formName" | translate }}
            <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
          </c-col>
          <c-input-group>
            <input cFormControl formControlName="formName" (keypress)="checkInputEnter($event)"/>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.formName?.errors && submitted">
            {{ "errorMessages.formValidate.formName." + getFirstError(formCreate!.controls!.formName!.errors) | translate : { maxlength: ruleValidations.maxLength255 } }}
          </c-form-check
          >
        </div>
        <div class="mb-3">
          <c-col class="mb-1">{{ "form.inputLabel.note" | translate }}</c-col>
          <editor formControlName="note" [init]="initEditor"></editor>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.note?.errors?.['maxlength'] && submitted">
            {{ "errorMessages.formValidate.input.maxlength" | translate : { maxlength: ruleValidations.maxLength4096 } }}
          </c-form-check>
        </div>
        <div class="mb-3 form-select-date">
          <c-col class="mb-1">
            {{ "form.inputLabel.dataRegistrationDeadline" | translate }}
            <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
          </c-col>
          <c-col lg="12">
            <c-date-picker
              placeholder="{{'form.placeholder.date' | translate}}"
              cFormControl
              class="input-date"
              [minDate]="minDate"
              formControlName="eventDate"
              [locale]="currentDatepickerLocale"
              [dayFormat]="dayFormat"
              (keypress)="checkInputEnter($event)"
            ></c-date-picker>
          </c-col>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.eventDate?.errors && submitted">
            {{ "errorMessages.formValidate.select.required" | translate }}
          </c-form-check>
        </div>
        <div class="mb-3">
          <div class="multiple-select">
            <div class="block-template">
              <c-col class="mb-1">
                <label for="radioOne1">
                  {{ "form.inputLabel.template" | translate }}
                  <c-badge *ngIf="defaultTemplateSelect === SELECT_TEMPLATE.TEMPLATE && accountRestrictConfig.isDisplayTemplateList" color="danger"> {{ "form.inputLabel.must" | translate }}</c-badge>
                </label>
              </c-col>
              <c-input-group>
                <label for="radioOne1" cInputGroupText class="label-select-radio">
                  <div>
                    <input
                      cFormCheckInput
                      [checked]="(!!this.templates?.length || !!currentUser?.hostname) && accountRestrictConfig.isDisplayTemplateList"
                      [disabled]="!this.templates?.length || !accountRestrictConfig.isDisplayTemplateList"
                      (change)="changeTemplateType(SELECT_TEMPLATE.TEMPLATE)"
                      [value]="SELECT_TEMPLATE.TEMPLATE"
                      id="radioOne1" name="use" type="radio"
                    />
                  </div>
                </label>
                <select cFormControl formControlName="templateId" cSelect (change)="handledChangeTemplate($event, SELECT_TEMPLATE.TEMPLATE)">
                  <option [selected]="true" [value]="null" hidden>
                    {{ "form.placeHolder.select" | translate }}
                  </option>
                  <option *ngFor="let item of templates" [value]="item.docId">
                    {{ item.nodes.meta.name }}
                  </option>
                </select>
              </c-input-group>
              <c-form-check class="text-danger" *ngIf="formCreate?.controls?.templateId?.errors && submitted">
                {{ "errorMessages.formValidate.select.required" | translate }}
              </c-form-check>
            </div>
            <div *ngIf="!currentUser?.hostname">
              <c-col class="mb-1">
                <label for="radioOne">
                  {{ "form.inputLabel.systemTemplate" | translate }}
                  <c-badge *ngIf="defaultTemplateSelect === SELECT_TEMPLATE.SYSTEM_TEMPLATE" color="danger"> {{ "form.inputLabel.must" | translate }}</c-badge>
                </label>
              </c-col>
              <c-input-group>
                <label for="radioOne" cInputGroupText class="label-select-radio">
                  <div>
                    <input
                      cFormCheckInput
                      [value]="SELECT_TEMPLATE.SYSTEM_TEMPLATE"
                      [checked]="!this.templates?.length || !currentUser?.hostname"
                      (change)="changeTemplateType(SELECT_TEMPLATE.SYSTEM_TEMPLATE)"
                      id="radioOne" name="use" type="radio"
                    />
                  </div>
                </label>
                <select cFormControl formControlName="systemTemplate" cSelect (change)="handledChangeTemplate($event, SELECT_TEMPLATE.SYSTEM_TEMPLATE)">
                  <option [selected]="true" [value]="null" hidden>
                    {{ "form.placeHolder.select" | translate }}
                  </option>
                  <option *ngFor="let item of systemTemplates" [attr.dataTemplate]="item" [value]="item.docId">
                    {{ item.title }}
                  </option>
                </select>
              </c-input-group>
              <c-form-check class="text-danger" *ngIf="formCreate?.controls?.systemTemplate?.errors && submitted">
                {{ "errorMessages.formValidate.select.required" | translate }}
              </c-form-check>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">
            {{ "form.inputLabel.members" | translate }}
            <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
          </c-col>
          <div class="d-flex justify-content-start align-items-center box-input-row">
            <c-input-group class="box-input-number">
              <div class="d-flex justify-content-between box-input-num">
                <img
                  class="button-change"
                  src="assets/svg/create-form/minus-square.svg"
                  (click)="decreaseValueInputNumber($event)"
                  alt="minus-square"
                />
                <input
                  aria-label="member"
                  cFormControl
                  class="no-border text-center"
                  type="text"
                  (keypress)="ignoreString($event)"
                  formControlName="member"
                />
                <img
                  class="button-change"
                  src="assets/svg/create-form/add-square.svg"
                  (click)="increaseValueInputNumber($event)"
                  alt="add-square"
                />
              </div>
            </c-input-group>
            <label>{{ unitString ?? "form.inputLabel.count" | translate }}</label>
          </div>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.member?.errors && submitted">
            {{ "errorMessages.formValidate.member." + getFirstError(formCreate!.controls!.member!.errors)
              | translate: {
                min: ruleValidations.minNumber1,
                max: maxRecords
              }
            }}
          </c-form-check>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">
            {{ "form.inputLabel.layout" | translate }}
            <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
          </c-col>
          <select cFormControl formControlName="layout" cSelect>
            <option [selected]="true" hidden>
              {{ "form.placeHolder.select" | translate }}
            </option>
            <option *ngFor="let layout of LAYOUT_FORM_LIST" [value]="layout">
              {{ 'form.layout.' + layout | translate }}
            </option>
          </select>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.layout?.errors && submitted">
            {{ "errorMessages.formValidate.select.required" | translate }}</c-form-check>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">
            {{ "form.inputLabel.color" | translate }}
            <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
          </c-col>
          <div class="d-flex">
            <div *ngFor="let item of colorTypesSet" class="me-3">
              <input
                formControlName="colorCode"
                class="check-box-color d-none"
                id="color{{ item.colorCode }}"
                [value]="item.colorCode"
                name="colorCode"
                cFormCheckInput
                type="radio"
              />
              <label
                cFormCheckLabel
                for="color{{ item.colorCode }}"
                [style.--border-color]="
                  item.colorCode === '#FFFFFF' ? '1px solid #667085' : ''
                "
                [style.--color]="item.colorCode"
                [style.border]="
                  item.colorCode === '#FFFFFF' ? '1px solid #667085' : ''
                "
                class="color-icon"
              ></label>
            </div>
          </div>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.colorCode?.errors && submitted">
            {{ "errorMessages.formValidate.select.required" | translate }}
          </c-form-check>
        </div>
        <div class="mb-3 d-flex justify-content-end">
          <button (click)="backToIndexForm()" class="back-btn">
            {{ "form.backButton" | translate }}
          </button>
          <button cButton type="submit" (click)="submit()" class="btn-submit">
            {{ "form.inputLabel.submit" | translate }}
          </button>
        </div>
      </form>
    </c-card>
  </div>
</c-container>
