export const colorTypes = [
  {
    id: 1,
    name: 'white',
    code: '#FFFFFF'
  },
  {
    id: 2,
    name: 'blue',
    code: '#0086C9'
  },
  {
    id: 3,
    name: 'orange',
    code: '#EC4A0A'
  },
  {
    id: 4,
    name: 'purple',
    code: '#7F56D9'
  },
  {
    id: 5,
    name: 'red',
    code: '#D92D20'
  },
  {
    id: 6,
    name: 'green',
    code: '#039855'
  },
  {
    id: 7,
    name: 'pink',
    code: '#DD2590'
  }
];

export const ColorType: { [key: string]: string; } = {
  '#FFFFFF': 'white',
  '#EC4A0A': 'orange',
  '#0086C9': 'blue',
  '#7F56D9': 'purple',
  '#D92D20': 'red',
  '#039855': 'green',
  '#DD2590': 'pink',
};

export const COLOR_METER_LIST = [
  { label: 'box', colorCode: 'rect', borderColor: 'red', type: 'rect' },
  { label: 'size', colorCode: 'oval', borderColor: 'yellow', type: 'oval' },
  { label: 'centerPoint', colorCode: 'yellow', borderColor: '', type: 'dot' },
  { label: 'startPoint', colorCode: 'blue', borderColor: '', type: 'dot' },
  { label: 'endPoint', colorCode: 'green', borderColor: '', type: 'dot' },
]

export const COLOR_METER_POINT_LIST = {
  centerPoint: 'yellow',
  startPoint: 'blue',
  endPoint: 'green',
  size: 'yellow',
  box: 'red',
}

export const DEFAULT_COLOR_TEMPLATE = '#DD2590';
export const STYLE_BUTTON_TBC = '#202e55'; // '#5e8bff';
export const STYLE_STRING_TBC = '#ffffff';
export const COLOR_WHITE = '#fff';
export const LIST_COLOR_OF_MONTHS = [
  '#ff8787',
  '#faa2c1',
  '#eebefa',
  '#b197fc',
  '#91a7ff',
  '#74c0fc',
  '#66d9e8',
  '#63e6be',
  '#8ce99a',
  '#c0eb75',
  '#ffe066',
  '#ffc078',
];
