<app-layout-auth [backgroundColor]="getFormInfo('backgroundCode')" [stringColor]="getFormInfo('stringCodeForm')">
  <c-container class="header-container">
    <header class="form-user-header">
      <a id="logo">
        <div>{{ getFormInfo("organizationName") }}</div>
      </a>
    </header>
  </c-container>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3 [class]="{'tbc-title': prefixForm === PREFIX_LIST.pluginTbc}">{{ getFormInfo("title") }}</h3>
      <p *ngIf="isDisplayTargetDate(prefixForm)">{{ ('form.inputLabel.deadlineForTesting' | translate) + '：' + getFormInfo("targetDate") }}</p>
      <div [innerHTML]="getFormInfo('videoDescription') | safeHtml"></div>
    </c-col>
    <c-col *ngIf="checkActiveRoute && !isVideoPage; else prefixBlock">
      <c-card class="card-no-border">
        <c-card-body class="card-border-bottom">
          <c-col class="mb-4">
            <h4 class="mb-0">{{ "errorMessagePage.title" | translate }}</h4>
          </c-col>
          <c-col>
            <span>{{ "errorMessagePage.message" | translate }}</span>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
    <ng-template #prefixBlock>
      <c-card class="form-info mb-3" *ngIf="formData">
        <c-col
          *ngFor="let data of dataShow; let i = index"
          [class]="{ 'mb-3': i !== dataShow.length - 1 }"
        >
          <c-col
            [ngStyle]="{'color': getFormInfo('stringCodeForm')}"
            class="mb-1 mt-1"
            *ngIf="SHOW_FIELDS_EXAM.includes(String(data?.key))"
          >
            {{ "webview.inputLabel." + data.key | translate }}
          </c-col>
          <c-input-group>
            <input
              *ngIf="SHOW_FIELDS_EXAM.includes(String(data?.key))"
              [readOnly]="true"
              cFormControl
              [value]="data.value"
            />
          </c-input-group>
        </c-col>
      </c-card>
      <c-card class="form-info mt-3 mb-3">
        <span
          *ngIf="videoUrl"
          id="playerContainer"
          #playerContainer
          [innerHTML]="videoUrl | safeHtml"
        ></span>
        <c-col class="btn-section mb-1">
          <span class="switch-mode-label">{{ 'webview.switchScreenModeLabel' | translate }}</span>
          <div class="d-flex btn-list">
            <button
              class="btn-player"
              cButton
              (click)="replayVideo()"
              [ngStyle]="{
                'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC : getFormInfo('stringCodeForm'),
                'backgroundColor': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC : getFormInfo('backgroundCode')
              }"
            >
              {{ "webview.btnLabel.replay" | translate }}
            </button>
            <button
              class="btn-player"
              id="playPauseBtn"
              cButton
              (click)="pauseVideo()"
              [ngStyle]="{
                'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC : getFormInfo('stringCodeForm'),
                'backgroundColor': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC : getFormInfo('backgroundCode')
              }"
            >
              {{ "webview.btnLabel." + isVideoPlaying | translate }}
            </button>
          </div>
        </c-col>
      </c-card>
      <c-card *ngIf="((this.formData?.status !== this.videoStatusList[0] || displayTestButton) && form?.showExam) || (this.formData?.status === this.videoStatusList[this.videoStatusList.length - 1]) || (displayTestButton && !form?.showExam)">
        <c-card-body>
          <c-col class="btn-list">
            <button
              class="btn-player"
              *ngIf="(displayTestButton || this.formData?.status !== this.videoStatusList[0]) && form?.showExam"
              cButton
              (click)="navigateExam()"
              [ngStyle]="{
              'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC : getFormInfo('stringCodeForm'),
              'backgroundColor': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC : getFormInfo('backgroundCode')
            }"
            >
              {{ "webview.btnLabel.test" | translate }}
            </button>
            <button
              class="btn-player btn-certificate"
              [ngStyle]="{
                  'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC : getFormInfo('stringCodeForm'),
                  'backgroundColor': getFormInfo('backgroundCode')
              }"
              cButton
              *ngIf="(this.formData?.status === this.videoStatusList[this.videoStatusList.length - 1]) || (displayTestButton && !form?.showExam)"
            >
              <a target="_blank"
                 [ngStyle]="{
                  'color': getFormInfo('stringCodeForm'),
                  'backgroundColor': getFormInfo('backgroundCode')
                  }"
                 routerLink="/{{prefixForm}}/{{formData.formId}}/{{formData.entryno}}/certificate">
                {{ form['showCertificate'] ? ("webview.btnLabel.issueCertificate" | translate) : ('webview.btnLabel.finishCourse' | translate) }}
              </a>
            </button>
          </c-col>
        </c-card-body>
      </c-card>
    </ng-template>
    <div class="text-center mt-5 pb-2 text-footer" [innerHTML]="getFormInfo('copyright') | safeHtml"></div>
  </div>
</app-layout-auth>
