import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {getFirstError} from "../../../../helpers";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from "@app/constants";
import { AlertService, FirebaseAnalyticService, GroupService } from "@app/services";
import {TranslateService} from "@ngx-translate/core";
import { CustomValidatorsService } from '@app/services/custom-validators.service';

@Component({
  selector: 'app-edit-group-name',
  templateUrl: './edit-name.component.html',
  styleUrls: ['./edit-name.component.scss']
})
export class EditNameComponent implements OnInit {
  @Input() path: any = '';
  @Input() visible: boolean = false;
  @ViewChild('editName') editName: any;
  @Output() closeEditModal = new EventEmitter<boolean>();
  group: any = {}
  submitted = false
  readonly ruleValidations = RuleValidations;
  formEditName = new FormGroup({
    name: new FormControl(null, [Validators.required, Validators.maxLength(this.ruleValidations.maxLength64), CustomValidatorsService.notAllowedOnlySpace]),
    hidden: new FormControl(false)
  })
  constructor(
    private groupService: GroupService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.generateGroupName().then();
    this.ref.detectChanges()
  }

  protected readonly getFirstError = getFirstError;

  async generateGroupName() {
    await this.groupService.getGroupByPath(this.path).subscribe((res) => {
      this.group = res.payload.data()
      this.formEditName.controls.name.setValue(this.group?.name)
      this.formEditName.controls.hidden.setValue(this.group?.hidden ?? false)
    })
  }

  toggleChecked() {
    this.formEditName.controls.hidden.setValue(!this.formEditName.controls.hidden.value)
  }

  visibleChange(e: any) {
    if (!e) {
      this.generateGroupName().then()
      this.submitted = false;
      this.closeEditModal.emit(e)
    }
  }

  submit() {
    this.submitted = true
    if (this.formEditName.valid) {
      this.groupService.updateGroupName(this.path, this.formEditName.value).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.UPDATE_NAME, EVENT_LOG_TYPE.SUCCESS)
        this.editName.visible = false
        this.alertService.success(this.translateService.instant('alertMessages.group.edit.success'));
      }).catch((error) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.UPDATE_NAME, EVENT_LOG_TYPE.ERROR)
        this.editName.visible = false
        this.alertService.error(this.translateService.instant('alertMessages.group.edit.fail'));
      })
    }
  }
}
