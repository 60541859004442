import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth/auth.component';
import { HeaderGuestComponent } from '@app/components/header-guest/header-guest.component';
import { RouterModule } from '@angular/router';
import { HeaderUserComponent } from '@app/components/header-user/header-user.component';
import { DropdownModule, GridModule } from '@coreui/angular';
import { NotifyPopupComponent } from '@app/components/notify-popup/notify-popup.component';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

@NgModule({
  declarations: [
    AuthComponent,
    HeaderGuestComponent,
    HeaderUserComponent,
    NotifyPopupComponent
  ],
  imports: [
    DropdownModule,
    CommonModule,
    RouterModule,
    GridModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); },
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    AuthComponent,
    HeaderUserComponent,
    NotifyPopupComponent,
    HeaderGuestComponent
  ]
})
export class LayoutsModule { }
