import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ROLE_STATUS } from '@app/constants';
import { FormService, UserService } from '@app/services';

@Injectable({ providedIn: 'root' })
export class GroupRoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private formService: FormService,
    private userService: UserService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.userService.getCurrentUser().subscribe({
      next: (authUser) => {
        if (authUser && authUser.uid.length) {
          this.formService.getValueOwnerFormById(authUser.uid, route.params['id']).subscribe((formData: any) => {
            const uidUser = localStorage.getItem('uid_user');
            const permission = localStorage.getItem('permission');
            if (route?.routeConfig?.path === 'form-user/:id' && uidUser && formData?.user_id === uidUser && permission === ROLE_STATUS.readonly) {
              this.router.navigate(['/404'], { queryParams: { returnUrl: state.url }}).then(r => { });
            }
            return true;
          })
        }
      }
    })
    return true;
  }
}
