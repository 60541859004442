import { AfterViewChecked, Component, ElementRef, OnInit } from '@angular/core';
import { FirebaseAnalyticService, FormCachesService, FormService } from "@app/services";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, firstValueFrom } from "rxjs";
import Player from '@vimeo/player';
import { ACTION_LIST, EVENT_LOG_TYPE, PREFIX_LIST, SHOW_FIELDS_EXAM, STYLE_BUTTON_TBC, STYLE_STRING_TBC, text, VIDEO_STATUS } from '@app/constants';
import { TranslateService } from '@ngx-translate/core';
import { getInfoDataByKey, isDisplayTargetDate } from "@app/helpers";

@Component({
  selector: 'app-web-view',
  templateUrl: './web-view.component.html',
  styleUrls: ['./web-view.component.scss']
})
export class WebViewComponent implements OnInit, AfterViewChecked {
  isDisplayTargetDate = isDisplayTargetDate;
  protected SHOW_FIELDS_EXAM = SHOW_FIELDS_EXAM
  protected readonly STYLE_BUTTON_TBC = STYLE_BUTTON_TBC;
  protected readonly STYLE_STRING_TBC = STYLE_STRING_TBC;
  protected String = String;
  checkActiveRoute: boolean = false;
  text = text
  form: any;
  formData: any;
  videoUrl: any;
  displayTestButton: boolean = false;
  dataShow: Array<{ value: string, key: string }> = [];
  prefixForm: string = '';
  isVideoPlaying: string = VIDEO_STATUS.PLAY;
  videoPlayer: Player | undefined;
  videoStatusList: Array<string> = [];
  isVideoPage: boolean = false;
  showVideo: boolean = false;
  formId: string | null = null;
  authUser: any;
  authUserId: string | null = null;

  constructor(
    private formService: FormService,
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private activatedRoute: ActivatedRoute,
    private formCachesService: FormCachesService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser().then();
  }

  ngAfterViewChecked(): void {
    this.checkDisplayTestButton();
    this.checkVideoPlaying();
  }

  async getCurrentUser() {
    this.formId = this.route.snapshot.params['formId'];
    // update by Tanaka on 2023/08/23
    this.formCachesService.getDetailFormCache(this.formId ?? '').subscribe({
      next: (formCacheValue: any) => {
        if (formCacheValue.length) {
          this.authUserId = formCacheValue[0]?.['userid'];
          this.generateVideo();
        }
      }
    })
  }

  async generateVideo() {
    const formDataId = this.route.snapshot.params['formDataId'];
    const formId = this.route.snapshot.params['formId'];
    // update by Tanaka on 2023/08/23
    this.form = (await firstValueFrom(this.formService.getValueOwnerFormById(this.authUserId ?? '', formId)));
    this.formData = (await firstValueFrom(this.formService.getOwnerFormDataById(this.authUserId ?? '', this.formId ?? '', formDataId)));
    this.showVideo = this.form.showVideo;
    const listStatus = this.form.nodes.nodes.objects.filter((item: any) => item.name === 'status')?.[0]?.value;
    const redirectToExam = listStatus[0] !== this.formData.status && listStatus[listStatus.length - 1] !== this.formData.status
    const redirectToCertificate = listStatus[listStatus.length - 1] === this.formData.status;
    const noRedirect = this.activatedRoute.snapshot.queryParamMap.get('not_redirect')
    if (redirectToCertificate && !noRedirect) {
      this.router.navigate([`plugin-tbc/${this.form.doc_id}/${this.formData.entryno}/certificate`]);
      const dataCertificateStatus = { ...this.formData, status: listStatus[2] };
      this.formService.updateFormDataStatus(this.form.user_id, this.form.doc_id, dataCertificateStatus.entryno, dataCertificateStatus).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_STATUS, EVENT_LOG_TYPE.SUCCESS);
      }).catch(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_STATUS, EVENT_LOG_TYPE.ERROR);
      });
    } else if ((!this.form.showVideo || redirectToExam) && !noRedirect?.length) {
      if (!this.form.showVideo) {
        const newData = { ...this.formData, status: listStatus[1] }
        this.formService.updateFormDataStatus(this.form.user_id, this.form.doc_id, newData.entryno, newData).then(() => {
          this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_STATUS, EVENT_LOG_TYPE.SUCCESS);
        }).catch(() => {
          this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_STATUS, EVENT_LOG_TYPE.ERROR);
        })
      }
      let queryParams = null;
      if (this.form.showExam) {
        queryParams = { 'not_redirect': true };
      }
      this.router.navigate([`plugin-tbc/${this.form.doc_id}/${this.formData.entryno}/exam`], { queryParams: queryParams });
    }
    this.checkCanActiveRoute();
    await this.checkPrefixForm();
    if (this.isVideoPage) {
      document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: any) => {
          document.getElementById('form-guest-body')!.style.backgroundColor = text.defaultBackgroundCode;
          if (this.isVideoPage) document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
        });
    }
    this.videoStatusList = this.form.nodes.nodes.objects.filter((element: any) => element.name === 'status')?.[0].value;
    this.prefixForm = this.form.nodes.nodes.meta.prefix;
    const videoList = this.form.videoList.filter((item: any) => item)
    this.videoUrl = videoList[this.form.useVideo - 1];
    if (!Boolean(this.form.useVideo)) {
      const randomVideoIndex = Math.floor(Math.random() * (videoList.length));
      this.videoUrl = videoList[randomVideoIndex];
    }

    SHOW_FIELDS_EXAM.forEach((item: string, index) => {
      this.dataShow[index] = {
        value: this.formData[item],
        key: item
      };
    })
  }

  getFormInfo(key: string) {
    return getInfoDataByKey(this.form, key, this.translateService.currentLang)
  }

  checkCanActiveRoute() {
    const formId = this.route.snapshot.params['formId'];
    this.checkActiveRoute = this.formData && this.formData?.['formId'] === formId;
  }

  async checkPrefixForm() {
    const prefixForm = this.form.nodes.nodes.meta.prefix;
    this.isVideoPage = Boolean(prefixForm && prefixForm === PREFIX_LIST.pluginTbc)
  }

  checkDisplayTestButton() {
    const iframe = this.elementRef.nativeElement?.querySelector('#playerContainer')?.querySelector('iframe');
    if (iframe) {
      this.videoPlayer = new Player(iframe);
      this.videoPlayer.on('ended', () => {
        if (this.formData.status === this.videoStatusList[0]) {
          this.displayTestButton = true;
          const statusIndex = this.videoStatusList.indexOf(this.formData.status);
          const status = this.videoStatusList[!Boolean(statusIndex) ? (statusIndex + 1) : statusIndex]
          const data = { status: this.form.showExam ? status : this.videoStatusList[this.videoStatusList.length - 1] };
          this.formService.updateFormDataStatus(this.form.user_id, this.formData.formId, this.formData.entryno, data).then(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_STATUS, EVENT_LOG_TYPE.SUCCESS)
          })
            .catch((e) => {
              this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_STATUS, EVENT_LOG_TYPE.ERROR)
            });
        }
      })
    }
  }

  replayVideo() {
    this.videoPlayer?.unload().then(() => {
      this.videoPlayer?.play().then();
    });
  }

  checkVideoPlaying() {
    const iframe = this.elementRef.nativeElement?.querySelector('#playerContainer')?.querySelector('iframe');
    if (iframe) {
      this.videoPlayer = new Player(iframe);
      this.videoPlayer.on('playing', () => {
        this.isVideoPlaying = VIDEO_STATUS.PAUSE;
      });
      this.videoPlayer.on('pause', () => {
        this.isVideoPlaying = VIDEO_STATUS.PLAY;
      });
    }
  }

  pauseVideo() {
    this.videoPlayer?.getPaused().then((isPaused) => {
      this.isVideoPlaying = VIDEO_STATUS.PLAY;
      this.videoPlayer?.pause().then();
      if (isPaused) {
        this.isVideoPlaying = VIDEO_STATUS.PAUSE;
        this.videoPlayer?.play().then();
      }
    })
  }

  navigateExam() {
    this.router.navigate([`${this.prefixForm}/${this.formData.formId}/${this.formData.entryno}/exam`], { queryParams: { 'not_redirect': true } }).then()
  }

  protected readonly PREFIX_LIST = PREFIX_LIST;
}
