import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RuleValidations } from "@app/constants";
import { getFirstError, setValueOfDeepData } from "@app/helpers";

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss']
})
export class FileSelectComponent implements OnChanges {
  @Input() form: any
  @Input() submitted: boolean = false;
  showErrors: {[key: string]: boolean} = {}

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(this.form.controls).forEach((item: string) => {
      this.showErrors[item] = false
    })
  }

  changeShowErrors(...keys: any[]) {
    this.showErrors = setValueOfDeepData(this.showErrors, keys, true)
  }

  protected readonly RuleValidations = RuleValidations;
  protected readonly getFirstError = getFirstError;
}
