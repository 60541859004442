<app-layout-auth>
  <img id="logo" src="./assets/img/logo/3.png" alt="logo" />
  <h1>{{ 'forgot-password.title' | translate }}</h1>
  <form
    [formGroup]="formForgotPassword"
    (ngSubmit)="forgotPassword()"
    novalidate
  >
    <c-input-group
      [ngClass]="{
        'is-invalid': (submitted || f['email'].touched) && f['email'].invalid,
        'is-valid': f['email'].touched && f['email'].valid
      }"
      class="input-group__mail"
    >
      <input
        cFormControl
        [ngClass]="{
          'is-invalid': (submitted || f['email'].touched) && f['email'].invalid,
          'is-valid': f['email'].touched && f['email'].valid
        }"
        formControlName="email"
        id="email"
        type="email"
        autocomplete="email"
        [placeholder]="'forgot-password.email' | translate"
      />
    </c-input-group>
    <c-form-feedback *ngIf="(submitted || f['email'].touched) && f['email'].errors">
      <div *ngIf="f['email'].errors['required']">
        {{'errorMessages.email.required'|translate}}
      </div>
      <div *ngIf="f['email'].errors['email']">
        {{'errorMessages.email.email'|translate}}
      </div>
    </c-form-feedback>
    <button cButton [disabled]="loading" type="submit">
      {{ 'forgot-password.reset' | translate }}
    </button>
  </form>
</app-layout-auth>
