<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container mb-5">
    <c-col class="justify-content-center mb-5">
      <div class="d-flex justify-content-between align-items-center box-title">
        <div>
          <h3>{{'formData.export.title'|translate}}</h3>
        </div>
        <div class="box-btn-head">
          <app-export-button
            [btnTitle]="'formData.export.btnDownload' | translate"
            [isDisplayIconExport]="true"
            [isDisabledExportButton]="disabledExportButton && !formConfig.isFormDelete"
            [columns]="dataExport.columns"
            [dataExport]="dataExport.data"
            [fileName]="formConfig.data.title + '-'"
            [firebaseActionLogName]="ACTION_LIST.FORM_DATA.EXPORT"
            [messageExportSuccess]="'alertMessages.formData.export.success' | translate"
          ></app-export-button>
          <button class="btn-common btn-back" (click)="backToIndexForm()">
            {{ 'form.backButton' | translate }}
          </button>
        </div>
      </div>
    </c-col>
    <c-col class="content-page">
      <c-card class="card-no-border account-card">
        <div class="card-body">
          <form [formGroup]="formSearch" action="">
            <div class="d-flex justify-content-between">
              <label cLabel for="keywords">{{ 'form.listFormData.boxSearch.input.label'|translate }}</label>
              <span class="button-collapse" (click)="toggleSearchBox()">
                <img class="caret-down" [class]="{'caret-down-open': visibleSearchBox}" height="20" width="20" src="assets/svg/common/caret-down.svg" alt="">
              </span>
            </div>
            <div [visible]="visibleSearchBox" cCollapse>
              <c-input-group class="mb-3 box-input">
                <span cInputGroupText class="icon-input" id="">
                  <img src="assets/svg/list-form-data/search-normal.svg" alt="">
                </span>
                <input
                  aria-describedby=""
                  id="keywords"
                  aria-label=""
                  class="input-search"
                  formControlName="keyword"
                  cFormControl
                  placeholder="{{ 'form.listFormData.boxSearch.input.placeHolder'|translate }}"
                  (keypress)="searchByEnter($event)"
                />
              </c-input-group>
              <c-col class="d-flex date-picker-row">
                <c-col class="mb-6">
                  <c-col class="mb-3">{{ 'formData.export.inputLabel.startDate' |translate}}</c-col>
                  <c-col class="select-date select-export-date">
                    <c-date-picker
                      [locale]="currentDatepickerLocale"
                      placeholder="{{ 'form.placeholder.date' | translate}} "
                      formControlName="startDate"
                      [dayFormat]="dayFormat"
                    ></c-date-picker>
                  </c-col>
                </c-col>
                <c-col class="mb-6">
                  <c-col class="mb-3">{{ 'formData.export.inputLabel.startTime' |translate}}</c-col>
                  <c-col class="select-date select-export-date input-time-picker">
                    <c-time-picker placeholder="00:00" formControlName="startTime"></c-time-picker>
                  </c-col>
                </c-col>
              </c-col>
              <c-col class="d-flex date-picker-row mt-3">
                <c-col class="mb-6">
                  <c-col class="mb-3">{{ 'formData.export.inputLabel.endDate' |translate}}</c-col>
                  <c-col class="select-date select-export-date">
                    <c-date-picker
                      [locale]="currentDatepickerLocale"
                      placeholder="{{ 'form.placeholder.date' | translate }}"
                      formControlName="endDate"
                      [dayFormat]="dayFormat"
                    ></c-date-picker>
                  </c-col>
                </c-col>
                <c-col class="mb-6">
                  <c-col class="mb-3">{{ 'formData.export.inputLabel.endTime' |translate}}</c-col>
                  <c-col class="select-date select-export-date input-time-picker">
                    <c-time-picker  placeholder="23:59"formControlName="endTime"></c-time-picker>
                  </c-col>
                </c-col>
              </c-col>
              <c-col class="btn-search-col">
                <button class="btn-common" (click)="clearSearchData()">
                  <span>{{'formData.export.btnClearData'|translate}}</span>
                </button>
                <button class="button-search btn float-end btn-search" (click)="search()">
                  {{ 'form.listFormData.boxSearch.button'|translate }}
                </button>
              </c-col>
            </div>
          </form>
        </div>
      </c-card>
      <h6 class="mt-5 mb-5">{{formConfig.data.title ? formConfig.data.title : '' }}</h6>
      <c-card class="card-no-border account-card mt-5">
        <div class="list-pc">
          <table responsive borderless cAlign="middle"  class="table-data" cTable>
            <thead>
            <tr>
              <th scope="col">{{'form.listFormData.boxTable.column.no'|translate}}</th>
              <th scope="col" *ngFor="let columnName of dataTableTitle">{{ columnName | titlecase }} </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let formData of formDataList | paginate: { itemsPerPage: 10, currentPage: pageNumber }; let index = index">
              <td scope="row">{{ formatUnitNumber(index + 1) }}</td>
              <td  *ngFor="let key of dataShow" [class]="{'media-area': checkInputMedia(key)['isMedia']}">
                <div [ngSwitch]="key">
                  <div *ngSwitchCase="'name'">
                    <a class="link" (click)="redirectToDetailFormData(formData.entryno)">
                      <span
                        *ngIf="formData[key].replace(RuleValidations.removeAllDoubleQuoteString, '').length >= 15"
                        [cPopoverTrigger]='"hover"'
                        cPopoverPlacement="top"
                        [cPopover]="formData[key]"
                      >
                        {{ formData[key].replace(RuleValidations.removeAllDoubleQuoteString, '').substring(0, 15) + '...' }}
                        </span>
                      <span *ngIf="formData[key].length < 15">
                        {{ formData[key].replace(RuleValidations.removeAllDoubleQuoteString, '') }}
                      </span>
                    </a>
                  </div>
                  <span *ngSwitchCase="'members'">{{ formData[key]|transform }}{{ ('form.listFormData.boxTable.textExtend.person'|translate) }}</span>
                  <span *ngSwitchCase="'status'">{{ formData[key] | titlecase | mapTranslateText: 'status': formConfig.statusNode }}</span>
                  <span *ngSwitchCase="'carpark'" [class]="'parking-status-' + (formData[key] ? 'yes' : 'no')">
                    {{formData[key]  ? ('form.listFormData.boxTable.textExtend.parking.yes'|translate) : ('form.listFormData.boxTable.textExtend.parking.no'|translate) }}
                  </span>
                  <span *ngSwitchCase="'howtovisit'">
                    {{ formData[key] | titlecase | mapTranslateText: 'howToVisit': formConfig.howToVisitNode[0]['candidateData'] }}  
                  </span>
                  <div *ngSwitchCase="'userid'">
                    <span *ngIf="formData['userid']" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="formData['ownerFormData']?.length > 10 ? formData['ownerFormData'] : ''">
                      {{ formData['ownerFormData'] > 10 ? formData['ownerFormData'].substring(0, 10) + '...' : formData['ownerFormData'] }}
                    </span>
                    <span *ngIf="!formData['userid']">
                      {{'formUser.notLogin' | translate}}
                    </span>
                  </div>
                  <div *ngSwitchCase="'entryno'">
                    <span [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="formData[key]">
                      {{formData[key].replaceAll('"', '').length > 10 ? formData[key].replaceAll('"', '').substring(0, 10) + '...' : formData[key].replaceAll('"', '')}}
                    </span>
                  </div>
                  <div *ngSwitchDefault>
                    <span *ngIf="!formData[key]?.['isMedia'] && !checkInputMedia(key)['isMedia']">{{formData[key]}}</span>
                    <div *ngIf="checkInputMedia(key)['isMedia'] || formData[key]?.['isMedia']">
                      <div [class]="{
                          'box-media': true,
                          'box-media__contain': !!formData[key]?.['url']?.length
                        }">
                        <img
                          *ngIf="formData[key] && formData[key]?.['url'] && formData[key]?.['dataType'] == DataType.image"
                          class="image-content"
                          [src]="formData[key]?.['dataType'] == 'image' ? formData[key]?.['url'] : formData[key]"
                          alt="no image"
                        >
                        <video controls
                          *ngIf="(checkInputMedia(key)['dataType'] == 'movie' && formData[key]) || formData[key]?.['dataType'] == 'movie'"
                          width="100%"
                          height="100%">
                          <source [src]="formData[key]?.['dataType'] == 'movie' ? formData[key]?.['url'] : formData[key]" type="video/mp4">
                        </video>
                        <audio controls
                          *ngIf="(checkInputMedia(key)['dataType'] == 'sound' && formData[key]) || formData[key]?.['dataType'] == 'sound'"
                          class="audio-area">
                          <source [src]="formData[key]?.['dataType'] == 'sound' ? formData[key]?.['url'] : formData[key]">
                        </audio>
                        <a
                          *ngIf="checkInputMedia(key)['dataType'] == 'file' && formData[key]"
                          class="box-type-file"
                          [href]="formData[key]"
                          download='download.pdf'
                          target="_blank"
                        >
                          <img [src]="getFileIcon(formData.entryno + key)['image']" width="100px" height="100%">
                          <span>{{ getFileIcon(formData.entryno + key)['mimeText'] | titlecase }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </c-card>
      <div class="card-body list-mb">
        <div *ngIf="formDataList.length > 0">
          <c-card class="item-mb" *ngFor="let formData of formDataList | paginate: { itemsPerPage: 10, currentPage: pageNumber }; let index = index">
            <div class="item-row">
              <div class="w-50">{{'form.listFormData.boxTable.column.no'|translate}}</div>
              <div class="w-50">{{ formatUnitNumber(index + 1) }}</div>
            </div>
            <div class="item-row"  *ngFor="let key of dataShow;let index = index" >
              <div class="w-50">{{dataTableTitle[index]|titlecase}}</div>
              <div class="w-50">
                <div [ngSwitch]="key">
                  <div *ngSwitchCase="'name'">
                    <a class="link" routerLink="/form/{{formData.formId}}/form-data-edit/{{formData.entryno}}" [queryParams]="{'type': 'export'}" >
                      <span
                        *ngIf="formData[key].replace(RuleValidations.removeAllDoubleQuoteString, '').length >= 10"
                        [cPopoverTrigger]='"hover"'
                        cPopoverPlacement="top"
                        [cPopover]="formData[key]"
                      >
                        {{ formData[key].replace(RuleValidations.removeAllDoubleQuoteString, '').substring(0, 10) + '...' }}
                      </span>
                      <span *ngIf="formData[key].length < 10">
                        {{ formData[key].replace(RuleValidations.removeAllDoubleQuoteString, '') }}
                      </span>
                    </a>
                  </div>
                  <span *ngSwitchCase="'members'">
                    {{ formData[key]|transform }}{{ ('form.listFormData.boxTable.textExtend.person'|translate) }}
                  </span>
                  <span *ngSwitchCase="'status'">
                    {{ formData[key] | titlecase | mapTranslateText: 'status': formConfig.statusNode }}
                  </span>
                  <span *ngSwitchCase="'howtovisit'">
                    {{ formData[key] | titlecase | mapTranslateText: 'howToVisit': formConfig.howToVisitNode[0]['candidateData'] }}
                  </span>
                  <div *ngSwitchCase="'carpark'" [class]="'parking-status-' + (formData[key] ? 'yes' : 'no')">
                    {{ formData[key] === 'yes' ? ('form.listFormData.boxTable.textExtend.parking.yes'|translate) : ('form.listFormData.boxTable.textExtend.parking.no'|translate) }}
                  </div>
                  <div *ngSwitchCase="'userid'">
                    <span [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="formData['ownerFormData']?.length > 10 ? formData['ownerFormData'] : ''">
                      {{ formData['ownerFormData'] > 10 ? formData['ownerFormData'].substring(0, 10) + '...' : formData['ownerFormData'] }}
                    </span>
                  </div>
                  <div *ngSwitchCase="'entryno'">
                    <span>{{formData[key].replaceAll('"', '')}}</span>
                  </div>
                  <div *ngSwitchDefault>
                    <div *ngIf="!formData[key]?.['isMedia'] && !checkInputMedia(key)['isMedia']">
                      {{ formData[key] }}
                    </div>
                    <div *ngIf="checkInputMedia(key)['isMedia'] || formData[key]?.['isMedia']">
                      <div [class]="{
                        'box-media': true,
                        'box-media__contain': !!formData[key]?.['url']?.length
                      }">
                        <img
                          *ngIf="formData[key] && formData[key]?.['url'] && formData[key]?.['dataType'] == DataType.image"
                          [src]="formData[key]?.['dataType'] == 'image' ? formData[key]['url'] : formData[key]"
                          class="image-content"
                          alt=""
                        >
                        <video controls
                          *ngIf="(checkInputMedia(key)['dataType'] == 'movie' && formData[key]) || formData[key]?.['dataType'] == 'movie'"
                          width="100%"
                          height="100%">
                          <source [src]="formData[key]?.['dataType'] == 'movie' ? formData[key]?.['url'] : formData[key]" type="video/mp4">
                        </video>
                        <audio controls
                          *ngIf="(checkInputMedia(key)['dataType'] == 'sound' && formData[key]) || formData[key]?.['dataType'] == 'sound'"
                          class="audio-area">
                          <source [src]="formData[key]?.['dataType'] == 'sound' ? formData[key]?.['url'] : formData[key]">
                        </audio>
                        <a *ngIf="checkInputMedia(key)['dataType'] == 'file' && formData[key]" class="box-type-file" [href]="formData[key]" download='download.pdf' target="_blank">
                          <img [src]="getFileIcon(formData.entryno + key)['image']" width="100px" height="100%">
                          <span>{{ getFileIcon(formData.entryno + key)['mimeText'] | titlecase }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </c-card>
        </div>
      </div>
    </c-col>
  </div>
  <c-col class="justify-content-center d-flex">
    <pagination-controls (pageChange)="pageNumber = $event" previousLabel="" nextLabel="" [autoHide]="true"></pagination-controls>
  </c-col>
</c-container>
