import { Component, Input, OnInit } from '@angular/core';
import { ngxLoadingAnimationTypes } from "ngx-loading";

@Component({
  selector: 'app-loading-block',
  templateUrl: './loading-block.component.html',
  styleUrls: ['./loading-block.component.scss']
})
export class LoadingBlockComponent implements OnInit {
  @Input() isLoading: boolean = false;
  protected readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  protected loadingConfig: any = {};
  constructor() { }

  ngOnInit(): void {
    this.generateLoadingConfig();
  }

  generateLoadingConfig() {
    this.loadingConfig = {
      animationType: ngxLoadingAnimationTypes.circleSwish,
      primaryColour: '#FEB273',
      backdropBorderRadius: '0.375rem',
      backdropBackgroundColour: 'white'
    };
  }
}
