import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormService, UserService } from '@app/services';
import { checkAccountRestrictMode } from './common';
import { IAccountRestrict } from "@app/models";
import { ACCOUNT_PAGE_RESTRICT } from '@app/constants';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountRestrictGuard implements CanActivate {
  constructor(
    private router: Router,
    private formService: FormService,
    private userService: UserService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pageName = route?.routeConfig?.data?.['page'];
    const authUser: any = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      const currentUser: any = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (currentUser) {
        const forms = await firstValueFrom(this.formService.getAll(currentUser.uid));
        const childAccounts = await firstValueFrom(this.userService.getChildUsers());
        await Promise.all([forms, childAccounts]).then((values) => {
          const totalDataForms = values[0].length;
          const totalDataChildAccounts = values[1].length;
          const accountRestrictConfig: IAccountRestrict = checkAccountRestrictMode(currentUser, { form: totalDataForms, childAccount: totalDataChildAccounts });
          if ((accountRestrictConfig.isDisabledCreateForm && pageName === ACCOUNT_PAGE_RESTRICT.pageFormCreate)
            || (!accountRestrictConfig.isDisplayTemplateList && ACCOUNT_PAGE_RESTRICT.pageTemplate.includes(pageName))
            || (!accountRestrictConfig.isDisplayCameraSetting && ACCOUNT_PAGE_RESTRICT.pageCamera.includes(pageName))
            || (!accountRestrictConfig.isDisplayChildAccountList && ACCOUNT_PAGE_RESTRICT.pageChildAccount.includes(pageName))
            || (accountRestrictConfig.isDisabledCreateChildAccount && pageName === 'child_account_create')) {
            this.router.navigate(['/404'], { queryParams: { returnUrl: state.url } }).then(r => { });
          }
        })
      }
    }
    return true;
  }
}
