<div class="d-flex button-block" *ngIf="!user.owner && currentEmail == user.email">
  <div class="button-block button-items" *ngIf="user.status == MemberJoinStatus.pending">
    <div class="pc-items">
      <button class="btn-common align-items-center" (click)="join()">
        {{ 'groups.memberAction.join' | translate }}
      </button>
      <button class="btn-second" (click)="delete()">
        {{ 'groups.memberAction.decline' | translate }}
      </button>
    </div>
    <div class="mb-items">
      <c-dropdown>
        <button cDropdownToggle class="btn-common align-items-center btn-dropdown">
          {{ 'groups.memberAction.action' | translate }}
        </button>
        <ul cDropdownMenu>
          <li (click)="join()"><a [routerLink]="[]" cDropdownItem>{{ 'groups.memberAction.join' | translate }}</a></li>
          <li (click)="delete()"><a [routerLink]="[]" cDropdownItem>{{ 'groups.memberAction.decline' | translate }}</a></li>
        </ul>
      </c-dropdown>
    </div>
  </div>
  <div class="d-flex button-block" *ngIf="user.status == MemberJoinStatus.accepted">
    <button [cModalToggle]="leaveGroup.id" class="btn-second me-3 align-items-center">
      {{ 'groups.memberAction.leave.button' | translate }}
    </button>

    <c-modal #leaveGroup alignment="center" [id]="'leaveGroup' + group.path">
      <c-modal-header>
        <h5 cModalTitle>{{ group.name }}</h5>
        <button [cModalToggle]="leaveGroup.id" cButtonClose></button>
      </c-modal-header>
      <c-modal-body>
        {{ 'groups.memberAction.leave.text' | translate }}
      </c-modal-body>
      <c-modal-footer>
        <button [cModalToggle]="leaveGroup.id" class="btn-second">
          {{ 'groups.memberAction.leave.no' | translate }}
        </button>
        <button class="btn-common" (click)="delete(true)">
          {{ 'groups.memberAction.leave.yes' | translate }}
        </button>
      </c-modal-footer>
    </c-modal>
  </div>
</div>
