// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {keyValueString} from "@app/models";

export const environment = {
  production: false,
  firebaseConfig: {
  apiKey: "AIzaSyA6BwMBES-itUAFalz9nUP_JgEu1k5lg_M",
  authDomain: "tabuchi-forms.firebaseapp.com",
  projectId: "tabuchi-forms",
  storageBucket: "tabuchi-forms.appspot.com",
  messagingSenderId: "6682169637",
  appId: "1:6682169637:web:62d91df047e6f0db278acc",
  measurementId: "G-Q97JNS1XEL"
  },
  publicStorageBucket: "gs://public-tabuchi-forms",
  openAiApiUrl: 'https://api.openai.com/v1',
  translateTextApiUrl: 'https://translation.googleapis.com/language/translate/v2',
  translateTextApiKey: 'AIzaSyCz2mEz9GtjO-MHy5AHN_CYZFpq0dXJ4sY',
  register_url_stripe: "",
  register_url_stripe_subscription: "",
  baseUrl: 'https://us-central1-tabuchi-forms.cloudfunctions.net/api',
  url_notification:"https://fcm.googleapis.com/fcm/send",
  key_notification:"key=AAAAlKvNBFA:APA91bE9cikuriHO_AsJslsUzW4xyjpJJegfFYJQtS1rkBLQlN2G7ThAh8kfUX1jH7F3fYz7AIelIdHHN2gbsNXs21L8KC38lPFdKliyfxJ_UuT5tWUlzlqSC70exeT5SsFX-o_bge3U",
  api_address: 'https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress',
  api_search_map:'https://msearch.gsi.go.jp/address-search/AddressSearch',
  api_search_zip_code: 'https://zipcloud.ibsnet.co.jp/api/search',
  service_name: {
    ja: '株式会社タブチ エフ１受講管理システム ',
    en: 'Tabuchi Attendance Management System'
  } as keyValueString,
  html_title: "株式会社タブチ エフ１ 受講管理システム",
  sing_up_link: false,
  no_login_link: false,
  purchase_link: false,
  google_login: false,
  apple_login: false,
  delete_account_link: false,
  support_message: {
    ja: 'noreply@pacenotes.io までメールで問い合わせください。',
    en: 'Please email us at noreply@pacenotes.io'
  } as keyValueString,
  title_image_url: "./assets/svg/logo-login.svg",
  default_domain: "tbc.pacenotes.io",
  environment_develop: "staging"
};
