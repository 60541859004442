<form cForm [formGroup]="form">
  <div class="mb-3">
    <label cLabel for="titleJa">{{'template.formItemsEdit.inputLabel.titleJa' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl formControlName="title_ja" id="titleJa" type="text" (input)="changeShowErrors('title_ja')">
    <c-form-check *ngIf="(showErrors['title_ja'] || submitted) && form.get('title_ja')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('title_ja')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="titleEn">{{'template.formItemsEdit.inputLabel.titleEng' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl formControlName="title_eng" id="titleEn" type="text" (input)="changeShowErrors('title_eng')">
    <c-form-check *ngIf="(showErrors['title_eng'] || submitted) && form.get('title_eng')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('title_eng')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="name">{{'template.formItemsEdit.inputLabel.name' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl formControlName="name" id="name" type="text" (input)="changeShowErrors('name')">
    <c-form-check *ngIf="(showErrors['name'] || submitted) && form.get('name')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('name')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="placeholderJa">{{'template.formItemsEdit.inputLabel.placeholderJa' | translate}}</label>
    <input cFormControl formControlName="placeholder_ja" id="placeholderJa" type="text" (input)="changeShowErrors('placeholder_ja')">
    <c-form-check *ngIf="(showErrors['placeholder_ja'] || submitted) && form.get('placeholder_ja')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('placeholder_ja')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="placeholderEn">{{'template.formItemsEdit.inputLabel.placeholderEng' | translate}}</label>
    <input cFormControl formControlName="placeholder_eng" id="placeholderEn" type="text" (input)="changeShowErrors('placeholder_eng')">
    <c-form-check *ngIf="(showErrors['placeholder_eng'] || submitted) && form.get('placeholder_eng')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('placeholder_eng')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="staticDatatype">{{'template.formItemsEdit.inputLabel.type' | translate}}</label>
    </c-col>
    <c-col>
      <input cFormControl formControlName="datatype" id="staticDatatype" plaintext readonly type="text"/>
    </c-col>
  </c-row>

  <div *ngIf="form.get('datatype')?.value !== INPUT_TYPE.map" formArrayName="validation">
    <div class="mb-3">
      <label cLabel>{{'template.formItemsEdit.inputLabel.minLength' | translate }} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
      <input cFormControl [formControlName]="2" id="minlength" type="number" (input)="changeShowErrors('validation')">
      <c-form-check *ngIf="(showErrors['validation'][2] || submitted) && form.get('validation')?.at(2)?.errors" class="text-danger">
        {{ "errorMessages.template.objectValidate." + getFirstError(form.get('validation')?.at(2)?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
      </c-form-check>
    </div>
    <div class="mb-3">
      <label cLabel>{{'template.formItemsEdit.inputLabel.maxLength' | translate }} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
      <input cFormControl [formControlName]="3" id="maxLength" type="number" (input)="changeShowErrors('validation')">
      <c-form-check *ngIf="(showErrors['validation'][3] || submitted) && form.get('validation')?.at(3)?.errors" class="text-danger">
        {{ "errorMessages.template.objectValidate." + getFirstError(form.get('validation')?.at(3)?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
      </c-form-check>
    </div>
  </div>

  <div class="mb-3" *ngIf="form.get('datatype')?.value === INPUT_TYPE.zip">
    <label cLabel for="regex">{{'template.formItemsEdit.inputLabel.regex' | translate}}</label>
    <select id="regex" formControlName="validationCondition" aria-label="Default select example" cSelect>
      <option [value]="null">{{'template.formItemsEdit.inputLabel.none' | translate }}</option>
      <option value="zip">zip</option>
    </select>
  </div>

  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="required">{{'template.formItemsEdit.inputLabel.required' | translate}}</label>
    </c-col>
    <c-col>
      <c-form-check>
        <input cFormCheckInput formControlName="required" id="required" type="checkbox"/>
      </c-form-check>
    </c-col>
  </c-row>
  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="readonly">{{'template.formItemsEdit.inputLabel.readonly' | translate}}</label>
    </c-col>
    <c-col>
      <c-form-check>
        <input cFormCheckInput formControlName="readonly" id="readonly" type="checkbox"/>
      </c-form-check>
    </c-col>
  </c-row>
  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="hidden">{{'template.formItemsEdit.inputLabel.hidden' | translate}}</label>
    </c-col>
    <c-col>
      <c-form-check>
        <input cFormCheckInput formControlName="hidden" id="hidden" type="checkbox"/>
      </c-form-check>
    </c-col>
  </c-row>
</form>
