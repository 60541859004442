<form [formGroup]="form">
  <label cLabel>{{titleInput}} <c-badge color="danger" *ngIf="isRequired">{{'form.inputLabel.must'|translate}}</c-badge></label>
  <c-input-group>
    <label class="box-input-file" [for]="id">
      <span >
        {{ 'common.customInputFile.chooseFile' | translate }}
      </span>
      <span id="previewFileName">
        {{ 'common.customInputFile.defaultTextInput' | translate }}
      </span>
      <input type="file" hidden="hidden" cFormControl
        [id]="id"
        [formControlName]="formControlName"
        (change)="handleFile($event)"
      >
    </label>
  </c-input-group>
  <c-form-check class="text-danger" *ngIf="showErrorMessages">{{errorMessage}}</c-form-check>
</form>
