import { environment } from "@environments/environment";

export const text = {
  defaultDomain: environment.default_domain,
  defaultCopyRight: "Copyright 2023",
  defaultStringCodeForm: "#242424",
  defaultBackgroundCode: "#fff9f2",
  baseColor: "#FB6514",
  defaultFormPrefix: "form-guest"
}

export const PREFIX_LIST = {
  pluginTbc: "plugin-tbc",
  pluginAnalogMeter: "plugin-analogmeter",
  pluginAttendance: "plugin-attendance",
  pluginNaruo: 'plugin-naruo',
  pluginWhisper: 'plugin-whisper',
  pluginChatGpt: 'plugin-chatgpt',
  pluginDalle: 'plugin-dalle'
}

export const MAX_WIDTH_MOBILE_DEVICES = 576;

export const supportLanguage = ['en', 'ja'];
export const NEW_LINE_CELL = "\n";
export const DATA_NONE = '-none-';
export const allowedKeysOnLyNumber = ['.', '+', '-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const DEFAULT_MAP_POSITION = {
  lat: 34.685211,
  lng: 135.525718
}
export const DEFAULT_MAP_ADDRESS = '大阪城'
export const KEYCODE_ENTER = 13;
export const KEYCODE = {
  unitSeparator: 31,
  zero: 48,
  nine: 57,
  dot: 46,
  negative: 45
}

export const DEFAULT_EMAIL_REGISTER = {
  title: 'シンシン フォーム: サインアップありがとうございました',
  body: '<p>シンシン フォームサービスにサインアップをありがとうございます。</p>' +
    '<p>ログインは <a href="https://synon-forms.web.app/#/login" target="_blank"> こちら </a> のアドレスからご利用ください。</p>' +
    '<p>シンシン フォーム サポートチーム</p>'
}

export const TYPE_EMAIL = {
  register: 1
}

export const VARIABLES_EMAIL_REGISTER = [
  "name",
  "email",
  "organizationName",
  "memberCode",
]

export const VARIABLES_PORTAL = [
  "name",
  "email",
  "organizationName",
  "memberCode",
]

export const DEFAULT_LANGUAGE_EMAIL: { key: string; value: number }[] = [
  { key: 'japanese', value: 1 },
  { key: 'english', value: 2 },
];

export const VIDEO_STATUS = {
  PLAY: 'play',
  PAUSE: 'pause'
}

export const MAX_LENGTH_TEXT_DISPLAY = 25

export const EXTEND_ROUTE_ACTIVE = {
  ACCOUNT: /\/groups|\/tabs|\/account|\/portal\/edit|\/list-template|\/list-account/,
  FORM: /\/form(?!-items-edit)/,
}

export const TEXT_HOW_TO_VISIT = 'howtovisit';
export const DATA_EXAM_MIN_INDEX = 1;
export const WATCH_VIDEO_AGAIN_URL = 'https://www.tabuchi.co.jp/product/relation/f1/';
export const ADDRESS_ZIPCODE_SHOW_LIMIT = 4;
export const ZIP_CODE_ADDRESS_LIMIT = 100;
export const COLUMNS_EXPORT_FORM_TBC = ['customercode', 'passingDate']
export const LIMIT_GENERATE_IMAGES_OPEN_AI = 10;
export const CHAT_COMPLETION_MODEL = {
  gpt3Turbo: {
    name: 'gpt-3.5-turbo',
    limitTokens: 4097,
  },
  gpt4: {
    name: 'gpt-4',
    limitTokens: 8192,
  },
}
export enum ChatCompletionErrorCode {
  invalidApiKey = 'invalid_api_key',
  tokenExceed = 'context_length_exceeded'
}
export enum KeyCode {
  Backspace = 8,
  ArrowLeft = 37,
  ArrowRight = 39,
  Delete = 46
}
export const SOUND_FILE_TYPE = 'audio/mpeg';

// Input: file content type, Output: file icon
export const OTHER_FILE_CONTENT_TYPE = <any>{
  "application/vnd.ms-powerpoint": "/assets/svg/common/ppt-file.svg",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "/assets/svg/common/ppt-file.svg",
  "text/csv": "/assets/svg/common/excel-file.svg",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "/assets/svg/common/excel-file.svg",
  "application/vnd.ms-excel": "/assets/svg/common/excel-file.svg",
  "application/pdf": "/assets/svg/common/pdf-file.svg",
  "application/msword": "/assets/svg/common/word-file.svg",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "/assets/svg/common/word-file.svg",
  "application/x-7z-compressed": "/assets/svg/common/zip-file.svg",
  "application/vnd.rar": "/assets/svg/common/zip-file.svg",
  "application/x-bzip": "/assets/svg/common/zip-file.svg",
  "application/x-bzip2": "/assets/svg/common/zip-file.svg",
  "application/gzip": "/assets/svg/common/zip-file.svg",
  "application/zip": "/assets/svg/common/zip-file.svg",
  "file": "/assets/svg/common/file.svg"
}
