import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { environment } from "@environments/environment";
import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { AlertService } from "./alert.service";
import { TranslateService } from "@ngx-translate/core";
import { IApiConfig } from "@app/models";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = environment.baseUrl;

  constructor(
    public angularFireAuth: AngularFireAuth,
    private http: HttpClient,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  async getClientHeader() {
    const currentUser = firebase.auth().currentUser;
    let idToken = '';
    await currentUser?.getIdToken().then((res) => idToken = res);
    const headers = {
      headers: { authorization: `Bearer ${idToken}` }
    };
    return headers;
  }

  async get(url: string = '',
    config: IApiConfig = { header: {}, isThirdParty: false, errorMessageKey: '' }) {
    if (!config.isThirdParty) config.header = await this.getClientHeader();
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, config.header).subscribe({
        next: (data: any) => resolve(data),
        error: error => reject(error)
      });
    }).catch(() => {
      if (config.errorMessageKey && config.errorMessageKey.length) 
        this.alertService.error(this.translateService.instant(config.errorMessageKey))
    });
  }

  async post(url: string, data: any, config: IApiConfig = {header: {}, isThirdParty: false}) {
    if (!config.isThirdParty) config.header = await this.getClientHeader();
    return new Promise<void>((resolve, reject) => {
      this.http.post(url, data, config.header).subscribe({
        next: (data: any) => resolve(data),
        error: error => reject(error)
      });
    });
  }
  
  async patch(path: string = '', data: any) {
    const headers = await this.getClientHeader();
    return new Promise<void>((resolve, reject) => {
      this.http.patch(`${this.baseUrl}/${path}`, data, headers).subscribe({
        next: (data: any) => resolve(data),
        error: error => reject(error)
      });
    });
  }
}
