<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-row class="mb-3">
      <c-col class="justify-content-center">
        <p class="title">{{ creditCardInfoText.creditCardInfo }}</p>
      </c-col>
      <c-col class="text-link">
        <button class="btn-common">
          <img src="assets/svg/form-cloud/icon-add.svg" alt="" />
          {{ creditCardInfoText.registerCreditCard }}
        </button>
      </c-col>
    </c-row>
    <c-card class="card-no-border account-card">
      <c-card-body class="account-detail">
        <c-row class="account-detail__row">
          <c-col>
            <div class="card-info">****-****-****-1011</div>
            <div class="card-info">有効期限: 22/04</div>
          </c-col>
          <c-col class="text-link">
            <button class="btn-delete-card" [cModalToggle]="modalDeleteCreditCard.id">
              <img src="assets/svg/trash.svg" alt="" />
              <span class="ms-2 delete-btn">{{ creditCardInfoText.deleteCreditCard }}</span>
            </button>
          </c-col>
        </c-row>
        <c-row class="account-detail__row">
          <c-col>
            <div class="card-info">****-****-****-1011</div>
            <div class="card-info">有効期限: 22/04</div>
          </c-col>
          <c-col class="text-link">
            <button class="btn-delete-card" [cModalToggle]="modalDeleteCreditCard.id">
              <img src="assets/svg/trash.svg" alt="" />
              <span class="ms-2 delete-btn">{{ creditCardInfoText.deleteCreditCard }}</span>
            </button>
          </c-col>
        </c-row>
        <c-row class="account-detail__row">
          <c-col>
            <div class="card-info">****-****-****-1011</div>
            <div class="card-info">有効期限: 22/04</div>
          </c-col>
          <c-col class="text-link">
            <button class="btn-delete-card" [cModalToggle]="modalDeleteCreditCard.id">
              <img src="assets/svg/trash.svg" alt="" />
              <span class="ms-2 delete-btn">{{ creditCardInfoText.deleteCreditCard }}</span>
            </button>
          </c-col>
        </c-row>
      </c-card-body>
      <c-modal #modalDeleteCreditCard backdrop="static" alignment="center" id="modalDeleteCreditCard" class="delete-modal" size="sm">
        <c-modal-header class="delete-modal__header">
          <p cModalTitle class="delete-modal__title">{{ creditCardInfoText.askDeleteCreditCard }}</p>
          <button [cModalToggle]="modalDeleteCreditCard.id" cButtonClose></button>
        </c-modal-header>
        <c-modal-footer class="delete-modal__footer">
          <button [cModalToggle]="modalDeleteCreditCard.id" cButton color="secondary">
            {{ formText.cancel }}
          </button>
          <button cButton color="primary">
            {{ formText.delete }}
          </button>
        </c-modal-footer>
      </c-modal>
    </c-card>
  </div>
</c-container>
