import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BAR_CHART_BORDER_WIDTH, LAYOUT_BACKGROUND_COLOR_LIST, LAYOUT_BORDER_COLOR_LIST, LimitTextDisplay } from '@app/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bar-chart-form-data',
  templateUrl: './bar-chart-form-data.component.html',
  styleUrls: ['./bar-chart-form-data.component.scss']
})
export class BarChartFormDataComponent implements OnChanges {
  @Input() listFormData: any;
  dataBarChart: any = {
    labels: [],
    datasets: [],
  };
  optionsBarChart = {};
  constructor(
    private translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateChartOptions();
    this.generateChartData();
  }

  generateChartOptions() {
    this.optionsBarChart = {
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 60
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (chart: any) => chart[0].label.length > LimitTextDisplay.barTooltipName
              ? chart[0].label.substring(0, LimitTextDisplay.barTooltipName) + '...'
              : chart[0].label,
            afterTitle: (tooltipItem: any) => {
              const index = tooltipItem[0].dataIndex;
              const date = `${this.translateService.instant('form.layout.dataDisplay.date')}: ${this.listFormData[index].date ?? ''}`;
              const time = `${this.translateService.instant('form.layout.dataDisplay.time')}: ${this.listFormData[index].time ?? ''}`;
              const value = `${this.translateService.instant('form.layout.dataDisplay.value')}: ${this.listFormData[index].value}`;
              return [date, time, value]
            },
            label: () => '',
            labelColor: () => {
              return { backgroundColor: 'rgb(76, 65, 67)' };
            },
          }
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy'
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true
            },
            mode: 'xy',
          }
        }
      }
    }
  }

  generateChartData() {
    this.dataBarChart = {
      labels: this.listFormData.map((formData: any) => formData.name.length > LimitTextDisplay.barLabelName
        ? formData.name.substring(0, LimitTextDisplay.barLabelName) + '...'
        : formData.name),
      datasets: [
        {
          label: '',
          data: this.listFormData.map((item: any) => item.value),
          backgroundColor: LAYOUT_BACKGROUND_COLOR_LIST,
          borderColor: LAYOUT_BORDER_COLOR_LIST,
          borderWidth: BAR_CHART_BORDER_WIDTH
        }
      ],
    }
  }
}
