import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getFirstError } from "@app/helpers";
import { INPUT_TYPE, RuleValidations } from "@app/constants";

@Component({
  selector: 'app-map-location-zip-code',
  templateUrl: './map-location-zip-code.component.html',
  styleUrls: ['./map-location-zip-code.component.scss']
})
export class MapLocationZipCodeComponent implements OnChanges {
  @Input() form: any
  @Input() submitted: boolean = false;
  showErrors: {[key: string | number]: any} = {}
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(this.form.controls).forEach((item: string) => {
      this.showErrors[item] = false
    })
  }

  changeShowErrors(key: string) {
    this.showErrors[key] = true
  }

  protected readonly getFirstError = getFirstError;
  protected readonly RuleValidations = RuleValidations;
  protected readonly INPUT_TYPE = INPUT_TYPE;
}
