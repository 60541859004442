import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from "@app/constants";
import {getFirstError} from "@app/helpers";
import { AlertService, FirebaseAnalyticService, GroupService } from "@app/services";
import {Group} from "@app/models/group";
import {TranslateService} from "@ngx-translate/core";
import { CustomValidatorsService } from '@app/services/custom-validators.service';

@Component({
  selector: 'app-create-group',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  @ViewChild('createGroup') createGroup: any;
  protected readonly getFirstError = getFirstError;
  readonly ruleValidations = RuleValidations;
  submitted = false;
  formCreateGroup = new FormGroup({
    name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(this.ruleValidations.maxLength64), CustomValidatorsService.notAllowedOnlySpace])
  });
  constructor(
    private groupService: GroupService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private firebaseAnalyticService: FirebaseAnalyticService
  ) { }

  ngOnInit(): void {
  }

  create() {
    this.submitted = true;
    if (this.formCreateGroup.valid) {
      this.groupService.create(this.formCreateGroup.value as Group).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.CREATE, EVENT_LOG_TYPE.SUCCESS)
        this.createGroup.visible = false
        this.alertService.success(this.translateService.instant('alertMessages.group.create.success'));
      }).catch((errors) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.CREATE, EVENT_LOG_TYPE.ERROR)
        this.createGroup.visible = false
        this.alertService.error(this.translateService.instant('alertMessages.group.create.fail'));
      })
    }
  }

  visibleChange(e: any) {
    if (!e) {
      this.formCreateGroup.controls.name.setValue('')
      this.submitted = false
    }
  }
}
