import { Component, Input, OnInit } from '@angular/core';
import { ACTION_LIST, EVENT_LOG_TYPE, MemberJoinStatus } from "@app/constants";
import { AlertService, FirebaseAnalyticService, GroupService, NotificationService } from "@app/services";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-owner-group',
  templateUrl: './owner-group.component.html',
  styleUrls: ['./owner-group.component.scss']
})
export class OwnerGroupComponent implements OnInit {
  @Input() user: any
  @Input() users: any
  @Input() group: any
  protected readonly MemberJoinStatus = MemberJoinStatus;
  constructor(
    private groupService: GroupService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private firebaseAnalyticService: FirebaseAnalyticService
  ) { }

  ngOnInit(): void {
  }

  async delete(useAlert: boolean = false) {
    const userDelete = this.group.groupUsers.map((user: any) => {
      for (const key in this.users) {
        if (this.users[key].email === user.email) {
          user.active = this.users[key]?.active;
        }
      }
      return user
    }).filter((user: any) => user.email == this.user.email)[0]
    userDelete.tokens = this.users.filter((user: any) => user.email == this.user.email)[0]?.tokens
    await this.groupService.deleteUserFromGroup(this.group, userDelete).then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.REMOVE_USER_FROM_GROUP, EVENT_LOG_TYPE.SUCCESS);
      if (useAlert) {
        if (userDelete.active) {
          this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_NOTIFICATION.REMOVE_USER_FROM_GROUP, EVENT_LOG_TYPE.SUCCESS);
          this.notificationService.sendNoticeGroup([userDelete], 'deleteUser', {groupName: this.group.name});
        }
        this.alertService.success(this.translateService.instant('alertMessages.group.remove.success', { email: this.user.email }));
      }
    }).catch((error) => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.REMOVE_USER_FROM_GROUP, EVENT_LOG_TYPE.ERROR);
      if (useAlert) {
        this.alertService.error(this.translateService.instant('alertMessages.group.remove.fail'));
      }
    })
  }
}
