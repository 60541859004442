import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService, UserService, AlertService } from '@app/services';
import { INotification } from '@app/models';
import { Subject, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { getDataNotice } from "@app/helpers";
import { NOW } from '@app/constants';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-notify-popup',
  templateUrl: './notify-popup.component.html',
  styleUrls: ['./notify-popup.component.scss']
})

export class NotifyPopupComponent implements OnInit, OnDestroy {
  private isNewMessage: boolean = false;
  private countResponse: number = 0;
  notifications: INotification[] = [];
  isHasUnreadMessage = new Subject<boolean>();
  isShow = false;
  currentLang: string = ''
  textNow: string = NOW;
  notifySubscription: Subscription | undefined;
  notifyEventSubscription: Subscription | undefined;

  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.getCurrentUser().then();
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = e.lang
    })
  }

  ngOnDestroy(): void {
    this.notifySubscription?.unsubscribe();
    this.notifyEventSubscription?.unsubscribe();
  }

  private async getCurrentUser() {
    const authUser = this.userService.getCurrentUserAuth();
    if (authUser && authUser.email) {
      await this.getNotification(authUser.email, authUser.uid);
    }
  }

  private async getNotification(email: string, id: string) {
    this.notifySubscription = this.notificationService.getNotification(email, id).subscribe((response) => {
      const countResponse = new Promise((resolve, reject) => {
        this.notifications = response.map(a => {
          const data = a.payload.doc.data() as INotification;
          data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
          this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
          });
          return data;
        });
        this.isHasUnreadMessage.next(this.notifications.some(notification => !notification.isRead));
        resolve(true);
      });
      countResponse.then()
    })
    this.notifyEventSubscription = this.notificationService.getNotificationEvent(email, id).subscribe((data) => {
      const alert = new Promise((resolve, reject) => {
        const notices = data.map(a => {
          const data = a.payload.doc.data() as INotification;
          if (data.createdAt instanceof Timestamp) data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
          this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            if (data.createdAt) data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
          });
          return data;
        });
        const haveNoticeUnread = Boolean(notices.filter(item => !item.isRead).length);
        if (haveNoticeUnread && this.countResponse != data.length && this.isNewMessage) {
          this.showAlert(this.translateService.instant('notification.youHaveNewNotify'));
        }
        resolve('');
      });
      alert.then(() => {
        this.countResponse = data.length;
        this.isNewMessage = true;
      })
    })
  }

  private showAlert(msg: string) {
    this.alertService.clear();
    this.alertService.success(msg);
  }

  toggle() {
    this.isShow = !this.isShow;
  }

  handleNavigate() {
    this.isShow = false;
  }

  protected readonly getDataNotice = getDataNotice;
}
