import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getFirstError, setValueOfDeepData } from "@app/helpers";
import { RuleValidations, TYPE_INPUT } from "@app/constants";

@Component({
  selector: 'app-unique-line',
  templateUrl: './unique-line.component.html',
  styleUrls: ['./unique-line.component.scss']
})
export class UniqueLineComponent implements OnChanges {
  @Input() form: any;
  @Input() submitted: boolean = false;
  showErrors: {[key: string | number]: any} = {}
  protected readonly getFirstError = getFirstError;
  protected readonly TYPE_INPUT = TYPE_INPUT;
  protected readonly RuleValidations = RuleValidations;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  changeShowErrors(...keys: any[]) {
    this.showErrors = setValueOfDeepData(this.showErrors, keys, true)
  }
}
