import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RuleValidations } from '@app/constants';
import { checkStringContainsHTMLTags } from '@app/helpers';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {

  constructor() { }

  static notAllowedOnlySpace(control: AbstractControl): ValidationErrors | null {
    if (typeof control.value === 'string' && !control.value?.replace(RuleValidations.regexOnlySpace, '').length) {
      return { required: true };
    }
    return null;
  }

  static minLengthHtml(minLength: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (typeof control.value === 'string' && checkStringContainsHTMLTags(control.value)) {
        const div = document.createElement('div');
        div.innerHTML = control.value;
        const value = div.textContent || div.innerText || "";
        if (value.length < minLength) return { minlength: true };
      }
      return null;
    }
  }

  static maxLengthHtml(maxLength: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (typeof control.value === 'string' && checkStringContainsHTMLTags(control.value)) {
        const div = document.createElement('div');
        div.innerHTML = control.value;
        const value = div.textContent || div.innerText || "";
        if (value.length > maxLength) return { maxlength: true };
      }
      return null;
    }
  }
}
