import { Component } from '@angular/core';
import { AlertService, UserService, FirebaseAnalyticService, NotificationService } from "@app/services";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { createMask } from '@ngneat/input-mask';
import { TranslateService } from '@ngx-translate/core';
import { EVENT_LOG_TYPE, RuleValidations, ACTION_LIST, ACCOUNT_DEFAULT_RESTRICT, AccountStatus, TokenAction } from '@app/constants';
import { checkAccountRestrictMode, getFirstError } from '@app/helpers';
import { environment } from "@environments/environment";
import { firstValueFrom, Subscription } from 'rxjs';
import { CustomValidatorsService } from '@app/services/custom-validators.service';
import { IAccountRestrict } from '@app/models';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  getFirstError = getFirstError
  ruleValidation = RuleValidations
  public currentUser: any;
  checkPass: any;
  formUpdateAccount: FormGroup = new FormGroup<any>({
    name: new FormControl('', [Validators.maxLength(RuleValidations.maxLength255), Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
    secretKey: new FormControl('', [Validators.required]),
    memberCode: new FormControl('', [Validators.maxLength(RuleValidations.maxLength255)]),
    organizationName: new FormControl('', [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
    apiKey: new FormControl('', [Validators.maxLength(RuleValidations.maxLength255)]),
    organizationId: new FormControl('', [Validators.maxLength(RuleValidations.maxLength255)])
  });
  submitted: boolean = false;
  email: string = '';
  id: string = '';
  dateInputMask = createMask<Date>({
    alias: 'datetime',
    inputFormat: 'yyyy/mm/dd',
    placeholder: '____/__/__',
  });
  isSmartPhoneScreen: boolean | undefined;
  loginBySso = Boolean(localStorage.getItem('loginBySso'))
  lang = localStorage.getItem('lang') ?? '';
  visibleGenerateUid: boolean = false;
  isDisplayAccountList: boolean = false;
  visibleDeleteAccountModal: boolean = false;
  accountRestrictConfig: IAccountRestrict = ACCOUNT_DEFAULT_RESTRICT;
  subscription: { [key: string]: Subscription | null } = {
    user: null
  }

  constructor(
    private userService: UserService,
    private router: Router,
    public angularFireAuth: AngularFireAuth,
    private alertService: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService,
    private notification: NotificationService
  ) {
    if (this.router.getCurrentNavigation()?.extras.state?.['update']) {
      this.alertService.success(this.translateService.instant('alertMessages.changePassword.success'));
    }
    if (this.router.getCurrentNavigation()?.extras.state?.['register']) {
      this.alertService.success(this.translateService.instant('alertMessages.register.success'));
    }
  }

  ngOnInit() {
    this.getCurrentUser().then();
    this.isSmartPhoneScreen = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize.bind(this));
    this.translateService.onLangChange.subscribe((e) => {
      this.lang = e.lang
    })
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.handleResize.bind(this));
    this.subscription['user']?.unsubscribe();
  }

  handleResize() {
    this.isSmartPhoneScreen = window.innerWidth < 768;
  }

  async logout() {
    const token = await this.userService.getCurrentUserAuth()?.getIdToken();
    const currentUser = await firstValueFrom(this.userService.getCurrentUser());
    if (currentUser) {
      const dataUser = await firstValueFrom(this.userService.getValueUserByUid(currentUser.uid));
      if (token && token.length && currentUser) await this.userService.updateAccountToken(dataUser, token, TokenAction.remove);
      this.userService.SignOut().then(res => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGOUT, EVENT_LOG_TYPE.SUCCESS);
        window.location.href = '/#/login';
        localStorage.removeItem('loginBySso');
        localStorage.removeItem('uid_user');
        localStorage.removeItem('email_user');
        localStorage.removeItem('isRedirect');
      }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGOUT, EVENT_LOG_TYPE.ERROR));
    }
  }

  async getCurrentUser() {
    const currentUser = await firstValueFrom(this.userService.getCurrentUser());
    if (currentUser && currentUser.uid.length) {
      this.subscription['user'] = this.userService.getValueUserByUid(currentUser.uid).subscribe({
        next: (value) => {
          if (value) {
            this.currentUser = value;
            this.accountRestrictConfig = checkAccountRestrictMode(this.currentUser);
            if (!this.currentUser.parentUserId) this.isDisplayAccountList = true;
            this.translateService.stream('account.planType').subscribe((item) => {
              this.currentUser.accountTypeName = item[this.currentUser.accountType];
            })
            this.formUpdateAccount.controls['name'].setValue(this.currentUser.name);
            if (this.currentUser.memberCode) {
              this.formUpdateAccount.controls['memberCode'].setValue(this.currentUser.memberCode);
            }
            if (this.currentUser.secretKey) {
              this.formUpdateAccount.controls['secretKey'].setValue(this.currentUser.secretKey);
            }
            if (this.currentUser.organizationName) {
              this.formUpdateAccount.controls['organizationName'].setValue(this.currentUser.organizationName);
            }
            if (this.currentUser.apiKey) {
              this.formUpdateAccount.controls['apiKey'].setValue(this.currentUser.apiKey);
            }
            if (this.currentUser.organizationId) {
              this.formUpdateAccount.controls['organizationId'].setValue(this.currentUser.organizationId);
            }
          }
        }
      })
    }
  }

  deleteAccount() {
    this.userService.updateStatusUser(this.currentUser.uid, true).then(response => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_AUTHENTICATE_STATUS, EVENT_LOG_TYPE.SUCCESS);

      this.notification.sendNoticeForOtherDevices(this.currentUser, 'deleteAccount', { isLogout: true }).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_NOTIFICATION.DELETE_ACCOUNT, EVENT_LOG_TYPE.SUCCESS);
        this.visibleDeleteAccountModal = false;

        this.userService.SignOut().then(() => {
          this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGOUT, EVENT_LOG_TYPE.SUCCESS);
          this.router.navigate(['login'], { state: { accountStatus: AccountStatus.isDeletedByOwner } }).then();
        }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGOUT, EVENT_LOG_TYPE.ERROR));

      }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_NOTIFICATION.DELETE_ACCOUNT, EVENT_LOG_TYPE.ERROR));

      this.userService.deleteAccount(this.currentUser.uid).then(res => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.DELETE, EVENT_LOG_TYPE.SUCCESS);
      }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.DELETE, EVENT_LOG_TYPE.ERROR));

    }).catch(error => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_AUTHENTICATE_STATUS, EVENT_LOG_TYPE.ERROR);
      this.userService.SignOut().then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGOUT, EVENT_LOG_TYPE.SUCCESS);
        this.router.navigate(['login']).then();
      }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGOUT, EVENT_LOG_TYPE.ERROR));
      return ({
        'message': 'Delete account failed: ' + error.message,
        'status': false,
      });
    })
  }

  async emailValidate(email: any) {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);
    if (user) {
      user.forEach((value, index) => {
        if (value == 'password') this.checkPass = true;
      });
    }
  }

  onValidate() {
    this.submitted = true;
    return this.formUpdateAccount.status === 'VALID';
  }

  updateAccount() {
    this.alertService.clear();
    if (this.onValidate()) {
      try {
        const userUpdate = {
          name: this.formUpdateAccount.value.name,
          secretKey: this.formUpdateAccount.value.secretKey,
          memberCode: this.formUpdateAccount.value.memberCode,
          organizationName: this.formUpdateAccount.value.organizationName,
          apiKey: this.formUpdateAccount.value.apiKey,
          organizationId: this.formUpdateAccount.value.organizationId
        }
        this.userService.updateAccount(userUpdate).then(res => {
          this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE, EVENT_LOG_TYPE.SUCCESS);
          this.alertService.success(this.translateService.instant('alertMessages.updateAccount.success'));
        });
      } catch (e) {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.updateAccount.fail'));
      }
    }
  }

  toggleGenerateUidModal() {
    this.visibleGenerateUid = !this.visibleGenerateUid;
  }

  receiveUid(uid: string) {
    this.formUpdateAccount.controls['secretKey'].setValue(uid);
  }

  toggleDeleteAccountModal() {
    this.visibleDeleteAccountModal = !this.visibleDeleteAccountModal;
  }

  protected readonly environment = environment;
}
