<form [formGroup]="form">
  <label (click)="openColorPicker()" cLabel>{{titleInput}} <c-badge color="danger" *ngIf="isRequired">{{'form.inputLabel.must'|translate}}</c-badge></label>
  <div class="box-color" (click)="openColorPicker()">
    <ngx-colors
      #colorPicker
      acceptLabel="{{ 'form.inputLabel.buttonColor.accept' | translate }}"
      cancelLabel="{{ 'form.inputLabel.buttonColor.cancel' | translate }}"
      format="hex"
      class="color-picker"
      [formControlName]="formControlName"
      ngx-colors-trigger>
    </ngx-colors>
    <span class="ms-2">{{form.get(formControlName)?.value}}</span>
  </div>
  <c-form-check class="text-danger" *ngIf="showErrorMessages">{{errorMessage}}</c-form-check>
</form>
