<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="justify-content-center mb-5">
    <h3>{{'portal.create.title' | translate}}</h3>
  </c-col>

  <c-col class="block-table mb-5" *ngIf="!!currentUser">
    <table class="table table-striped table-hover">
      <thead>
      <tr cTableColor="dark">
        <th>{{ "formEdit.emailTab.tableHeader.field" | translate }}</th>
        <th>{{ "formEdit.emailTab.tableHeader.data" | translate }}</th>
        <th>{{ "formEdit.emailTab.tableHeader.variable" | translate }}</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of VARIABLES_PORTAL; let i = index">
          <td>{{ item }}</td>
          <td class="data-variables" [innerHTML]="currentUser[item] ?? '' | safeHtml"></td>
          <td>{{ '={' + item + '}=' }}</td>
        </tr>
      </tbody>
    </table>
  </c-col>

  <c-card>
    <c-card-body>
      <form [formGroup]="formCreatePortal" (ngSubmit)="submit()">
        <c-col class="mb-1">{{'portal.create.title' | translate}}</c-col>
        <editor formControlName="portalHTML" [init]="initEditor" (onChange)="changeEditor($event)"></editor>
        <c-form-check class="text-danger" *ngIf="formCreatePortal.get('portalHTML')?.errors && submitted">
          {{ "errorMessages.portal.portalHTML." + getFirstError(formCreatePortal.get('portalHTML')?.errors) | translate : { maxlength: ruleValidations.maxLength4096 } }}
        </c-form-check>
        <div class="d-flex justify-content-end mt-3"><button cLoadingButton [disabledOnLoading]="true" [loading]="isLoadingSave" type="submit" class="btn-save">{{'portal.create.save' | translate}}</button></div>
      </form>
    </c-card-body>
  </c-card>
</c-container>
