export enum RouterNameList {
  formCreate = 'form/create',
}

export const HeaderGuestRoutingName = [
  '404',
  'non-account',
  'reissue/:id',
  'form-guest/:id',
  ':prefix/:formId/:formDataId',
  ':prefix/:formId/:formDataId/exam',
  ':prefix/:formId/:formDataId/certificate',
];

export const showButtonReissue = [
  'form-guest/:id'
];

export const HideHeaderUserRoutingName = [
  'form-guest/:id',
  ':prefix/:formId/:formDataId',
  'reissue/:id',
  ':prefix/:formId/:formDataId/exam',
  ':prefix/:formId/:formDataId/certificate',
];
