import { Injectable } from '@angular/core';
import { INotification } from '@app/models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { getMessage } from "@app/helpers";
import { MESSAGES_NOTIFICATION } from "@app/constants";
import { UserService } from './user.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl: string = environment.url_notification;
  keyNotification: string = environment.key_notification;

  constructor(
    private db: AngularFirestore,
    private translateService: TranslateService,
    private http: HttpClient,
    private userService: UserService
  ) { }

  // Create notification
  async create(Notification: INotification) {
    await this.db.collection('messages').add({
      messageTitle: Notification.messageTitle,
      messageBody: Notification.messageBody,
      sendAll: Notification.sendAll,
      user_uid: Notification.user_uid,
    });
  }

  async batchCreateNotice(
    userIds: string[],
    messageTitle: { ja: string, en: string },
    messageBody: { ja: string, en: string },
    tokens?: string[]
  ) {
    const batch = this.db.firestore.batch();
    userIds.forEach((userId: any) => {
    const newDocRef = this.db.collection('users').doc(userId).collection('messages').doc().ref;
      batch.set(newDocRef, {
        createdAt: new Date(),
        isDelete: false,
        isRead: false,
        messageBody: messageBody,
        messageTitle: messageTitle,
        timeDeleteMessage: null,
        timePushMessage: new Date(),
        tokens: tokens ?? null
      })
    })
    await batch.commit();
  }

  async sendNoticeForOtherDevices(user: any, type: string,
    accountConfig: {
      isLogout?: boolean,
      isChildAccount?: boolean,
      isUpdatePassword?: boolean
    } = { isLogout: false, isChildAccount: false, isUpdatePassword: false }) {
    const tokens = user.tokens;
    let dataMessage = MESSAGES_NOTIFICATION.account[type as keyof typeof MESSAGES_NOTIFICATION.account]
    if (accountConfig.isChildAccount) {
      dataMessage = MESSAGES_NOTIFICATION.childAccount[type as keyof typeof MESSAGES_NOTIFICATION.childAccount]
    }
    const dataNotice = {
      messageTitle: dataMessage.title,
      messageBody: dataMessage.body,
      sendAll: false,
      user_uid: user.uid,
    }
    let currentTokens = [];
    if (accountConfig.isUpdatePassword) {
      const currentUser: any = await firstValueFrom(this.userService.getValueUserByUid(user.uid));
      currentTokens = currentUser.tokens
    }
    let body: any = {
      "registration_ids": tokens,
      "notification": {
        "title": dataMessage.title.ja,
        "body": dataMessage.body.ja,
        "text": dataMessage.body.ja,
      }
    }
    if (accountConfig.isLogout) body = {...body, data: {'logout': accountConfig.isLogout}};
    await this.create(dataNotice);
    await this.batchCreateNotice([user.uid], dataMessage.title, dataMessage.body, currentTokens)
    if (tokens?.length) {
      this.sendNoticeApp(body)
    }
  }

  sendNoticeGroup(dataUser: any, typeNotice: string, variables: { [key: string]: string }) {
    if (Boolean(dataUser.length)) {
      const dataUserIds = dataUser.map((user: any) => user.id);
      const dataMessage = MESSAGES_NOTIFICATION.group[typeNotice as keyof typeof MESSAGES_NOTIFICATION.group]
      const notification = {
        messageTitle: {
          ja: getMessage(dataMessage.title.ja, variables),
          en: getMessage(dataMessage.title.en, variables)
        },
        messageBody: {
          ja: getMessage(dataMessage.body.ja, variables),
          en: getMessage(dataMessage.body.en, variables)
        },
        sendAll: false,
        user_uid: dataUserIds
      }
      let tokens: string[] = [];
      dataUser.forEach((item: any) => {
        tokens = [...tokens, ...(item.tokens ?? [])]
      })
      const bodyNotiApp = {
        "registration_ids": tokens,
        "notification": {
          "title": notification.messageTitle.ja,
          "body": notification.messageBody.ja,
          "text": notification.messageBody.ja,
        }
      }
      this.batchCreateNotice(dataUserIds, notification.messageTitle, notification.messageBody).then();
      this.create(notification).then();
      if (Boolean(tokens.length)) {
        this.sendNoticeApp(bodyNotiApp)
      }
    }
  }

  getAll(email: string, id: string) {
    return this.db.collection('users', ref => ref.where('email', '==', email))
      .doc(id).collection('messages', ref => ref
        .where('isDelete', '==', false)
        .orderBy('timePushMessage', 'desc'))
      .snapshotChanges();
  }

  getNotification(email: string, id: string) {
    return this.db.collection('users', ref => ref.where('email', '==', email))
      .doc(id).collection('messages', ref => ref
        .where('isDelete', '==', false)
        .orderBy('timePushMessage', 'desc')
        .limit(5))
      .snapshotChanges();
  }

  getNotificationEvent(email: string, id: string) {
    return this.db.collection('users/' + id + '/messages').snapshotChanges();
  }

  delete(email: string, id: string, messageId: string | undefined) {
    return this.db.collection('users', ref => ref.where('email', '==', email))
      .doc(id).collection('messages').doc(messageId)
      .update({ 'isDelete': true });
  }

  readMark(email: string, id: string, messageId: string | undefined) {
    return this.db.collection('users', ref => ref.where('email', '==', email))
      .doc(id).collection('messages').doc(messageId)
      .update({ 'isRead': true });
  }

  nextPage(email: string, id: string, currentLastDocumentTime: any) {
    return this.db.collection('users', ref => ref.where('email', '==', email))
      .doc(id).collection('messages', ref => ref
        .where('isDelete', '==', false)
        .orderBy('timePushMessage', 'desc')
        .startAfter(currentLastDocumentTime)
        .limit(10))
      .get();
  }

  timeSince(date: any): string {
    const time: any = new Date();
    const seconds = Math.floor((time - date) / 1000);

    let interval = seconds / 31536000;
    if (interval > 1 && interval < 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.yearAgo')}`;
    }
    if (interval >= 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.yearsAgo')}`;
    }
    interval = seconds / 2592000;
    if (interval > 1 && interval < 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.monthAgo')}`;
    }
    if (interval >= 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.monthsAgo')}`;
    }
    interval = seconds / 86400;
    if (interval > 1 && interval < 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.dayAgo')}`;
    }
    if (interval >= 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.daysAgo')}`;
    }
    interval = seconds / 3600;
    if (interval > 1 && interval < 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.hourAgo')}`;
    }
    if (interval >= 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.hoursAgo')}`;
    }
    interval = seconds / 60;
    if (interval > 1 && interval < 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.minute')}`;
    }
    if (interval >= 2) {
      return `${Math.floor(interval)}${this.translateService.instant('notification.timeSince.minutes')}`;
    }
    return this.translateService.instant('notification.timeSince.now');
  }

  sendNoticeApp(body: any) {
    const headers = {
      'content-type': 'application/json',
      'Authorization': this.keyNotification
    }
    return this.http.post(this.baseUrl, JSON.stringify(body), { 'headers': headers }).subscribe();
  }

  getNewestNotification(email: string, id: string) {
    return this.db.collection('users', ref => ref
      .where('email', '==', email)).doc(id).collection('messages', ref => ref
      .where('isDelete', '==', false)
      .orderBy('timePushMessage', 'desc')
      .limit(1)).valueChanges();
  }
}
