<c-col class="d-flex common-header">
  <c-col [lg]="5" [md]="8">
    <a class="d-flex align-items-center" id="header__left" routerLink="/portal">
      <img cImg src="assets/img/logo.png" alt="logo" />
      <h5 class="mb-0 ps-3" id="header__title">
        {{ environment.service_name[currentLanguage] }}
      </h5>
    </a>
  </c-col>
  <c-col [lg]="7" [md]="4" class="d-flex align-items-center justify-content-end menu-pc">
    <div class="me-4">
      <a class="btn-header" routerLink="/portal" routerLinkActive="btn-header--active"
        [routerLinkActiveOptions]="{ exact: true }">
        <svg fill="#FB6514" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-25.6 -25.6 563.20 563.20" xml:space="preserve" stroke="#FB6514">
          <g id="SVGRepo_bgCarrier" stroke-width="0"/>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
          <g id="SVGRepo_iconCarrier"> <g> <g> <path d="M461.913,11.965H50.087C22.469,11.965,0,34.434,0,62.052v292.174c0,27.618,22.469,50.087,50.087,50.087h129.391v62.33 h-31.165c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696c5.508,0,198.153,0,215.374,0 c9.22,0,16.696-7.475,16.696-16.696s-7.475-16.696-16.696-16.696h-31.165v-62.33h129.391c27.618,0,50.087-22.469,50.087-50.087 V62.052C512,34.434,489.531,11.965,461.913,11.965z M299.13,466.643H212.87v-62.33h86.261V466.643z M478.609,354.226 c0,9.206-7.49,16.696-16.696,16.696c-5.754,0-405.689,0-411.826,0c-9.206,0-16.696-7.49-16.696-16.696V62.052 c0-9.206,7.49-16.696,16.696-16.696h411.826c9.206,0,16.696,7.49,16.696,16.696V354.226z"/> </g> </g> <g> <g> <path d="M435.478,71.791H76.522c-9.22,0-16.696,7.475-16.696,16.696v239.304c0,9.22,7.475,16.696,16.696,16.696h358.957 c9.22,0,16.696-7.475,16.696-16.696V88.487C452.174,79.267,444.699,71.791,435.478,71.791z M418.783,311.096H93.217V105.183 h325.565V311.096z"/> </g> </g> <g> <g> <path d="M286.456,155.946H138.719c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696h147.738 c9.22,0,16.696-7.475,16.696-16.696S295.677,155.946,286.456,155.946z"/> </g> </g> <g> <g> <path d="M375.652,155.946h-32.006c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696h32.006 c9.22,0,16.696-7.475,16.696-16.696S384.873,155.946,375.652,155.946z"/> </g> </g> <g> <g> <path d="M286.456,226.941H138.719c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696h147.738 c9.22,0,16.696-7.475,16.696-16.696C303.152,234.416,295.677,226.941,286.456,226.941z"/> </g> </g> <g> <g> <path d="M375.652,226.941h-32.006c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696h32.006 c9.22,0,16.696-7.475,16.696-16.696C392.348,234.416,384.873,226.941,375.652,226.941z"/> </g> </g> </g>
        </svg>
        <span class="ps-2">{{ "layout.header.menu.portal" | translate }}</span>
      </a>
    </div>
    <div class="me-4">
      <a class="btn-header" routerLink="/form" [class]="{'btn-header--active' : customMatcher(EXTEND_ROUTE_ACTIVE.FORM)}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4"
            d="M17.028 0H6.972C2.604 0 0 2.604 0 6.972V17.028C0 21.396 2.604 24 6.972 24H17.028C21.396 24 24 21.396 24 17.028V6.972C24 2.604 21.396 0 17.028 0Z"
            fill="#667085" />
          <path
            d="M19.5717 8.24399C19.5717 8.73599 19.1757 9.14399 18.6717 9.14399H12.3717C11.8797 9.14399 11.4717 8.73599 11.4717 8.24399C11.4717 7.75199 11.8797 7.34399 12.3717 7.34399H18.6717C19.1757 7.34399 19.5717 7.75199 19.5717 8.24399Z"
            fill="#667085" />
          <path
            d="M9.56388 7.08012L6.86387 9.78012C6.68387 9.96012 6.45588 10.0441 6.22788 10.0441C5.99988 10.0441 5.75988 9.96012 5.59188 9.78012L4.69188 8.88012C4.33187 8.53212 4.33187 7.95612 4.69188 7.60812C5.03988 7.26012 5.60388 7.26012 5.96388 7.60812L6.22788 7.87212L8.29188 5.80812C8.63987 5.46012 9.20388 5.46012 9.56388 5.80812C9.91188 6.15612 9.91188 6.73212 9.56388 7.08012Z"
            fill="#667085" />
          <path
            d="M19.5717 16.6439C19.5717 17.1359 19.1757 17.5439 18.6717 17.5439H12.3717C11.8797 17.5439 11.4717 17.1359 11.4717 16.6439C11.4717 16.1519 11.8797 15.7439 12.3717 15.7439H18.6717C19.1757 15.7439 19.5717 16.1519 19.5717 16.6439Z"
            fill="#667085" />
          <path
            d="M9.56388 15.48L6.86387 18.18C6.68387 18.36 6.45588 18.444 6.22788 18.444C5.99988 18.444 5.75988 18.36 5.59188 18.18L4.69188 17.28C4.33187 16.932 4.33187 16.356 4.69188 16.008C5.03988 15.66 5.60388 15.66 5.96388 16.008L6.22788 16.272L8.29188 14.208C8.63987 13.86 9.20388 13.86 9.56388 14.208C9.91188 14.556 9.91188 15.132 9.56388 15.48Z"
            fill="#667085" />
        </svg>
        <span class="ps-2">{{ "layout.header.menu.form" | translate }}</span>
      </a>
    </div>
    <div class="me-4">
      <a class="btn-header" routerLink="/account" [class]="{'btn-header--active' : customMatcher(EXTEND_ROUTE_ACTIVE.ACCOUNT)}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4"
            d="M12 22.01C17.5228 22.01 22 17.5329 22 12.01C22 6.48716 17.5228 2.01001 12 2.01001C6.47715 2.01001 2 6.48716 2 12.01C2 17.5329 6.47715 22.01 12 22.01Z" />
          <path
            d="M12 6.93994C9.93 6.93994 8.25 8.61994 8.25 10.6899C8.25 12.7199 9.84 14.3699 11.95 14.4299C11.98 14.4299 12.02 14.4299 12.04 14.4299C12.06 14.4299 12.09 14.4299 12.11 14.4299C12.12 14.4299 12.13 14.4299 12.13 14.4299C14.15 14.3599 15.74 12.7199 15.75 10.6899C15.75 8.61994 14.07 6.93994 12 6.93994Z" />
          <path
            d="M18.7797 19.36C16.9997 21 14.6197 22.01 11.9997 22.01C9.37973 22.01 6.99973 21 5.21973 19.36C5.45973 18.45 6.10973 17.62 7.05973 16.98C9.78973 15.16 14.2297 15.16 16.9397 16.98C17.8997 17.62 18.5397 18.45 18.7797 19.36Z" />
        </svg>
        <span class="ps-2">{{ "layout.header.menu.account" | translate }}</span>
      </a>
    </div>
    <div>
      <button
        class="btn-header"
        [ngClass]="{ 'btn-header--active': router.isActive('/notification', true) }"
        id="notifications-button"
        (click)="toggleNotifications()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4"
            d="M20.3992 16.33C20.1192 17.08 19.5292 17.65 18.7592 17.91C17.6792 18.27 16.5692 18.54 15.4492 18.73C15.3392 18.75 15.2292 18.7701 15.1192 18.7801C14.9392 18.8101 14.7592 18.83 14.5792 18.85C14.3592 18.8801 14.1292 18.9 13.8992 18.92C13.2692 18.97 12.6492 19 12.0192 19C11.3792 19 10.7392 18.97 10.1092 18.91C9.83923 18.89 9.57923 18.8601 9.31923 18.82C9.16923 18.8 9.01923 18.7801 8.87923 18.76C8.76923 18.74 8.65923 18.73 8.54923 18.71C7.43923 18.53 6.33923 18.26 5.26923 17.9C4.46923 17.63 3.85923 17.06 3.58923 16.33C3.31923 15.61 3.41923 14.77 3.84923 14.05L4.97923 12.17C5.21923 11.76 5.43923 10.97 5.43923 10.49V8.63005C5.43923 5.00005 8.38923 2.05005 12.0192 2.05005C15.6392 2.05005 18.5892 5.00005 18.5892 8.63005V10.49C18.5892 10.97 18.8092 11.76 19.0592 12.17L20.1892 14.05C20.5992 14.75 20.6792 15.57 20.3992 16.33Z" />
          <path
            d="M12.0002 10.76C11.5802 10.76 11.2402 10.42 11.2402 10V6.90001C11.2402 6.48001 11.5802 6.14001 12.0002 6.14001C12.4202 6.14001 12.7602 6.48001 12.7602 6.90001V10C12.7502 10.42 12.4102 10.76 12.0002 10.76Z" />
          <path
            d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" />
          <circle cx="18" cy="4.5" r="2.5" fill="#0086C9" stroke="white"
            *ngIf="notifyPopupComponent.isHasUnreadMessage | async" />
        </svg>
        <span class="ps-2">{{ "layout.header.menu.notice" | translate }}</span>
      </button>
    </div>
    <div class="ms-4 d-flex align-items-center">
      <img *ngIf="lang == 'en'" src="assets/svg/lang/us.svg" alt="en" />
      <img *ngIf="lang == 'ja'" src="assets/svg/lang/ja.svg" alt="ja" />
      <c-dropdown>
        <button class="btn-header">
          <span cButton cDropdownToggle class="ps-2">
            {{ "layout.header.menu.lang.item." + lang | translate }}
          </span>
        </button>
        <ul cDropdownMenu>
          <li>
            <a data-lang="en" (click)="onChange($event)" [active]="lang == 'en'" cDropdownItem>
              {{"layout.header.menu.lang.item.en" | translate }}
            </a>
          </li>
          <li>
            <a data-lang="ja" (click)="onChange($event)" [active]="lang == 'ja'" cDropdownItem>
              {{"layout.header.menu.lang.item.ja" | translate }}
            </a>
          </li>
        </ul>
      </c-dropdown>
    </div>
  </c-col>
  <c-col class="menu-mb">
    <div class="navbar">
      <div class="container nav-container">
        <input class="checkbox" type="checkbox" name="" id="hamburgerMenu" (click)="onClickMenu($event)" />
        <label for="hamburgerMenu" class="hamburger-lines">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </label>
        <div class="menu-items">
          <li class="item-menu-mb">
            <a class="btn-header header-mb" routerLink="/portal" routerLinkActive="btn-header--active" [routerLinkActiveOptions]="{ exact: false }">
              <svg fill="#FB6514" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-25.6 -25.6 563.20 563.20" xml:space="preserve" stroke="#FB6514">
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                <g id="SVGRepo_iconCarrier"> <g> <g> <path d="M461.913,11.965H50.087C22.469,11.965,0,34.434,0,62.052v292.174c0,27.618,22.469,50.087,50.087,50.087h129.391v62.33 h-31.165c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696c5.508,0,198.153,0,215.374,0 c9.22,0,16.696-7.475,16.696-16.696s-7.475-16.696-16.696-16.696h-31.165v-62.33h129.391c27.618,0,50.087-22.469,50.087-50.087 V62.052C512,34.434,489.531,11.965,461.913,11.965z M299.13,466.643H212.87v-62.33h86.261V466.643z M478.609,354.226 c0,9.206-7.49,16.696-16.696,16.696c-5.754,0-405.689,0-411.826,0c-9.206,0-16.696-7.49-16.696-16.696V62.052 c0-9.206,7.49-16.696,16.696-16.696h411.826c9.206,0,16.696,7.49,16.696,16.696V354.226z"/> </g> </g> <g> <g> <path d="M435.478,71.791H76.522c-9.22,0-16.696,7.475-16.696,16.696v239.304c0,9.22,7.475,16.696,16.696,16.696h358.957 c9.22,0,16.696-7.475,16.696-16.696V88.487C452.174,79.267,444.699,71.791,435.478,71.791z M418.783,311.096H93.217V105.183 h325.565V311.096z"/> </g> </g> <g> <g> <path d="M286.456,155.946H138.719c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696h147.738 c9.22,0,16.696-7.475,16.696-16.696S295.677,155.946,286.456,155.946z"/> </g> </g> <g> <g> <path d="M375.652,155.946h-32.006c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696h32.006 c9.22,0,16.696-7.475,16.696-16.696S384.873,155.946,375.652,155.946z"/> </g> </g> <g> <g> <path d="M286.456,226.941H138.719c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696h147.738 c9.22,0,16.696-7.475,16.696-16.696C303.152,234.416,295.677,226.941,286.456,226.941z"/> </g> </g> <g> <g> <path d="M375.652,226.941h-32.006c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696h32.006 c9.22,0,16.696-7.475,16.696-16.696C392.348,234.416,384.873,226.941,375.652,226.941z"/> </g> </g> </g>
              </svg>
              <span class="ps-2">{{ "layout.header.menu.portal" | translate }}</span>
            </a>
          </li>
          <li class="item-menu-mb">
            <a class="btn-header header-mb" routerLink="/form" routerLinkActive="btn-header--active" [routerLinkActiveOptions]="{ exact: false }">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4"
                  d="M17.028 0H6.972C2.604 0 0 2.604 0 6.972V17.028C0 21.396 2.604 24 6.972 24H17.028C21.396 24 24 21.396 24 17.028V6.972C24 2.604 21.396 0 17.028 0Z"
                  fill="#667085" />
                <path
                  d="M19.5717 8.24399C19.5717 8.73599 19.1757 9.14399 18.6717 9.14399H12.3717C11.8797 9.14399 11.4717 8.73599 11.4717 8.24399C11.4717 7.75199 11.8797 7.34399 12.3717 7.34399H18.6717C19.1757 7.34399 19.5717 7.75199 19.5717 8.24399Z"
                  fill="#667085" />
                <path
                  d="M9.56388 7.08012L6.86387 9.78012C6.68387 9.96012 6.45588 10.0441 6.22788 10.0441C5.99988 10.0441 5.75988 9.96012 5.59188 9.78012L4.69188 8.88012C4.33187 8.53212 4.33187 7.95612 4.69188 7.60812C5.03988 7.26012 5.60388 7.26012 5.96388 7.60812L6.22788 7.87212L8.29188 5.80812C8.63987 5.46012 9.20388 5.46012 9.56388 5.80812C9.91188 6.15612 9.91188 6.73212 9.56388 7.08012Z"
                  fill="#667085" />
                <path
                  d="M19.5717 16.6439C19.5717 17.1359 19.1757 17.5439 18.6717 17.5439H12.3717C11.8797 17.5439 11.4717 17.1359 11.4717 16.6439C11.4717 16.1519 11.8797 15.7439 12.3717 15.7439H18.6717C19.1757 15.7439 19.5717 16.1519 19.5717 16.6439Z"
                  fill="#667085" />
                <path
                  d="M9.56388 15.48L6.86387 18.18C6.68387 18.36 6.45588 18.444 6.22788 18.444C5.99988 18.444 5.75988 18.36 5.59188 18.18L4.69188 17.28C4.33187 16.932 4.33187 16.356 4.69188 16.008C5.03988 15.66 5.60388 15.66 5.96388 16.008L6.22788 16.272L8.29188 14.208C8.63987 13.86 9.20388 13.86 9.56388 14.208C9.91188 14.556 9.91188 15.132 9.56388 15.48Z"
                  fill="#667085" />
              </svg>
              <span class="ps-2">{{ "layout.header.menu.form" | translate }}</span>
            </a>
          </li>
          <li class="item-menu-mb">
            <a class="btn-header header-mb" routerLink="/account" routerLinkActive="btn-header--active" [routerLinkActiveOptions]="{ exact: false }">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4"
                  d="M12 22.01C17.5228 22.01 22 17.5329 22 12.01C22 6.48716 17.5228 2.01001 12 2.01001C6.47715 2.01001 2 6.48716 2 12.01C2 17.5329 6.47715 22.01 12 22.01Z" />
                <path
                  d="M12 6.93994C9.93 6.93994 8.25 8.61994 8.25 10.6899C8.25 12.7199 9.84 14.3699 11.95 14.4299C11.98 14.4299 12.02 14.4299 12.04 14.4299C12.06 14.4299 12.09 14.4299 12.11 14.4299C12.12 14.4299 12.13 14.4299 12.13 14.4299C14.15 14.3599 15.74 12.7199 15.75 10.6899C15.75 8.61994 14.07 6.93994 12 6.93994Z" />
                <path
                  d="M18.7797 19.36C16.9997 21 14.6197 22.01 11.9997 22.01C9.37973 22.01 6.99973 21 5.21973 19.36C5.45973 18.45 6.10973 17.62 7.05973 16.98C9.78973 15.16 14.2297 15.16 16.9397 16.98C17.8997 17.62 18.5397 18.45 18.7797 19.36Z" />
              </svg>
              <span class="ps-2">{{ "layout.header.menu.account" | translate }}</span>
            </a>
          </li>
          <li class="item-menu-mb">
            <a class="btn-header header-mb" routerLink="/notification" routerLinkActive="btn-header--active" [routerLinkActiveOptions]="{ exact: false }">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4"
                  d="M20.3992 16.33C20.1192 17.08 19.5292 17.65 18.7592 17.91C17.6792 18.27 16.5692 18.54 15.4492 18.73C15.3392 18.75 15.2292 18.7701 15.1192 18.7801C14.9392 18.8101 14.7592 18.83 14.5792 18.85C14.3592 18.8801 14.1292 18.9 13.8992 18.92C13.2692 18.97 12.6492 19 12.0192 19C11.3792 19 10.7392 18.97 10.1092 18.91C9.83923 18.89 9.57923 18.8601 9.31923 18.82C9.16923 18.8 9.01923 18.7801 8.87923 18.76C8.76923 18.74 8.65923 18.73 8.54923 18.71C7.43923 18.53 6.33923 18.26 5.26923 17.9C4.46923 17.63 3.85923 17.06 3.58923 16.33C3.31923 15.61 3.41923 14.77 3.84923 14.05L4.97923 12.17C5.21923 11.76 5.43923 10.97 5.43923 10.49V8.63005C5.43923 5.00005 8.38923 2.05005 12.0192 2.05005C15.6392 2.05005 18.5892 5.00005 18.5892 8.63005V10.49C18.5892 10.97 18.8092 11.76 19.0592 12.17L20.1892 14.05C20.5992 14.75 20.6792 15.57 20.3992 16.33Z" />
                <path
                  d="M12.0002 10.76C11.5802 10.76 11.2402 10.42 11.2402 10V6.90001C11.2402 6.48001 11.5802 6.14001 12.0002 6.14001C12.4202 6.14001 12.7602 6.48001 12.7602 6.90001V10C12.7502 10.42 12.4102 10.76 12.0002 10.76Z" />
                <path
                  d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" />
                <circle cx="18" cy="4.5" r="2.5" fill="#0086C9" stroke="white"
                  *ngIf="notifyPopupComponent.isHasUnreadMessage | async" />
              </svg>
              <span class="ps-2">{{"layout.header.menu.notice" | translate}}</span>
            </a>
          </li>
          <li class="item-menu-mb">
            <div class="d-flex align-items-center box-lang-mb">
              <img *ngIf="lang == 'en'" src="assets/svg/lang/us.svg" alt="en" />
              <img *ngIf="lang == 'ja'" src="assets/svg/lang/ja.svg" alt="ja" />
              <c-dropdown>
                <button class="btn-header">
                  <span cButton cDropdownToggle class="ps-2">
                    {{ "layout.header.menu.lang.item." + lang | translate }}
                  </span>
                </button>
                <ul cDropdownMenu>
                  <li>
                    <a data-lang="en" (click)="onChange($event)" [active]="lang == 'en'" cDropdownItem>
                      {{ "layout.header.menu.lang.item.en" | translate }}
                    </a>
                  </li>
                  <li>
                    <a data-lang="ja" (click)="onChange($event)" [active]="lang == 'ja'" cDropdownItem>
                      {{ "layout.header.menu.lang.item.ja" | translate }}
                    </a>
                  </li>
                </ul>
              </c-dropdown>
            </div>
          </li>
        </div>
      </div>
    </div>
  </c-col>
  <app-notify-popup #notifyPopupComponent id="notifyPopupComponent"></app-notify-popup>
</c-col>
