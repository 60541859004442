import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AlertService, FormService } from "@app/services";
import firebase from 'firebase/compat/app';
import { Router, ActivatedRoute } from "@angular/router";
import { DATA_PROPERTY_SEND_MAIL, formatDatePicker, INIT_EDITOR, RuleValidations } from "@app/constants";
import { checkInputEnter, getFirstError } from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { EditorOptions } from 'tinymce';

@Component({
  selector: 'app-email-edit',
  templateUrl: './email-edit.component.html',
  styleUrls: ['./email-edit.component.scss']
})
export class EmailEditComponent implements OnChanges {
  @Input('form') form: any;
  @Input('isOutDate') isOutDate: boolean = false;
  @Input('formEdit') formEdit: any;
  @Input('submitAllTab') submitAllTab: any;
  @Output() submittedData = new EventEmitter<Boolean>();
  @Output() isBackToFormList = new EventEmitter<Boolean>();
  checkInputEnter = checkInputEnter;
  getFirstError = getFirstError;
  ruleValidations = RuleValidations;
  initEditor: Partial<EditorOptions> = INIT_EDITOR;
  submitted = false;
  loading = false;
  referenceData: Array<any> = [];

  constructor(
    private formService: FormService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDataFormEdit().then();
  }

  backToFormList() {
    this.isBackToFormList.emit(true);
  }

  async getDataFormEdit() {
    if (this.form) {
      this.getReferenceData();
    }
  }

  getReferenceData() {
    const formNode = this.form?.nodes?.nodes?.objects;
    this.referenceData = formNode?.map((node: any) => {
      return { field: node.name, data: "", variable: `={${node.name}}=` }
    })
    this.referenceData?.push({ field: "formDataUrl", data: "", variable: "={formDataUrl}=" });

    for (let [key, item] of Object.entries(this.form)) {
      if (item instanceof firebase.firestore.Timestamp) item = moment(item.toDate()).format(formatDatePicker);
      if (DATA_PROPERTY_SEND_MAIL.includes(key))
        this.referenceData?.push({ field: key, data: item, variable: `={${key}}=` });
    }
  }

  changeSendEmail(property: string) {
    this.addValidateForSendEmail(property)
  }

  addValidateForSendEmail(property: string) {
    switch (property) {
      case 'isSendMailFormRegist':
        this.processValidator(['titleMailFormRegist', 'bodyMailFormRegist'], property)
        break;
      case 'isSendMailReissueQr':
        this.processValidator(['titleMailReissueQr', 'bodyMailReissueQr'], property)
        break;
      case 'isSendMailUpdateFormData':
        this.processValidator(['bodyMailUpdateFormData', 'titleMailUpdateFormData'], property)
        break;
      default:
        return;
    }
  }

  processValidator(keys: Array<string>, property: string) {
    const controls = this.formEdit.controls;
    const value = controls[property].value;
    keys.forEach((key: string) => {
      controls[key].setErrors(null);
      controls[key].clearValidators();
      if (value && !controls[key].value) {
        controls[key].setErrors({ required: true });
      }
    })
  }

  async submit() {
    this.submitted = true;
    if (this.formEdit.valid && this.form) {
      this.submittedData.emit(true)
    }
  }
}
