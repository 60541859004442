<app-layout-auth>
  <div style="text-align: center">
    <img id="logo" [src]="logoLogin" alt="logo" />
  </div>
  <h1>{{'login.login'|translate}}</h1>
  <form [formGroup]="form" (ngSubmit)="login()" novalidate>
    <c-input-group
      [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
      class="input-group__mail"
    >
      <input
        cFormControl
        [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
        formControlName="email"
        autoComplete="email"
        type="email"
        [placeholder]="'login.email'|translate"
      />
    </c-input-group>
    <c-form-feedback *ngIf="submitted && f['email'].errors">
      <div *ngIf="f['email'].errors['required']">
        {{'errorMessages.email.required'|translate}}
      </div>
      <div *ngIf="f['email'].errors['pattern']">
        {{'errorMessages.email.email'|translate}}
      </div>
    </c-form-feedback>
    <app-input-password
      class="input-group-container"
      [formControlNameInput]="'password'"
      [placeHolderInput]="'login.password'|translate"
      [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
    ></app-input-password>
    <c-form-feedback *ngIf="submitted && f['password'].errors">
      <div *ngIf="f['password'].errors['required']">
        {{'errorMessages.password.required'|translate}}
      </div>
    </c-form-feedback>
    <a routerLink="/forgot-password">{{'login.ifYouForgotPassword'|translate}}</a>
    <button cButton [disabled]="loading" type="submit">
      {{'login.login'|translate}}
    </button>
  </form>
  <c-row class="mt-3 align-items-center social-login" *ngIf="environment.google_login || environment.apple_login">
    <c-col [md]="8" class="d-flex p-0">
      <p class="mb-0">{{'login.socialLogin'|translate}}</p>
    </c-col>
    <c-col [md]="3" class="p-0">
      <div class="group-btn group-btn-social">
        <button *ngIf="environment.google_login" class="btn-social" (click)="SignInGoogle()">
          <img src="./assets/img/login/login-google.png" />
        </button>
        <button *ngIf="environment.apple_login" class="btn-social" (click)="SignInApple()">
          <img src="./assets/img/login/login-apple.png" />
        </button>
      </div>
    </c-col>
  </c-row>
  <div id="notice" [class]="{'without-sing-up-link': !environment.sing_up_link}">
    <div *ngIf="environment.sing_up_link">
      {{'login.or'|translate}}
    </div>
    <div *ngIf="environment.sing_up_link">
      {{'login.ifYouDontHaveAccount'|translate}}
      <a routerLink="/register">{{'login.signUp'|translate}}</a>
    </div>
    <div>
      <a *ngIf="environment.no_login_link" routerLink="/non-account">{{'login.useWithoutLogin'|translate}}</a>
    </div>
  </div>
</app-layout-auth>
