<c-modal [visible]="visibleGenerateUid" class="custom-modal custom-modal__no-body">
  <c-modal-header>
    <h5><b>{{ 'account.modal.generateUid.title' | translate }}</b></h5>
    <button cButtonClose (click)="toggleGenerateUidModal()"></button>
  </c-modal-header>
  <c-modal-footer>
    <button  cButton class="btn-close-modal" (click)="toggleGenerateUidModal()">{{ 'account.modal.generateUid.no' | translate }}</button>
    <button cButton class="btn-confirm-modal" (click)="generateUid()">
      {{ 'account.modal.generateUid.yes' | translate }}
    </button>
  </c-modal-footer>
</c-modal>
