import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { COLOR_WHITE, DEVICE_WIDTH, LimitTextDisplay, LIMIT_LINE_CHART_LEGEND_DISPLAY_SCROLL, LINE_CHART_DOT_RADIUS, LIST_COLOR_OF_MONTHS } from '@app/constants';
import { getLongText, onlyUnique } from '@app/helpers';

@Component({
  selector: 'app-line-chart-form-data',
  templateUrl: './line-chart-form-data.component.html',
  styleUrls: ['./line-chart-form-data.component.scss']
})
export class LineChartFormDataComponent implements OnChanges {
  @Input() listFormData: any;
  @ViewChild('legendContainer') legendContainerRef!: ElementRef;
  skipped = (ctx: any, value: any) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
  down = (ctx: any, value: any) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;
  optionsLineChart = {};
  dataLineChart: { labels: Array<string>, datasets: any } = {
    labels: [],
    datasets: []
  };
  plugins: any = [];
  constructor(
    private renderer2: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateChartHtmlLegend();
    this.generateChartData();
    this.generateChartOptions();
  }

  generateChartHtmlLegend() {
    const getOrCreateLegendList = (chart: any, id: any) => {
      const legendContainer = document.getElementById(id);
      let legendList = legendContainer?.querySelector('ul');
      if (!legendList) {
        legendList = document.createElement('ul');
        legendList.classList.add('chartLegend');
        this.renderer2.appendChild(legendContainer, legendList)
      }
      return legendList;
    }
    const htmlLegendPlugin = {
      id: 'htmlLegendPlugin',
      afterUpdate(chart: any, args: any, options: any) {
        const windowWidth = window.innerWidth;
        const isDevicePc = windowWidth > DEVICE_WIDTH.laptop.min;
        const isDeviceTablet = windowWidth >= DEVICE_WIDTH.tablet.min && windowWidth <= DEVICE_WIDTH.tablet.max;
        const isDeviceTabletExpand = windowWidth >= DEVICE_WIDTH.tablet.max && windowWidth <= DEVICE_WIDTH.tablet.expand;
        const isDeviceSp = windowWidth < DEVICE_WIDTH.phone.max;

        const ul = getOrCreateLegendList(chart, options.containerID);
        ul.style.display = 'grid';
        if (chart.data.datasets.length > LIMIT_LINE_CHART_LEGEND_DISPLAY_SCROLL) {
          ul.style.height = isDevicePc ? '200px' : '135px';
          ul.style.overflowY = 'scroll';
        } else {
          ul.style.height = 'auto';
          ul.style.overflowY = 'auto';
        }
        if (isDevicePc || isDeviceTabletExpand) ul.style.gridTemplateColumns = '24.5% 24.5% 24.5% 24.5%';
        if (isDeviceTablet) ul.style.gridTemplateColumns = '33% 33% 33%';
        if (isDeviceSp) ul.style.gridTemplateColumns = '49.5% 49.5%';

        while (ul.firstChild) ul.firstChild.remove();
        const legendItems = chart.options.plugins.legend.labels.generateLabels(chart);
        legendItems.forEach((item: any, index: number, arr: any) => {
          const li = document.createElement('li');
          li.classList.add('legendItem');
          li.style.cursor = 'pointer';
          li.style.listStyleType = 'none';
          li.style.marginTop = '0.375rem';
          li.style.display = 'grid';
          li.style.alignItems = 'center';
          if (isDevicePc) li.style.gridTemplateColumns = '10% 90%';
          if (isDeviceTablet || isDeviceTabletExpand) li.style.gridTemplateColumns = '15% 85%';
          if (isDeviceSp) li.style.gridTemplateColumns = '20% 80%';

          li.onclick = () => {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            chart.update();
          }
          const colorBox = document.createElement('span');
          colorBox.classList.add('colorBox');
          colorBox.style.borderColor = item.strokeStyle;
          colorBox.style.backgroundColor = item.strokeStyle;
          colorBox.style.display = 'block';
          colorBox.style.width = '20px';
          colorBox.style.height = '20px';
          const labelText = document.createElement('span');
          labelText.innerHTML = item.text.length > 25 ? getLongText(item.text, 25) : item.text;
          labelText.style.textDecoration = item.hidden ? 'line-through' : 'none';
          li.appendChild(colorBox);
          li.appendChild(labelText)
          if (ul) ul.appendChild(li);
        })
      }
    }
    this.plugins[0] = htmlLegendPlugin;
  }

  generateChartOptions() {
    this.optionsLineChart = {
      radius: LINE_CHART_DOT_RADIUS,
      maintainAspectRatio: false,
      responsive: true,
      fill: false,
      interaction: {
        intersect: false
      },
      plugins: {
        htmlLegendPlugin: {
          containerID: 'legend-container'
        },
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (chart: any) => {
              let tooltipEl = document.getElementsByClassName('chartjs-tooltip');
              if (tooltipEl[0]) {
                const centerWindow = window.innerWidth / 2;
                const dotX = chart.element.x;
                this.renderer2.setStyle(tooltipEl[0], 'transform', 'translate(-50%, 0)');
                if (dotX > centerWindow) this.renderer2.setStyle(tooltipEl[0], 'transform', 'translate(-100%, 0)');
                if (dotX < centerWindow) this.renderer2.setStyle(tooltipEl[0], 'transform', 'translate(0, 0)');
              }
              const label = chart.dataset.label.length > LimitTextDisplay.lineTooltipName
                ? chart.dataset.label.substring(0, LimitTextDisplay.lineTooltipName) + '...'
                : chart.dataset.label;
              return `${label}: ${chart.formattedValue}`;
            }
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy'
          },
          zoom: {
            wheel: {
              enabled: true
            },
            pinch: {
              enabled: true
            },
            mode: 'xy',
          }
        }
      }
    };
  }

  generateChartData() {
    this.dataLineChart = { labels: [], datasets: [] };
    const listFormDataName = this.listFormData.map((item: any) => item.name).filter(onlyUnique);
    this.dataLineChart.labels = this.listFormData.map((item: any) => item.dateTime).filter(onlyUnique);
    listFormDataName.forEach((formDataName: any, index: number) => {
      const color = this.getColorByIndex(index);
      const data = {
        label: formDataName,
        backgroundColor: color,
        borderColor: color,
        pointBackgroundColor: color,
        pointBorderColor: COLOR_WHITE,
        spanGaps: true,
        segment: {
          borderColor: (ctx: any) => this.skipped(ctx, color),
          borderDash: (ctx: any) => this.skipped(ctx, [6, 6]),
        },
      }
      const dataValue: Array<number> = [];
      this.listFormData.forEach((formData: any) => {
        if (formData.name === formDataName) {
          for (const [index, label] of this.dataLineChart.labels.entries()) {
            if (formData.dateTime === label) dataValue[index] = formData.value;
            else formData[index] = NaN;
          }
        }
      })
      const dataSet = { ...data, data: dataValue }
      this.dataLineChart.datasets.push(dataSet);
    })
  }

  getColorByIndex(index: number) {
    const colorIndex = index % LIST_COLOR_OF_MONTHS.length;
    return LIST_COLOR_OF_MONTHS[colorIndex];
  }
}
