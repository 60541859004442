<app-layout-auth [backgroundColor]="getFormInfo('backgroundCode')">
  <header class="form-user-header container">
    <a class="logo">
      <div>{{ getFormInfo("organizationName") }}</div>
    </a>
  </header>
  <div class="container-reissue container">
    <h3>{{ getFormInfo('title') }}</h3>
    <p *ngIf="isDisplayTargetDate(prefixForm)">{{ ('form.inputLabel.eventDate' | translate) + '：' + getFormInfo("targetDate") }}</p>
    <div [innerHTML]="getFormInfo('remark') | safeHtml"></div>
    <c-col>
      <c-col class="mt-3 mb-5">
        <h5>{{ "reissue.title" | translate }}</h5>
      </c-col>
      <c-card class="card-no-border">
        <c-card-body class="card-border-bottom" [ngStyle]="{ 'color': getFormInfo('stringCodeForm') }">
          <form cForm [formGroup]="formReissue" novalidate>
            <div class="mb-3">
              <label class="label-name" cLabel for="name">
                {{ "reissue.form.name" | translate }}
                <c-badge color="danger">{{ "reissue.form.required" | translate }}</c-badge>
              </label>
              <input cFormControl id="name" formControlName="name" type="text" />
              <c-form-check class="text-danger"
                *ngIf="getFirstError(f['name']?.errors) === 'required' && submitted">
                {{ "errorMessages.formValidate.formName.required" | translate }}
              </c-form-check>

            </div>
            <div class="mb-3">
              <label class="label-name" cLabel for="email">
                {{ "reissue.form.email" | translate }}
                <c-badge color="danger">{{ "reissue.form.required" | translate }}</c-badge>
              </label>
              <input cFormControl formControlName="email" id="email" type="text" />
              <c-form-check class="text-danger" *ngIf="getFirstError(f['email']?.errors) === 'required' && submitted">
                {{ "errorMessages.formValidate.formName.required" | translate }}</c-form-check>
              <c-form-check class="text-danger" *ngIf="getFirstError(f['email']?.errors) === 'pattern' && submitted">
                {{ "errorMessages.email.inCorrect" | translate }}</c-form-check>
            </div>
            <div class="d-flex justify-content-end">
              <button [disabledOnLoading]="true" [loading]="isClickLoading" cLoadingButton (click)="submit()" cButton
                [style.--string-color]="prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC + ' !important' : getFormInfo('stringCodeForm')"
                [style.--background-color]="prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC + ' !important' : getFormInfo('backgroundCode')"
                [class]="{'btn-submit-dynamic-color': getFormInfo('backgroundCode').length
                  && getFormInfo('backgroundCode') != text.defaultBackgroundCode}"
                class="label-name btn-common btn-submit" type="submit"
              >
                {{ "reissue.form.send" | translate }}
              </button>
            </div>
          </form>
        </c-card-body>
      </c-card>
    </c-col>
  </div>
  <div class="text-center mt-5 pb-2 text-footer" [innerHTML]="getFormInfo('copyright') | safeHtml"></div>
</app-layout-auth>
