import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Group, GroupUsers } from "@app/models/group";
import { UserService } from "@app/services/user.service";
import { initializeApp } from "@angular/fire/app";
import { environment } from "@environments/environment";
import { getAuth } from "@firebase/auth";
import { ulid } from "ulid";
import { MemberJoinStatus, ROLE_STATUS } from "@app/constants";
import { combineLatest, filter, firstValueFrom, map, switchMap } from "rxjs";
import { removeItem } from "@app/helpers";
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private app = initializeApp(environment.firebaseConfig);
  private auth = getAuth(this.app);

  constructor(
    private firestore: AngularFirestore,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  async create(data: Group) {
    const batch = this.firestore.firestore.batch();
    const uid = this.auth.currentUser?.uid ?? ''
    const user: any = (await firstValueFrom(this.userService.getUserInById(uid))).payload.data()
    const email = this.auth.currentUser?.email
    const name = user?.name;
    const groupId = ulid();
    const docRefGroups = this.firestore.collection('users').doc(uid).collection('groups').doc(groupId).ref;
    const docRefGroupUser = this.firestore.collection('users').doc(uid).collection('groups').doc(groupId).collection('group_users').doc(uid).ref;
    if (user && email) {
      const dataUserOwner = {
        status: MemberJoinStatus.accepted,
        owner: true,
        email: email,
        name: name
      };
      const dataGroup = { ...data, createdAt: new Date(), emails: [this.auth.currentUser?.email] }
      batch.set(docRefGroups, dataGroup);
      batch.set(docRefGroupUser, dataUserOwner);
      await batch.commit();
    }
  }

  index(groupUserPath: any) {
    return combineLatest(groupUserPath.map((path: string) => {
      return this.firestore.doc(path).valueChanges().pipe(switchMap((group: any) => {
        return this.firestore.doc(path).collection('group_users').snapshotChanges().pipe(
          filter((item) => !!item),
          map((res) => {
            if (group) {
              const groupUsers: any[] = [];
              res.forEach((item: any) => {
                const user = item.payload.doc.data()
                user.id = item.payload.doc.ref.id
                groupUsers.unshift(user)
              })
              group.groupUsers = groupUsers.sort((a, b) => b.owner - a.owner);
              group.path = path;
            }
            return group
          }))
      }))
    }))
  }

  async getHighestPermissionGroupUsers(currentUser: any, formId: string) {
    let permission = null;
    if (currentUser.parentUserId && currentUser.parentUserId.length) {
      const groups = await firstValueFrom(this.firestore.collection('users').doc(currentUser?.parentUserId).collection('groups', ref => ref.where('formIds', 'array-contains', formId)).snapshotChanges())
      const groupData = groups.filter((group: any) => group.payload?.doc.data()['emails'].includes(currentUser.email));
      let countRoleFull = 0;
      let countRoleReadAndAdd = 0;
      let countRoleReadonly = 0;
  
      for (const group of groupData) {
        const groupUsersData = (await group.payload.doc.ref.collection('group_users').where('email', '==', currentUser.email).get()).docChanges();
        countRoleFull += groupUsersData.filter((item: any) => item.doc.data().permission === ROLE_STATUS.full).length;
        countRoleReadAndAdd += groupUsersData.filter((item: any) => item.doc.data().permission === ROLE_STATUS.readAndAdd).length;
        countRoleReadonly += groupUsersData.filter((item: any) => item.doc.data().permission === ROLE_STATUS.readonly).length;
      }
      if (countRoleFull > 0) permission = ROLE_STATUS.full;
      else if (countRoleReadAndAdd > 0) permission = ROLE_STATUS.readAndAdd;
      else if (countRoleReadonly > 0) permission = ROLE_STATUS.readonly;
    }
    return permission;
  }

  getSubscribeGroupUserPathByEmail(email: string) {
    return this.firestore.collectionGroup('group_users', ref => ref.where('email', '==', email).orderBy('owner')).snapshotChanges()
  }

  getGroupUserPathsByEmail() {
    const email = this.auth.currentUser?.email ?? ''
    return this.firestore.collectionGroup('group_users', ref => ref.where('email', '==', email).orderBy('owner')).snapshotChanges()
  }

  getSnapshotGroupUserPathsByEmail(email: string) {
    return this.firestore.collectionGroup('group_users', ref => ref.where('email', '==', email).orderBy('owner')).snapshotChanges()
  }

  getGroupUsersByPath(path: string) {
    return this.firestore.collection(path + '/group_users').snapshotChanges();
  }

  async inviteUserToGroup(path: string, users: { email: string, id: string, name: string, status: number, owner: boolean }[], permission: string) {
    const batch = this.firestore.firestore.batch();
    users.forEach((user) => {
      const newDocRef = this.firestore.collection(path + '/group_users').doc(user.id).ref;
      batch.set(newDocRef, {
        email: user.email,
        name: user.name,
        owner: user.owner,
        status: user.status,
        permission: permission
      })
    })
    await batch.commit();
  }

  getGroupByPath(path: string) {
    return this.firestore.doc(path).snapshotChanges();
  }

  getGroupsByEmail(ownerUid: string, email:string) {
    return this.firestore.collection('users').doc(ownerUid).collection('groups', ref => ref.where('emails', 'array-contains', email).orderBy('createdAt', 'desc')).get();
  }

  async updateGroupName(groupPath: string, data: Partial<Group>) {
    await this.firestore.doc(groupPath).update(data)
  }

  async deleteUserFromGroup(group: Group, user: any) {
    const batch = this.firestore.firestore.batch();
    const groupRef = this.firestore.doc(group.path ?? '').ref;
    const groupUserRef = this.firestore.doc(group.path ?? '').collection('/group_users').doc(user.id).ref;
    const emails = group.emails ?? [];
    group.emails = removeItem(emails, user.email)
    const { groupUsers, path, ...dataGroup } = group
    batch.update(groupRef, dataGroup)
    batch.delete(groupUserRef)
    await batch.commit();
  }

  async joinToGroup(group: Group, userId: string, data: GroupUsers) {
    const batch = this.firestore.firestore.batch();
    const groupRef = this.firestore.doc(group.path ?? '').ref;
    const groupUserRef = this.firestore.doc(group.path ?? '').collection('group_users').doc(userId).ref;
    const emails = group.emails ?? [];
    if (data.email) group.emails = [...emails, data.email]
    const { groupUsers, path, ...dataGroup } = group
    batch.update(groupRef, dataGroup)
    batch.update(groupUserRef, data)
    await batch.commit();
  }

  async delete(group: any) {
    const batch = this.firestore.firestore.batch();
    const groupRef = this.firestore.doc(group.path).ref;
    batch.delete(groupRef);
    if (group.groupUsers) {
      for (const key in group.groupUsers) {
        const docRef = this.firestore.doc(group.path + '/group_users/' + group.groupUsers[key].id).ref;
        batch.delete(docRef)
      }
    }
    await batch.commit();
  }

  async shareFormToGroups(data: Array<{ path: string, formIds: Array<string> }>) {
    const batch = this.firestore.firestore.batch();
    for (const key in data) {
      const { path, ...dataUpdate } = data[key]
      const newDocRef = this.firestore.doc(path).ref;
      batch.set(newDocRef, dataUpdate)
    }
    await batch.commit();
  }

  getGroupUsersChildAccountByEmail(email: string) {
    return this.firestore.collectionGroup('group_users', ref => ref.where('email', '==', email)).snapshotChanges();
  }

  getGroupChildAccountByEmail(uid: string, email: string) {
    return this.firestore.collection('users').doc(uid).collection('groups', ref => ref.where('emails', 'array-contains', email)).snapshotChanges();
  }

  deleteChildAccountInGroup(childAccountGroup: any, email: string) {
    const batch = this.firestore.firestore.batch();
    childAccountGroup.groupUser.forEach((groupUserItem: string) => {
      const groupUserRef = this.firestore.doc(groupUserItem).ref;
      batch.delete(groupUserRef);
    })
    childAccountGroup.group.forEach((groupItem: any) => {
      const groupRef = this.firestore.doc(groupItem.path).ref;
      const dataUpdate = {emails: removeItem(groupItem.emails, email)};
      batch.update(groupRef, dataUpdate)
    })
    batch.commit();
  }
}
