import { FormArray, FormControl, FormGroup } from "@angular/forms";
import {
  AccountPlanType,
  ACCOUNT_DEFAULT_RESTRICT,
  formatEntryNo,
  ITEM_PER_PAGE,
  KEYCODE_ENTER,
  MaximumChildAccountCreated,
  MAXIMUM_FORM_CREATED_FREE_PLAN,
  MEDIA_INPUT,
  PERMISSION_GROUP_LIST,
  RANDOM_LOWERCASE,
  RANDOM_UPPERCASE,
  ROLE_STATUS,
  RuleValidations, SEARCH_FIRST_CHARACTER_WITH_SPACE,
  text
} from "@app/constants";
import { IAccountRestrict } from "@app/models";
import { IPermission } from "@app/models/group";
import { Timestamp } from "firebase/firestore";
import * as moment from 'moment';

export function removeItem(arr: Array<any>, value: any, usingIndex: boolean = false) {
  return arr.filter(function (ele, index) {
    if (usingIndex) return value !== index
    return ele !== value;
  });
}

export function onlyUnique(value: any, index: number, array: Array<any>) {
  return array.indexOf(value) === index;
}

export function chunkArray(array: Array<any>, perChunk: number) {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])
}

export function checkInputEnter(event: any) {
  if (event.keyCode == KEYCODE_ENTER) {
    event.preventDefault();
  }
}

export function checkDataTypeFormUser(node: any, data: any, key: string) {
  if (Boolean(node.length)) {
    if (node[0].datatype === MEDIA_INPUT.image || node[0].datatype === MEDIA_INPUT.movie || node[0].datatype === MEDIA_INPUT.sound) {
      return {
        url: data[key],
        isMedia: true,
        dataType: node[0].datatype
      }
    }
  }
  return data[key];
}

export function compareArray(baseArray: Array<any>, checkArray: Array<any>) {
  baseArray.sort();
  checkArray.sort();
  return baseArray.length === checkArray.length && baseArray.every((element, index) => element === checkArray[index]);
}

export function compareArrayWithPosition(baseArray: any[], checkArray: any[]): boolean {
  let isMatch = true;

  for (let i = 0; i < baseArray.length; i++) {
    if (baseArray[i] !== checkArray[i]) {
      isMatch = false;
      break;
    }
  }

  return isMatch;
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getSubstringTextNumber(sectionClassName: string, sectionParentClassName: string, isTextWidth: boolean = false) {
  const deviceWidth = window.innerWidth;
  const dataSectionWidth = document.querySelector(sectionClassName)?.clientWidth;
  const dataSectionParentWidth = document.querySelector(sectionParentClassName)?.clientWidth;
  let textDisplay = 12;
  if (dataSectionWidth) {
    const textSectionWidth = 10;
    textDisplay = Math.floor(Number(dataSectionWidth) / textSectionWidth);
    if (deviceWidth <= 768) {
      let textSpWidth = 20;
      if (isTextWidth) textSpWidth = 25;
      textDisplay = Math.floor(Number(dataSectionParentWidth) / textSpWidth);
    }
  }
  return textDisplay;
}

export function getLongText(text?: string, numberOfTextDisplay?: number) {
  if (!text) return "";
  if (numberOfTextDisplay && text?.length < numberOfTextDisplay) return text;
  return text?.substring(0, numberOfTextDisplay) + '...';
}

export function getFirstError(errors: any) {
  if (errors) {
    const [firstError] = Object.keys(errors);
    return firstError;
  }
  return null;
}

export function formatUnitNumber(num: number) {
  return (num < 10) ? '0' + num.toString() : num.toString();
}

export function formatDateString(date: Date, month: string | number) {
  return `${date.getFullYear()}\/${month}\/${date.getDate()}`;
}

export function getEntryno() {
  return moment().format(formatEntryNo);
}

export function getLocaleDatePicker(currentLanguage: string): string {
  if (currentLanguage === 'en') return 'en-US';
  else return 'ja-JP';
}

export function camelize(str: string) {
  return str.replace(RuleValidations.regexFirstLetter, function (match, index) {
    if (+match === 0) return "";
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function titleCase(string: string) {
  return string[0].toUpperCase() + string.substr(1).toLowerCase()
}

export function getTotalPropertyValue(key: string, list: any) {
  return list.reduce((a: any, b: any) => Number(a) + (Number(b[key]?.replace(RuleValidations.getNumberInString, '') || 0)), 0);
}

export function convertMbToB(size: number) {
  return 1024 * 1024 * size
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

export function scrollToBottom() {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
}

export function getDataNotice(data: any, language: string) {
  let result = data;
  if (result && typeof result === 'object') {
    result = result[language]
  }
  return result;
}

export function getMessage(message: string, variables?: { [key: string]: string; }) {
  let result = message;
  if (variables) {
    Object.keys(variables).forEach((item) => {
      const itemVariable = '={' + item + '}='
      result = result.replace(RegExp(itemVariable, "g"), variables[item])
    })
  }
  return result
}

export function checkColorBg(color: string) {
  if (text['defaultBackgroundCode'] != color) return color
  return text.baseColor
}

export function removeObjectFromArray(array: Array<any>, valueObjectRemove: any, fieldOfObject: string) {
  return array.filter(item => item[fieldOfObject] !== valueObjectRemove);
}

export function displayPageIndex(pageNumber: number, index: number) {
  const pageIndex = (pageNumber - 1) * ITEM_PER_PAGE + index + 1;
  if (pageIndex < ITEM_PER_PAGE) return `0${pageIndex.toString()}`
  return pageIndex.toString();
}
export function isSubset(array1: Array<any>, array2: Array<any>) {
  for (let i = 0; i < array1.length; i++) {
    const item = array1[i];
    if (!array2.some((element) => JSON.stringify(element) === JSON.stringify(item))) {
      return false;
    }
  }
  return true;
}

export function deepClone(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item: any) => deepClone(item));
  } if (typeof obj === 'object' && obj !== null) {
    const clonedObj: any = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] instanceof Timestamp) {
        clonedObj[key] = obj[key];
      } else {
        clonedObj[key] = deepClone(obj[key]);
      }
    });
    return clonedObj;
  } else {
    return obj;
  }
}

export function deepCloneFormGroup(value: any): any {
  if (Array.isArray(value)) {
    return new FormArray([...value.map((item: any) => deepCloneFormGroup(item))]);
  } else if (typeof value === 'object' && value) {
    const clonedObj: any = {};
    Object.keys(value).forEach((key) => {
      clonedObj[key] = deepCloneFormGroup(value[key]);
    })
    return new FormGroup(clonedObj);
  } else {
    return new FormControl(value);
  }
}

export function getPermissionRoleGroupUser(permissionData: string | null = null) {
  let permissionConfig: IPermission = PERMISSION_GROUP_LIST.full;
  let userPermission = localStorage.getItem('permission');
  if (!localStorage.getItem('permission')) userPermission = permissionData;
  if (userPermission) {
    switch (userPermission) {
      case ROLE_STATUS.readonly: {
        permissionConfig = PERMISSION_GROUP_LIST.readonly;
        break;
      }
      case ROLE_STATUS.readAndAdd: {
        permissionConfig = PERMISSION_GROUP_LIST.readAndAdd;
        break;
      }
      default: {
        permissionConfig = PERMISSION_GROUP_LIST.full;
      }
    }
  }
  return permissionConfig;
}

export function checkStringContainsHTMLTags(str: string) {
  const div = document.createElement('div');
  div.innerHTML = str;
  return !!div.getElementsByTagName('*').length;
}

export function inputNumber(value: string) {
  const arrayValue = value.split('');
  return arrayValue.filter((item: string) => !isNaN(Number(item)) && item !== 'e').join('');
}

export function titleCaseAllWord(string: string) {
  return string.replace(
    SEARCH_FIRST_CHARACTER_WITH_SPACE,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function synchronizeValuesOfDeepData(data: any, valueChange: any): any {
  if (Array.isArray(data)) {
    return data.map((item) => synchronizeValuesOfDeepData(item, valueChange));
  } else if (typeof data === 'object' && !!data) {
    const nullObject: any = {};
    for (const key in data) {
      nullObject[key] = synchronizeValuesOfDeepData(data[key], valueChange);
    }
    return nullObject;
  } else {
    return valueChange;
  }
}

export function setValueOfDeepData(data: any, keys: any[], valueChange: any) {
  let currentData = data;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === keys.length - 1) {
      currentData[key] = valueChange;
    } else {
      currentData = currentData[key];
    }
  }
  return data
}

export function replaceAllForString(search: string, replace: string, string: string) {
  return string.replace(new RegExp(search, 'g'), replace);
}

export function generateRandomString(length: number, randomCharacter: string = '') {
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * randomCharacter.length);
    randomString += randomCharacter.charAt(randomIndex);
  }
  return randomString;
}

// TODO: generate random string with at least 1 uppercase, 1 lowercase and 1 number
export function generateRandomPassword(): any {
  const randomNumber =  Math.floor(Math.random() * 10);
  const randomCharacter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const randomString = generateRandomString(1, RANDOM_UPPERCASE) + generateRandomString(1, RANDOM_LOWERCASE) + randomNumber + generateRandomString(5, randomCharacter);
  return randomString;
}

export function formatStringToDate(dateString: any = '') {
  let date: any = null;
  if (dateString.length) {
    const [year, month, day, hour, minute, second] = dateString.split(/[- :]/);
    date = new Date(year, month - 1, day, hour, minute, second);
  }
  return date;
}

export function addParamToUrl(param: string, value: string | string[] | null, currentRoute: string = '') {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (!value) {
    // Remove the param from the URL
    urlSearchParams.delete(param);
  } else {
    // Add or update the param in the URL
    if (Array.isArray(value)) {
      value.forEach((v) => urlSearchParams.append(param, v));
    } else {
      urlSearchParams.set(param, value);
    }
  }
  if (value!.length) {
    const newUrl = `${currentRoute}?${urlSearchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }
}

export function getDuplicateIndexItemInArray(list: Array<string>) {
  const duplicateNameForms = list.reduce((acc: any, value, index) => {
    if (acc[value]) acc[value].push(index + 1);
    else acc[value] = [index + 1];
    return acc;
  }, {});
  return Object.entries(duplicateNameForms)
    .filter(([key, value]) => (value as any).length > 1)
    .flatMap(([key, value]) => value);
}

export function filterAddressInPhoneTable(phoneTableList: Array<any>, address: string, propertyName: string) {
  let value = "";
  if (propertyName === 'phone') value = phoneTableList.filter((phoneTable: any) => phoneTable.prefecture === 'default')[0].phone;
  const checkPrefecture = phoneTableList.filter((phoneTable: any) => {
    const addressList = phoneTable.prefecture.split(' ');
    const checkAddress = addressList.filter((addressText: string) => address.includes(addressText));
    return addressList.length === checkAddress.length;
  }).sort((prevValue: any, nextValue: any) => nextValue.prefecture.length - prevValue.prefecture.length);
  if (checkPrefecture.length) value = checkPrefecture[0][propertyName];
  return value;
}

export function checkAccountRestrictMode(user: any, totalRecords: { form?: number, childAccount?: number } = { form: 0, childAccount: 0 }): IAccountRestrict {
  let accountRestrictConfig: IAccountRestrict = ACCOUNT_DEFAULT_RESTRICT;
  switch (user.accountType) {
    case AccountPlanType.free: {
      accountRestrictConfig = {
        isDisabledCreateForm: false,
        isCreateGroup: false,
        isDisplayTemplateList: false,
        isDisplayChildAccountList: false,
        isDisplayCameraSetting: false,
        isDisabledCreateChildAccount: true
      }
      if (totalRecords.form) {
        accountRestrictConfig['isDisabledCreateForm'] = Boolean(totalRecords.form >= MAXIMUM_FORM_CREATED_FREE_PLAN)
      }
      break;
    }
    case AccountPlanType.basic: {
      accountRestrictConfig['isCreateGroup'] = false;
      accountRestrictConfig['isDisplayChildAccountList'] = false;
      accountRestrictConfig['isDisplayCameraSetting'] = false;
      accountRestrictConfig['isDisabledCreateChildAccount'] = true;
      break;
    }
    case AccountPlanType.standard: {
      accountRestrictConfig['isDisplayCameraSetting'] = false;
      if (totalRecords.childAccount) {
        accountRestrictConfig['isDisabledCreateChildAccount'] = Boolean(totalRecords.childAccount >= MaximumChildAccountCreated.standard)
      }
      break;
    }
    case AccountPlanType.premium: {
      if (totalRecords.childAccount) {
        accountRestrictConfig['isDisabledCreateChildAccount'] = Boolean(totalRecords.childAccount >= MaximumChildAccountCreated.premium)
      }
      break;
    }
    case AccountPlanType.premiumhd: {
      if (totalRecords.childAccount) {
        accountRestrictConfig['isDisabledCreateChildAccount'] = Boolean(totalRecords.childAccount >= MaximumChildAccountCreated.premiumhd);
      }
      break;
    }
    default: {
      if (totalRecords.childAccount) {
        accountRestrictConfig['isDisabledCreateChildAccount'] = Boolean(totalRecords.childAccount >= MaximumChildAccountCreated.premiumpro);
      }
    }
  }
  return accountRestrictConfig;
}

export function checkImportExceedPlanCreateChildUser(user: any, total: {totalFileRecords: number, totalUserList: number}) {
  let isValid = true;
  const totalRecords = total.totalFileRecords + total.totalUserList;
  switch (user.accountType) {
    case AccountPlanType.standard: {
      if (totalRecords > MaximumChildAccountCreated.standard) isValid = false;
      break;
    }
    case AccountPlanType.premium: {
      if (totalRecords > MaximumChildAccountCreated.premium) isValid = false;
      break;
    }
    case AccountPlanType.premiumhd: {
      if (totalRecords > MaximumChildAccountCreated.premiumhd) isValid = false;
      break;
    }
    default: {
      if (totalRecords > MaximumChildAccountCreated.premiumpro) isValid = false;
    }
  }
  return isValid;
}

export function formatSystemTemplateList(systemTemplateList: any) {
  const formatSystemTemplateList = systemTemplateList.map((item: any) => {
    const docId = item.payload.doc.id;
    const systemTemplate: any = item.payload.doc.data();
    systemTemplate.docId = docId;
    systemTemplate.nodes = JSON.parse(systemTemplate.nodes);
    return systemTemplate;
  }).filter((item: any) => item.nodes.meta.publish).sort((prev: any, next: any) => prev.displayIndex - next.displayIndex);
  return formatSystemTemplateList;
}

export function getNumberInString(str: string) {
  return Number(str?.match(RuleValidations.extractNumberInString));
}

export function dayFormat(date: Date) { return date.getDate(); }

export function formatDateObj(date: Date) {
  var dateObj = new Date(date),
  month = '' + (dateObj.getMonth() + 1),
  day = '' + dateObj.getDate(),
  year = dateObj.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}
