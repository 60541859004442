import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RuleValidations } from "@app/constants";

@Component({
  selector: 'app-input-link',
  templateUrl: './input-link.component.html',
  styleUrls: ['./input-link.component.scss']
})
export class InputLinkComponent implements OnChanges {
  @Input() link: string = ''
  @Input() placeholder: string | null = ''
  @Input() disabled: boolean = false
  @Output() dataLink = new EventEmitter<string | null>();
  checkLinkValid = false;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkLinkValid = RuleValidations.REGEX_LINK.test(this.link)
  }

  changeLink(event: any) {
    const link = event.target.value;
    this.link = '';
    this.checkLinkValid = RuleValidations.REGEX_LINK.test(link)
    this.dataLink.emit(link)
    if (RuleValidations.REGEX_LINK.test(link)) this.link = link
  }
}
