import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { initializeApp } from "@angular/fire/app";
import { environment } from "@environments/environment";
import { getAuth } from "@firebase/auth";
import { ulid } from 'ulid';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private app = initializeApp(environment.firebaseConfig);
  private auth = getAuth(this.app);
  constructor(
    private firestore: AngularFirestore,
  ) { }

  getTemplateList() {
    const userId = this.auth.currentUser?.uid ?? '';
    return this.firestore.collection('users').doc(userId).collection('templates', ref => ref.orderBy('createdAt', 'desc')).snapshotChanges();
  }

  getValueTemplateList(userId: string) {
    return this.firestore.collection('users').doc(userId).collection('templates', ref => ref.orderBy('createdAt', 'desc')).valueChanges();
  }

  delete(docId: string) {
    return this.firestore.collection('users').doc(this.auth.currentUser?.uid ?? '').collection('templates').doc(docId).delete();
  }

  create(data: any) {
    const templateId = ulid();
    const dataUpdate = { ...data, docId: templateId };
    return this.firestore.collection('users').doc(this.auth.currentUser?.uid ?? '').collection('templates').doc(templateId).set(dataUpdate);
  }

  getDetailTemplateById(id: string) {
    const userId = this.auth.currentUser?.uid ?? '';
    return new Promise((resolve, reject) => {
      this.firestore.collection('users').doc(userId).collection('templates').doc(id).valueChanges().subscribe((response: any) => {
        try {
          response = {...response, nodes: JSON.parse(response.nodes)};
          resolve(response)
        } catch(e) {
          reject('get template fail');
        }
      });
    });
  }

  async update(docId: string, data: any) {
    const userId = this.auth.currentUser?.uid ?? '';
    await this.firestore.collection('users').doc(userId).collection('templates').doc(docId).update(data);
  }

  getSnapshotSystemTemplateList() {
    return this.firestore.collection('systemtemplates').snapshotChanges();
  }

  getValueOwnerTemplateById(userId: string, templateId: string) {
    return this.firestore.collection('users').doc(userId).collection('templates').doc(templateId).valueChanges();
  }

  getSnapshotTemplateList(userId: string) {
    return this.firestore.collection('users').doc(userId).collection('templates', ref => ref.orderBy('createdAt', 'desc')).snapshotChanges()
  }
}
