<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="mb-5 d-flex box-head-form">
    <c-col [xl]="6" [lg]="6" [md]="7">
      <h2 class="mb-0">{{'tab.title' | translate}}</h2>
    </c-col>
  </c-col>
  <c-card class="mb-5">
    <c-card-body>
      <form action="" [formGroup]="formAddTab">
        <div class="mb-3 w-100 section-header">
          <label cLabel for="inputEmail-1">{{'tab.formCreate.label' | translate}}</label>
          <div class="d-flex w-100 justify-content-between block-action align-items-center">
            <div class="input-block">
              <input cFormControl id="inputEmail-1" type="text" formControlName="name">
              <span class="text-danger" *ngIf="formAddTab.controls.name.errors?.['required'] && isSubmitted">{{'errorMessages.tab.name.required' | translate}}</span>
              <span class="text-danger" *ngIf="formAddTab.controls.name.errors?.['maxlength'] && isSubmitted">{{'errorMessages.tab.name.maxlength' | translate: {maxlength: RuleValidations.maxLength255} }}</span>
            </div>
            <button class="btn-common btn-add-tab" (click)="createTab()">{{'tab.formCreate.create' | translate}}</button>
          </div>
        </div>
      </form>
    </c-card-body>
  </c-card>
  <c-card class="mb-5">
    <c-card-body *ngIf="this.tabs?.length">
      <ul cListGroup cdkDropList id="list" class="list" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let tab of tabs index as i">
          <li cListGroupItem [cdkDragDisabled]="!!tab['isDisable']" class="drag-content d-flex align-items-center justify-content-between cursor-pointer" cdkDrag *ngIf="i + 1 <= maxItemShow">
            <div cdkDragHandle>
              <div class="d-flex align-items-center">
                <div class="w-10 drag-icon me-3">
                  <img src="./assets/svg/common/drag.svg" alt="">
                </div>
                <div class="w-100">
                  <span (click)="editTab(tab)">{{getLongText(tab.name, numberOfTextDisplay)}}</span>
                </div>
              </div>
            </div>
            <div class="drag-action">
              <div class="drag-action__disabled">
                <c-form-check class="block-disabled">
                  <input cFormCheckInput [id]="'check' + i" (change)="changeDisabled(tab)" [checked]="tab.isDisable" type="checkbox"/>
                  <label cFormCheckLabel [for]="'check' + i">{{'tab.disabled' | translate}}</label>
                </c-form-check>
              </div>
              <div class="drag-action__button">
                <span class="up" *ngIf="i" (click)="moveUp(tab)">
                  <img src="./assets/svg/common/down-circle.svg" alt="" [style]="stylesButton">
                </span>
                <span *ngIf="(i != ((tabs?.length ?? 0) - 1)) && (tabs?.length ?? 0) > 1" (click)="moveDown(tab)">
                  <img src="./assets/svg/common/down-circle.svg" alt="">
                </span>
              </div>
              <div class="drag-action__delete">
                <span *ngIf="tab.isDisplayDeleteButton" (click)="toggleDeleteTabModal(tab)">
                  <img class="icon-action" src="assets/svg/delete.svg"/>
                </span>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="(tabs?.length ?? 0) > maxItemShow && !showFullItem" class="show-more cursor-pointer mt-2" (click)="showMoreItem()">
        <span>{{'tab.showMore' | translate}}</span>
        <img src="./assets/svg/common/arrow-down.svg" alt="">
      </div>
      <div class="d-flex justify-content-end">
        <div class="d-flex justify-content-end" *ngIf="isChanged" (click)="updateIndexTab()">
          <button class="btn-common mt-3">{{'tab.save' | translate}}</button>
        </div>
        <div class="d-flex justify-content-end ms-3" *ngIf="isChanged" (click)="resetData()">
          <button class="btn-second mt-3">{{'tab.reset' | translate}}</button>
        </div>
      </div>
    </c-card-body>
  </c-card>
</c-container>
<app-edit-tab [tab]="tabSelected" [authUser]="authUser" [visible]="editModal" (modalStatus)="receiveModalStatus($event)"></app-edit-tab>
<c-modal id="deleteModal" class="custom-modal custom-modal__no-body" [visible]="visibleDeleteTabModal">
  <c-modal-header>
    <h5>
      <b>{{ "tab.modal.delete.title" | translate }}</b>
    </h5>
    <button cButtonClose (click)="toggleDeleteTabModal('')"></button>
  </c-modal-header>
  <c-modal-footer>
    <button cButton class="btn-close-modal" (click)="toggleDeleteTabModal('')">
      {{ "tab.modal.delete.no" | translate }}
    </button>
    <button cButton class="btn-confirm-modal" (click)="deleteTab()" [disabled]="isDisabledDeleteButton">
      {{ "tab.modal.delete.yes" | translate }}
    </button>
  </c-modal-footer>
</c-modal>
