import { Component, OnDestroy, OnInit } from '@angular/core';
import { ACTION_LIST, EVENT_LOG_TYPE, INIT_EDITOR, RuleValidations, VARIABLES_PORTAL } from "@app/constants";
import { AlertService, ApiService, FirebaseAnalyticService, UserService } from "@app/services";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { getFirstError } from "@app/helpers";
import { firstValueFrom, Subscription } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreatePortalComponent implements OnInit, OnDestroy {
  initEditor = INIT_EDITOR;
  protected readonly INIT_EDITOR = INIT_EDITOR;
  protected readonly VARIABLES_PORTAL = VARIABLES_PORTAL;
  currentUser: any;
  formCreatePortal = new FormGroup({
    portalHTML: new FormControl<null | string>(null)
  })
  ruleValidations = RuleValidations;
  submitted: boolean = false;
  isLoadingSave: boolean = false;
  private baseUrl = environment.baseUrl;
  subscription: { [key: string]: Subscription | null } = {
    currentUser: null
  }

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser().then()
  }

  ngOnDestroy(): void {
    this.subscription['currentUser']?.unsubscribe();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.subscription['currentUser'] = this.userService.getValueUserByUid(authUser.uid).subscribe({
        next: (currentUser) => {
          if (currentUser) {
            this.currentUser = currentUser;
            this.formCreatePortal.controls.portalHTML.setValue(this.currentUser.portalHTML);
          }
        }
      })
    }
  }

  changeEditor(event: any) {
    const rawText: string = event.editor.getContent({ format: 'text' })
    const portalHTML = this.formCreatePortal.get('portalHTML')
    portalHTML?.setValidators([]);
    if (rawText.length > this.ruleValidations.maxLength4096) portalHTML?.setValidators([Validators.maxLength(this.ruleValidations.maxLength4096)]);
    this.formCreatePortal.controls.portalHTML.updateValueAndValidity();
  }

  async submit() {
    this.submitted = true;
    this.isLoadingSave = true;
    if (this.formCreatePortal.valid && this.currentUser.secretKey && this.currentUser.secretKey.length) {
      const dataUpdate = {
        secretKey: this.currentUser.secretKey,
        portalHTML: this.formCreatePortal.get('portalHTML')?.value
      }
      this.apiService.post(`${this.baseUrl}/update-portal-html`, dataUpdate).then(() => {
        this.isLoadingSave = false;
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_PORTAL_HTML, EVENT_LOG_TYPE.SUCCESS);
        this.alertService.success(this.translateService.instant('alertMessages.portal.edit.success'));
      }).catch(() => {
        this.isLoadingSave = false;
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_PORTAL_HTML, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.portal.edit.fail'));
      });
    } else {
      this.isLoadingSave = false;
      this.alertService.error(this.translateService.instant('alertMessages.portal.emptySecretKey'));
    }
  }

  protected readonly getFirstError = getFirstError;
}
