<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="justify-content-center mb-5">
    <h3>{{'form.examDetail.title' | translate}}</h3>
  </c-col>

  <c-card>
    <c-card-body>
      <form [formGroup]="formEditTest">
        <div class="block-index-header mb-3">
          <div class="index-data">
            <label cLabel for="index">{{'form.examDetail.input.index' | translate}}</label>
            <input readonly cFormControl formControlName="index" id="index" type="text" />
          </div>
          <div>
            <button class="btn-common" (click)="addQuestion()">{{'form.examDetail.input.addQuestion' | translate}}</button>
          </div>
        </div>

        <div class="form-edit-test">
          <c-nav variant="underline">
            <c-nav-item>
              <a [active]="true" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="0" cNavLink>
                {{ "layout.header.menu.lang.item.ja" | translate }}
              </a>
            </c-nav-item>
            <c-nav-item>
              <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="1" cNavLink>
                {{ "layout.header.menu.lang.item.en" | translate }}
              </a>
            </c-nav-item>
          </c-nav>
          <c-tab-content #tabContent="cTabContent" [activeTabPaneIdx]="1">
            <c-tab-pane class="pt-3 pb-3">
              <div class="mb-3" formGroupName="name">
                <label cLabel for="nameJa">{{'form.examDetail.input.name' | translate}} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></label>
                <input cFormControl id="nameJa" formControlName="ja" type="text"/>
                <c-form-check class="text-danger" *ngIf="formEditTest?.controls?.name?.controls?.ja?.errors && submitted">
                  {{ "errorMessages.formValidate.input.required" | translate }}
                </c-form-check>
              </div>
              <div class="mb-3" formGroupName="examDescription">
                <label cLabel for="examDescriptionJa">{{'form.examDetail.input.description' | translate}}</label>
                <editor formControlName="ja" id="examDescriptionJa" [init]="INIT_EDITOR"></editor>
              </div>
              <div formArrayName="questions">
                <div [formGroupName]="questionIndex" *ngFor="let question of this.formEditTest.controls.questions.controls; let questionIndex = index" class="mb-3 box-qa">
                  <div class="button-close" *ngIf="this.formEditTest.controls.questions.controls.length > 1">
                    <button cButtonClose (click)="prepareDeleteQuestion(questionIndex)"></button>
                  </div>
                  <div class="mb-3" formGroupName="question">
                    <label cLabel [for]="'q' + questionIndex + 'ja'">{{'form.examDetail.input.question' | translate}} {{questionIndex + 1}} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></label>
                    <textarea cFormControl [id]="'q' + questionIndex + 'ja'" formControlName="ja" rows="3"></textarea>
                    <c-form-check class="text-danger" *ngIf="question?.get('question')?.get('ja')?.errors && submitted">
                      {{ "errorMessages.formValidate.input.required" | translate }}
                    </c-form-check>
                  </div>
                  <div formArrayName="answerList">
                    <div [formGroupName]="answerIndex" class="mb-3 list-answer" *ngFor="let answer of getChildControls(question, 'answerList'); let answerIndex = index">
                      <div class="input-block">
                        <label cLabel [for]="'a' + questionIndex + String(answerIndex) + 'ja'">{{'form.examDetail.input.answer' | translate}}{{answerIndex + 1}} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></label>
                        <textarea cFormControl [id]="'a' + questionIndex + String(answerIndex) + 'ja'" formControlName="ja"></textarea>
                        <c-form-check class="text-danger" *ngIf="answer.get('ja')?.errors && submitted">
                          {{ "errorMessages.formValidate.input.required" | translate }}
                        </c-form-check>
                      </div>
                      <div class="btn-answer-list" [class]="{'d-none': getChildControls(question, 'answerList').length <= 1}">
                        <div class="drag-action__button">
                          <span class="up" *ngIf="answerIndex" (click)="moveUpAnswer(questionIndex, answerIndex)">
                            <img src="./assets/svg/common/down-circle.svg" alt="" [style]="{transform: 'rotate(-180deg)'}">
                          </span>
                          <span *ngIf="(answerIndex != ((getChildControls(question, 'answerList')?.length ?? 0) - 1)) && (getChildControls(question, 'answerList')?.length ?? 0) > 1" (click)="moveDownAnswer(questionIndex, answerIndex)">
                            <img src="./assets/svg/common/down-circle.svg" alt="">
                          </span>
                        </div>
                        <button cButton color="danger" class="button-remove-answer" (click)="removeAnswer(questionIndex, answerIndex)" variant="outline">
                          <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                            <g id="SVGRepo_iconCarrier">
                              <path fill-rule="evenodd" d="M18 10a1 1 0 01-1 1H3a1 1 0 110-2h14a1 1 0 011 1z"/>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>

                    <button cButton color="success" class="mb-3 button-add-answer" (click)="addAnswer(questionIndex)" variant="outline">
                      <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                        <g id="SVGRepo_iconCarrier">
                          <path fill-rule="evenodd" d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div formArrayName="correct">
                    <label cLabel >{{'form.examDetail.input.correctAnswer' | translate}} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></label>
                    <div class="mb-2 correct-list" *ngFor="let correct of getChildControls(question, 'correct'); let correctIndex = index">
                      <label cLabel [for]="'c-' + questionIndex + String(correctIndex) + '-ja'">{{'form.examDetail.input.answer' | translate}}{{correctIndex + 1}}</label>
                      <input cFormCheckInput [id]="'c-' + questionIndex + String(correctIndex) + '-ja'" (change)="changeCorrect(question)" type="checkbox" [formControlName]="correctIndex"/>
                    </div>
                    <c-form-check class="text-danger" *ngIf="question.get('correct')?.errors && submitted">
                      {{ "errorMessages.formValidate.correctAnswer.required" | translate }}
                    </c-form-check>
                  </div>
                </div>
              </div>
            </c-tab-pane>
            <c-tab-pane class="pt-3 pb-3">
              <div class="mb-3" formGroupName="name">
                <label cLabel for="nameEn">{{'form.examDetail.input.name' | translate}} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></label>
                <input cFormControl id="nameEn" formControlName="en" type="text"/>
                <c-form-check class="text-danger" *ngIf="formEditTest?.controls?.name?.controls?.en?.errors && submitted">
                  {{ "errorMessages.formValidate.input.required" | translate }}
                </c-form-check>
              </div>
              <div class="mb-3" formGroupName="examDescription">
                <label cLabel for="examDescriptionEn">{{'form.examDetail.input.description' | translate}}</label>
                <editor formControlName="en" id="examDescriptionEn" [init]="INIT_EDITOR"></editor>
              </div>
              <div formArrayName="questions">
                <div [formGroupName]="questionIndex" *ngFor="let question of this.formEditTest.controls.questions.controls; let questionIndex = index" class="mb-3 box-qa">
                  <div class="button-close" *ngIf="this.formEditTest.controls.questions.controls.length > 1">
                    <button cButtonClose (click)="prepareDeleteQuestion(questionIndex)"></button>
                  </div>
                  <div class="mb-3" formGroupName="question">
                    <label cLabel [for]="'q' + questionIndex + 'en'">{{'form.examDetail.input.question' | translate}} {{questionIndex + 1}} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></label>
                    <textarea cFormControl [id]="'q' + questionIndex + 'en'" formControlName="en" rows="3"></textarea>
                    <c-form-check class="text-danger" *ngIf="question?.get('question')?.get('en')?.errors && submitted">
                      {{ "errorMessages.formValidate.input.required" | translate }}
                    </c-form-check>
                  </div>
                  <div formArrayName="answerList">
                    <div [formGroupName]="answerIndex" class="mb-3 list-answer" *ngFor="let answer of getChildControls(question, 'answerList'); let answerIndex = index">
                      <div class="input-block">
                        <label cLabel [for]="'a' + questionIndex + String(answerIndex) + 'en'">{{'form.examDetail.input.answer' | translate}}{{answerIndex + 1}} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></label>
                        <textarea cFormControl [id]="'a' + questionIndex + String(answerIndex) + 'en'" formControlName="en" rows="3"></textarea>
                        <c-form-check class="text-danger" *ngIf="answer.get('en')?.errors && submitted">
                          {{ "errorMessages.formValidate.input.required" | translate }}
                        </c-form-check>
                      </div>
                      <div>
                        <button cButton color="danger" class="button-remove-answer" (click)="removeAnswer(questionIndex, answerIndex)" variant="outline">
                          <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                            <g id="SVGRepo_iconCarrier">
                              <path fill-rule="evenodd" d="M18 10a1 1 0 01-1 1H3a1 1 0 110-2h14a1 1 0 011 1z"/>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <button cButton color="success" class="mb-3 button-add-answer" (click)="addAnswer(questionIndex)" variant="outline">
                      <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                        <g id="SVGRepo_iconCarrier">
                          <path fill-rule="evenodd" d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div formArrayName="correct">
                    <label cLabel >{{'form.examDetail.input.correctAnswer' | translate}} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></label>
                    <div class="mb-2 correct-list" *ngFor="let correct of getChildControls(question, 'correct'); let correctIndex = index">
                      <label cLabel [for]="'c-' + questionIndex + String(correctIndex) + '-en'">{{'form.examDetail.input.answer' | translate}} {{correctIndex + 1}}</label>
                      <input cFormCheckInput [id]="'c-' + questionIndex + String(correctIndex) + '-en'" (change)="changeCorrect(question)" type="checkbox" [formControlName]="correctIndex"/>
                    </div>
                    <c-form-check class="text-danger" *ngIf="question.get('correct')?.errors && submitted">
                      {{ "errorMessages.formValidate.correctAnswer.required" | translate }}
                    </c-form-check>
                  </div>
                </div>
              </div>
            </c-tab-pane>
            <div class="d-flex justify-content-end">
              <button class="btn-second me-3">
                <a href="" class="btn-back" [routerLink]="'/form/edit/' + formId" [state]="{isRedirectFromTestDetail: true}">
                  {{'form.examDetail.input.back' | translate}}
                </a>
              </button>
              <button class="btn-common" (click)="submit()">{{'form.examDetail.input.save' | translate}}</button>
            </div>
          </c-tab-content>
        </div>
      </form>
    </c-card-body>
  </c-card>
</c-container>

<c-modal #deleteQuestionModal alignment="center" id="deleteQuestionModal" (visibleChange)="visibleChangeModal($event)">
  <c-modal-header>
    <h5 cModalTitle>{{'form.examDetail.input.modal.title' | translate}}</h5>
    <button [cModalToggle]="deleteQuestionModal.id" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    {{'form.examDetail.input.modal.message' | translate}}
  </c-modal-body>
  <c-modal-footer>
    <button [cModalToggle]="deleteQuestionModal.id" class="btn-second">
      {{'form.examDetail.input.modal.no' | translate}}
    </button>
    <button class="btn-common" (click)="removeQuestion(deleteQuestionIndex)">{{'form.examDetail.input.modal.yes' | translate}}</button>
  </c-modal-footer>
</c-modal>
