<form cForm [formGroup]="form">
  <div class="mb-3">
    <label cLabel for="titleJa">{{'template.formItemsEdit.inputLabel.titleJa' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl formControlName="title_ja" id="titleJa" type="text" (input)="changeShowErrors('title_ja')">
    <c-form-check *ngIf="(showErrors['title_ja'] || submitted) && form.get('title_ja')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('title_ja')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="titleEn">{{'template.formItemsEdit.inputLabel.titleEng' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl formControlName="title_eng" id="titleEn" type="text" (input)="changeShowErrors('title_eng')">
    <c-form-check *ngIf="(showErrors['title_eng'] || submitted) && form.get('title_eng')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('title_eng')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="name">{{'template.formItemsEdit.inputLabel.name' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl formControlName="name" id="name" type="text" (input)="changeShowErrors('name')">
    <c-form-check *ngIf="(showErrors['name'] || submitted) && form.get('name')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('name')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="placeholderJa">{{'template.formItemsEdit.inputLabel.placeholderJa' | translate}}</label>
    <input cFormControl formControlName="placeholder_ja" id="placeholderJa" type="text" (input)="changeShowErrors('placeholder_ja')">
    <c-form-check *ngIf="(showErrors['placeholder_ja'] || submitted) && form.get('placeholder_ja')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('placeholder_ja')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div class="mb-3">
    <label cLabel for="placeholderEn">{{'template.formItemsEdit.inputLabel.placeholderEng' | translate}}</label>
    <input cFormControl formControlName="placeholder_eng" id="placeholderEn" type="text" (input)="changeShowErrors('placeholder_eng')">
    <c-form-check *ngIf="(showErrors['placeholder_eng'] || submitted) && form.get('placeholder_eng')?.errors" class="text-danger">
      {{ "errorMessages.template.objectValidate." + getFirstError(form.get('placeholder_eng')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>
  <div>
    <label>Value <c-badge color="danger" class="mb-2">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <div formArrayName="value" *ngFor="let item of form.get('value').controls; index as indexValue">
      <div class="d-flex justify-content-between mb-2">
        <input type="text" class="w-80" [formControlName]="indexValue" cFormControl id="value" (input)="changeShowErrors('value', indexValue)">
        <button cButton color="danger"
                (click)="removeCandidate(indexValue)"
                class="button-remove-answer"
                variant="outline"
                *ngIf="form.get('value').controls.length > 1">
          <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
            <g id="SVGRepo_bgCarrier" stroke-width="0"/>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
            <g id="SVGRepo_iconCarrier">
              <path fill-rule="evenodd" d="M18 10a1 1 0 01-1 1H3a1 1 0 110-2h14a1 1 0 011 1z"/>
            </g>
          </svg>
        </button>
      </div>
      <c-form-check *ngIf="(showErrors['value'][indexValue] || submitted) && form.get('value')?.at(indexValue)?.errors" class="text-danger">
        {{ "errorMessages.template.objectValidate." + getFirstError(form.get('value')?.at(indexValue)?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
      </c-form-check>
    </div>

    <button cButton color="success" class="mb-3 button-add-answer" variant="outline" (click)="addCandidate()">
      <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
        <g id="SVGRepo_iconCarrier">
          <path fill-rule="evenodd" d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"/>
        </g>
      </svg>
    </button>
  </div>

  <div class="mb-3" formGroupName="candidateData">
    <div class="inline-item-candidate" formArrayName="ja">
      <label cLabel>{{'template.formItemsEdit.inputLabel.candidateJa' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
      <div class="box-input-candidate">
        <div *ngFor="let item of form.get('candidateData').get('ja').controls; index as indexCandidateJa">
          <input
                 cFormControl
                 (input)="changeShowErrors('candidateData', 'ja', indexCandidateJa)"
                 type="text"
                 class="mb-2"
                 [formControlName]="indexCandidateJa">
          <c-form-check *ngIf="(showErrors['candidateData']['ja'][indexCandidateJa] || submitted) && form.get('candidateData')?.get('ja')?.at(indexCandidateJa)?.errors" class="text-danger">
            {{ "errorMessages.template.objectValidate." + getFirstError(form.get('candidateData')?.get('ja')?.at(indexCandidateJa)?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
          </c-form-check>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3" formGroupName="candidateData">
    <div class="inline-item-candidate" formArrayName="en">
      <label cLabel>{{'template.formItemsEdit.inputLabel.candidateEng' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
      <div class="box-input-candidate">
        <div *ngFor="let item of form.get('candidateData').get('en').controls; index as indexCandidateEn">
          <input cFormControl
                 (input)="changeShowErrors('candidateData', 'en', indexCandidateEn)"
                 type="text"
                 class="mb-2"
                 [formControlName]="indexCandidateEn">
          <c-form-check *ngIf="(showErrors['candidateData']['en'][indexCandidateEn] || submitted) && form.get('candidateData')?.get('en')?.at(indexCandidateEn)?.errors" class="text-danger">
            {{ "errorMessages.template.objectValidate." + getFirstError(form.get('candidateData')?.get('en')?.at(indexCandidateEn)?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
          </c-form-check>
        </div>
      </div>
    </div>
  </div>
  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="staticDatatype">{{'template.formItemsEdit.inputLabel.type' | translate}}</label>
    </c-col>
    <c-col>
      <input cFormControl formControlName="datatype" id="staticDatatype" plaintext readonly type="text"/>
    </c-col>
  </c-row>


  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="required">{{'template.formItemsEdit.inputLabel.required' | translate}}</label>
    </c-col>
    <c-col>
      <c-form-check>
        <input cFormCheckInput formControlName="required" id="required" type="checkbox"/>
      </c-form-check>
    </c-col>
  </c-row>
  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="readonly">{{'template.formItemsEdit.inputLabel.readonly' | translate}}</label>
    </c-col>
    <c-col>
      <c-form-check>
        <input cFormCheckInput formControlName="readonly" id="readonly" type="checkbox"/>
      </c-form-check>
    </c-col>
  </c-row>
  <c-row class="mb-3 d-flex align-items-center">
    <c-col>
      <label cCol for="hidden">{{'template.formItemsEdit.inputLabel.hidden' | translate}}</label>
    </c-col>
    <c-col>
      <c-form-check>
        <input cFormCheckInput formControlName="hidden" id="hidden" type="checkbox"/>
      </c-form-check>
    </c-col>
  </c-row>
</form>
