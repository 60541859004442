<div class="image-list">
  <c-card class="image-section card-no-border" *ngFor="let formData of dataImageChart">
    <c-card-header>
      <span>{{ formData.name.length > LimitTextDisplay.imageName ? formData.name.substring(0, LimitTextDisplay.imageName) + '...' : formData.name }}</span>
    </c-card-header>
    <c-card-body>
      <img [src]="formData.path.url" />
    </c-card-body>
    <c-card-footer>
      <div class="image-section__info">
        <span>{{ formData.date }} {{ formData.time }}</span>
        <span>{{ 'form.layout.dataDisplay.value' | translate }}: {{ formData.value }}</span>
      </div>
    </c-card-footer>
  </c-card>
</div>
