import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ACTION_LIST, EVENT_LOG_TYPE, formatDateTimeTemplateEn, formatDateTimeTemplateJa, ITEM_PER_PAGE, MAX_HEIGHT_REMARK_BLOCK } from '@app/constants';
import { AlertService, FirebaseAnalyticService, FormService, TemplateService } from '@app/services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { DomUtil } from "leaflet";
import hasClass = DomUtil.hasClass;
import { addParamToUrl, dayFormat } from '@app/helpers';

@Component({
  selector: 'app-list-template',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.scss']
})
export class ListTemplateComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();
  pageNumber: number = 1;
  formSearch: FormGroup = new FormGroup<any>({
    querySearch: new FormControl(''),
    color: new FormControl('')
  });
  visibleSearchSection: boolean = false;
  currentFormatDate: string = "";
  currentLanguage = this.translate.currentLang;
  queryParams: any;
  colorTypesSet: any;
  templates: any;
  originalTemplates: any;
  isLoading: boolean = true;
  visibleDeleteModal: boolean = false;
  isDisabledBtnDelete: boolean = false;
  templateDeleteId: string = '';
  dataShowToggleReadMore: any = {}
  constructor(
    private translate: TranslateService,
    private router: Router,
    private formService: FormService,
    private templateService: TemplateService,
    private alertService: AlertService,
    private renderer2: Renderer2,
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private firebaseAnalyticService: FirebaseAnalyticService
  ) { }

  ngOnInit(): void {
    this.getColors();
    this.getTemplateList().then();
    this.getCurrentFormatDate();
    this.setQueryParamForm().then();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async getTemplateList() {
    this.templateService.getTemplateList().pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.templates = response.map((item) => {
        const docId = item.payload.doc.id;
        const template: any = item.payload.doc.data();
        template.nodes = JSON.parse(template.nodes);
        template.docId = docId;
        return template;
      });
      this.originalTemplates = this.templates;
      this.isLoading = false;
      this.search();
    });
  }

  async setQueryParamForm() {
    const querySearch = this.activatedRoute.snapshot.queryParamMap.get('query_search');
    const color = this.activatedRoute.snapshot.queryParamMap.get('color');
    if (querySearch) this.formSearch.controls['querySearch'].setValue(querySearch);
    if (color) this.formSearch.controls['color'].setValue(color);
  }

  search() {
    this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.SEARCH, EVENT_LOG_TYPE.SUCCESS);
    this.templates = this.originalTemplates;
    this.searchKeyword().then();
    this.searchColor().then();
    this.pageNumber = 1;
    this.checkShowReadMore();
    this.ref.detectChanges();
  }

  async searchColor() {
    const colorValue = this.formSearch.controls['color'].value;
    addParamToUrl('color', colorValue, 'list-template');
    if (Boolean(colorValue.length)) {
      this.templates = this.templates.filter((data: any) => colorValue === data.nodes.meta.color);
    }
  }

  async searchKeyword() {
    const querySearchValue = this.formSearch.controls['querySearch'].value;
    addParamToUrl('query_search', querySearchValue, 'list-template');
    if (querySearchValue) {
      this.templates = this.templates.filter((data: any) =>
        (data.nodes.meta.name && data.nodes.meta.name.search(querySearchValue) != -1)
        || (data.remark && data.remark.search(querySearchValue) != -1)
      );
    }
  }

  openSearchSection() {
    this.visibleSearchSection = !this.visibleSearchSection;
  }

  onUncheckColor() {
    this.formSearch.controls['color'].setValue("");
    addParamToUrl('color', '', 'list-template');
  }

  private async getColors() {
    const data = await firstValueFrom(this.formService.getColors());
    this.colorTypesSet = data.map((item: any) => {
      return item.payload.doc.data();
    });
  }

  getCurrentFormatDate() {
    this.currentFormatDate = formatDateTimeTemplateJa;
    if (this.translate.currentLang === 'en') this.currentFormatDate = formatDateTimeTemplateEn;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentFormatDate = formatDateTimeTemplateJa;
      if (event.lang === 'en') this.currentFormatDate = formatDateTimeTemplateEn;
    });
  }

  checkPaginate() {
    const listFormLeftOver = Math.ceil(this.templates.length / this.itemPerPage);
    const checkItemLength = this.templates.length > (this.itemPerPage * this.pageNumber);
    if (this.pageNumber != listFormLeftOver && !checkItemLength) {
      this.pageNumber = listFormLeftOver;
    }
  }

  toggleDeleteModal(templateId: string = '') {
    this.isDisabledBtnDelete = false;
    this.visibleDeleteModal = !this.visibleDeleteModal;
    if (this.visibleDeleteModal) this.templateDeleteId = templateId;
  }

  deleteTemplate() {
    this.isDisabledBtnDelete = true;
    this.templateService.delete(this.templateDeleteId).then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.DELETE, EVENT_LOG_TYPE.SUCCESS);
      this.visibleDeleteModal = false;
      this.alertService.success(this.translate.instant('alertMessages.template.delete.success'));
    }).catch(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.DELETE, EVENT_LOG_TYPE.ERROR);
      this.alertService.error(this.translate.instant('alertMessages.template.delete.fail'));
    });
  }

  pageChange(page: number) {
    this.pageNumber = page;
    this.checkShowReadMore();
  }

  checkShowReadMore() {
    this.ref.detectChanges();
    const elements = document.getElementsByClassName('remark-block');
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const getDocId = element.getAttribute('data-id');
      if (element.clientHeight && element.clientHeight >= MAX_HEIGHT_REMARK_BLOCK && getDocId) {
        this.dataShowToggleReadMore[getDocId] = true;
        this.renderer2.setStyle(element, 'max-height', MAX_HEIGHT_REMARK_BLOCK + 'px');
      }
    }
  }

  toggleReadMore(event: any) {
    const clickedButton = event.target;
    const siblingElement = clickedButton.previousElementSibling;
    siblingElement.classList.toggle('remark-block__open');
    this.translate.stream('common.longText.showMore').subscribe((text) => {
      clickedButton.textContent = text;
    });
    if (hasClass(siblingElement, 'remark-block__open')) {
      this.translate.stream('common.longText.showLess').subscribe((text) => {
        clickedButton.textContent = text;
      });
    }
  }

  protected readonly dayFormat = dayFormat;
  protected readonly Boolean = Boolean;
  protected readonly itemPerPage = ITEM_PER_PAGE;
}
