import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { AlertService, FirebaseAnalyticService, GroupService, NotificationService, UserService } from "@app/services";
import {TranslateService} from "@ngx-translate/core";
import {initializeApp} from "@angular/fire/app";
import {environment} from "@environments/environment";
import {getAuth} from "@firebase/auth";
import { ACTION_LIST, EVENT_LOG_TYPE, MemberJoinStatus } from "@app/constants";
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-member-group',
  templateUrl: './member-group.component.html',
  styleUrls: ['./member-group.component.scss']
})
export class MemberGroupComponent implements OnInit {
  @Input() user: any
  @Input() group: any
  @Input() users: any
  private app = initializeApp(environment.firebaseConfig);
  private auth = getAuth(this.app);
  currentEmail = this.auth.currentUser?.email
  protected readonly MemberJoinStatus = MemberJoinStatus;
  currentUser: any;
  constructor(
    private groupService: GroupService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private ref: ChangeDetectorRef,
    private notificationService: NotificationService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser().then();
    this.ref.detectChanges();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.currentUser = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
    }
  }

  async delete(useAlert: boolean = false) {
    const userDelete = this.group.groupUsers.filter((user: any) => user.email == this.user.email)[0]
    await this.groupService.deleteUserFromGroup(this.group, userDelete).then(() => {
      if (useAlert) {
        this.alertService.success(this.translateService.instant('alertMessages.group.leave.success', {groupName: this.group.name}));
      }
    }).catch((error) => {
      if (useAlert) {
        this.alertService.error(this.translateService.instant('alertMessages.group.leave.fail'));
      }
    })
  }

  async join() {
    try {
      const parentUser = await firstValueFrom(this.userService.getValueUserByUid(this.currentUser.parentUserId));
      this.users.push(parentUser);
      const user = this.group.groupUsers.filter((user: any) => user.email == this.user.email)[0];
      const userReceiveNotice = this.group.groupUsers.map((item: any) => {
        for (const key in this.users) {
          if (this.users[key].email === item.email) {
            item.active = this.users[key]?.active;
          }
        }
        const dataTokens = this.users.filter((user: any) => user.email === item.email)[0]?.tokens
        item.tokens = dataTokens ?? [];
        return item
      }).filter((item: any) => item.email !== user.email && item.active && (item.owner || item.status === MemberJoinStatus.accepted))
      const { id, ...data } = user
      await this.groupService.joinToGroup(this.group, user.id, { ...data, status: MemberJoinStatus.accepted }).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.JOIN, EVENT_LOG_TYPE.SUCCESS)
        this.notificationService.sendNoticeGroup(userReceiveNotice, 'joinToGroup', {
          groupName: this.group.name,
          email: user.email
        })
      })
    } catch (e) {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.JOIN, EVENT_LOG_TYPE.ERROR)
    }
  }
}
