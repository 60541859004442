import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from "@angular/forms";
import { RuleValidations } from "@app/constants";
import { getFirstError } from "@app/helpers";
import { FormService } from '@app/services';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-basic-template-setting-tab',
  templateUrl: './basic-tab.component.html',
  styleUrls: ['./basic-tab.component.scss']
})
export class BasicTabComponent implements OnChanges {
  @Input() formTemplateSetting: FormGroup = new FormGroup<any>({});
  @Input() submitted: boolean = false;
  colorTypesSet: any
  constructor(
    private formService: FormService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getColors();
  }

  get unitJaControls() {
    return this.formTemplateSetting.controls['unit'].get('ja') as FormArray;
  }

  get unitEnControls() {
    return this.formTemplateSetting.controls['unit'].get('en') as FormArray;
  }

  private async getColors() {
    const data = await firstValueFrom(this.formService.getColors())
    this.colorTypesSet = data.map((item: any) => {
      return item.payload.doc.data()
    })
  }

  setValidateUnit(event: any, key: string = '') {
    const value = event.target.value;
    if (!value.length) {
      if (key === 'en') this.unitEnControls.setErrors({ required: true });
      if (key === 'ja') this.unitJaControls.setErrors({ required: true });
    }
    if (value.length && value.length > RuleValidations.maxLength255) {
      if (key === 'en') this.unitEnControls.setErrors({ maxlength: true });
      if (key === 'ja') this.unitJaControls.setErrors({ maxlength: true });
    }
  }

  protected readonly RuleValidations = RuleValidations;
  protected readonly getFirstError = getFirstError;
}
