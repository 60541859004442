import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { INIT_EDITOR, LAYOUT_FORM_LIST, PREFIX_LIST, SELECT_TEMPLATE } from "@app/constants";
import { AlertService, FormService, TemplateService, UserService } from "@app/services";
import { firstValueFrom, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import { RuleValidations } from "@app/constants";
import { checkInputEnter, dayFormat, formatSystemTemplateList, getFirstError, getLocaleDatePicker } from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';
import { EditorOptions } from "tinymce";
import { TabService } from "@app/services/tab.service";
import { ITab } from "@app/models/tab";
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-basic-edit',
  templateUrl: './basic-edit.component.html',
  styleUrls: ['./basic-edit.component.scss']
})
export class BasicEditComponent implements OnChanges, OnDestroy {
  @Input('form') form: any;
  @Input('isOutDate') isOutDate: boolean = false;
  @Input('submitAllTab') submitAllTab: any;
  @Input('formEdit') formEdit: any;
  @Input('minNumberParking') minNumberParking: number = 0;
  @Input('currentUser') currentUser: any;
  @Output() submittedData = new EventEmitter<Boolean>();
  @Output() isBackToFormList = new EventEmitter<any>();

  id: string = '';
  minDate = new Date(this.getYesterday());
  maxDate = new Date(this.minDate.getFullYear() + 1, 0, 0);
  unitString: string = "";
  minRecord: number | string | undefined;
  currentLanguage = this.translate.currentLang;
  colorTypesSet: any;
  systemTemplates: any;
  submitted = false;
  loading = false;
  currentDatepickerLocale = "";
  initEditor: Partial<EditorOptions> = INIT_EDITOR
  templates: any;
  defaultTemplateSelect = 0;
  tabs: Array<ITab> = []
  prefix: string = "";
  subscriptionList: { [key: string]: Array<Subscription> | null } = {
    systemTemplate: [],
    template: []
  }

  constructor(
    private formService: FormService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private tabService: TabService,
    private templateService: TemplateService,
    private userService: UserService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDataFormEdit();
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.translate.onLangChange.subscribe((e) => {
      this.changeLangWithDatePicker(e.lang)
      this.getDataFormEdit();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscriptionList['systemTemplate']?.forEach((sub) => sub.unsubscribe());
    this.subscriptionList['template']?.forEach((sub) => sub.unsubscribe());
  }

  private async getDataFormEdit() {
    await this.getColors();
    await this.getTabs();
    this.getSystemTemplates();
    this.getTemplate();
    if (this.form) {
      this.prefix = this.form.nodes.nodes.meta.prefix;
      let dataTemplate = this.systemTemplates?.filter((item: any) => item.docId == this.form.systemTemplateId)[0];
      if (!!this.form.templateId) dataTemplate = this.templates?.filter((item: any) => item.docId == this.form.templateId)[0];
      this.unitString = dataTemplate?.nodes.meta.unit[this.translate.currentLang];
      this.minRecord = this.form.maxRecords;
    }
  }

  private async getColors() {
    const data = await firstValueFrom(this.formService.getColors())
    this.colorTypesSet = data.map((item: any) => {
      return item.payload.doc.data()
    })
  }

  async getTemplate() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      const templateSubscription = this.templateService.getSnapshotTemplateList(authUser.uid).subscribe({
        next: (data) => {
          const templates = data.map((item) => {
            const docId = item.payload.doc.id;
            const template: any = item.payload.doc.data();
            template.docId = docId;
            template.nodes = JSON.parse(template.nodes);
            return template;
          }).sort((prev, next) => prev.displayIndex - next.displayIndex);
          this.templates = templates;
          this.changeTemplateType(SELECT_TEMPLATE.SYSTEM_TEMPLATE);
          if (this.templates.length) this.changeTemplateType(SELECT_TEMPLATE.TEMPLATE);
        }
      })
      this.subscriptionList['template']?.push(templateSubscription);
    }
  }

  private getSystemTemplates() {
    const systemTemplateSubscription = this.templateService.getSnapshotSystemTemplateList().subscribe({
      next: (data) => {
        this.systemTemplates = formatSystemTemplateList(data);
      }
    })
    this.subscriptionList['systemTemplate']?.push(systemTemplateSubscription);
  }

  private changeLangWithDatePicker(lang: string) {
    this.currentLanguage = lang
    this.currentDatepickerLocale = getLocaleDatePicker(lang);
  }

  changeTemplateType(type: number) {
    if (this.formEdit.controls.templateId && this.formEdit.controls.systemTemplateId) {
      this.defaultTemplateSelect = type;
      this.formEdit.controls.templateId.disable();
      this.formEdit.controls.templateId.updateValueAndValidity();
      this.formEdit.controls.systemTemplateId.disable();
      this.formEdit.controls.systemTemplateId.updateValueAndValidity();
    }
  }

  async getTabs() {
    if (this.currentUser) {
      this.tabs = await firstValueFrom(this.tabService.index(this.currentUser.uid));
      this.tabs = this.tabs.filter((tab) => !tab.isDisable || tab.docId === this.form.tabId);
    }
  }

  changeTab(event: any) {
    const data = event.target.value;
    this.formEdit.controls['tabId'].setValue(data ?? null)
  }

  private getYesterday() {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - 1));
  }

  ignoreString($event: any) {
    const keycode = $event?.keyCode
    if (keycode < 48 || keycode > 57) {
      $event?.preventDefault()
    }
  }

  increaseValueInputNumber($event: any, name: string) {
    const member = this.formEdit.controls[name];
    if (member.value) {
      member.setValue(Number(member.value) + 1)
    } else {
      member.setValue(1)
    }
  }

  decreaseValueInputNumber($event: any, name: string) {
    const member = this.f[name];
    if (member.value) {
      member.setValue(Number(member.value) - 1)
    } else {
      member.setValue(1)
    }
  }

  checkInputOnlyNumber(event: any) {
    this.checkInputEnter(event);
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  changeDate(event: any) {
    if (this.formEdit.eventDate) this.formEdit.eventDate.setValue(event)
  }

  async submit() {
    this.submitted = true;
    if (this.formEdit.valid && this.form) {
      this.submittedData.emit(true)
    }
  }

  protected readonly SELECT_TEMPLATE = SELECT_TEMPLATE;

  get f() {
    // @ts-ignore
    return this.formEdit.controls;
  }

  backToFormList() {
    this.isBackToFormList.emit(true);
  }

  setRawTextRemarkError(event: any) {
    const rawText: string = event.editor.getContent({ format: 'text' })
    const remarkForm = this.formEdit.get('remark');
    remarkForm?.setValidators([]);
    if (rawText.length > this.ruleValidations.maxLength4096) {
      remarkForm?.setValidators([Validators.maxLength(this.ruleValidations.maxLength4096)]);
    }
    this.formEdit.controls.remark.updateValueAndValidity();
  }

  protected readonly getFirstError = getFirstError;
  protected readonly checkInputEnter = checkInputEnter;
  protected readonly dayFormat = dayFormat;
  protected readonly INIT_EDITOR = INIT_EDITOR;
  protected readonly LAYOUT_FORM_LIST = LAYOUT_FORM_LIST;
  protected readonly SELECT_TEMPLATE_PLUGIN_ATTENDANCE = PREFIX_LIST.pluginAttendance;
  protected readonly ruleValidations = RuleValidations;
}
