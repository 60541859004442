import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService, AlertService, FirebaseAnalyticService } from '@app/services';
import { AccountStatus, ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from '@app/constants';
import { TranslateService } from '@ngx-translate/core';
import {environment} from "@environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(RuleValidations.emailPattern)]),
    password: new FormControl('', [Validators.required])
  })
  loading = false;
  loadingRedirect = false;
  submitted = false;
  readonly environment = environment
  logoLogin = environment.title_image_url

  constructor(
    private angularFireAuth: AngularFireAuth,
    private alertService: AlertService,
    private userService: UserService,
    private router: Router,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService
  ) {
    this.checkDisplayAlertMessageByAccountStatus();
  }

  ngOnInit() {
    this.getAccountUser().then();
  }

  get f() {
    // @ts-ignore
    return this.form.controls;
  }

  checkDisplayAlertMessageByAccountStatus() {
    const accountStatus = this.router.getCurrentNavigation()?.extras.state?.['accountStatus'];
    if (accountStatus) {
      switch (accountStatus) {
        case AccountStatus.inactiveByParent: {
          this.alertService.error(this.translateService.instant('alertMessages.accountIsInactive'));
          break;
        }
        case AccountStatus.isDeletedByParent: {
          this.alertService.error(this.translateService.instant('alertMessages.accountIsDeleted'));
          break;
        }
        case AccountStatus.isUpdatePassword: {
          this.alertService.error(this.translateService.instant('alertMessages.accountChangePassword'));
          break;
        }
        default: {
          this.alertService.error(this.translateService.instant('alertMessages.deleteAccount.success'));
        }
      }
    }
  }

  login() {
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    const auth = getAuth();
    auth.languageCode = 'ja';
    this.angularFireAuth.signInWithEmailAndPassword(this.f['email'].value, this.f['password'].value)
      .then(async (res: any) => {
        await this.userService.addCurrentUserToken();
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGIN, EVENT_LOG_TYPE.SUCCESS);
        localStorage.setItem('email_user', res.user?.email)
        localStorage.setItem('uid_user', res.user?.uid)
        window.location.href = '/#/account';
        this.userService.updateLastLogin()
      })
      .catch(error => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGIN, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.login.fail'));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async getAccountUser() {
    let user = await this.userService.userValue();
    if (user) {
      this.loadingRedirect = false;
      this.router.navigate(['account']).then();
    } else {
      this.loadingRedirect = true;
    }
  }
  async SignInApple() {
    this.userService.signInApple().then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGIN_WITH_APPLE, EVENT_LOG_TYPE.SUCCESS);
    }).catch(
      error => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGIN_WITH_APPLE, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.login.fail'));
      }
    );
  }
  async SignInGoogle() {
    this.alertService.clear();
    this.userService.signInGoogle().then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGIN_WITH_GOOGLE, EVENT_LOG_TYPE.SUCCESS);
    }).catch(
      error => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.LOGIN_WITH_GOOGLE, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.login.fail'));
      }
    );
  }
}
