import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, FormService, UserService } from "@app/services";
import { getAuth } from "@firebase/auth";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DataType, INIT_EDITOR } from "@app/constants";
import { scrollToBottom } from "@app/helpers";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidatorsService } from '@app/services/custom-validators.service';

@Component({
  selector: 'app-test-detail',
  templateUrl: './test-detail.component.html',
  styleUrls: ['./test-detail.component.scss']
})
export class TestDetailComponent implements OnInit {
  protected readonly String = String;
  @ViewChild('deleteQuestionModal') deleteQuestionModal: any
  testForm: any;
  formId: string | null = '';
  testId: string | null = '';
  form: any;
  exam: any;
  deleteQuestionIndex: number | null = null
  submitted: boolean = false;
  INIT_EDITOR = INIT_EDITOR;
  formEditTest = this.formBuilder.group({
    examDescription: new FormGroup({
      ja: new FormControl(null),
      en: new FormControl(null)
    }),
    index: new FormControl(null),
    name: new FormGroup({
      ja: new FormControl(null, [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
      en: new FormControl(null, [Validators.required, CustomValidatorsService.notAllowedOnlySpace])
    }),
    questions: new FormArray<any | number>([]),
  });
  authUser: any;
  constructor(
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
    this.testForm = this.router.getCurrentNavigation()?.extras.state?.['testForm'];
    this.exam = this.router.getCurrentNavigation()?.extras.state?.['exam'];
  }

  ngOnInit(): void {
    this.getParamFromUrl();
    this.initForm();
  }

  initForm() {
    this.authUser = this.userService.getCurrentUserAuth();
    if (!this.testForm && this.authUser) {
      this.getForm();
    } else {
      this.generateEditTestForm();
    }
  }

  getChildControls(questionControl: any, key: string) {
    return (questionControl.get(key) as FormArray).controls
  }

  generateEditTestForm() {
    this.formEditTest.controls.index.setValue(this.exam.index)
    this.formEditTest.controls.name.controls.ja.setValue(this.exam.name.ja);
    this.formEditTest.controls.name.controls.en.setValue(this.exam.name.en);
    this.formEditTest.controls.examDescription.controls.ja.setValue(this.exam.examDescription.ja);
    this.formEditTest.controls.examDescription.controls.en.setValue(this.exam.examDescription.en);
    this.exam.questions.forEach((item: any, index: number) => {
      const dataForm = new FormGroup({
        datatype: new FormControl(item.datatype),
        correct: new FormArray<any>([]),
        answerList: new FormArray<any>([]),
        question: new FormGroup({
          ja: new FormControl(item.question.ja, [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
          en: new FormControl(item.question.en, [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
        })
      })
      const correctArray = dataForm.controls.correct as FormArray;
      const answerList = dataForm.controls.answerList as FormArray;

      item.correct.forEach((value: boolean) => {
        correctArray.push(new FormControl(value));
      });
      const dataChecked = item.correct.filter((item: boolean) => item).length
      correctArray.setErrors(null)
      if (!dataChecked) correctArray.setErrors({required: true})

      item.answerList.forEach((item: {ja: string, en: string}) => {
        answerList.push(new FormGroup({
          ja: new FormControl(item.ja, [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
          en: new FormControl(item.en, [Validators.required, CustomValidatorsService.notAllowedOnlySpace])
        }));
      })
      this.formEditTest.controls.questions.push(dataForm);
    })
  }

  getForm() {
    this.formService.getValueOwnerFormById(this.authUser.uid, this.formId ?? '').subscribe((response: any) => {
      const form = response[0];
      this.testForm = form.testForm;
      this.exam = this.testForm.filter((item: any) => item.index == this.testId);
      this.generateEditTestForm()
    })
  }

  async getParamFromUrl () {
    this.formId = this.activatedRoute.snapshot.params['id'];
    this.testId = this.activatedRoute.snapshot.params['testId'];
  }

  addQuestion() {
    const dataForm = new FormGroup({
      datatype: new FormControl(DataType.radio),
      correct: new FormArray<any>([]),
      answerList: new FormArray<any>([]),
      question: new FormGroup({
        ja: new FormControl(null, [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
        en: new FormControl(null, [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
      })
    })
    const correctArray = dataForm.controls.correct as FormArray;
    const answerList = dataForm.controls.answerList as FormArray;
    correctArray.push(new FormControl(true));

    answerList.push(new FormGroup({
      ja: new FormControl(null, [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
      en: new FormControl(null, [Validators.required, CustomValidatorsService.notAllowedOnlySpace])
    }));
    this.formEditTest.controls.questions.push(dataForm)
    this.ref.detectChanges()
    scrollToBottom()
  }

  removeQuestion(key: number | null) {
    if (key !== null) {
      this.formEditTest.controls.questions.removeAt(key);
      this.ref.detectChanges()
      this.deleteQuestionModal.visible = false
    }
  }

  prepareDeleteQuestion(questionIndex: number) {
    this.deleteQuestionIndex = questionIndex;
    this.deleteQuestionModal.visible = true;
  }

  addAnswer(questionIndex: number) {
    const answerListArray = (this.formEditTest.controls.questions.at(questionIndex).get('answerList') as FormArray);
    const correctArray = (this.formEditTest.controls.questions.at(questionIndex).get('correct') as FormArray);
    answerListArray.push(new FormGroup({
      ja: new FormControl(null, [Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
      en: new FormControl(null, [Validators.required, CustomValidatorsService.notAllowedOnlySpace])
    }));
    correctArray.push(new FormControl(false));
    if (!correctArray.value.filter((item: any) => item).length) {
      correctArray.setErrors({required: true});
    }
    this.ref.detectChanges();
  }

  removeAnswer(questionIndex: number, key: number) {
    const answerListArray = (this.formEditTest.controls.questions.at(questionIndex).get('answerList') as FormArray);
    const correctArray = (this.formEditTest.controls.questions.at(questionIndex).get('correct') as FormArray);
    answerListArray.removeAt(key);
    correctArray.removeAt(key);
    if (!correctArray.value.filter((item: any) => item).length) {
      correctArray.setErrors({required: true});
    }
    this.ref.detectChanges();
  }

  changeCorrect(question: any) {
    const dataValue = question.controls.correct.controls.map((item: any) => item.value);
    question.controls.correct.setValue(dataValue);
    const dataChecked = dataValue.filter((item: boolean) => item).length
    question.controls.correct.setErrors(null)
    if (!dataChecked) question.controls.correct.setErrors({required: true})
    question.controls.datatype.setValue(DataType.radio)
    if (dataChecked > 1) question.controls.datatype.setValue(DataType.check)
  }

  visibleChangeModal(visible: boolean) {
    if (!visible) this.deleteQuestionIndex = null;
  }

  async submit() {
    this.submitted = true;
    if (this.formEditTest.valid && this.formId) {
      const newTestForm = this.testForm.map((item: any) => {
        const index = this.formEditTest.controls.index.value;
        if (item.index === index) {
          item = {...this.formEditTest.value}
        }
        return item
      });

      await this.formService.update({testForm: newTestForm}, this.authUser.uid, this.formId).then(() => {
        this.router.navigate([`form/edit/${this.formId}`], {state: {isRedirectFromTestDetail: true}}).then(() => {
          this.alertService.success(this.translateService.instant('alertMessages.detailTest.update.success'))
        })
      }).catch((e) => {
        this.router.navigate([`form/edit/${this.formId}`], {state: {isRedirectFromTestDetail: true}}).then(() => {
          this.alertService.error(this.translateService.instant('alertMessages.detailTest.update.fail'))
        })
      })
    } else {
      this.alertService.error(this.translateService.instant('alertMessages.detailTest.update.validate'))
    }
  }

  moveDownAnswer(questionIndex: any, currentIndex: any) {
    const answerListControl= this.formEditTest.controls.questions.controls.at(questionIndex)?.get('answerList') as FormArray;
    const answerControl = answerListControl.controls.at(currentIndex);
    answerListControl.removeAt(currentIndex);
    answerListControl.insert(Number(currentIndex + 1), answerControl);
  }

  moveUpAnswer(questionIndex: any, currentIndex: any) {
    const answerListControl= this.formEditTest.controls.questions.controls.at(questionIndex)?.get('answerList') as FormArray;
    const answerControl = answerListControl.controls.at(currentIndex);
    answerListControl.removeAt(currentIndex);
    answerListControl.insert(Number(currentIndex - 1), answerControl);
  }
}
