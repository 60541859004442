import { DOT_RADIUS } from "@app/constants";

export function drawDot(context: any, x: number, y: number, color: string) {
  context.beginPath();
  context.arc(x, y, DOT_RADIUS, 0, 2 * Math.PI);
  context.fillStyle = color;
  context.fill();
}

export function drawOval(context: any, centerX: number, centerY: number, radiusX: number, radiusY: number, color: string | undefined) {
  context.beginPath();
  context.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
  context.strokeStyle = color;
  context.stroke();
}

export function drawRectangle(context: any, startX: number, startY: number, width: number, height: number, color: string) {
  context.beginPath();
  context.rect(startX, startY, width, height);
  context.strokeStyle = color;
  context.stroke();
}
