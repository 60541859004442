import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs'
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { initializeApp } from "@angular/fire/app";
import { environment } from "@environments/environment";
import { getAuth, onAuthStateChanged } from "@firebase/auth";

@Injectable({ providedIn: 'root' })
export class MessagingService {
  currentMessage = new BehaviorSubject<any>(null);

  constructor(private angularFireMessaging: AngularFireMessaging, private angularFireAuth: AngularFireAuth) {
    this.angularFireMessaging.messages.subscribe((_messaging) => { });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  async receiveMessaging() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.currentMessage.next(payload);
    }, (error) => { })
  }
  async listenMessage() {
    let user = await this.userValue();
    if (user) {
      await this.receiveMessaging();
    }
  }
  async userValue() {
    const app = await initializeApp(environment.firebaseConfig);
    const auth = await getAuth(app);
    return new Promise((resolve, reject) => {
      try {
        onAuthStateChanged(auth, user => {
          resolve(user);
        });
      } catch {
        reject('api failed');
      }
    });
  }

}
