<app-layout-auth>
  <c-col *ngIf="environment.no_login_link">
    <c-col class="mb-5">
      <h2>{{ environment.service_name[language] }}</h2>
    </c-col>
    <c-card class="card-no-border">
      <c-card-body class="card-border-bottom">
        <c-col class="mb-4">
          <h4 class="mb-0">{{ "non-account.support" | translate }}</h4>
        </c-col>
        <c-col>
          <span>{{environment.support_message[language]}}</span>
        </c-col>
      </c-card-body>
      <c-card-body>
        <c-col class="d-flex justify-content-between align-items-center">
          <h4 class="mb-0">{{ "non-account.account" | translate }}</h4>
          <a routerLink="/login">
            <button class="btn-common">{{ "login.login" | translate }}</button>
          </a>
        </c-col>
      </c-card-body>
    </c-card>
  </c-col>
  <div *ngIf="!environment.no_login_link" class="w-100">
    <c-col>
      <c-card class="card-no-border">
        <c-card-body>
          <c-col class="mb-4">
            <h4 class="mb-0">{{ "errorMessagePage.title" | translate }}</h4>
          </c-col>
          <c-col>
            <span>{{ "alertMessages.formData.pageCannotBeDisplay" | translate }}</span>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </div>
</app-layout-auth>
