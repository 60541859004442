<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3>{{ "accountManagement.create.title" | translate }}</h3>
    </c-col>
    <c-card class="card-no-border account-card">
      <c-card-body>
        <form [formGroup]="formCreate" novalidate>
          <div class="mb-3">
            <label cLabel for="name">
              {{ "accountManagement.create.inputLabel.userName" | translate }}
              <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
            </label>
            <input cFormControl id="name" formControlName="name" (keypress)="checkInputEnter($event)"/>
            <c-form-check class="text-danger" *ngIf="formCreate?.controls?.name?.errors && submitted">
              {{ "errorMessages.accountManagement.create." + getFirstError(formCreate.controls.name.errors) | translate : { maxlength: ruleValidations.maxLength255 } }}
            </c-form-check>
          </div>
          <div class="mb-3">
            <label cLabel for="email">
              {{ "accountManagement.create.inputLabel.email" | translate }}
              <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
            </label>
            <input cFormControl id="email" formControlName="email" (keypress)="checkInputEnter($event)"/>
            <c-form-check class="text-danger" *ngIf="formCreate?.controls?.email?.errors && submitted">
              {{ "errorMessages.accountManagement.create." + getFirstError(formCreate.controls.email.errors) | translate : { maxlength: ruleValidations.maxLength255 } }}
            </c-form-check>
          </div>
          <div class="mb-3">
            <label cLabel for="organizationName">
              {{ "accountManagement.create.inputLabel.organizationName" | translate }}
              <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
            </label>
            <input cFormControl id="organizationName" formControlName="organizationName" (keypress)="checkInputEnter($event)"/>
            <c-form-check class="text-danger" *ngIf="formCreate?.controls?.organizationName?.errors && submitted">
              {{ "errorMessages.accountManagement.create." + getFirstError(formCreate.controls.organizationName.errors) | translate : { maxlength: ruleValidations.maxLength255 } }}
            </c-form-check>
          </div>
          <div class="mb-3">
            <label cLabel for="memberCode">
              {{ "accountManagement.create.inputLabel.memberCode" | translate }}
            </label>
            <input cFormControl id="memberCode" formControlName="memberCode" (keypress)="checkInputEnter($event)"/>
            <c-form-check class="text-danger" *ngIf="formCreate?.controls?.memberCode?.errors && submitted">
              {{ "errorMessages.accountManagement.create." + getFirstError(formCreate.controls.memberCode.errors) | translate : { maxlength: ruleValidations.maxLength255 } }}
            </c-form-check>
          </div>
          <span class="switch-button">
            <c-form-check [switch]="true">
              <input cFormCheckInput id="active" [checked]="true" formControlName="active" type="checkbox" />
              <label cFormCheckLabel for="active">{{ 'accountManagement.listUser.btnLabel.active' | translate }}</label>
            </c-form-check>
          </span>
          <div class="mb-3 d-flex justify-content-end">
            <button (click)="backToIndexForm()" class="back-btn">
              {{ "accountManagement.create.btnLabel.back" | translate }}
            </button>
            <button cLoadingButton [loading]="isLoading" [disabledOnLoading]="true" cButton type="submit" (click)="submit()" class="btn-submit">
              {{ "accountManagement.create.btnLabel.create" | translate }}
            </button>
          </div>
        </form>
      </c-card-body>
    </c-card>
  </div>
</c-container>
