<form [formGroup]="formTemplateSetting">
  <div class="mb-3">
    <label cLabel for="title">{{'template.basicSetting.basicTab.inputLabel.title' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl id="title" type="text" formControlName="title" />
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('title')?.errors && submitted">
      {{ "errorMessages.template.title." + getFirstError(formTemplateSetting.get('title')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

  <div class="mb-3">
    <label cLabel for="name">{{'template.basicSetting.basicTab.inputLabel.name' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl id="name" type="text" formControlName="name" />
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('name')?.errors && submitted">
      {{ "errorMessages.template.name." + getFirstError(formTemplateSetting.get('name')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

  <div class="mb-3">
    <label cLabel for="version">{{'template.basicSetting.basicTab.inputLabel.version' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl id="version" type="text" formControlName="version" />
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('version')?.errors && submitted">
      {{ "errorMessages.template.version." + getFirstError(formTemplateSetting.get('version')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

  <div class="mb-3">
    <label cLabel for="author">{{'template.basicSetting.basicTab.inputLabel.author' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl id="author" type="text" formControlName="author" />
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('author')?.errors && submitted">
      {{ "errorMessages.template.author." + getFirstError(formTemplateSetting.get('author')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

  <div class="mb-3">
    <label cLabel for="copyRight">{{'template.basicSetting.basicTab.inputLabel.copyright' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <input cFormControl id="copyRight" type="text" formControlName="copyRight" />
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('copyRight')?.errors && submitted">
      {{ "errorMessages.template.copyright." + getFirstError(formTemplateSetting.get('copyRight')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

  <div class="mb-3">
    <c-col class="mb-1">
      {{ "form.inputLabel.color" | translate }}
      <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
    </c-col>
    <div class="d-flex">
      <div *ngFor="let item of colorTypesSet" class="me-3">
        <input
          formControlName="color"
          class="check-box-color d-none"
          id="color{{ item.colorCode }}"
          [value]="item.colorCode"
          name="color"
          cFormCheckInput
          type="radio"
        />
        <label
          cFormCheckLabel
          for="color{{ item.colorCode }}"
          [style.--border-color]="
            item.colorCode === '#FFFFFF' ? '1px solid #667085' : ''
          "
          [style.--color]="item.colorCode"
          [style.border]="
            item.colorCode === '#FFFFFF' ? '1px solid #667085' : ''
          "
          class="color-icon"
        ></label>
      </div>
    </div>
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('color')?.errors && submitted">
      {{ "errorMessages.formValidate.select.required" | translate }}
    </c-form-check>
  </div>

  <div class="mb-3">
    <label cLabel for="style">{{'template.basicSetting.basicTab.inputLabel.style' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <select aria-label="Default select example" id="style" cSelect formControlName="style">
      <option value="default">{{'template.basicSetting.basicTab.inputLabel.styleItem.default' | translate}}</option>
    </select>
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('style')?.errors && submitted">
      {{ "errorMessages.template.style." + getFirstError(formTemplateSetting.get('style')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

  <div class="mb-3" formGroupName="unit">
    <label cLabel>{{'template.basicSetting.basicTab.inputLabel.unit' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <div class="inline-unit">
      <div class="d-flex input-ja-unit">
        <label cLabel for="ja" class="me-3 mb-0">{{"layout.header.menu.lang.item.ja" | translate }}</label>
        <div formArrayName="ja" *ngFor="let itemJa of unitJaControls.controls; let unitJaIndex = index;">
          <input cFormControl id="ja" class="input-unit" type="text" [formControlName]="unitJaIndex" (input)="setValidateUnit($event, 'ja')" />
          <c-form-check class="text-danger" *ngIf="unitJaControls.errors && submitted">
            {{ "errorMessages.template.unit." + getFirstError(formTemplateSetting.get('unit')?.get('ja')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
          </c-form-check>
        </div>
      </div>
      <div class="d-flex">
        <label cLabel for="en" class="me-3 mb-0">{{"layout.header.menu.lang.item.en" | translate }}</label>
        <div formArrayName="en" *ngFor="let itemEn of unitEnControls.controls; let unitEnIndex = index;">
          <input cFormControl class="input-unit" id="en" type="text" [formControlName]="unitEnIndex" (input)="setValidateUnit($event, 'en')" />
          <c-form-check class="text-danger" *ngIf="unitEnControls?.errors && submitted">
            {{ "errorMessages.template.unit." + getFirstError(formTemplateSetting.get('unit')?.get('en')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
          </c-form-check>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <label cLabel>{{'template.basicSetting.basicTab.inputLabel.publish' | translate}} <c-badge color="danger">{{'form.inputLabel.must'|translate}}</c-badge></label>
    <div class="block-publish">
      <c-form-check [inline]="true">
        <input cFormCheckInput id="publishTrue" type="radio" formControlName="publish" [value]="true"/>
        <label cFormCheckLabel for="publishTrue">{{'template.basicSetting.basicTab.inputLabel.publishValue.yes' | translate}}</label>
      </c-form-check>
      <c-form-check inline>
        <input cFormCheckInput id="publishFalse" type="radio" formControlName="publish" [value]="false"/>
        <label cFormCheckLabel for="publishFalse">{{'template.basicSetting.basicTab.inputLabel.publishValue.no' | translate}}</label>
      </c-form-check>
    </div>
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('publish')?.errors && submitted">
      {{ "errorMessages.template.publish." + getFirstError(formTemplateSetting.get('publish')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

  <div class="mb-3">
    <label cLabel for="copyRight">Prefix</label>
    <input cFormControl id="prefix" type="text" formControlName="prefix" />
    <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('prefix')?.errors && submitted">
      {{ "errorMessages.template.prefix." + getFirstError(formTemplateSetting.get('prefix')?.errors) | translate : { maxlength: RuleValidations.maxLength255 } }}
    </c-form-check>
  </div>

</form>
