<c-modal #inviteGroup class="invite-modal" alignment="center" [id]="'inviteModal' + pathGroup" [visible]="visible" (visibleChange)="visibleChange($event)">
  <c-modal-header>
    <h5 cModalTitle>{{ 'groups.invite.inviteUser' | translate }}</h5>
    <button [cModalToggle]="inviteGroup.id" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    <form [formGroup]="formInviteUser">
      <div class="d-flex">
        <c-form-check inline (click)="choosePermission('readonly')" class="block-permission">
          <input class="input-permission" cFormCheckInput #readonlyRadioRef id="readonly" formControlName="permission" type="radio" value="readonly" />
          <label cFormCheckLabel for="readonly">{{ 'groups.memberRole.readonly' | translate }}</label>
        </c-form-check>
        <c-form-check inline (click)="choosePermission('read+add')" class="block-permission">
          <input class="input-permission" cFormCheckInput #readAndAddRadioRef id="read+add" formControlName="permission" type="radio" value="read+add" />
          <label cFormCheckLabel for="read+add">{{ 'groups.memberRole.read+add' | translate }}</label>
        </c-form-check>
        <c-form-check inline (click)="choosePermission('full')" class="block-permission">
          <input class="input-permission" cFormCheckInput #fullRadioRef id="full" formControlName="permission" type="radio" value="full" />
          <label cFormCheckLabel for="full">{{ 'groups.memberRole.full' | translate }}</label>
        </c-form-check>
      </div>
      <ng-multiselect-dropdown
        placeholder="{{ 'groups.invite.select.placeholder' |translate }}"
        [settings]="dropdownSettings"
        [data]="users"
        formControlName="users"
      ></ng-multiselect-dropdown>
      <div class="d-flex mt-2">
        <c-form-check class="text-danger" *ngIf="formInviteUser?.controls?.users?.errors && submitted">
          {{ "errorMessages.groups.users." + getFirstError(formInviteUser!.controls!.users!.errors) | translate }}
        </c-form-check>
      </div>
    </form>
  </c-modal-body>
  <c-modal-footer>
    <button [cModalToggle]="inviteGroup.id" class="btn-second">
      {{ 'groups.close' | translate }}
    </button>
    <button (click)="submit()" class="btn-common">{{ 'groups.invite.invite' | translate }}</button>
  </c-modal-footer>
</c-modal>
