import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GROUP_PER_PAGE } from "@app/constants";
import { AlertService, FirebaseAnalyticService, FormCachesService, FormService, GroupService, NotificationService, UserService } from "@app/services";
import { TranslateService } from "@ngx-translate/core";
import { initializeApp } from "@angular/fire/app";
import { environment } from "@environments/environment";
import { getLongText, getSubstringTextNumber } from '@app/helpers';

@Component({
  selector: 'app-tab-owner',
  templateUrl: './tab-owner.component.html',
  styleUrls: ['./tab-owner.component.scss']
})
export class TabOwnerComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() groups: any
  @Input() delete: any
  @Input() checkOwnerGroup: any
  @Input() users: any
  @Input() getSubTextGroup: any
  @Input() page: any
  @Output() pageOwner = new EventEmitter<number>();
  getLongText = getLongText;
  itemPerPage = GROUP_PER_PAGE;
  groupDeletePath: string = ''
  visibleItems: boolean[] = [];
  private app = initializeApp(environment.firebaseConfig);
  textNameDisplay: number = 12;
  textEmailDisplay: number = 12;
  selectedGroup: any;
  visibleInvite: boolean = false;
  editGroup: any;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.checkLongText()
  }
  constructor(
    private groupService: GroupService,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formCachesService: FormCachesService,
    private formService: FormService
  ) { }

  ngOnInit(): void { }
  ngOnChanges(changes: SimpleChanges): void {
    this.checkLongText();
  }

  ngAfterViewChecked(): void {
    this.checkLongText();
  }

  changePage(event: any) {
    this.page = event;
    this.pageOwner.emit(event)
  }

  toggleCollapse(index: number) {
    this.visibleItems[index] = !this.visibleItems[index];
  }

  checkLongText() {
    this.textNameDisplay = getSubstringTextNumber('.name-owner-section', '.user-owner-info');
    this.textEmailDisplay = getSubstringTextNumber('.email-owner-section', '.user-owner-info');
  }

  openInviteModal(group: any) {
    this.selectedGroup = group;
    this.visibleInvite = true;
  }

  openEditModal(group: any) {
    this.editGroup = group
  }

  receiveCloseInviteModal(event: any) {
    if (!event) {
      this.selectedGroup = null;
      this.visibleInvite = false;
    }
  }

  receiveCloseEditModal(event: boolean) {
    if (!event) {
      this.editGroup = null;
    }
  }
}
