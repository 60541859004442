import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertService, FirebaseAnalyticService, FormCachesService, FormService, GroupService, NotificationService, UserService } from "@app/services";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ACCOUNT_DEFAULT_RESTRICT, ACTION_LIST, EVENT_LOG_TYPE } from "@app/constants";
import firebase from 'firebase/compat/app';
import { IAccountRestrict } from '@app/models';
import { checkAccountRestrictMode } from '@app/helpers';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, OnDestroy {
  @ViewChild('deleteGroup') deleteGroup: any;
  formSearch = new FormGroup({
    keywords: new FormControl(null)
  })
  groups: any
  groupsNoFilter: any
  users: any
  groupDeletePath: string = ''
  groupUsersPath: any[] = []
  currentTab: number = 1;
  pageOwner: number = 1
  pageMember: number = 1;
  currentUser: any;
  accountRestrictConfig: IAccountRestrict = ACCOUNT_DEFAULT_RESTRICT;
  subscription: { [key: string]: Subscription | null } = {
    groupUserPathList: null,
    groupUserList: null,
    currentUser: null,
    childUserList: null,
  };
  listSubscription: {[key: string]: Array<Subscription> | null} = {
    formCachesList: [],
    formList: []
  };

  constructor(
    private groupService: GroupService,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private formCachesService: FormCachesService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser().then();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscription['groupUserPathList']?.unsubscribe();
    this.subscription['groupUserList']?.unsubscribe();
    this.subscription['currentUser']?.unsubscribe();
    this.subscription['childUserList']?.unsubscribe();
    this.listSubscription['formCachesList']?.forEach((sub) => sub.unsubscribe())
    this.listSubscription['formList']?.forEach((sub) => sub.unsubscribe())
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.subscription['currentUser'] = this.userService.getValueUserByUid(authUser.uid).subscribe({
        next: (currentUser) => {
          this.currentUser = currentUser;
          if (this.currentUser) {
            this.getDataGroups();
            this.getChildUserList()
            this.accountRestrictConfig = checkAccountRestrictMode(this.currentUser);
          }
        }
      })
    }
  }

  getDataGroups() {
    this.subscription['groupUserPathList'] = this.groupService.getSnapshotGroupUserPathsByEmail(this.currentUser.email).subscribe({
      next: (data) => {
        this.groupUsersPath = [];
        this.groups = [];
        data.forEach((item) => {
          this.groupUsersPath.push(item.payload.doc.ref.parent.parent?.path)
        });
        if (this.groupUsersPath.length) {
          this.subscription['groupUserList'] = this.groupService.index(this.groupUsersPath).subscribe((res: any) => {
            this.groups = res.filter((item: any) => item).sort((a: { createdAt: number; }, b: { createdAt: number; }) => b.createdAt - a.createdAt)
            this.groupsNoFilter = res.filter((item: any) => item).sort((a: { createdAt: number; }, b: { createdAt: number; }) => b.createdAt - a.createdAt)
            this.getGroupsByTab(this.groupsNoFilter);
          })
        }
      }
    })
  }

  getChildUserList() {
    const parentUserId = this.currentUser.parentUserId;
    const userId = parentUserId && parentUserId.length ? parentUserId : this.currentUser.uid;
    this.subscription['childUserList'] = this.userService.getValueChildUserList(userId).subscribe({
      next: (data) => this.users = data
    })
  }

  getGroupsByTab(data: any) {
    if (this.currentTab === 0) {
      this.groups = data?.filter((group: any) => {
        return Boolean(group.groupUsers.filter((user: any) => user.owner && user.email === this.currentUser?.email).length)
      })
    } else {
      this.groups = data?.filter((group: any) => {
        return Boolean(group.groupUsers.filter((user: any) => !user.owner && user.email === this.currentUser?.email).length)
      })
    }
  }

  search() {
    const keywords = this.formSearch.value.keywords;
    this.groups = this.groupsNoFilter;
    if (keywords) {
      this.groups = this.groups.filter((item: any) => {
        const checkEmailGroupUsers = item.groupUsers.filter((user: any) => user.email.includes(keywords)).length
        return item.name.includes(keywords) || Boolean(checkEmailGroupUsers);
      })
    }
    this.getGroupsByTab(this.groups)
    this.ref.detectChanges()
  }

  checkOwnerGroup(group: any) {
    const currentEmail = firebase.auth().currentUser?.email;
    if (group && group.groupUsers && currentEmail) {
      return Boolean(
        group.groupUsers.filter((user: {
          email: string | null | undefined;
          owner: any;
        }) => user.email == currentEmail && user.owner).length
      );
    }
    return false
  }

  delete(group: any) {
    const currentAuthEmail = firebase.auth().currentUser?.email
    const ownerGroup = group.groupUsers.filter((user: any) => user.owner)[0]['email']
    if (currentAuthEmail == ownerGroup) {
      const users = group.groupUsers.map((user: any) => {
        for (const key in this.users) {
          if (this.users[key].email === user.email) {
            user.tokens = this.users[key]?.tokens;
            user.active = this.users[key]?.active;
          }
        }
        return user
      }).filter((item: any) => item.email !== ownerGroup && item.active)
      this.groupDeletePath = group.path;
      this.groupService.delete(group).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.DELETE, EVENT_LOG_TYPE.SUCCESS);
        if (this.deleteGroup) {
          this.deleteGroup.visible = false;
        }
        const formCachesPromises = [];
        if (group.formIds && group.formIds.length) {
          for (const formId of group.formIds) {
            const formCacheSubscription = this.formCachesService.getDetailFormCache(formId).subscribe();
            this.listSubscription['formCachesList']?.push(formCacheSubscription);
            formCachesPromises.push(new Promise((resolve, reject) => this.formCachesService.getDetailFormCache(formId).subscribe({ next: (data) => resolve(data) })))
          }
          Promise.all(formCachesPromises).then((listFormCache) => {
            if (listFormCache.length) {
              const formPromises: any = [];
              listFormCache.forEach((formCache: any) => {
                const data = formCache[0];
                const formSubscription = this.formService.getValueOwnerFormById(data.userid, data.formid).subscribe();
                this.listSubscription['formCachesList']?.push(formSubscription);
                formPromises.push(new Promise((resolve, reject) => this.formService.getValueOwnerFormById(data.userid, data.formid).subscribe({ next: (data) => resolve(data) })))
              })
              Promise.all(formPromises).then((res) => {
                const dataForm = res.filter((item) => item);
                this.formService.updateMultipleGroupPathForm(dataForm, this.groupDeletePath).then();
              })
            }
          })
        }
        this.groups = this.groups.filter((item: any) => item.path !== group.path);
        this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_NOTIFICATION.DELETE_GROUP, EVENT_LOG_TYPE.SUCCESS);
        this.notificationService.sendNoticeGroup(users, 'deleteGroup', {groupName: group.name});
        this.alertService.success(this.translateService.instant('alertMessages.group.delete.success'))
      }).catch((error) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.DELETE, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.group.delete.fail'))
      })
      this.ref.detectChanges();
    }
  }

  getSubTextGroup(user: any) {
    const currentAuthEmail = firebase.auth().currentUser?.email
    if (currentAuthEmail === user.email) {
      return this.translateService.instant('groups.subText.you')
    } else if (user.owner) {
      return this.translateService.instant('groups.subText.owner')
    } else {
      return this.translateService.instant('groups.subText.participant')
    }
  }

  changeTab(tabIndex: number) {
    this.currentTab = tabIndex
    this.getGroupsByTab(this.groupsNoFilter)
  }
  receivePageOwner(event: number) {
    this.pageOwner = event
  }
  receivePageMember(event: number) {
    this.pageMember = event
  }
}
