import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GROUP_PER_PAGE } from "@app/constants";
import { initializeApp } from "@angular/fire/app";
import { environment } from "@environments/environment";
import { getAuth } from "@firebase/auth";
import { AlertService, GroupService, NotificationService, UserService } from "@app/services";
import { TranslateService } from "@ngx-translate/core";
import { getLongText, getSubstringTextNumber } from '@app/helpers';

@Component({
  selector: 'app-tab-member',
  templateUrl: './tab-member.component.html',
  styleUrls: ['./tab-member.component.scss']
})
export class TabMemberComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() groups: any
  @Input() delete: any
  @Input() checkOwnerGroup: any
  @Input() users: any
  @Input() getSubTextGroup: any
  getLongText = getLongText;
  itemPerPage = GROUP_PER_PAGE;
  page: number = 1;
  groupDeletePath: string = ''
  visibleItems: boolean[] = [];
  private app = initializeApp(environment.firebaseConfig);
  private auth = getAuth(this.app);
  @Output() pageMember = new EventEmitter<number>();
  textNameDisplay: number = 12;
  textEmailDisplay: number = 12;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.checkLongText();
  }

  constructor(
    private groupService: GroupService,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.checkLongText();
  }

  ngAfterViewChecked(): void {
    this.checkLongText();
  }

  changePage(event: any) {
    this.page = event;
    this.pageMember.emit(event)
  }

  toggleCollapse(index: number) {
    this.visibleItems[index] = !this.visibleItems[index];
  }

  checkLongText() {
    this.textNameDisplay = getSubstringTextNumber('.name-member-section', '.user-member-info');
    this.textEmailDisplay = getSubstringTextNumber('.email-member-section', '.user-member-info');
    this.ref.detectChanges();
  }
}
