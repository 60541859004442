<header [ngClass]="{'form-user-header': isFormUser}">
  <a id="logo" routerLink="/login">
    <img src="./assets/img/logo.png" alt="logo" />
    <div>{{ environment.service_name[currentLanguage] }}</div>
  </a>
  <div class="btn-section">
    <div *ngIf="isShowReissueButton">
      <a
        class="btn-header btn-reissue"
        routerLink="/reissue"
        routerLinkActive="btn-header--active"
        [routerLinkActiveOptions]="{ exact: false }">
        <img src="assets/svg/scan-barcode.svg" alt="scan barcode" />
        <span class="ps-2">{{ "layout.header.menu.qrReissue" | translate }}</span>
      </a>
    </div>
    <div id="auth" *ngIf="router.isActive('/forgot-password', true)">
      <a class="active" routerLink="/login">{{ 'login.login' | translate }}</a>
    </div>
  </div>
</header>
