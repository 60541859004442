import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { AlertService, FormService } from "@app/services";
import { Router, ActivatedRoute } from "@angular/router";
import { DEFAULT_USE_VIDEO_VALUE, INIT_EDITOR, RuleValidations } from "@app/constants";
import { clearAllErrors, getFirstError } from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-video-edit',
  templateUrl: './video-edit.component.html',
  styleUrls: ['./video-edit.component.scss']
})
export class VideoEditComponent implements OnChanges {
  @Input('formEdit') formEdit: any;
  @Input('videoList') videoList: Array<any> = [];
  @Input('submitAllTab') submitAllTab: any;
  @Input('isOutDate') isOutDate: boolean = false;
  @Output() submittedData = new EventEmitter<Boolean>();
  @Output() isBackToFormList = new EventEmitter<Boolean>();
  getFirstError = getFirstError;
  protected readonly Boolean = Boolean
  ruleValidations = RuleValidations;
  initEditor = INIT_EDITOR;
  submitted = false;
  lastFormControl: any;

  constructor(
    private formService: FormService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formEdit) {
      const controlNames = Object.keys(this.formEdit.controls);
      const lastControlName = controlNames[controlNames.length - 1];
      if (this.formEdit.controls.useVideo?.value) {
        this.checkVideoError()
      }
      this.lastFormControl = this.formEdit.get(lastControlName);
    }
  }

  get videoListControl() {
    return this.formEdit.controls.videoList.controls as FormArray;
  }

  get useVideoForm() {
    return this.formEdit.controls.useVideo;
  }

  backToFormList() {
    this.isBackToFormList.emit(true);
  }

  setRandomVideoError() {
    const { videoList, ..._} = this.formEdit.value;
    this.useVideoForm.setErrors(null);
    if (!videoList.filter((item: string) => item.length).length) {
      this.useVideoForm.setErrors({requiredAll: true});
    }
  }

  checkVideoError() {
    clearAllErrors(this.formEdit);
    if (this.useVideoForm.value === DEFAULT_USE_VIDEO_VALUE) {
     this.setRandomVideoError(); 
    } else {
      const videoControl = this.videoListControl?.at(this.useVideoForm.value - 1);
      videoControl.addValidators([Validators.required]);
      videoControl.updateValueAndValidity();
    }
  }

  async submit() {
    this.submitted = true;
    if (this.formEdit.valid) {
      this.submittedData.emit(true)
    }
  }
}
