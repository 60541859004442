import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TabService } from "@app/services/tab.service";
import { ulid } from "ulid";
import { ITab } from "@app/models/tab";
import { ACTION_LIST, EVENT_LOG_TYPE, ITEM_PER_PAGE, RuleValidations } from "@app/constants";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, FirebaseAnalyticService, FormService, UserService } from "@app/services";
import { Router } from "@angular/router";
import { getLongText } from "@app/helpers";
import { CustomValidatorsService } from '@app/services/custom-validators.service';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.outerWidth
    this.getNumberOfTextDisplay()
  }
  protected readonly ITEM_PER_PAGE = ITEM_PER_PAGE;
  protected readonly RuleValidations = RuleValidations;
  formAddTab = new FormGroup({
    name: new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]),
    index: new FormControl(0, []),
    isDisable: new FormControl(false, []),
    docId: new FormControl('', []),
    createdAt: new FormControl(new Date(), []),
  })
  isChanged: boolean = false;
  maxItemShow: number = ITEM_PER_PAGE;
  showFullItem: boolean = false;
  screenWidth: number = 0;
  numberOfTextDisplay: number = 0;
  stylesButton = {
    transform: 'rotate(-180deg)'
  }
  tabs: Array<ITab> | undefined;
  rawTabs: Array<ITab> | undefined;
  isSubmitted: boolean = false;
  tabSelected: ITab = {}
  editModal: boolean = false;
  visibleDeleteTabModal: boolean = false;
  tabId: string = '';
  isDisabledDeleteButton: boolean = false;
  authUser: any;
  subscription: {[key: string] : Subscription | null} = {
    tabs: null
  }

  constructor(
    private tabService: TabService,
    private ref: ChangeDetectorRef,
    private translateService: TranslateService,
    private alertService: AlertService,
    private router: Router,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formService: FormService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.screenWidth = window.outerWidth;
    this.getNumberOfTextDisplay();
    this.getTabList().then()
  }

  ngOnDestroy(): void {
    this.subscription['tabs']?.unsubscribe();
  }

  async getTabList() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.authUser = authUser;
      this.subscription['tabs'] = this.tabService.index(this.authUser.uid).subscribe(async (res) => {
        this.formAddTab.controls.index.setValue(res.length)
        this.rawTabs = res.map((item: ITab) => {
          return { ...item }
        });
        this.tabs = res;
        for (const tab of this.tabs) {
          const forms = await firstValueFrom(this.formService.getFormsByTabId(this.authUser.uid, tab.docId ?? ''));
          tab['isDisplayDeleteButton'] = false;
          if (!forms.length) tab['isDisplayDeleteButton'] = true;
        }
      })
    }
  }

  editTab(tab: ITab) {
    this.tabSelected = tab;
    this.editModal = true
  }

  receiveModalStatus(status: boolean) {
    if (!status) {
      this.tabSelected = {};
      this.editModal = status;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.tabs) {
      moveItemInArray(this.tabs, event.previousIndex, event.currentIndex);
      this.checkChangeTab()
    }
  }

  moveUp(item: ITab) {
    if (this.tabs) {
      const currentIndex = this.tabs.indexOf(item);
      if (currentIndex > 0 && !item.isDisable) {
        moveItemInArray(this.tabs, currentIndex, currentIndex - 1);
        this.checkChangeTab()
      }
    }
  }

  moveDown(item: ITab) {
    if (this.tabs) {
      const currentIndex = this.tabs.indexOf(item);
      if (currentIndex < this.tabs.length && !item.isDisable) {
        moveItemInArray(this.tabs, currentIndex, currentIndex + 1);
        this.checkChangeTab()
      }
    }
  }

  changeDisabled(tab: ITab) {
    this.tabs = this.tabs?.map((item) => {
      if (item.docId === tab.docId) {
        item.isDisable = !item.isDisable
      }
      return item
    })
    this.checkChangeTab()
  }

  checkChangeTab() {
    if (this.tabs && this.rawTabs) {
      this.isChanged = false
      for (const tab of this.tabs) {
        for (const rawTab of this.rawTabs) {
          const indexOfTab = this.tabs.indexOf(tab)
          const indexOfRawTab = this.rawTabs.indexOf(rawTab)
          if (tab.docId === rawTab.docId && ((tab.isDisable !== rawTab.isDisable) || (indexOfTab !== indexOfRawTab))) {
            this.isChanged = true
            break
          }
        }
      }
    }
  }

  resetData() {
    if (this.rawTabs)
    this.tabs = this.rawTabs.map((item: ITab) => {
      return { ...item }
    });
    this.isChanged = false
  }

  updateIndexTab() {
    if (this.tabs) {
      this.tabs = (this.tabs).map((item: ITab, index: number) => {
        item.index = (this.tabs?.length ?? 0) - 1 - index;
        return item;
      });
      this.tabService.update(this.authUser.uid, this.tabs).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TAB.UPDATE_INDEX, EVENT_LOG_TYPE.SUCCESS)
        this.router.navigate(['account']).then(() => this.alertService.success(this.translateService.instant('alertMessages.tab.update.success')))
      }).catch((e) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TAB.UPDATE_INDEX, EVENT_LOG_TYPE.ERROR)
        this.alertService.error(this.translateService.instant('alertMessages.tab.update.fail'))
      })
    }
  }

  showMoreItem() {
    this.maxItemShow = this.maxItemShow + ITEM_PER_PAGE
    const listTabs = document.getElementById('list');
    this.ref.detectChanges();
    if (listTabs && this.tabs) {
      const domShow = listTabs.getElementsByClassName('list-group-item').length;
      this.showFullItem = domShow === this.tabs.length
    }
  }

  getNumberOfTextDisplay() {
    if ((375 <= this.screenWidth && this.screenWidth < 470) || this.screenWidth < 375) this.numberOfTextDisplay = 5;
    else if (470 <= this.screenWidth && this.screenWidth < 576) this.numberOfTextDisplay = 10;
    else if (576 <= this.screenWidth && this.screenWidth < 768) this.numberOfTextDisplay = 15;
    else if (768 <= this.screenWidth && this.screenWidth < 992) this.numberOfTextDisplay = 25;
    else if (992 <= this.screenWidth && this.screenWidth < 1200) this.numberOfTextDisplay = 40;
    else if (1200 <= this.screenWidth && this.screenWidth < 1400) this.numberOfTextDisplay = 50;
    else this.numberOfTextDisplay = 60;
  }

  createTab() {
    this.isSubmitted = true
    this.formAddTab.controls.docId.setValue(ulid());
    this.formAddTab.controls.isDisable.setValue(false);
    this.formAddTab.controls.createdAt.setValue(new Date());
    if (this.formAddTab.valid) {
      this.tabService.create(this.authUser.uid, this.formAddTab.value as ITab).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TAB.CREATE, EVENT_LOG_TYPE.SUCCESS)
        this.isSubmitted = false;
        this.formAddTab.reset();
        this.alertService.success(this.translateService.instant('alertMessages.tab.create.success'))
      }).catch((e) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TAB.CREATE, EVENT_LOG_TYPE.ERROR)
        this.alertService.success(this.translateService.instant('alertMessages.tab.create.fail'))
      })
    }
  }

  toggleDeleteTabModal(tab: any) {
    this.tabId = tab.docId ?? '';
    this.visibleDeleteTabModal = !this.visibleDeleteTabModal;
  }

  deleteTab() {
    this.visibleDeleteTabModal = false;
    this.isDisabledDeleteButton = true;
    this.tabService.delete(this.authUser.uid, this.tabId).then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.TAB.DELETE, EVENT_LOG_TYPE.SUCCESS);
      this.alertService.success(this.translateService.instant('alertMessages.tab.delete.success'));
      this.isDisabledDeleteButton = false;
    }).catch((error) => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.TAB.DELETE, EVENT_LOG_TYPE.ERROR);
      this.alertService.error(this.translateService.instant('alertMessages.tab.delete.fail'));
      this.isDisabledDeleteButton = false;
    })
  }

  protected readonly getLongText = getLongText;
}
