import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountRestrictGuard, AuthGuard } from './helpers';
import { AccountComponent } from './views/pages/account/account.component';
import { CreditCardInfoComponent } from './views/pages/credit-card-info/credit-card-info.component';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';
import { LoginComponent } from './views/pages/login/login.component';
import { NonAccountComponent } from './views/pages/non-account/non-account.component';
import { NotificationComponent } from './views/pages/notification/notification.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { UpdatePasswordComponent } from './views/pages/update-password/update-password.component';
import { FormCreateComponent } from "@app/views/pages/form-create/form-create.component";
import { FormComponent } from "@app/views/pages/form/form.component";
import { FormUserComponent } from "@app/views/pages/form-user/form-user.component";
import { FormEditComponent } from "@app/views/pages/form-edit/form-edit.component";
import { ListFormDataComponent } from "@app/views/pages/list-form-data/list-form-data.component";
import { ReissueComponent } from "@app/views/pages/reissue/reissue.component";
import { FormDataEditComponent } from "@app/views/pages/list-form-data/edit/form-data-edit.component";
import { FormGuestComponent } from './views/pages/form-guest/form-guest.component';
import { ExportComponent } from "@app/views/pages/list-form-data/export/export.component";
import { GroupsComponent } from "@app/views/pages/groups/groups.component";
import { WebViewComponent } from './views/pages/web-view/web-view.component';
import { ExamComponent } from "@app/views/pages/web-view/exam/exam.component";
import { CertificateComponent } from './views/pages/web-view/certificate/certificate.component';
import { TabComponent } from "@app/views/pages/tab/tab.component";
import { ListDevicesComponent } from "@app/views/pages/list-form-data/list-devices/list-devices.component";
import { EditDevicesComponent } from "@app/views/pages/list-form-data/list-devices/edit-devices/edit-devices.component";
import { GroupRoleGuard } from '@app/helpers';
import { PageNotFoundComponent } from './views/pages/page-not-found/page-not-found.component';
import { CreatePortalComponent } from "@app/views/pages/portal/create/create.component";
import { PortalComponent } from './views/pages/portal/portal.component';
import { ListTemplateComponent } from './views/pages/list-template/list-template.component';
import { CreateTemplateComponent } from './views/pages/list-template/create/create-template.component';
import { TestDetailComponent } from "@app/views/pages/form-edit/test-edit/test-detail/test-detail.component";
import { TemplateSettingComponent } from '@app/views/pages/list-template/template-setting/template-setting.component';
import { TemplateItemsEditComponent } from './views/pages/list-template/template-items-edit/template-items-edit.component';
import { ListAccountComponent } from './views/pages/list-account/list-account.component';
import {
  PreviewItemFormDataComponent
} from "@app/views/pages/list-template/template-items-edit/preview-item-form-data/preview-item-form-data.component";
import { CreateAccountComponent } from './views/pages/list-account/create-account/create-account.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'account'
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'reissue/:id',
    component: ReissueComponent,
    data: {
      title: 'Reissue Page'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot password Page'
    }
  },
  {
    path: 'non-account',
    component: NonAccountComponent,
    data: {
      title: 'Non-account Page'
    }
  },
  {
    path: 'notification',
    canActivate: [AuthGuard],
    component: NotificationComponent,
    data: {
      title: 'Notification Page',
    }
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    data: {
      title: 'page 404'
    }
  },
  {
    path: 'form',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: FormComponent,
        data: {
          title: 'Form index'
        },
      },
      {
        path: 'create',
        component: FormCreateComponent,
        canActivate: [AccountRestrictGuard],
        data: {
          title: 'Form Create Page',
          page: 'form_create'
        }
      },
      {
        path: 'edit/:id',
        children: [
          {
            path: '',
            component: FormEditComponent,
            data: {
              title: 'Form data Page'
            }
          },
          {
            path: 'test/:testId',
            component: TestDetailComponent,
            data: {
              title: 'Form data Page'
            }
          },
        ]
      },
      {
        path: ':id',
        children: [
          {
            path: 'form-data',
            component: ListFormDataComponent,
            data: {
              title: 'Form data Page'
            }
          },
          {
            path: 'export',
            component: ExportComponent,
            data: {
              title: 'Form data Page'
            }
          },
          {
            path: 'form-data-edit/:docId',
            component: FormDataEditComponent,
            data: {
              title: 'Form data Page'
            }
          },
          {
            path: 'devices',
            data: {
              title: 'List devices page'
            },
            children: [
              {
                path: '',
                component: ListDevicesComponent,
                canActivate: [AccountRestrictGuard],
                data: {
                  title: 'Form data Page',
                  page: 'camera_list'
                }
              },
              {
                path: ':camera_id',
                canActivate: [AccountRestrictGuard],
                component: EditDevicesComponent,
                data: {
                  title: 'Form data Page',
                  page: 'camera_setting_edit'
                }
              }
            ]
          },
        ]
      },
    ]
  },
  {
    path: 'groups',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: GroupsComponent,
        data: {
          title: 'Group index'
        },
      },
    ]
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AccountComponent,
        data: {
          title: 'Account Page'
        },
      },
      {
        path: 'update-password',
        component: UpdatePasswordComponent,
        data: {
          title: 'Update password Page'
        }
      },
      {
        path: 'credit-card-info',
        component: CreditCardInfoComponent,
        data: {
          title: 'Credit card info Page'
        }
      },

    ]
  },
  {
    path: 'form-guest/:id',
    component: FormGuestComponent,
    data: {
      title: 'Form-guest'
    }
  },
  {
    path: 'form-user/:id',
    canActivate: [GroupRoleGuard],
    component: FormUserComponent,
    data: {
      title: 'Form-user'
    }
  },
  {
    path: 'list-template',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ListTemplateComponent,
        canActivate: [AccountRestrictGuard],
        data: {
          title: 'Template index',
          page: 'template_list'
        },
      },
      {
        path: 'create',
        canActivate: [AccountRestrictGuard],
        component: CreateTemplateComponent,
        data: {
          title: 'Template create',
          page: 'template_create'
        },
      },
      {
        path: 'basic-setting/:templateId',
        canActivate: [AccountRestrictGuard],
        component: TemplateSettingComponent,
        data: {
          title: 'Template basic setting',
          page: 'template_basic_setting'
        },
      },
      {
        path: 'form-items-edit/:templateId',
        children: [
          {
            path: '',
            component: TemplateItemsEditComponent,
            canActivate: [AccountRestrictGuard],
            data: {
              title: 'Template item setting',
              page: 'template_item_edit'
            }
          },
          {
            path: 'preview',
            component: PreviewItemFormDataComponent,
            data: {
              title: 'Template basic preview',
              page: 'template_preview'
            }
          },
        ]
      }
    ]
  },
  {
    path: 'list-account',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ListAccountComponent,
        canActivate: [AccountRestrictGuard],
        data: {
          title: 'Account index',
          page: 'child_account_list'
        },
      },
      {
        path: 'create',
        component: CreateAccountComponent,
        canActivate: [AccountRestrictGuard],
        data: {
          title: 'Create account',
          page: 'child_account_create'
        }
      }
    ]
  },
  {
    path: 'tabs',
    canActivate: [AuthGuard],
    component: TabComponent,
    data: {
      title: 'Tab page'
    }
  },
  {
    path: 'portal',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PortalComponent,
        data: {
          title: 'Portal index'
        },
      },
      {
        path: 'edit',
        component: CreatePortalComponent,
        data: {
          title: 'Portal Create Or Update Page'
        }
      },
    ]
  },
  {
    path: ':prefix/:formId/:formDataId/exam',
    component: ExamComponent,
    data: {
      title: 'Exam page'
    }
  },
  {
    path: ':prefix/:formId/:formDataId/certificate',
    component: CertificateComponent,
    data: {
      title: 'Certificate page'
    }
  },
  {
    path: ':prefix/:formId/:formDataId',
    component: WebViewComponent,
    data: {
      title: 'Web-view page'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
