<div class="select-date-section">
  <span>{{ 'form.layout.dataDisplay.month' | translate }}</span>
  <input 
    type="text"
    [className]="'select-date-section__input'"
    placeholder="Datepicker"
    class="form-control"
    [bsValue]="date"
    (onShown)="searchDate($event)"
    [placeholder]="'form.layout.dataDisplay.selectDate'| translate"
    [bsConfig]="datePickerConfig"
    bsDatepicker
  >
</div>
<full-calendar #calendar [options]="calendarOptions"></full-calendar>
