import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MAX_VALUE_LENGTH_INDEX_LIST, MIN_MAX_VALUE_DATA_TYPE_LIST, MIN_VALUE_LENGTH_INDEX_LIST, RuleValidations, TYPE_INPUT } from "@app/constants";
import { getFirstError, setValueOfDeepData, synchronizeValuesOfDeepData } from "@app/helpers";

@Component({
  selector: 'app-single-line-form',
  templateUrl: './single-line-form.component.html',
  styleUrls: ['./single-line-form.component.scss']
})
export class SingleLineFormComponent implements OnChanges {
  @Input() form: any;
  @Input() submitted: boolean = false;
  @Input() validationListIndex: any = [];
  isNumberInput: boolean = false;
  showErrors: { [key: string | number]: any } = {}
  formValidation = [];
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.isNumberInput = (MIN_MAX_VALUE_DATA_TYPE_LIST.includes(this.form.controls.datatype.value))
    this.showErrors = this.setValuesToFalse(this.form.value, false);
  }

  setValuesToFalse(obj: any, valueChange: any): any {
    return synchronizeValuesOfDeepData(obj, false)
  }

  changeShowErrors(...keys: any[]) {
    this.showErrors = setValueOfDeepData(this.showErrors, keys, true)
  }

  protected readonly MIN_VALUE_LENGTH_INDEX_LIST = MIN_VALUE_LENGTH_INDEX_LIST;
  protected readonly MAX_VALUE_LENGTH_INDEX_LIST = MAX_VALUE_LENGTH_INDEX_LIST;
  protected readonly RuleValidations = RuleValidations;
  protected readonly getFirstError = getFirstError;
  protected readonly TYPE_INPUT = TYPE_INPUT;
}
