<div>
  <c-modal #editTabName [visible]="visible" alignment="center" id="editTabName" (visibleChange)="emittedModalToggle()">
    <c-modal-header>
      <h5 cModalTitle>{{'tab.formUpdate.title' | translate}}</h5>
      <button [cModalToggle]="editTabName.id" cButtonClose></button>
    </c-modal-header>
    <c-modal-body>
      <form cForm [formGroup]="formEditTab">
        <div>
          <label cLabel for="tabName">{{'tab.formCreate.label' | translate}}</label>
          <input cFormControl formControlName="name" id="tabName" type="text"/>
          <span class="text-danger" *ngIf="formEditTab.controls.name.errors?.['required'] && isSubmitted">{{'errorMessages.tab.name.required' | translate}}</span>
          <span class="text-danger" *ngIf="formEditTab.controls.name.errors?.['maxlength'] && isSubmitted">{{'errorMessages.tab.name.maxlength' | translate: {maxlength: RuleValidations.maxLength255} }}</span>
        </div>
      </form>
    </c-modal-body>
    <c-modal-footer>
      <button [cModalToggle]="editTabName.id" class="btn-second">
        {{'tab.formUpdate.close' | translate}}
      </button>
      <button class="btn-common" (click)="updateTabName()">{{'tab.formUpdate.save' | translate}}</button>
    </c-modal-footer>
  </c-modal>
</div>
