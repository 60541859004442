<c-card>
  <c-card-body>
    <form action="" [formGroup]="formEdit">
      <div class="mb-3" *ngIf="formEdit.controls?.['showCertificate']">
        <c-col class="mb-1">{{ "formEdit.certificateTab.inputLabel.showCertificate.title" | translate }}</c-col>
        <c-input-group>
          <c-form-check inline>
            <input cFormCheckInput checked id="showCertificateTrue" [value]="true" formControlName="showCertificate" type="radio" />
            <label cFormCheckLabel for="showCertificateTrue">{{ "formEdit.certificateTab.inputLabel.showCertificate.use" | translate }}</label>
          </c-form-check>
          <c-form-check inline>
            <input cFormCheckInput id="showCertificateFalse" [value]="false" formControlName="showCertificate" type="radio" />
            <label cFormCheckLabel for="showCertificateFalse">{{ "formEdit.certificateTab.inputLabel.showCertificate.notUse" | translate }}</label>
          </c-form-check>
        </c-input-group>
      </div>
      <div class="mb-3">
        <c-col class="mb-1">{{ "formEdit.certificateTab.inputLabel.description" | translate }}</c-col>
        <editor formControlName="certificateDescription" [init]="initEditor" (onSelectionChange)="changeEditor($event)"></editor>
        <c-form-check class="text-danger" *ngIf="formEdit.get('certificateDescription').errors?.['maxlength'] && (submitAllTab || submitted)">
          {{ "errorMessages.formValidate.input.maxlength" | translate : { maxlength: ruleValidations.maxLength4096 } }}
        </c-form-check>
      </div>
      <div class="mb-3 d-flex justify-content-end">
        <button (click)="backToFormList()" class="btn-second me-1">
          {{ "form.backButton" | translate }}
        </button>
        <button class="btn-common ms-1" type="submit" (click)="submit()"
          [class]="{
            'btn-submit': true,
            'btn-submit-disabled': isOutDate
          }">
          {{ "form.inputLabel.submitChange" | translate }}
        </button>
      </div>
    </form>
  </c-card-body>
</c-card>
