import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ulid } from 'ulid';

@Component({
  selector: 'app-modal-generate-uid',
  templateUrl: './generate-uid.component.html',
  styleUrls: ['./generate-uid.component.scss']
})
export class GenerateUidComponent implements OnInit {
  @Input('visibleGenerateUid') visibleGenerateUid: boolean = false;
  @Output() getUid = new EventEmitter<string>;

  constructor() { }

  ngOnInit(): void {
  }
  toggleGenerateUidModal() {
    this.visibleGenerateUid = !this.visibleGenerateUid;
  }

  generateUid() {
    this.getUid.emit(ulid());
    this.visibleGenerateUid = false;
  }
}
