import { Injectable } from '@angular/core';
import {
  getAuth,
  onAuthStateChanged,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  OAuthProvider
} from "@firebase/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { initializeApp } from "@angular/fire/app";
import { environment } from "@environments/environment";
import { sendPasswordResetEmail } from "@angular/fire/auth";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { serverTimestamp } from 'firebase/firestore';
import { HttpClient } from "@angular/common/http";
import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';
import {
  DataType,
  DATA_VARIABLES_EMAIL,
  DEFAULT_EMAIL_REGISTER,
  DEFAULT_LANGUAGE_EMAIL, EMAIL_KEY
} from "@app/constants";
import { TokenAction, VALIDATE_DATA } from "@app/constants/user-import";
import { ulid } from "ulid";
import { chunkArray, generateRandomPassword, onlyUnique } from "@app/helpers";
import { ApiService } from './api.service';
import { IUser } from '@app/models';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private app = initializeApp(environment.firebaseConfig);
  private auth = getAuth(this.app);
  userId: any;
  private baseUrl = environment.baseUrl;

  constructor(public angularFireAuth: AngularFireAuth,
    private db: AngularFirestore,
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router,
  ) { }

  getCurrentUserAuth() {
    return this.auth.currentUser ?? null;
  }

  getCurrentUser() {
    return this.angularFireAuth.authState;
  }

  async userValue() {
    const app = await initializeApp(environment.firebaseConfig);
    const auth = await getAuth(app);
    return new Promise((resolve, reject) => {
      try {
        onAuthStateChanged(auth, user => {
          resolve(user);
        });
      } catch {
        reject('api failed');
      }
    });
  }

  async signInGoogle() {
    const googleAuth = new firebase.auth.GoogleAuthProvider();
    if (navigator.userAgent.includes("Firefox")) {
      this.angularFireAuth.signInWithPopup(googleAuth).then(data => {
        this.storeUser().then();
      });
    }
    this.angularFireAuth.signInWithPopup(googleAuth).then(data => {
      this.storeUser().then();
      localStorage.setItem('loginBySso', String(true));
      window.location.href = '/#/account';
    });
  }

  async signInApple() {
    let provider = new OAuthProvider('apple.com');
    this.angularFireAuth.signInWithPopup(provider).then(() => {
      this.storeUser().then();
      localStorage.setItem('loginBySso', String(true));
      window.location.href = '/#/account';
    });
  }

  async storeUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser && currentUser.uid.length) {
      this.apiService.get(`${this.baseUrl}/get-user-by-email/${currentUser?.email}`).then(async (response: any) => {
        if (typeof response === 'object' && !Object.keys(response).length) {
          await this.apiService.post(`${this.baseUrl}/create-user`, { email: currentUser?.email });
        } else {
          await this.db.collection('users').doc(currentUser?.uid).update({ lastLoginAt: serverTimestamp() })
        }
      })
    }
  }

  async register(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.createUserWithEmailAndPassword(email, password).then(() => {
        this.apiService.post(`${this.baseUrl}/create-user`, { email: email }).then(async (response: any) => {
          if (response) {
            await this.sendMailRegisterAccount(response['user'])
            resolve(response['user'])
          }
        })
      }).catch((error) => reject(error));
    })
  }

  async SignOut() {
    await this.angularFireAuth.signOut();
  }

  async forgotPassword(email: string) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      const currentUser = await firstValueFrom(this.getValueUserByUid(user.uid));
      if (token && token.length && currentUser) await this.updateAccountToken(currentUser, token, TokenAction.remove);
      const credential = EmailAuthProvider.credential(
        user.email ? user.email : '',
        oldPassword,
      );
      await reauthenticateWithCredential(
        auth.currentUser,
        credential
      )
      await updatePassword(user, newPassword);
    }
  }

  async deleteAccount(id: string) {
    await this.db.collection('users',).doc(id).update({
      active: false,
      tokens: [],
    })
  }

  async update(user: any) {
    return this.db.collection('users').doc(user.uid).update(user)
  }

  async getDocId(email: any) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.db.collection('users', ref => ref.where('email', '==', email)).snapshotChanges().subscribe((res: any) => {
          resolve(res[0].payload.doc.id);
        });
      } catch (e) {
        reject('api fail');
      }
    })
  }

  async updateStripeSub(sub: string, email: string, planName: string, accountType: string) {
    let id = await this.getDocId(email);
    await this.db.collection('users').doc(id).update({
      "accountTypeName": accountType,
      "accountType": planName,
      "sub": sub,
      "purchaseDate": serverTimestamp(),
    });
  }

  async getUserByMail(email: any) {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users', ref => ref.where('email', '==', email)).valueChanges({ idField: 'id' }).subscribe((user) => {
        resolve(user)
      })
    })
  }

  async updateAccount(param: any) {
    const batch = this.db.firestore.batch();
    const userId = firebase.auth().currentUser?.uid;
    const email = firebase.auth().currentUser?.email ?? "";
    const dataUpdateUser = {
      displayName: param.name,
      name: param.name,
      secretKey: param.secretKey,
      memberCode: param.memberCode,
      organizationName: param.organizationName,
      apiKey: param.apiKey,
      organizationId: param.organizationId
    };
    const dataUpdateFormUser = {
      ownerFormData: param.name
    }
    const userDocRef = this.db.collection('users').doc(userId).ref;
    batch.update(userDocRef, dataUpdateUser);
    await this.updateOwnerFormData(batch, dataUpdateFormUser, userId);
    await this.updateGroupUser(batch, email, param.name ?? "");
    await batch.commit();
  }

  async updateOwnerFormData(batch: any, dataUpdate: any, userId: string | undefined) {
    const formCollection = this.db.collection('users').doc(userId).collection('form');
    const forms = await firstValueFrom(formCollection.valueChanges());
    if (forms.length) {
      forms.forEach((form: any) => {
        const formId = form.doc_id;
        const nodes = form.nodes.nodes.objects;
        const uidNode = nodes.filter((node: any) => node.datatype === DataType.uid);
        if (uidNode && uidNode.length) {
          formCollection.doc(formId).collection('form-data', ref => ref.where('userid', '==', userId)).get().toPromise().then((res) => {
            res?.forEach((item) => {
              batch.update(item.ref, dataUpdate);
            })      
          })
        }
      })
    }
  }

  async updateGroupUser(batch: any, email: string, name: string) {
    await this.db.collectionGroup('group_users', ref => ref.where('email', '==', email)).get().toPromise().then((res) => {
      res?.forEach((item) => {
        const dataUpdate = {
          name: name
        }
        batch.update(item.ref, dataUpdate);
      })
    })
  }

  async sendMailRegisterAccount(user: any) {
    const dataEmailTemplate = (await this.getTemplateEmail(EMAIL_KEY.SIGN_UP)).docs.map((item) => item.data())[0];
    if (dataEmailTemplate) {
      const dataEmail = this.formatEmail(dataEmailTemplate, user);
      await this.db.collection("mail").add(dataEmail);
    }
  }

  async updateStatusUser(id?: string, status?: boolean) {
    const user = { disabled: status };
    this.apiService.patch(`users/${id}`, user);
  }

  getUserInDBByEmail(email: string) {
    return this.db.collection('users', ref => ref.where('email', '==', email)).snapshotChanges();
  }

  getUserInById(id: string) {
    return this.db.collection('users').doc(id).snapshotChanges();
  }

  async updateLastLogin() {
    await this.db.collection('users').doc(firebase.auth().currentUser?.uid).update({
      lastLoginAt: new Date()
    })
  }

  getChildUsers() {
    const userId = firebase.auth().currentUser?.uid;
    return this.db.collection('users', ref => ref.where('parentUserId', '==', userId).orderBy('createAt', 'desc')).valueChanges()
  }

  // add by Tanaka on 2023/08/11
  async getCurrentUserId() {
    return firebase.auth().currentUser?.uid ?? '';
  }

  // update by Tanaka on 2023/08/11
  async getUserByUid(uid: string) {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users').doc(uid).valueChanges({ idField: 'id' }).subscribe((user) => {
        resolve(user)
      })
    })
  }

  async updateAccountActiveMode(uid: string, activeMode: boolean) {
    await this.db.collection('users').doc(uid).update({ active: activeMode });
  }

  async updateAuthenticateAccountStatus(id?: string, status?: boolean) {
    this.apiService.patch(`update-authenticate-account/${id}`, { disabled: status });
  }

  checkValidateImportUsers(users: Array<{ [key: string]: string }>) {
    let validData = true;
    const listColumValidate = [];
    for (let i = 0; i < users?.length; i++) {
      const user = users[i];
      for (let j = 0; j < VALIDATE_DATA.length; j++) {
        const itemField = VALIDATE_DATA[j]
        if (!user[itemField.name]?.length && itemField.required) {
          validData = false;
          listColumValidate.push(itemField.name)
        }
      }
    }
    return {
      status: validData,
      listColumValidate: listColumValidate
    };
  }

  checkInvalidActiveImport(users: Array<{ [key: string]: string }>) {
    let invalidActive = false;
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      if (user['active'] !== 'true' && user['active'] !== 'false') {
        invalidActive = true
        break
      }
    }
    return {
      status: invalidActive
    }
  }

  checkDuplicateEmailImport(users: Array<{ [key: string]: string }>) {
    const totalData = users?.length;
    if (totalData === 1) {
      return {
        status: false,
      }
    }
    const uniqueEmail = users.map((item) => item['email']).filter(onlyUnique)?.length;
    return {
      status: totalData !== uniqueEmail,
    }
  }

  async checkExistUsersImport(users: Array<{ [key: string]: string }>): Promise<{ status: boolean, listEmail: any[] }> {
    let isUserExist: boolean = false;
    let listExistEmail: any[] = [];
    const promises = [];
    for (const user of users) {
      promises.push(await this.getUserByEmailApi(user['email']));
    }
    await Promise.all(promises).then((response) => {
      const checkUserExist = response.filter((item: any) => typeof item === 'object' && Object.keys(item).length).map((item: any) => item['user']);
      if (checkUserExist.length) {
        isUserExist = true;
        listExistEmail = checkUserExist.map((item: any) => item['email']).filter(onlyUnique);
      }
    })
    return {
      status: isUserExist,
      listEmail: listExistEmail
    };
  }

  async checkExistUserImportAuthenticate(users: Array<{ [key: string]: string }>) {
    const currentUser = firebase.auth().currentUser;
    let idToken = '';
    await currentUser?.getIdToken().then((res) => { idToken = res; })
    return new Promise((resolve, reject) => {
      this.http.post(`${this.baseUrl}/get-users-exist-authenticate`, users, { headers: { authorization: `Bearer ${idToken}` } }).toPromise().then((res) => {
        resolve(res)
      })
    })
  }

  async sendMailCreateChildUser(dataUser: any) {
    const dataEmailTemplate = (await this.getTemplateEmail(EMAIL_KEY.IMPORT)).docs.map((item) => item.data())[0];
    const dataEmail = this.formatEmail(dataEmailTemplate, dataUser);
    this.db.collection('mail').doc().set(dataEmail);
  }

  async batchCreateUsers(users: Array<{ [key: string]: any }>) {
    const currentUser = firebase.auth().currentUser;
    const currentUserId = currentUser?.uid;
    const dataImport: Array<IUser> = users.map((user) => {
      const data: IUser = {
        accountType: 'free',
        accountTypeName: 'フリーアカウント',
        accountTypePredict: null,
        active: !(user['active'] !== 'true'),
        appUserId: null,
        createAt: serverTimestamp(),
        displayName: user['name'],
        email: user['email']?.toLowerCase(),
        lastLoginAt: null,
        linked_ui: null,
        listSubscriptionAwaitBuy: null,
        listSubscriptionAwaitCancel: null,
        listSubscriptionBuying: null,
        memberCode: null,
        name: user['name'],
        organizationName: user['organizationName'],
        parentUserId: currentUserId ? currentUserId : '',
        payment_method: null,
        photoURL: null,
        purchaseDate: null,
        secretKey: null,
        tokens: null,
        uid: ulid(),
        updateAt: serverTimestamp(),
        disabled: user['active'] !== 'true',
        password: generateRandomPassword(),
      }
      return data;
    })
    const chunkUser = chunkArray(dataImport, 10);
    this.importUsersToAuthentication(chunkUser).then(async () => {
      this.sendMailImportUsersQueue(dataImport).then()
    }).catch((e) => { })
  }

  async importUsersToAuthentication(users: Array<IUser>) {
    this.apiService.post(`${this.baseUrl}/import-users-queue`, users).then();
  }

  async sendMailImportUsersQueue(users: Array<IUser>) {
    this.apiService.post(`${this.baseUrl}/send-mail-import-users-queue`, users).then();
  }

  private formatEmail(templateEmail: any, user: any) {
    let titleEmail = DEFAULT_EMAIL_REGISTER.title;
    let bodyEmail = DEFAULT_EMAIL_REGISTER.body;
    if (templateEmail) {
      const lang = DEFAULT_LANGUAGE_EMAIL.filter((item) => item.value === templateEmail.defaultLanguage)[0]
      titleEmail = templateEmail['title' + lang.key.charAt(0).toUpperCase() + lang.key.slice(1)];
      bodyEmail = templateEmail['body' + lang.key.charAt(0).toUpperCase() + lang.key.slice(1)];
      DATA_VARIABLES_EMAIL.forEach((item) => {
        const itemVariable = '={' + item + '}='
        titleEmail = titleEmail.replace(new RegExp(itemVariable, "g"), (user?.[item] ?? ''))
        bodyEmail = bodyEmail.replace(new RegExp(itemVariable, "g"), (user?.[item] ?? ''))
      })
    }
    return {
      to: user.email,
      message: {
        subject: titleEmail,
        html: bodyEmail,
      },
    }
  }

  async getTemplateEmail(key?: string) {
    const query = this.db.collection('emailTemplates', (ref) => {
      let refQuery: firebase.firestore.Query<DocumentData>;
      refQuery = ref;
      if (!!key) refQuery = refQuery.where('key', '==', key)
      return refQuery
    }).get();
    return await firstValueFrom(query)
  }

  async deleteChildAccount(dataUser: any) {
    await this.apiService.post(`${this.baseUrl}/delete-child-account`, dataUser).then(async () => {
      await this.db.collection('users').doc(dataUser.uid).delete();
    })
  }

  async createChildAccount(user: { [key: string]: any }) {
    const currentUser = firebase.auth().currentUser;
    const currentUserId = currentUser?.uid;
    const dataUser: IUser = {
      accountType: 'free',
      accountTypeName: 'フリーアカウント',
      accountTypePredict: null,
      active: user['active'],
      appUserId: null,
      createAt: serverTimestamp(),
      displayName: user['name'],
      email: user['email'].toLowerCase(),
      lastLoginAt: null,
      linked_ui: null,
      listSubscriptionAwaitBuy: null,
      listSubscriptionAwaitCancel: null,
      listSubscriptionBuying: null,
      memberCode: null,
      name: user['name'],
      organizationName: user['organizationName'],
      parentUserId: currentUserId ? currentUserId : '',
      payment_method: null,
      photoURL: null,
      purchaseDate: null,
      secretKey: null,
      tokens: null,
      uid: ulid(),
      updateAt: serverTimestamp(),
      disabled: !user['active'],
      password: generateRandomPassword(),
    }
    const { photoURL: photoURL, lastLoginAt: lastLoginAt, ...dataCreateApi} = dataUser;
    const { password: password, disabled: disabled, ...dataCreate } = dataUser;
    this.apiService.post(`${this.baseUrl}/create-child-account`, dataCreateApi).then(async () => {
      await this.db.collection('users').doc(dataUser.uid).set(dataCreate).then(async () => {
        await this.sendMailCreateChildUser(dataUser);
      });
    });
  }

  getUserByEmailApi(email: string) {
    return new Promise((resolve, reject) => {
      this.apiService.get(`${this.baseUrl}/get-user-by-email/${email}`).then((res) => {
        resolve(res);
      })
    })
  }

  getValueUserByUid(userId: string) {
    return this.db.collection('users').doc(userId).valueChanges();
  }

  getValueChildUserList(userId: string) {
    return this.db.collection('users', ref => ref.where('parentUserId', '==', userId)).valueChanges();
  }

  async updateAccountToken(user: any, token: string, tokenAction: number) {
    if (user) {
      let tokens: string[] | null = [];
      if (user.tokens) tokens = user.tokens;
      if (tokens) {
        if (tokenAction === TokenAction.add) tokens.push(token);
        else if (tokenAction === TokenAction.remove) {
          const tokenIndex = tokens.findIndex((userToken: string) => userToken === token);
          if (tokenIndex > -1) tokens.splice(tokenIndex, 1);
          if (!tokens.length) tokens = null;
        }
      }
      await this.db.collection('users').doc(user.uid).update({ tokens: tokens });
    }
  }

  async addCurrentUserToken() {
    const currentUser = this.getCurrentUserAuth();
    const token = await this.getCurrentUserAuth()?.getIdToken();
    if (token && token.length && currentUser) {
      const dataUser = await firstValueFrom(this.getValueUserByUid(currentUser.uid));
      await this.updateAccountToken(dataUser, token, TokenAction.add);
    }
  }

  async signOutAuthGuard(accountStatus: string) {
    const token = await this.getCurrentUserAuth()?.getIdToken();
    const currentUser = await firstValueFrom(this.getCurrentUser());
    if (currentUser) {
      const dataUser = await firstValueFrom(this.getValueUserByUid(currentUser.uid));
      if (token && token.length && currentUser) await this.updateAccountToken(dataUser, token, TokenAction.remove);
      this.SignOut().then(async () => await this.router.navigate(['login'], { state: { accountStatus: accountStatus } }));
    }
  }
}
