import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountStatus, MESSAGES_NOTIFICATION } from '@app/constants';
import { NotificationService, UserService } from '@app/services';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.userService.getCurrentUser().subscribe({
      next: async (currentUser: any) => {
        if (currentUser && currentUser.uid.length) {
          const notification = await firstValueFrom(this.notificationService.getNewestNotification(currentUser.email, currentUser.uid));
          const currentToken = await this.userService.getCurrentUserAuth()?.getIdToken();
          if (notification[0]
            && notification[0]['messageTitle']['en'] === MESSAGES_NOTIFICATION.account.changePassword.title.en
            && notification[0]['tokens'].includes(currentToken)
          ) {
            await this.userService.signOutAuthGuard(AccountStatus.isUpdatePassword);
          } else {
            const dataCurrentUser: any = await firstValueFrom(this.userService.getValueUserByUid(currentUser.uid));
            if (dataCurrentUser && !dataCurrentUser.active) {
              await this.userService.signOutAuthGuard(AccountStatus.inactiveByParent);
            } else if (!dataCurrentUser) {
              await this.userService.signOutAuthGuard(AccountStatus.isDeletedByParent);
            }
          }
        } else {
          this.router.navigate(['/login']).then();
        }
      }
    })
    return true;
  }
}
