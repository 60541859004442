import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ITEM_PER_PAGE, ACCOUNT_ACTIVE_MODE, ACCOUNT_EXPORT_HEADERS, LIST_COLUMN_ALLOW, ACCOUNT_EXPORT_FILE_NAME, ACTION_LIST, EVENT_LOG_TYPE, RuleValidations, MESSAGES_NOTIFICATION, ACCOUNT_DEFAULT_RESTRICT } from '@app/constants';
import { AlertService, FirebaseAnalyticService, GroupService, NotificationService, UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { checkAccountRestrictMode, checkImportExceedPlanCreateChildUser, chunkArray, getLongText, getSubstringTextNumber } from '@app/helpers';
import { Papa } from "ngx-papaparse";
import { IAccountRestrict } from '@app/models';

@Component({
  selector: 'app-list-account',
  templateUrl: './list-account.component.html',
  styleUrls: ['./list-account.component.scss']
})
export class ListAccountComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('nameSection') nameSection: any;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | null = null;
  @HostListener('window:orientationchange')
  onOrientationChange() {
    this.checkLongText();
  }
  getLongText = getLongText;
  protected Boolean = Boolean;
  protected readonly ACCOUNT_EXPORT_FILE_NAME = ACCOUNT_EXPORT_FILE_NAME;
  protected ACCOUNT_EXPORT_HEADERS = ACCOUNT_EXPORT_HEADERS;
  protected ACCOUNT_ACTIVE_MODE = ACCOUNT_ACTIVE_MODE;
  protected readonly ACTION_LIST = ACTION_LIST;
  itemPerPage = ITEM_PER_PAGE;
  pageNumber: number = 1;
  formSearch: FormGroup = new FormGroup<any>({
    querySearch: new FormControl('')
  });
  visibleSearchSection: boolean = false;
  currentFormatDate: string = "";
  currentLanguage = this.translateService.currentLang;
  queryParams: any;
  colorTypesSet: any;
  accounts: any;
  originalAccounts: any;
  isLoading: boolean = true;
  textNameDisplay: number = 12;
  textEmailDisplay: number = 12;
  isDisableExportButton: boolean = true;
  private observer: MutationObserver | any;
  userFileImport: any;
  showPreview: boolean = false;
  isSaveLoading: boolean = false;
  isValidateFile: boolean = false;
  visibleDeleteChildAccountModal: boolean = false;
  dataDeleteAccount: any;
  isDeleteAccount: boolean = false;
  accountRestrictConfig: IAccountRestrict = ACCOUNT_DEFAULT_RESTRICT;
  currentUser: any;
  subscription: { [key: string]: Subscription | null } = {
    childAccountList: null
  }

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    private papa: Papa,
    private alertService: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private notificationService: NotificationService,
    private groupService: GroupService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser().then();
    this.setQueryParamForm().then();
  }

  ngAfterViewInit(): void {
    const listData = document.body
    this.observer = new MutationObserver((mutationsList, observer) => {
      const nameSection = listData.querySelector('.name-section')
      if (nameSection) this.checkLongText();
    });
    const config = { attributes: true, childList: true, subtree: true };
    this.observer.observe(listData, config);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    this.subscription['childAccountList']?.unsubscribe();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.currentUser = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (this.currentUser) this.getChildAccountList();
    }
  }

  sortAccountName(accounts: any) {
    return accounts.sort((prevAccount: any, nextAccount: any) => {
      const prevAccountName = prevAccount.name;
      const nextAccountName = nextAccount.name;
      if (!prevAccountName) return 1;
      if (!nextAccountName) return -1;
      const collator = new Intl.Collator('ja');
      const comparisonResult = collator.compare(prevAccountName, nextAccountName);
      if (!comparisonResult) return prevAccountName.localeCompare(nextAccountName);
      return comparisonResult;
    });
  }

  getChildAccountList() {
    this.subscription['childAccountList'] = this.userService.getValueChildUserList(this.currentUser?.uid).subscribe({
      next: (response) => {
        this.accounts = response;
        this.accounts = this.sortAccountName(this.accounts);
        this.accountRestrictConfig = checkAccountRestrictMode(this.currentUser, { childAccount: this.accounts.length });
        if (this.accounts.length) this.isDisableExportButton = false;
        this.originalAccounts = response;
        this.isLoading = false;
        this.search();
      }
    })
  }

  async setQueryParamForm() {
    const querySearch = this.activatedRoute.snapshot.queryParamMap.get('query_search');
    if (querySearch) this.formSearch.controls['querySearch'].setValue(querySearch);
  }

  search() {
    this.accounts = this.originalAccounts;
    this.searchKeyword().then();
    if (this.formSearch.controls['querySearch'].value) this.pageNumber = 1;
    this.ref.detectChanges();
    this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.SEARCH, EVENT_LOG_TYPE.SUCCESS);
  }

  async searchKeyword() {
    const querySearchFormValue = this.formSearch.controls['querySearch'].value;
    if (querySearchFormValue?.length) {
      this.accounts = this.accounts.filter((data: any) =>
        (data.email && data.email.includes(querySearchFormValue)) || (data.name && data.name.includes(querySearchFormValue))
      );
    }
  }

  openSearchSection() {
    this.visibleSearchSection = !this.visibleSearchSection;
  }

  pageChange(page: number) {
    this.pageNumber = page;
  }

  checkLongText() {
    this.textNameDisplay = getSubstringTextNumber('.name-section', '.data-section', true);
    this.textEmailDisplay = getSubstringTextNumber('.email-section', '.data-section', true);
    this.ref.detectChanges();
  }

  updateAccountActive(account: any, event: any) {
    const accountActiveMode = event.target.checked;
    this.userService.updateAccountActiveMode(account.uid, accountActiveMode).then((res) => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_ACCOUNT_ACTIVE_STATUS, EVENT_LOG_TYPE.SUCCESS);
      this.userService.updateAuthenticateAccountStatus(account.uid, !accountActiveMode).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_AUTHENTICATE_STATUS, EVENT_LOG_TYPE.SUCCESS);
        if (!accountActiveMode) {
          this.notificationService.sendNoticeForOtherDevices(account, 'inactiveAccount', { isLogout: true, isChildAccount: true }).then(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_NOTIFICATION.INACTIVE_ACCOUNT, EVENT_LOG_TYPE.SUCCESS);
          });
        }
      }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_AUTHENTICATE_STATUS, EVENT_LOG_TYPE.ERROR));
    }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.UPDATE_ACCOUNT_ACTIVE_STATUS, EVENT_LOG_TYPE.ERROR));
  }

  triggerInputFile() {
    if (this.fileInput && this.fileInput.nativeElement) this.fileInput.nativeElement.value = null;
    this.fileInput?.nativeElement.click();
  }

  async uploadFileImport(event: any) {
    this.isValidateFile = true;
    const file = event.target.files[0];
    const fileContent = await file.text();
    const dataFile: any = this.papa.parse(fileContent, { skipEmptyLines: true, header: true, delimiter: ',' })
    if (dataFile.errors.length) {
      this.alertService.error(this.translateService.instant('alertMessages.accountManagement.import.fail'))
      this.isValidateFile = false;
      return;
    }
    const users = this.formatDataUser(dataFile);
    for (const user of users) {
      if (user.memberCode.length > RuleValidations.maxLength255) {
        this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.maxLength', { columnName: 'memberCode', maxlength: RuleValidations.maxLength255 }));
        this.isValidateFile = false;
        return;
      }
      if (user.name.length > RuleValidations.maxLength255) {
        this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.maxLength', { columnName: 'name', maxlength: RuleValidations.maxLength255 }));
        this.isValidateFile = false;
        return;
      }
      if (!(new RegExp(RuleValidations.regexEmail).test(user.email))) {
        this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.emailIncorrect'));
        this.isValidateFile = false;
        return;
      }
    }
    const checkDuplicateEmail = this.userService.checkDuplicateEmailImport(users);
    if (checkDuplicateEmail.status) {
      this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.duplicateEmail'));
      this.isValidateFile = false;
      return;
    }

    const checkInvalidActive = this.userService.checkInvalidActiveImport(users);
    if (checkInvalidActive.status) {
      this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.activeInvalid'));
      this.isValidateFile = false;
      return;
    }

    const checkValidationData = this.userService.checkValidateImportUsers(users);
    if (!checkValidationData.status) {
      const dataListColumnValidate = checkValidationData.listColumValidate.join(', ')
      this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.required', { listColumn: dataListColumnValidate }));
      this.isValidateFile = false;
      return;
    }

    const checkExistEmail = await this.userService.checkExistUsersImport(users);
    if (checkExistEmail.status) {
      const dataListEmailExist = checkExistEmail.listEmail.join(', ')
      this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.emailExist', { listEmail: dataListEmailExist }));
      this.isValidateFile = false;
      return;
    }
    const total = {
      totalFileRecords: users.length,
      totalUserList: this.accounts.length
    }
    const checkExceedCreateChildAccount = checkImportExceedPlanCreateChildUser(this.currentUser, total);
    if (!checkExceedCreateChildAccount) {
      this.alertService.error(this.translateService.instant('alertMessages.accountManagement.import.exceedPlanMaximumCreateUser'));
      this.isValidateFile = false;
      return;
    }

    const newUser = chunkArray(users, 100);
    this.userService.checkExistUserImportAuthenticate(newUser).then((response: any) => {
      if (response.users.length) {
        const dataListEmailExist = response.users.map((user: any) => user.email).join(', ')
        this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.emailExist', { listEmail: dataListEmailExist }));
        this.isValidateFile = false;
        return;
      } else {
        this.userFileImport = users;
        this.isValidateFile = false;
      }
    });
  }

  async previewDataFile() {
    this.showPreview = true
  }

  cancelFile() {
    this.userFileImport = null;
    this.showPreview = false;
  }
  async importUser() {
    this.isSaveLoading = true;
    await this.userService.batchCreateUsers(this.userFileImport).then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.IMPORT_LIST_CHILD_ACCOUNT, EVENT_LOG_TYPE.SUCCESS);
      this.alertService.success(this.translateService.instant('alertMessages.accountManagement.import.success'))
    }).catch((e) => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.IMPORT_LIST_CHILD_ACCOUNT, EVENT_LOG_TYPE.ERROR);
      this.alertService.error(this.translateService.instant('alertMessages.accountManagement.import.fail'))
    }).finally(() => {
      this.isSaveLoading = false;
      this.cancelFile();
    });
  }


  formatDataUser(dataFile: any) {
    return dataFile.data.map((row: any) => {
      const filteredRow: any = {};
      LIST_COLUMN_ALLOW.forEach((field: string) => {
        if (row.hasOwnProperty(field)) {
          filteredRow[field] = row[field];
        }
      });
      return filteredRow;
    });
  }

  toggleDeleteChildAccountModal(account: any = {}) {
    this.dataDeleteAccount = account;
    this.visibleDeleteChildAccountModal = !this.visibleDeleteChildAccountModal;
  }

  async deleteChildAccount() {
    this.isDeleteAccount = true;
    this.visibleDeleteChildAccountModal = false;
    const deleteChildAccountGroup: { group: Array<any>, groupUser: Array<string> } = { group: [], groupUser: [] };
    const dataGroupUser = await firstValueFrom(this.groupService.getGroupUsersChildAccountByEmail(this.dataDeleteAccount.email));
    const dataGroup = await firstValueFrom(this.groupService.getGroupChildAccountByEmail(this.currentUser.uid, this.dataDeleteAccount.email));
    dataGroupUser.forEach((item: any) => {
      const docIdPath = item.payload.doc.ref?.path;
      deleteChildAccountGroup.groupUser.push(docIdPath)
    })
    dataGroup.forEach((item: any) => {
      const data = item.payload.doc.data();
      const docIdPath = item.payload.doc.ref?.path;
      deleteChildAccountGroup.group.push({ path: docIdPath, emails: data.emails })
    })

    this.userService.deleteChildAccount(this.dataDeleteAccount).then(() => {
      this.notificationService.sendNoticeForOtherDevices(this.dataDeleteAccount, 'deleteAccount', { isLogout: true, isChildAccount: true }).then();
      this.groupService.deleteChildAccountInGroup(deleteChildAccountGroup, this.dataDeleteAccount.email);
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.DELETE_CHILD_ACCOUNT, EVENT_LOG_TYPE.SUCCESS);
      this.alertService.success(this.translateService.instant('alertMessages.accountManagement.delete.success', { email: this.dataDeleteAccount.email }));
      this.isDeleteAccount = false;
    }).catch(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.DELETE_CHILD_ACCOUNT, EVENT_LOG_TYPE.ERROR);
      this.alertService.success(this.translateService.instant('alertMessages.accountManagement.delete.success'));
    });
  }
}
