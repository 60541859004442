<button *ngIf="!user.owner && user.status === MemberJoinStatus.accepted" [cModalToggle]="removeUser.id" class="btn-second">
  {{ 'groups.remove' | translate }}
</button>

<c-modal #removeUser alignment="center" [id]="'removeUser' + user.id + group.path">
  <c-modal-header>
    <h5 cModalTitle>{{ 'groups.deleteUser.title' | translate }}</h5>
    <button [cModalToggle]="removeUser.id" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    {{ 'groups.deleteUser.text' | translate: {email: user.email} }}
  </c-modal-body>
  <c-modal-footer>
    <button [cModalToggle]="removeUser.id" class="btn-second btn-modal">
      {{ 'groups.deleteUser.no' | translate }}
    </button>
    <button class="btn-common btn-modal" (click)="delete(true)">
      {{ 'groups.deleteUser.yes' | translate }}
    </button>
  </c-modal-footer>
</c-modal>

<button *ngIf="!user.owner && user.status === MemberJoinStatus.pending" (click)="delete()" class="btn-second">
  {{ 'groups.removeInvitation' | translate }}
</button>
