import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_USE_TEST, INIT_EDITOR, MAX_PERCENT_PASSING_SCORE, MIN_PERCENT_PASSING_SCORE } from '@app/constants';
import { checkInputEnter, getFirstError } from '@app/helpers';
import { AlertService, FormService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-test-edit',
  templateUrl: './test-edit.component.html',
  styleUrls: ['./test-edit.component.scss'],
})
export class TestEditComponent implements OnChanges {
  checkInputEnter = checkInputEnter;
  protected Number = Number;
  @Input('formEdit') formEdit: any
  @Input('submitAllTab') submitAllTab: any
  @Input('examList') examList: any
  @Input('form') form: any
  @Input('isOutDate') isOutDate: boolean = false;
  @Output() submittedData = new EventEmitter<Boolean>();
  @Output() isBackToFormList = new EventEmitter<Boolean>();
  @Output() dataTestForm = new EventEmitter<any>();
  protected readonly getFirstError = getFirstError;
  protected readonly String = String;
  submitted: boolean = false;
  testList: any = [];
  initEditor = INIT_EDITOR;
  currentLang: string = ''

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: TranslateService,
    private formService: FormService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentLang = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = e.lang
    })
    this.generateForm();
    if (this.examList) {
      this.examList = this.examList.sort((a: any, b: any) => a.index - b.index);
    }
  }

  get useTestControl() {
    return this.formEdit.controls['useTest'];
  }

  changeScores(event: any) {
    if (isNaN(event.target.value)) {
      const dataArray: Array<string | null> = event.target.value.split('');
      event.target.value = dataArray.filter((item: any) => !isNaN(item)).join('')
    }
    const dataPassingScores = Number(event.target.value);
    this.setErrorScores(dataPassingScores)
  }

  moveUp(item: any) {
    if (this.examList) {
      const currentIndex = this.examList.indexOf(item);
      if (currentIndex > 0) {
        moveItemInArray(this.examList, currentIndex, currentIndex - 1);
        this.dataTestForm.emit(this.examList)
      }
    }
  }

  moveDown(item: any) {
    if (this.examList) {
      const currentIndex = this.examList.indexOf(item);
      if (currentIndex < this.examList.length) {
        moveItemInArray(this.examList, currentIndex, currentIndex + 1);
        this.dataTestForm.emit(this.examList)
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.examList, event.previousIndex, event.currentIndex);
    this.examList = this.examList.map((item: any, index: any) => {
      item.index = index + 1;
      return item
    });
    this.dataTestForm.emit(this.examList)
  }

  setErrorScores(scores: number) {
    this.formEdit.controls.passingScore.setErrors(null)
    if (!this.formEdit.controls.passingScore.value) {
      this.formEdit.controls.passingScore.setErrors({required: true})
    }
    if (scores <= MIN_PERCENT_PASSING_SCORE) {
      this.formEdit.controls.passingScore.setErrors({min: true})
    }
    if (scores > MAX_PERCENT_PASSING_SCORE) {
      this.formEdit.controls.passingScore.setErrors({max: true})
    }
  }

  async generateForm() {
    this.testList = this.examList?.map((item: any) => {
      return {
        index: Number(item.index),
        name: item.name
      };
    });
    this.testList?.unshift({
      index: DEFAULT_USE_TEST,
      name: null
    })
    this.setErrorScores(this.formEdit.controls.passingScore.value)
  }

  backToFormList() {
    this.isBackToFormList.emit(true);
  }

  async submit() {
    this.submitted = true;
    if (this.formEdit.invalid) {
      this.alertService.error(this.translateService.instant('errorMessages.formValidate.allTabValidate'))
    }
    if (this.formEdit.valid) {
      this.submittedData.emit(true)
    }
  }
}
