import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { checkInputEnter } from "@app/helpers";
import { AddressSearchService } from "@app/services";
import { ADDRESS_ZIPCODE_SHOW_LIMIT, RuleValidations } from "@app/constants";

@Component({
  selector: 'app-zipcode-input',
  templateUrl: './zipcode.component.html',
  styleUrls: ['./zipcode.component.scss']
})
export class ZipcodeComponent implements OnInit {
  @Input() readonly: boolean = false
  @Input() dataZipcode: string = ''
  @Input() placeholder: string | null = ''
  @Input() required: boolean = false;
  @Output() selectDataZipcode = new EventEmitter<{zipcode: string | null, address: string | null}>;
  @Output() validZipcode = new EventEmitter<any>;
  protected readonly checkInputEnter = checkInputEnter;
  protected readonly ADDRESS_ZIPCODE_SHOW_LIMIT = ADDRESS_ZIPCODE_SHOW_LIMIT;
  currentStatusValid: any;
  showList: boolean = false;
  dataAddress: string[] | null = [];
  isShowList: boolean = true;
  constructor(
    private addressSearchService: AddressSearchService
  ) { }

  ngOnInit(): void {
  }

  async searchAddressByZipcode(event: any) {
    this.dataZipcode = event.target.value;
    this.selectDataZipcode.emit({
      zipcode: this.dataZipcode,
      address: null
    });
    const validator: any = {};
    if (this.required) {
      if (!this.dataZipcode.length) validator['required'] = true;
      if (!new RegExp(RuleValidations['zip']).test(this.dataZipcode)) validator['incorrectZip'] = true;
      if (!this.currentStatusValid) validator['notExist'] = true;
    }
    this.validZipcode.emit(validator);
    const data = await this.addressSearchService.getAddressByZipcode(this.dataZipcode);
    if (!!data?.[0]) delete validator.notExist;
    this.validZipcode.emit(validator);
    this.currentStatusValid = !!data?.[0];    
    if (data?.[0]) {
      this.dataAddress = data.map((address: any) => {
        return Object.values(Object.keys(address)
        .filter((key) => key.includes('address'))
        .reduce((cur, key) => { return Object.assign(cur, { [key]: address[key] }) }, {}))
        .filter((item) => item)
        .join(' ');
      });
    }
    this.showList = !!data?.[0];
    if (!this.dataZipcode.length) this.dataAddress = [];
    this.isShowList = true;
    // TODO: auto fill if zipcode address has one record
    const isDataAddressHasOneRecord = data && data.length === 1;
    if (isDataAddressHasOneRecord) {
      this.isShowList = false;
      if (this.dataAddress) {
        this.selectDataZipcode.emit({
          zipcode: this.dataZipcode,
          address: this.dataAddress[0]
        })
      }
    }
  }


  choseAddress(address: string) {
    this.showList = false
    this.selectDataZipcode.emit({
      zipcode: this.dataZipcode,
      address: address
    })
  }
}
