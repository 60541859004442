import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";
import { RouterService, UserService } from '@app/services';
import { filter } from 'rxjs';
import { supportLanguage } from './constants';
import { environment } from "@environments/environment";

@Component({
  selector: 'body',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  postId: any;
  title = environment.html_title;
  message: any;
  supportLanguage = supportLanguage;
  language: string = '';
  authentication: any = null;
  isFormGuest: boolean = false;
  previousUrl: string = '';
  currentUrl: string = '';

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    titleService: Title,
    private routerService: RouterService
  ) {
    titleService.setTitle(this.title);
    this.translateService.addLangs(this.supportLanguage);
    if (localStorage.getItem('lang')) {
      this.language = <string>localStorage.getItem('lang');
      this.translateService.use(<string>localStorage.getItem('lang'));
    } else {
      this.language = 'ja'
      this.translateService.use('ja');
      localStorage.setItem('lang', 'ja')
    }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
    this.checkAuth();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.isFormGuest = Boolean(["form-guest", "reissue"].includes(event.url));
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.routerService.setPreviousUrl(this.previousUrl);
      });
  }

  onChange(event: any) {
    localStorage.setItem('lang', (event.target as HTMLInputElement).value)
    this.language = (event.target as HTMLInputElement).value;
    location.reload();
  }

  async checkAuth() {
    const user: any = await this.userService.userValue();
    this.authentication = Boolean(user?.email)
  }
}
