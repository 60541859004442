import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ACTION_LIST, DEFAULT_COLOR_TEMPLATE, EVENT_LOG_TYPE, INIT_EDITOR, RuleValidations } from '@app/constants';
import { checkInputEnter, formatSystemTemplateList, getFirstError } from '@app/helpers';
import { AlertService, FirebaseAnalyticService, TemplateService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { Timestamp } from 'firebase/firestore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss']
})
export class CreateTemplateComponent implements OnInit, OnDestroy {
  checkInputEnter = checkInputEnter;
  getFirstError = getFirstError;
  ruleValidations = RuleValidations;
  initEditor = INIT_EDITOR;
  formCreate: FormGroup = new FormGroup({
    copySystemTemplateId: new FormControl(null, {validators: [Validators.required]}),
    name: new FormControl(null, {validators: [Validators.required, Validators.maxLength(RuleValidations.maxLength255)]}),
    remark: new FormControl(null, {validators: [Validators.maxLength(RuleValidations.maxLength4096)]})
  });
  systemTemplates: any;
  submitted: boolean = false;
  selectedSystemTemplate: any;
  queryParams: any = {
    query_search: '',
    color: ''
  }
  subscription: { [key: string]: Subscription | null } = {
    systemTemplateList: null
  }

  constructor(
    private templateService: TemplateService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private router: Router,
    private firebaseAnalyticService: FirebaseAnalyticService
  ) {
    this.queryParams = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit(): void {
    this.getSystemTemplate();
  }

  ngOnDestroy(): void {
    this.subscription['systemTemplateList']?.unsubscribe();
  }

  getSystemTemplate() {
    this.subscription['systemTemplateList'] = this.templateService.getSnapshotSystemTemplateList().subscribe({
      next: (data) => {
        this.systemTemplates = formatSystemTemplateList(data);
      }
    })
  }

  selectSystemTemplate(event: any) {
    const systemTemplateId: string = event.target.value;
    this.selectedSystemTemplate = this.systemTemplates.filter((systemTemplate: any) => systemTemplate.docId === systemTemplateId)[0];
    this.formCreate.controls['name'].setValue(this.selectedSystemTemplate.nodes.meta.name ?? '');
    this.formCreate.controls['remark'].setValue(this.selectedSystemTemplate.remark ?? '');
  }

  submit() {
    this.submitted = true;
    if (this.formCreate.valid) {
      const systemTemplate = this.systemTemplates.filter((systemTemplate: any) => systemTemplate.docId === this.formCreate.controls['copySystemTemplateId'].value)[0];
      const nodes = systemTemplate.nodes;
      nodes.meta.name = this.formCreate.controls['name'].value;
      nodes.meta.color = DEFAULT_COLOR_TEMPLATE;
      const dataCreate = {
        title: this.formCreate.controls['name'].value,
        nodes: JSON.stringify(nodes),
        colorCode: systemTemplate.colorCode,
        displayIndex: systemTemplate.displayIndex,
        maxRecords: systemTemplate.maxRecords,
        templateType: systemTemplate.templateType,
        remark: this.formCreate.controls['remark'].value,
        createdAt: Timestamp.now()
      }
      this.templateService.create(dataCreate).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.CREATE, EVENT_LOG_TYPE.SUCCESS);
        this.router.navigate(['/list-template']).then();
        this.alertService.success(this.translateService.instant('alertMessages.template.create.success'));
      }).catch(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.CREATE, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.template.create.fail'));
      });
    }
  }

  backToListTemplate() {
    this.router.navigate(['list-template'], { queryParams: this.queryParams }).then();
  }
}
