import { Component, OnDestroy, OnInit } from '@angular/core';
import { INIT_EDITOR, MAX_LENGTH_TEXT_DISPLAY, VARIABLES_PORTAL } from '@app/constants';
import { getLongText } from '@app/helpers';
import { UserService } from '@app/services';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit, OnDestroy {
  getLongText = getLongText;
  protected readonly MAX_LENGTH_TEXT_DISPLAY = MAX_LENGTH_TEXT_DISPLAY;
  initEditor = INIT_EDITOR;
  user: any;
  parentUser: any;
  currentPortal: string = '';
  parentPortal: string = '';
  subscription: { [key: string] : Subscription | null } = {
    currentUser: null,
    parentUser: null
  }

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.getCurrentUser().then();
  }

  ngOnDestroy(): void {
    this.subscription['currentUser']?.unsubscribe();
    this.subscription['parentUser']?.unsubscribe();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.subscription['currentUser'] = this.userService.getValueUserByUid(authUser.uid).subscribe({
        next: (data) => {
          this.user = data;
          this.currentPortal = this.generatePortal(this.user, this.user.portalHTML ?? '')
          this.getParentUser();
        }
      })
    }
  }

  getParentUser() {
    if (this.user.parentUserId && this.user.parentUserId != '') {
      this.subscription['parentUser'] = this.userService.getUserInById(this.user.parentUserId).subscribe((item) => {
        this.parentUser = item.payload.data();
        this.parentPortal = this.generatePortal(this.parentUser, this.parentUser?.portalHTML ?? '')
      });
    }
  }

  generatePortal(user: any, dataPortal: string) {
    VARIABLES_PORTAL.forEach((item) => {
      dataPortal = dataPortal.split('={' + item + '}=').join(user[item] ?? '');
    })
    return dataPortal;
  }
}
