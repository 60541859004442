import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INIT_EDITOR } from "@app/constants";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-input-label-html',
  templateUrl: './input-label-html.component.html',
  styleUrls: ['./input-label-html.component.scss']
})
export class InputLabelHtmlComponent implements OnInit {
  editor: any;
  @Input() disabled: boolean = false;
  @Input() value: string | null = null;
  @Input() placeholder: any | null | {en: string, ja: string} = {en: '', ja: ''};
  @Output() dataLabelHtml = new EventEmitter<string | null>();
  currentLang: string = '';
  initEditor = { ...INIT_EDITOR };
  protected readonly INIT_EDITOR = INIT_EDITOR;

  constructor(
    private translateService: TranslateService
  ) {
  }
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = e.lang;
      this.editor.editor.setContent(this.placeholder[this.currentLang]);
      this.placeholder[this.currentLang] = this.editor.editor.getContent()
      if (this.value && this.editor) this.editor.editor.setContent(this.value);
    })
  }

  initializeEditor(event: any) {
    this.editor = event
    this.editor.editor.setContent(this.placeholder[this.currentLang]);
    this.placeholder[this.currentLang] = this.editor.editor.getContent();
    if (this.value) this.editor.editor.setContent(this.value);
  }

  changeDataLabelHtml(event: any) {
    this.value = event.editor.getContent();
    this.dataLabelHtml.emit(this.value !== this.placeholder[this.currentLang] ? this.value : null)
  }

  blurEditor() {
    const dataContent = this.editor.editor.getContent();
    if (!dataContent.length) this.editor.editor.setContent(this.placeholder[this.currentLang])
  }

  focusEditor() {
    const dataContent = this.editor.editor.getContent();
    if (dataContent === this.placeholder.en || dataContent === this.placeholder.ja) this.editor.editor.setContent('')
  }
}
