<div class="mb-3 template">
  <c-col class="mb-1">{{ "template.basicSetting.displayListTab.inputLabel.listDisplayItem" | translate }}
    <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
  </c-col>
  <ng-multiselect-dropdown
    id="dataComponent"
    #dataComponent
    [settings]="dropdownSettings"
    [data]="nodeObjects"
    placeholder='{{ "template.basicSetting.displayListTab.inputLabel.pleasSelect" | translate }}'
    [(ngModel)]="selectedNodes"
    (onSelect)="onNodeSelect($event)"
    (onDeSelect)="onNodeDeselect($event)"
  ></ng-multiselect-dropdown>
  <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('data')?.errors && submitted">
    {{ "errorMessages.template.listDisplayItem." + getFirstError(formTemplateSetting.get('data')?.errors) | translate }}
  </c-form-check>
  <div class="mt-3" *ngIf="selectedNodes.length">
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let node of selectedNodes; index as index" cdkDrag>
        <span>{{ node[currentLangKey] }}</span>
        <div class="d-flex button-block">
          <div (click)="moveUp(node)">
            <img [class]="{'d-none': !index}" src="./assets/svg/common/down-circle.svg" alt="">
          </div>
          <div (click)="moveDown(node)">
            <img [class]="{'d-none': index === (selectedNodes.length - 1)}" src="./assets/svg/common/down-circle.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mb-3 template">
  <c-col class="mb-1">{{ "template.basicSetting.displayListTab.inputLabel.listSearchItem" | translate }}
    <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
  </c-col>
  <ng-multiselect-dropdown
    #searchComponent
    [settings]="dropdownSettings"
    [data]="nodeObjects"
    placeholder='{{ "template.basicSetting.displayListTab.inputLabel.pleasSelect" | translate }}'
    [(ngModel)]="listNodeSearchSelected"
    (onSelect)="onNodeSearchSelect($event)"
    (onDeSelect)="onNodeSearchDeselect($event)"
  ></ng-multiselect-dropdown>
  <c-form-check class="text-danger" *ngIf="formTemplateSetting.get('search')?.errors && submitted">
    {{ "errorMessages.template.listSearchItem." + getFirstError(formTemplateSetting.get('search')?.errors) | translate }}
  </c-form-check>
</div>
