import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AlertService, FirebaseAnalyticService, GroupService, NotificationService } from "@app/services";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {TranslateService} from "@ngx-translate/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { ACTION_LIST, EVENT_LOG_TYPE, MemberJoinStatus, ROLE_STATUS } from "@app/constants";
import {getFirstError} from "@app/helpers";

@Component({
  selector: 'app-invite-group',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit, OnChanges {
  @ViewChild('readonlyRadioRef') readonlyRadioRef: any;
  @ViewChild('readAndAddRadioRef') readAndAddRadioRef: any;
  @ViewChild('fullRadioRef') fullRadioRef: any;
  @Input() visible: boolean = false
  @Input() groupName: string = ''
  @Input() pathGroup: string = ''
  @Input() users: any = {}
  @Input() groupUsers: any = {}
  @Output() closeInviteModal = new EventEmitter<boolean>();
  protected readonly getFirstError = getFirstError;
  @ViewChild('inviteGroup') inviteGroup: any;
  protected Object = Object
  roleStatusList = ROLE_STATUS
  usersRaw: any;
  textSearchDropDown: { selectAllText: string, unSelectAllText: string, searchPlaceholderText: string } = {
    selectAllText: '',
    unSelectAllText: '',
    searchPlaceholderText: ''
  };
  submitted: boolean = false
  dropdownSettings: IDropdownSettings = {
    ...this.textSearchDropDown,
    singleSelection: false,
    idField: 'email',
    textField: 'email',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: '',
    enableCheckAll: false,
    noDataAvailablePlaceholderText: ''
  };
  formInviteUser = new FormGroup({
    users: new FormControl(null, [Validators.required]),
    permission: new FormControl(ROLE_STATUS.full, [])
  })
  constructor(
    private translateService: TranslateService,
    private groupService: GroupService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.formatDataUsers().then();
    this.dataSettingDropdown()
    this.translateService.onLangChange.subscribe(() => {
      this.dataSettingDropdown()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groupUsers']) {
      this.usersRaw = this.users
      this.formatDataUsers().then()
    }
  }

  visibleChange(e: any) {
    this.renderer.addClass(document.body, 'modal-is-open');
    this.renderer.addClass(document.querySelector('.invite-modal'), 'modal-is-open');
    if (!e) {
      this.renderer.removeClass(document.querySelector('.invite-modal'), 'modal-is-open');
      this.renderer.removeClass(document.body, 'modal-is-open');
      this.formInviteUser.reset()
      this.submitted = false
      this.closeInviteModal.emit(e)
    }
  }

  async formatDataUsers() {
    this.users = this.usersRaw?.filter((item: any) => {
      if (!item.active) return false;
      return !this.groupUsers.some((user: any) => user.email === item.email) && item.email;
    });
    this.users = this.users.sort((prevUser: any, nextUser: any) => prevUser.email.localeCompare(nextUser.email, undefined, { sensitivity: 'base' }))
  }

  dataSettingDropdown() {
    this.dropdownSettings = {
      ...this.dropdownSettings,
      selectAllText: this.translateService.instant('groups.invite.select.all'),
      unSelectAllText: this.translateService.instant('groups.invite.select.all'),
      searchPlaceholderText: this.translateService.instant('groups.invite.select.placeholderSearch')
    }
  }

  choosePermission(permission: string) {
    switch (permission) {
      case ROLE_STATUS.readonly: {
        this.renderer.setProperty(this.readonlyRadioRef.nativeElement, 'checked', true);
        this.formInviteUser.controls.permission.setValue(ROLE_STATUS.readonly);
        break;
      }
      case ROLE_STATUS.readAndAdd: {
        this.renderer.setProperty(this.readAndAddRadioRef.nativeElement, 'checked', true);
        this.formInviteUser.controls.permission.setValue(ROLE_STATUS.readAndAdd);
        break;
      }
      default: {
        this.renderer.setProperty(this.fullRadioRef.nativeElement, 'checked', true);
        this.formInviteUser.controls.permission.setValue(ROLE_STATUS.full);
      }
    }
  }

  submit() {
    this.submitted = true
    if (this.formInviteUser.value.users && this.formInviteUser.valid) {
      const dataInvite: {
        email: string,
        id: string,
        name: string,
        status: number,
        owner: boolean,
        tokens: any
      }[] = this.formInviteUser.value.users
      dataInvite.map((item) => {
        const dataUser = this.users.filter((user: any) => user.email == item.email)[0]
        item.id = dataUser.uid
        item.name = dataUser.name ?? null
        item.owner = false
        item.status = MemberJoinStatus.pending
        item.tokens = dataUser?.tokens
        return item
      });
      this.groupService.inviteUserToGroup(this.pathGroup, dataInvite, this.formInviteUser.controls.permission.value!).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.INVITE_USER, EVENT_LOG_TYPE.SUCCESS)
        this.inviteGroup.visible = false;
        this.notificationService.sendNoticeGroup(dataInvite, 'invite', { groupName: this.groupName });
        this.alertService.success(this.translateService.instant('alertMessages.group.invite.success'));
      }).catch((error) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.GROUP.INVITE_USER, EVENT_LOG_TYPE.ERROR)
        this.inviteGroup.visible = false
        this.alertService.error(this.translateService.instant('alertMessages.group.invite.success'));
      })
    }
  }
}
