import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ITab } from "@app/models/tab";

@Injectable({
  providedIn: 'root'
})
export class TabService {
  constructor(
    private firestore: AngularFirestore,
  ) { }

  index(userId: string, withoutDisabled: boolean = false) {
    return this.firestore.collection('users').doc(userId).collection('tabs', ref => {
      let query = ref.orderBy('index', 'desc');
      if (withoutDisabled) {
        query = query.where('isDisable', '==', false);
      }
      return query;
    }).valueChanges();
  }

  create(userId: string, data: ITab) {
    return this.firestore.collection('users').doc(userId).collection('tabs').doc(data.docId).set(data)
  }

  async update(userId: string, tabs: Array<ITab>) {
    const batch = this.firestore.firestore.batch();
    tabs.forEach((item) => {
      const tabRef = this.firestore.collection('users').doc(userId).collection('tabs').doc(item.docId).ref
      batch.update(tabRef, item)
    })
    await batch.commit();
  }

  async delete(userId: string, tabId: string) {
    await this.firestore.collection('users').doc(userId).collection('tabs').doc(tabId).delete();
  }
}
