<div *ngFor="let group of groups | paginate: { id: 'owner', itemsPerPage: itemPerPage, currentPage: page }; index as index"  class="mb-3">
  <c-card *ngIf="group && group.path != groupDeletePath">
    <c-card-body>
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <span class="group-name" (click)="openEditModal(group)">{{group.name}}</span>
          </div>
          <div *ngIf="groups.length &&checkOwnerGroup(group) && group.path != groupDeletePath" class="btn-list">
            <button class="btn-common btn-invite" (click)="openInviteModal(group)">{{ 'groups.invite.inviteUser' | translate }}</button>
            <button [cModalToggle]="deleteGroup.id" class="btn-second">{{ 'groups.delete.button' | translate }}</button>
            <c-modal #deleteGroup alignment="center" [id]="'deleteGroup' + group.path" class="custom-modal">
              <c-modal-header>
                <h5 cModalTitle>{{ group.name }}</h5>
                <button [cModalToggle]="deleteGroup.id" cButtonClose></button>
              </c-modal-header>
              <c-modal-body>
                {{ 'groups.delete.text' | translate }}
              </c-modal-body>
              <c-modal-footer>
                <button [cModalToggle]="deleteGroup.id" class="btn-close-modal">
                  {{ 'groups.delete.no' | translate }}
                </button>
                <button (click)="delete(group)" class="btn-confirm-modal">
                  {{ 'groups.delete.yes' | translate }}
                </button>
              </c-modal-footer>
            </c-modal>
          </div>
        </div>
        <div class="data-group-user">
          <div class="user-owner-info">
            <div *ngFor="let user of group.groupUsers; index as userIndex">
              <div *ngIf="userIndex < itemPerPage" class="user-item mt-3">
                <div class="info-text name-owner-section">
                  <span class="role-section" *ngIf="(user.name?.length < 9) || !user.name" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.name">
                    <img *ngIf="user.permission" class="role-section__image" src="assets/img/groups/{{user.permission}}.png" />
                    {{ getLongText(user.name, textNameDisplay) }}
                  </span>
                  <span *ngIf="(user.name?.length >= 9) && !!user.name" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.name">
                    <img *ngIf="user.permission" class="role-section__image" src="assets/img/groups/{{user.permission}}.png" />
                    {{ getLongText(user.name, textNameDisplay) }}
                  </span>
                </div>
                <div class="info-text email-owner-section">
                  <span class="info-pc"  [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.email">{{ getLongText(user.email, textEmailDisplay) }}</span>
                  <span class="info-mb" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.email">{{ getLongText(user.email, textEmailDisplay) }}</span>
                </div>
                <div class="info-subtext info-text">{{getSubTextGroup(user)}}</div>
                <div class="button-action">
                  <app-owner-group *ngIf="groups.length &&checkOwnerGroup(group) && group.path != groupDeletePath" [users]="users" [user]="user" [group]="group"></app-owner-group>
                </div>
              </div>
              <div  [visible]="visibleItems[index]" cCollapse>
                <div *ngIf="userIndex >= itemPerPage" class="user-item mt-3">
                  <div>
                    <span *ngIf="(user.name?.length < 9) || !user.name" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.name">
                        <img *ngIf="user.permission" class="role-section__image" src="assets/img/groups/{{user.permission}}.png" />
                        {{ getLongText(user.name, textNameDisplay) }}
                    </span>
                    <span *ngIf="(user.name?.length >= 9) && !!user.name" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.name">
                        <img *ngIf="user.permission" class="role-section__image" src="assets/img/groups/{{user.permission}}.png" />
                        {{ getLongText(user.name, textNameDisplay) }}
                    </span>
                  </div>
                  <div class="info-text">
                    <span class="info-pc"  [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.email">{{ getLongText(user.email, textEmailDisplay) }}</span>
                    <span class="info-mb" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.email">{{ getLongText(user.email, textEmailDisplay) }}</span>
                  </div>
                  <div  class="info-text info-subtext">{{getSubTextGroup(user)}}</div>
                  <div>
                    <app-owner-group *ngIf="groups.length && checkOwnerGroup(group) && group.path != groupDeletePath" [users]="users" [user]="user" [group]="group"></app-owner-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span *ngIf="group.groupUsers.length > itemPerPage" (click)="toggleCollapse(index)" class="button-accordion">
            {{ ('groups.toggleUserList.' + !!visibleItems[index]) | translate }}
            <img class="caret-down" [class]="{'caret-down-open': visibleItems[index]}" height="20" width="20" src="assets/svg/common/caret-down-orange-600.svg" alt="">
          </span>
        </div>
      </div>
    </c-card-body>
  </c-card>
</div>
<c-col class="justify-content-center d-flex">
  <pagination-controls id="owner" (pageChange)="changePage($event)" previousLabel="" nextLabel="" [autoHide]="true"></pagination-controls>
</c-col>
<app-invite-group
  *ngIf="selectedGroup"
  [visible]="visibleInvite"
  [pathGroup]="selectedGroup.path"
  [users]="users"
  [groupName]="selectedGroup.name"
  [groupUsers]="selectedGroup.groupUsers"
  (closeInviteModal)="receiveCloseInviteModal($event)"
></app-invite-group>
<app-edit-group-name *ngIf="editGroup && editGroup.path != groupDeletePath" [visible]="!!editGroup" (closeEditModal)="receiveCloseEditModal($event)" [path]="editGroup.path"></app-edit-group-name>

