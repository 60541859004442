import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from '@app/constants';
import { checkInputEnter, getFirstError } from '@app/helpers';
import { AlertService, FirebaseAnalyticService, UserService } from '@app/services';
import { CustomValidatorsService } from '@app/services/custom-validators.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  protected readonly getFirstError = getFirstError;
  protected readonly checkInputEnter = checkInputEnter;
  ruleValidations = RuleValidations;
  submitted: boolean = false;
  currentUser: any;
  callCurrentUser: Subscription | undefined;
  queryParams: any;
  isLoading: boolean = false;
  formCreate = new FormGroup({
    name: new FormControl(null, { validators: [Validators.required, Validators.maxLength(this.ruleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace] }),
    email: new FormControl(null, { validators: [Validators.required, Validators.maxLength(this.ruleValidations.maxLength255), Validators.email] }),
    organizationName: new FormControl(null, { validators: [Validators.required, Validators.maxLength(this.ruleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace] }),
    memberCode: new FormControl(null, { validators: [Validators.maxLength(this.ruleValidations.maxLength255)] }),
    active: new FormControl(true, { validators: [Validators.required] }),
  });
  constructor(
    private userService: UserService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.queryParams = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.callCurrentUser?.unsubscribe();
  }

  backToIndexForm() {
    this.router.navigate(['list-account'], { queryParams: { query_search: this.queryParams?.querySearch ?? '' } }).then();
  }

  async submit() {
    this.submitted = true;
    this.isLoading = true;
    if (this.formCreate.valid) {
      const emailValue = this.formCreate.controls.email.value;
      if (typeof emailValue == 'string') {
        this.userService.getUserByEmailApi(emailValue).then((response: any) => {
          const dataUser = response['user'];
          if (dataUser) {
            this.alertService.error(this.translateService.instant('errorMessages.accountManagement.import.emailExist', { listEmail: [dataUser.email] }));
            return;
          }
          this.userService.createChildAccount(this.formCreate.value).then(() => {
            this.isLoading = false;
            this.router.navigate(['list-account']).then(() => {
              this.alertService.success(this.translateService.instant('alertMessages.accountManagement.create.success'));
            });
            this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.CREATE_CHILD_ACCOUNT, EVENT_LOG_TYPE.SUCCESS);
          }).catch(() => {
            this.isLoading = false;
            this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.CREATE_CHILD_ACCOUNT, EVENT_LOG_TYPE.ERROR);
            this.alertService.error(this.translateService.instant('alertMessages.accountManagement.create.fail'));
          });
        })
      }
    }
  }
}
