import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges, OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { drop, getFirstError, moveDown, moveUp, titleCaseAllWord } from '@app/helpers';
import { TranslateService } from "@ngx-translate/core";
import { MultiSelectComponent } from "@coreui/angular-pro";

@Component({
  selector: 'app-display-list-tab',
  templateUrl: './display-list-tab.component.html',
  styleUrls: ['./display-list-tab.component.scss']
})
export class DisplayListTabComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() formTemplateSetting: FormGroup = new FormGroup<any>({});
  @Input() templateNode: any;
  @Input() submitted: boolean = false;
  currentLangKey: string = ''
  protected readonly getFirstError = getFirstError;
  @ViewChild('dataComponent') dataComponent: MultiSelectComponent | any;
  @ViewChild('searchComponent') searchComponent: MultiSelectComponent | any;
  private observer: MutationObserver | any;
  dropdownSettings: IDropdownSettings = {
    searchPlaceholderText: '',
    singleSelection: false,
    idField: 'key',
    textField: '',
    itemsShowLimit: 6,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: '',
    enableCheckAll: false,
    noDataAvailablePlaceholderText: ''
  };
  listNodeSearchSelected: Array<{key: string, title_ja: string, title_eng: string, [key: string]: string}> = [];
  nodeObjects: Array<{key: string, title_ja: string, title_eng: string, [key: string]: string}> = [];
  selectedNodes: Array<{key: string, title_ja: string, title_eng: string, [key: string]: string}> = [];

  constructor(
    private translateService: TranslateService,
    private ref: ChangeDetectorRef,
    private renderer2: Renderer2,
  ) { }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentLangKey = this.translateService.currentLang === 'ja' ? 'title_ja' : 'title_eng';
    this.dropdownSettings = {
      ...this.dropdownSettings,
      textField: this.currentLangKey
    }
    this.nodeObjects = this.templateNode.objects.map((item: any) => {
      return {
        key: item.name,
        title_ja: titleCaseAllWord(item.title_ja),
        title_eng: titleCaseAllWord(item.title_eng)
      }
    });
    this.listNodeSearchSelected = this.formTemplateSetting.controls['search']?.value.map((item: string) => {
      const data = this.nodeObjects.filter((node: any) => node.key === item)[0];
      if (data) {
        return {
          key: data.key,
          title_ja: titleCaseAllWord(data.title_ja),
          title_eng: titleCaseAllWord(data.title_eng)
        }
      }
      return {}
    });
    this.selectedNodes = this.formTemplateSetting.controls['data']?.value.map((item: any) => {
      item.title_ja = titleCaseAllWord(item.title_ja);
      item.title_eng = titleCaseAllWord(item.title_eng);
      return item
    });
    this.translateService.onLangChange.subscribe((e) => {
      this.changeLang(e)
      this.ref.detectChanges()
    })
  }

  ngAfterViewInit(): void {
    const dropdownListData = (this.dataComponent.cdr.rootNodes[0] as HTMLElement)?.querySelector('.dropdown-list');
    const dropdownListSearch = (this.searchComponent.cdr.rootNodes[0] as HTMLElement)?.querySelector('.dropdown-list');
    this.renderer2.setStyle(dropdownListSearch, 'bottom', '45px');
    if (dropdownListData && dropdownListSearch) {
      this.observer = new MutationObserver((mutationsList, observer) => {
        this.renderer2.setStyle(document.body, 'overflow-y', 'hidden');
        if (dropdownListData.hasAttribute('hidden') && dropdownListSearch.hasAttribute('hidden')) {
          this.renderer2.setStyle(document.body, 'overflow-y', 'auto');
        }
      });
      const config = { attributes: true, childList: true, subtree: true };
      this.observer.observe(dropdownListData, config);
      this.observer.observe(dropdownListSearch, config);
    }
  }

  changeLang(event: any) {
    this.currentLangKey = event.lang === 'ja' ? 'title_ja' : 'title_eng';
    const dataSelected = this.formTemplateSetting.controls['data']?.value;
    const dataSearchSelected = this.formTemplateSetting.controls['search']?.value.map((item: string) => {
      const data = this.nodeObjects.filter((node: any) => node.key === item)[0];
      return {
        key: data.key,
        title_ja: titleCaseAllWord(data.title_ja),
        title_eng: titleCaseAllWord(data.title_eng)
      }
    });
    this.dataComponent._data = this.changeLangMultipleSelect(this.nodeObjects)
    this.dataComponent.selectedItems = this.changeLangMultipleSelect(dataSelected);
    this.dataComponent._placeholder = this.translateService.instant('template.basicSetting.displayListTab.inputLabel.pleasSelect')
    this.searchComponent._data = this.changeLangMultipleSelect(this.nodeObjects)
    this.searchComponent.selectedItems = this.changeLangMultipleSelect(dataSearchSelected);
    this.searchComponent._placeholder = this.translateService.instant('template.basicSetting.displayListTab.inputLabel.pleasSelect')
    document.body.click();
    this.ref.detectChanges()
  }

  changeLangMultipleSelect(baseData: Array<any>) {
    return baseData.map((item) => {
      return {
        id: item.key,
        text: item[this.currentLangKey]
      }
    })
  }

  onNodeSelect(item: any) {
    const selectedItem = this.nodeObjects.filter((node) => item.key === node.key)[0];
    if (selectedItem) (this.formTemplateSetting.controls['data'] as FormArray).push(new FormControl(selectedItem))
  }

  onNodeDeselect(item: any) {
    const dataFormArray = this.formTemplateSetting.controls['data'] as FormArray
    const indexItemRemove = this.formTemplateSetting.controls['data'].value.findIndex((node: any) => item.key === node.key)
    if (indexItemRemove !== -1) (dataFormArray).removeAt(indexItemRemove);
    dataFormArray.setErrors(null)
    if (!dataFormArray.value.length) dataFormArray.setErrors({ required: true })
  }

  drop(item: any) {
    drop(item, this.selectedNodes);
    this.mapDataMultipleSelect(this.selectedNodes);
  }
  moveUp(item: any) {
    moveUp(item, this.selectedNodes);
    this.mapDataMultipleSelect(this.selectedNodes);
  };
  moveDown(item: any) {
    moveDown(item, this.selectedNodes);
    this.mapDataMultipleSelect(this.selectedNodes);
  };

  mapDataMultipleSelect(data: Array<any>) {
    const dataFormArray = this.formTemplateSetting.controls['data'] as FormArray;
    dataFormArray.clear();
    data.forEach((item) => {
      const selectedItem = this.nodeObjects.filter((node) => item.key === node.key)[0];
      dataFormArray.push(new FormControl(selectedItem))
    });
  }

  onNodeSearchSelect(item: any) {
    const selectedItem = this.nodeObjects.filter((node) => item.key === node.key)[0];
    if (selectedItem) {
      (this.formTemplateSetting.controls['search'] as FormArray).push(new FormControl(selectedItem.key))
    }
  }

  onNodeSearchDeselect(item: any) {
    const dataFormArray = this.formTemplateSetting.controls['search'] as FormArray
    const indexItemRemove = this.formTemplateSetting.controls['search'].value.findIndex((node: any) => item.key === node)
    if (indexItemRemove !== -1) dataFormArray.removeAt(indexItemRemove);
    dataFormArray.setErrors(null)
    if (!dataFormArray.value.length) dataFormArray.setErrors({ required: true })
  }
}
