import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ACTION_LIST,
  DEFAULT_USE_TEST,
  DEFAULT_USE_VIDEO_VALUE, EVENT_LOG_TYPE, formatDatePicker,
  formatDatePickerUs,
  PREFIX_LIST,
  RuleValidations
} from '@app/constants';
import { AlertService, FirebaseAnalyticService, FormService, RouterService, TemplateService, UserService } from '@app/services';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment/moment";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidatorsService } from '@app/services/custom-validators.service';

@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.scss']
})
export class FormEditComponent implements OnInit, OnDestroy {
  isDisplayTab = new BehaviorSubject<boolean>(false);
  form: any;
  isOutDate: boolean = false;
  stringCodeForm: string = "";
  ruleValidations = RuleValidations;
  backgroundCode: string = "";
  videoList: Array<string> = [];
  examList: any;
  submitAllTab: boolean = false;
  nodesData: any
  videoData: any
  minNumberParking: number = 1;
  currentUser: any;
  isRedirectFromTestDetail: boolean = false;
  formId: string = '';
  queryParams: any;
  subscription: {[key: string] : Subscription | null} = {
    form: null
  }

  //form advanced
  formEditAdvanced = new FormGroup({
    organizationName: new FormControl('', { validators: [Validators.maxLength(this.ruleValidations.maxLength32)] }),
    copyright: new FormControl('', { validators: [Validators.maxLength(this.ruleValidations.maxLength1024)] }),
    templateType: new FormControl('', { validators: [Validators.required, CustomValidatorsService.notAllowedOnlySpace] }),
    stringCodeForm: new FormControl('', {}),
    backgroundCode: new FormControl('', {}),
    secretKey: new FormControl('', {}),
    useQrCode: new FormControl(true, {}),
    registFormWithoutLogin: new FormControl(true, {}),
  });

  //form basic
  formEditBasic = new FormGroup<any>({
    title: new FormControl('', { validators: [Validators.required, Validators.maxLength(this.ruleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace] }),
    remark: new FormControl(''),
    targetDate: new FormControl('', { validators: [Validators.required] }),
    systemTemplateId: new FormControl(null),
    templateId: new FormControl(null),
    maxRecords: new FormControl(1, { validators: [Validators.required, Validators.min(this.ruleValidations.minNumber1), Validators.max(this.ruleValidations.maxNumber99999999)] }),
    layout: new FormControl('', { validators: [Validators.required] }),
    displayIndex: new FormControl(null),
    colorCode: new FormControl('', { validators: [Validators.required] }),
    tabId: new FormControl(null),
    archive: new FormControl(null),
  });

  //form email
  formEditEmail = new FormGroup<any>({
    emailSynchronous: new FormControl('', { validators: [Validators.pattern(this.ruleValidations.multipleEmailSeparateByComma)] }),
    isSendMailFormRegist: new FormControl(true, { validators: [] }),
    titleMailFormRegist: new FormControl('', { validators: [] }),
    bodyMailFormRegist: new FormControl('', { validators: [] }),
    isSendMailReissueQr: new FormControl(true, { validators: [] }),
    titleMailReissueQr: new FormControl('', { validators: [] }),
    bodyMailReissueQr: new FormControl('', { validators: [] }),
    isSendMailUpdateFormData: new FormControl(true, { validators: [] }),
    titleMailUpdateFormData: new FormControl('', { validators: [] }),
    bodyMailUpdateFormData: new FormControl('', { validators: [] }),
  });

  //form video
  formEditVideo = new FormGroup({
    videoList: new FormArray<FormControl>([]),
    videoDescription: new FormControl(null),
    useVideo: new FormControl(DEFAULT_USE_VIDEO_VALUE),
    showVideo: new FormControl(null)
  });

  //form exam
  formEditExam = this.formBuilder.group({
    passingScore: new FormControl(null, { validators: [Validators.required, CustomValidatorsService.notAllowedOnlySpace] }),
    useTest: new FormControl(0, {}),
    showExam: new FormControl(null),
  });

  //form certificate
  formEditCertificate = new FormGroup({
    certificateDescription: new FormControl(null),
    showCertificate: new FormControl(null)
  })

  constructor(
    private route: ActivatedRoute,
    private formService: FormService,
    private routerService: RouterService,
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private translateService: TranslateService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private userService: UserService,
    private templateService: TemplateService
  ) {
    this.isRedirectFromTestDetail = this.router.getCurrentNavigation()?.extras.state?.['isRedirectFromTestDetail'];
    this.queryParams = this.router.getCurrentNavigation()?.extras.state;
  }

  public ngOnInit() {
    this.getCurrentUser().then();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscription['form']?.unsubscribe();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.currentUser = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (this.currentUser) this.getForm().then();
    }
  }

  async recipeSubmitted() {
    if (this.currentUser) {
      this.formService.getValueOwnerFormById(this.currentUser.uid, this.formId).subscribe((response) => {
        const currentForm = response;
        if (!currentForm) {
          this.router.navigate(['form'], { state: { create: true } }).then(() => {
            this.alertService.error(this.translateService.instant('alertMessages.form.update.notExist'));
          });
          return;
        }    
      })
    }

    this.form = {
      ...this.form,
      ...this.formEditBasic.value,
      ...this.formEditAdvanced.value,
      ...this.formEditEmail.value,
      nodes: this.nodesData
    }
    if (this.nodesData.nodes.meta.prefix === PREFIX_LIST.pluginTbc) {
      this.form = {
        ...this.form,
        ...this.formEditExam.value,
        ...this.formEditCertificate.value,
        ...this.formEditVideo.value,
        testForm: this.examList
      }
    }
    this.submitAllTab = true;
    let isFormInvalid = this.formEditAdvanced.valid && this.formEditEmail.valid && this.formEditBasic.valid;
    if (this.nodesData.nodes.meta.prefix === PREFIX_LIST.pluginTbc) {
      isFormInvalid = isFormInvalid && this.formEditVideo.valid && this.formEditExam.valid;
    }
    if (!isFormInvalid) {
      this.alertService.error(this.translateService.instant('errorMessages.formValidate.allTabValidate'))
      return
    }

    this.formService.update(this.form, this.form.user_id, this.form.doc_id).then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.UPDATE, EVENT_LOG_TYPE.SUCCESS)
      this.router.navigate(['form'], {state:  { isSubmitEditForm: true }}).then(() => {
        this.alertService.success(this.translateService.instant('alertMessages.form.update.success'));
      });
    }).catch((e: any) => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.UPDATE, EVENT_LOG_TYPE.ERROR)
    })
  }

  receiveDataTestForm(dataTestForm: any) {
    this.examList = dataTestForm
  }

  backToFormList(isBackToFormList: any) {
    if (isBackToFormList) {
      this.queryParams = {
        ...this.queryParams,
        query_search: this.queryParams?.querySearch ?? '',
        start_date: this.queryParams?.startDate,
        end_date: this.queryParams?.endDate
      }
      this.router.navigate(['form'], { queryParams: this.queryParams }).then();
    }
  }

  async getForm() {
    const params = await firstValueFrom(this.route.params);
    this.formId = params['id'];
    this.subscription['form'] = this.formService.getValueOwnerFormById(this.currentUser.uid, this.formId).subscribe((response) => {
      this.form = response;
      const prefix = this.form.nodes.nodes.meta.prefix;
      if (prefix == PREFIX_LIST.pluginTbc) this.isDisplayTab.next(true);
      this.isOutDate = moment(this.form.targetDate.toDate()).diff(moment(), 'days') < 0;
      this.nodesData = this.form.nodes;
      this.examList = this.form.testForm;
      this.generateFormVideo();
      this.generateFormExam();
      this.generateDataFormBasic();
      this.generateDataAdvanced();
      this.generateDataEmail();
      this.generateFormCertificate();
      Object.keys(this.formEditEmail.controls).forEach(key => {
        if (key === 'isSendMailFormRegist' && this.form[key]) {
          this.formEditEmail.controls['titleMailFormRegist'].setValidators([Validators.required]);
          this.formEditEmail.controls['bodyMailFormRegist'].setValidators([Validators.required]);
        } else if (key === 'isSendMailReissueQr' && this.form[key]) {
          this.formEditEmail.controls['titleMailReissueQr'].setValidators([Validators.required]);
          this.formEditEmail.controls['bodyMailReissueQr'].setValidators([Validators.required]);
        } else if (key === 'isSendMailUpdateFormData' && this.form[key]) {
          this.formEditEmail.controls['titleMailUpdateFormData'].setValidators([Validators.required]);
          this.formEditEmail.controls['bodyMailUpdateFormData'].setValidators([Validators.required]);
        }
        this.formEditEmail.updateValueAndValidity();
      });
    })
  }

  generateFormCertificate() {
    this.formEditCertificate.controls['certificateDescription'].setValue(this.form.certificateDescription ?? null);
    this.formEditCertificate.controls['showCertificate'].setValue(this.form.showCertificate ?? null);
  }

  private generateFormVideo() {
    if (this.form.hasOwnProperty('useVideo') && this.form.videoList) {
      this.videoList = this.form.videoList;
      this.formEditVideo.controls.useVideo.setValue(this.form.useVideo);
      this.formEditVideo.controls.videoDescription.setValue(this.form.videoDescription ?? null);
      this.formEditVideo.controls.showVideo.setValue(this.form.showVideo ?? null);
      if (this.form.videoList.length) {
        for (const video of this.form.videoList) {
          this.formEditVideo.controls.videoList.push(new FormControl(video));
        }
      }
    }
  }
  private generateFormExam() {
    if (this.form.hasOwnProperty('testForm') && this.form.testForm) {
      this.formEditExam.controls.passingScore.setValue(this.form?.passingScore ?? null)
      this.formEditExam.controls.useTest.setValue(this.form.useTest ?? DEFAULT_USE_TEST);
      this.formEditExam.controls.showExam.setValue(this.form.showExam ?? null);
    }
  }

  private async generateDataFormBasic() {
    this.formEditBasic.controls['tabId'].setValue(this.form?.tabId ?? null)
    this.formEditBasic.controls['archive'].setValue(this.form?.archive ?? null)
    this.formEditBasic.controls['maxRecords'].setValidators([Validators.max(this.ruleValidations.maxNumber99999999), Validators.min(this.form.maxRecords), Validators.required])
    this.formEditBasic.controls['title'].setValue(this.form.title);
    this.formEditBasic.controls['remark'].setValue(this.form.remark);
    this.formEditBasic.controls['targetDate'].setValue(this.formatDate(this.form.targetDate));
    this.formEditBasic.controls['systemTemplateId'].setValue(this.form.systemTemplateId);
    this.formEditBasic.controls['templateId'].setValue(this.form.templateId);
    this.formEditBasic.controls['maxRecords'].setValue(this.form.maxRecords);
    this.formEditBasic.controls['layout'].setValue(this.form.layout);
    this.formEditBasic.controls['displayIndex'].setValue(this.form.displayIndex ? parseInt(this.form.displayIndex) : null);
    this.formEditBasic.controls['colorCode'].setValue(this.form.colorCode);
    this.formEditBasic.controls['targetDate'].setValue(new Date(this.formEditBasic.controls['targetDate'].value));

    if (this.form.nodes.nodes.meta.prefix === PREFIX_LIST.pluginAttendance || this.form.nodes.nodes.meta.prefix === PREFIX_LIST.pluginNaruo) {
      this.formEditBasic.addControl('maximumNumberOfParkingLots', new FormControl(null));
      await this.setValidateMaxMinParkingLot();
      this.formEditBasic.controls['maximumNumberOfParkingLots'].setValue(Number(this.form.maximumNumberOfParkingLots));
    }
  }

  private async generateDataAdvanced() {
    this.formEditAdvanced.controls.organizationName.setValue(this.form.organizationName);
    this.formEditAdvanced.controls.copyright.setValue(this.form.copyright);
    this.formEditAdvanced.controls.templateType.setValue(this.form.templateType);
    this.formEditAdvanced.controls.stringCodeForm.setValue(this.form.stringCodeForm);
    this.formEditAdvanced.controls.backgroundCode.setValue(this.form.backgroundCode);
    this.formEditAdvanced.controls.useQrCode.setValue(this.form.useQrCode);
    this.formEditAdvanced.controls.secretKey.setValue(this.form.secretKey);
    if (this.form.hasOwnProperty('registFormWithoutLogin')) this.formEditAdvanced.controls.registFormWithoutLogin.setValue(this.form.registFormWithoutLogin);
    if (this.form.secretKey) this.formEditAdvanced.controls.secretKey.setValue(this.form.secretKey);
  }

  private generateDataEmail() {
    this.formEditEmail.controls['emailSynchronous'].setValue(this?.form?.emailSynchronous ?? null);
    this.formEditEmail.controls['isSendMailFormRegist'].setValue(this.form.isSendMailFormRegist ?? true);
    this.formEditEmail.controls['isSendMailReissueQr'].setValue(this.form.isSendMailReissueQr ?? true);
    this.formEditEmail.controls['isSendMailUpdateFormData'].setValue(this.form.isSendMailUpdateFormData ?? true);
    this.formEditEmail.controls['titleMailFormRegist'].setValue(this.form.titleMailFormRegist ?? '');
    this.formEditEmail.controls['titleMailReissueQr'].setValue(this.form.titleMailReissueQr ?? '');
    this.formEditEmail.controls['titleMailUpdateFormData'].setValue(this.form.titleMailUpdateFormData ?? '');
    this.formEditEmail.controls['bodyMailFormRegist'].setValue(this.form.bodyMailFormRegist ?? '');
    this.formEditEmail.controls['bodyMailReissueQr'].setValue(this.form.bodyMailReissueQr ?? '');
    this.formEditEmail.controls['bodyMailUpdateFormData'].setValue(this.form.bodyMailUpdateFormData ?? '');
  }

  private formatDate(targetDate: any) {
    const date = targetDate.toDate();
    if (this.translateService.currentLang === 'en') {
      return moment(date).format(formatDatePickerUs)
    }
    return moment(date).format(formatDatePicker)
  }

  async setValidateMaxMinParkingLot() {
    const dataMinParkingLot: any = await firstValueFrom(this.formService.getOwnerFormDataByHowToVisit(this.currentUser.uid, this.form.doc_id));
    this.minNumberParking = dataMinParkingLot.length;
    this.formEditBasic.controls['maximumNumberOfParkingLots'].setValidators([Validators.required, Validators.min(this.minNumberParking), Validators.max(this.ruleValidations.maxNumberCar99999)]);
    this.formEditBasic.controls['maximumNumberOfParkingLots'].updateValueAndValidity();
  }
}
