<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="justify-content-center mb-5">
    <h3>{{ 'account.title'|translate }}</h3>
  </c-col>
  <c-col class="mb-5">
    <c-dropdown class="mb-2">
      <button class="btn-common" cButton cDropdownToggle>
        {{ 'template.btnLabel.setting' | translate }}
      </button>
      <ul cDropdownMenu>
        <li><a routerLink="/list-account" *ngIf="isDisplayAccountList && accountRestrictConfig.isDisplayChildAccountList" routerLinkActive="active" cDropdownItem>{{ 'accountManagement.title' | translate }}</a></li>
        <li><a routerLink="/groups" routerLinkActive="active" cDropdownItem>{{ "layout.header.menu.groups" | translate }}</a></li>
        <li><a routerLink="/tabs" routerLinkActive="active" cDropdownItem>{{'tab.title' | translate}}</a></li>
        <li><a routerLink="/portal/edit" routerLinkActive="active" cDropdownItem>{{'portal.create.title' | translate}}</a></li>
        <li><a routerLink="/list-template" *ngIf="accountRestrictConfig.isDisplayTemplateList" routerLinkActive="active" cDropdownItem>{{ 'template.title' | translate }}</a></li>
      </ul>
    </c-dropdown>
    <c-col>
      <form cForm [formGroup]="formUpdateAccount" (ngSubmit)="updateAccount()" class="needs-validate">
        <c-card class="mb-3 card-no-border">
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.name'|translate }}
              <c-badge color="danger" shape="rounded-pill">{{ 'account.required'|translate }}</c-badge>
            </c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.name'|translate }}"
                  formControlName="name"
                />
              </c-col>
              <c-col *ngIf="submitted && formUpdateAccount.controls['name'].invalid" class="invalid-feedback">
                <c-col *ngIf="formUpdateAccount.controls['name'].errors && formUpdateAccount.controls['name'].errors?.['maxlength']">
                  {{ 'errorMessages.name.maxLength'|translate }}
                </c-col>
                <c-col *ngIf="formUpdateAccount.controls['name'].errors && formUpdateAccount.controls['name'].errors?.['required']">
                  {{ 'errorMessages.name.required'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.organization_name'|translate }}
              <c-badge color="danger" shape="rounded-pill">{{ 'account.required'|translate }}</c-badge>
            </c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.organization_name'|translate }}"
                  formControlName="organizationName"
                />
              </c-col>
              <c-col *ngIf="submitted && formUpdateAccount.controls['organizationName'].invalid" class="invalid-feedback">
                <c-col *ngIf="formUpdateAccount.controls['organizationName'].errors && formUpdateAccount.controls['organizationName'].errors['required']">
                  {{ 'errorMessages.organization.required'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.member_code'|translate }}</c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.member_code'|translate }}"
                  formControlName="memberCode"
                />
              </c-col>
              <c-col *ngIf="submitted && formUpdateAccount.controls['memberCode'].invalid" class="invalid-feedback">
                <c-col *ngIf="submitted && formUpdateAccount.controls['memberCode'].errors && formUpdateAccount.controls['memberCode'].errors">
                  {{ 'errorMessages.memberCode.' + getFirstError(formUpdateAccount.controls['memberCode'].errors) | translate: { maxlength: ruleValidation.maxLength255 } }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.secret_key'|translate }}
              <c-badge color="danger" shape="rounded-pill">{{ 'account.required'|translate }}</c-badge>
            </c-col>
            <c-col>
              <c-col class="d-flex justify-content-end secretkey" >
                <input
                  *ngIf="isSmartPhoneScreen"
                  [cPopover]="formUpdateAccount.controls['secretKey'].value"
                  [cPopoverTrigger]="'hover'"
                  cPopoverPlacement="top"
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.secret_key'|translate }}"
                  formControlName="secretKey"
                  class="input-secret-key"
                  readonly="readonly"
                />
                <input
                  *ngIf="!isSmartPhoneScreen"
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.secret_key'|translate }}"
                  formControlName="secretKey"
                  readonly="readonly"
                />
                <div class="btn-genid" (click)="toggleGenerateUidModal()">{{ 'account.btn_gen_id'|translate }}</div>
              </c-col>
              <c-col *ngIf="submitted && formUpdateAccount.controls['secretKey'].invalid" class="invalid-feedback">
                <c-col *ngIf="formUpdateAccount.controls['secretKey'].errors &&  formUpdateAccount.controls['secretKey'].errors['required']">
                  {{ 'errorMessages.common.required'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
        </c-card>
        <c-col class="mb-5">
          <c-col class="mb-2">OpenAI</c-col>
          <c-col>
            <c-card class="card-no-border">
              <c-card-body class="card-border-bottom d-flex">
                <c-col>API Key</c-col>
                <c-col>
                  <c-col class="d-flex justify-content-end">
                    <input
                      cFormControl
                      type="text"
                      formControlName="apiKey"
                    />
                  </c-col>
                  <c-col *ngIf="submitted && formUpdateAccount.controls['apiKey'].invalid" class="invalid-feedback">
                    <c-col *ngIf="submitted && formUpdateAccount.controls['apiKey'].errors && formUpdateAccount.controls['apiKey'].errors">
                      {{ 'errorMessages.common.' + getFirstError(formUpdateAccount.controls['apiKey'].errors) | translate: { maxlength: ruleValidation.maxLength255 } }}
                    </c-col>
                  </c-col>
                </c-col>
              </c-card-body>
              <c-card-body class="card-border-bottom d-flex">
                <c-col>Organization ID</c-col>
                <c-col>
                  <c-col class="d-flex justify-content-end">
                    <input
                      cFormControl
                      type="text"
                      formControlName="organizationId"
                    />
                  </c-col>
                  <c-col *ngIf="submitted && formUpdateAccount.controls['organizationId'].invalid" class="invalid-feedback">
                    <c-col *ngIf="submitted && formUpdateAccount.controls['organizationId'].errors && formUpdateAccount.controls['organizationId'].errors">
                      {{ 'errorMessages.common.' + getFirstError(formUpdateAccount.controls['organizationId'].errors) | translate: { maxlength: ruleValidation.maxLength255 } }}
                    </c-col>
                  </c-col>
                </c-col>
              </c-card-body>
            </c-card>
          </c-col>
        </c-col>
        <button type="submit" class="btn-common width-100">{{ 'account.btn_update'|translate }}</button>
      </form>
    </c-col>
  </c-col>
  <c-col class="mb-5">
    <c-col class="mb-2">{{ 'account.support'|translate }}</c-col>
    <c-col>
      <c-card class="card-no-border">
        <c-card-body>
          <c-col>{{ environment.support_message[lang] }}</c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
  <c-col>
    <c-col class="mb-2">{{ 'account.title'|translate }}</c-col>
    <c-col>
      <c-card class="card-no-border">
        <c-card-body class="card-border-bottom">
          <c-col class="d-flex">
            <c-col>{{ 'account.email'|translate }}</c-col>
            <c-col class="d-flex justify-content-end">
              <span>{{ currentUser?.email }}</span>
            </c-col>
          </c-col>
        </c-card-body>
        <c-card-body class="card-border-bottom">
          <c-col class="d-flex">
            <c-col>{{ 'account.plan'|translate }}</c-col>
            <c-col class="d-flex justify-content-end">
              <span>{{ currentUser?.accountTypeName }}</span>
            </c-col>
          </c-col>
        </c-card-body>
        <c-card-body class="card-border-bottom">
          <c-col class="d-flex">
            <c-col>{{ 'account.password'|translate }}</c-col>
            <c-col class="d-flex justify-content-end">
              <a routerLink="/account/update-password" class="btn-link" [hidden]="loginBySso">
                <button class="btn-change-password text-color-base">
                  <span class="me-3">{{ 'account.btn_password'|translate }}</span>
                  <img src="assets/svg/flight-record/icon-arrow-right.svg" alt=""/>
                </button>
              </a>
            </c-col>
          </c-col>
        </c-card-body>
        <c-card-body class="card-border-bottom">
          <c-col>
            <button class="btn-logout text-color-base" (click)="logout()">
              <img src="assets/svg/logout.svg" alt="" />
              <span class="ms-2">{{ 'account.btn_logout'|translate }}</span>
            </button>
          </c-col>
        </c-card-body>
        <c-card-body *ngIf="environment.delete_account_link" class="card-border-bottom">
          <c-col>
            <button class="btn-delete-account" (click)="toggleDeleteAccountModal()">
              <img src="assets/svg/trash.svg" alt="" />
              <span class="ms-2 delete-btn">{{ 'account.remove_account'|translate }}</span>
            </button>
            <c-modal class="custom-modal" backdrop="static" alignment="center" [visible]="visibleDeleteAccountModal">
              <c-modal-header>
                <h4 cModalTitle>{{ 'account.modal_title_remove'|translate }}</h4>
                <button (click)="toggleDeleteAccountModal()" cButtonClose></button>
              </c-modal-header>
              <c-modal-body>
                {{ 'account.modal_title_confirm'|translate }}
              </c-modal-body>
              <c-modal-footer>
                <button (click)="toggleDeleteAccountModal()" cButton class="btn-close-modal" >
                  {{ 'account.btn_modal_back'|translate }}
                </button>
                <button cButton class="btn-confirm-modal" (click)="deleteAccount()">
                  {{ 'account.btn_modal_confirm'|translate }}
                </button>
              </c-modal-footer>
            </c-modal>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
  <app-modal-generate-uid [visibleGenerateUid]="visibleGenerateUid" (getUid)="receiveUid($event)"></app-modal-generate-uid>
</c-container>
