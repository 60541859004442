import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FirebaseAnalyticService, FormCachesService, FormService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { checkColorBg, filterAddressInPhoneTable, getInfoDataByKey, isDisplayTargetDate } from "@app/helpers";
import { ACTION_LIST, EVENT_LOG_TYPE, formatTargetDateJapan, PREFIX_LIST, STYLE_BUTTON_TBC, STYLE_STRING_TBC, text } from "@app/constants";
import { filter, firstValueFrom } from 'rxjs';
import * as moment from "moment";

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  isDisplayTargetDate = isDisplayTargetDate;
  checkColorBg = checkColorBg
  protected readonly STYLE_BUTTON_TBC = STYLE_BUTTON_TBC;
  protected readonly STYLE_STRING_TBC = STYLE_STRING_TBC;
  form: any;
  formUser: any;
  prefixForm: string = "";
  doc = new jsPDF('p', 'pt', 'a4');
  isLoading: boolean = true;
  phoneTableList: any;
  phoneNumber: string = '';
  authUser: any;
  formId: string | null = null;
  authUserId: string | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private translateService: TranslateService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private router: Router,
    private formCachesService: FormCachesService
  ) { }

  ngOnInit(): void {
    this.getPhoneTableList();
    this.getCurrentUser();
  }

  async getPhoneTableList() {
    this.phoneTableList = await firstValueFrom(this.formService.getPhoneTableList());
  }

  getCurrentUser() {
    this.formId = this.activatedRoute.snapshot.params['formId'];
    // update by Tanaka on 2023/08/23
    this.formCachesService.getDetailFormCache(this.formId ?? '').subscribe({
      next: (formCacheValue: any) => {
        if (formCacheValue.length) {
          this.authUserId = formCacheValue[0]?.['userid'];
          this.getForm();
          this.getFormData();
        }
      }
    })
  }

  getForm() {
    // update by Tanaka on 2023/08/23
    this.formService.getValueOwnerFormById(this.authUserId ?? '', this.formId ?? '').subscribe((response) => {
      this.form = response;
      this.prefixForm = this.form.nodes.nodes.meta.prefix;
      if (this.form) {
        if (this.router.url.includes("certificate")) {
          document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
        }
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
          document.getElementById('form-guest-body')!.style.backgroundColor = text.defaultBackgroundCode;
          if (event.url.includes("certificate")) {
            document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
          }
        });
      }
    })
  }

  getFormData() {
    const formDataId = this.activatedRoute.snapshot.paramMap.get('formDataId');
    if (formDataId) {
      // update by Tanaka on 2023/08/23
      this.formService.getOwnerFormDataById(this.authUserId ?? '', this.formId ?? '', formDataId).subscribe((response) => {
        this.formUser = response;
        this.phoneNumber = filterAddressInPhoneTable(this.phoneTableList, this.formUser.address, 'phone');
        this.isLoading = false
        this.formUser.passingDateString = ''
        if (this.formUser.hasOwnProperty('passingDate')) this.formUser.passingDateString = moment(this.formUser.passingDate.toDate()).format(formatTargetDateJapan);
      })
    }
  }

  getFormUserInfo(key: string) {
    let data = "";
    if (this.formUser && this.formUser[key]) {
      data = this.formUser[key];
    }
    return data;
  }

  getFormInfo(key: string) {
    return getInfoDataByKey(this.form, key, this.translateService.currentLang)
  }

  downloadPdf() {
    try {
      let data = document.getElementById('doc')!;
      const pageWidth = this.doc.internal.pageSize.getWidth();
      const pageHeight = this.doc.internal.pageSize.getHeight();
      html2canvas(data, {
        scale: 3
      }).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/png', '10.0')
        this.doc.addImage(contentDataURL, 'TIFF', 0, 0, pageWidth, pageHeight, '', 'NONE');
        this.doc.save(`受講修了証_${this.formUser.company}_${this.formUser.name}.pdf`);
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.DOWNLOAD_CERTIFICATE, EVENT_LOG_TYPE.SUCCESS);
      });
    } catch (e) {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.DOWNLOAD_CERTIFICATE, EVENT_LOG_TYPE.ERROR);
    }
  }

  protected readonly PREFIX_LIST = PREFIX_LIST;
}
