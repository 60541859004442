<div 
  *ngIf="isGuest"
  class="form-group language"
  [style.--background-color]="backgroundColor"
  [style.--string-color]="checkColorBg(stringColor)">
  <div class="ms-4 d-flex align-items-center item-language" [ngClass]="{'set-default-color': backgroundColor == text.defaultBackgroundCode}">
    <img *ngIf="language == 'en'" src="assets/svg/lang/us.svg" alt="en" />
    <img *ngIf="language == 'ja'" src="assets/svg/lang/ja.svg" alt="ja" />
    <c-dropdown>
      <button class="btn-header">
        <span cButton cDropdownToggle class="ps-2">
          {{ "layout.header.menu.lang.item." + language | translate }}
        </span>
      </button>
      <ul cDropdownMenu>
        <li>
          <a data-lang="en" (click)="onChange($event)" [active]="language == 'en'" cDropdownItem>
            {{ "layout.header.menu.lang.item.en" | translate }}
          </a>
        </li>
        <li>
          <a data-lang="ja" (click)="onChange($event)" [active]="language == 'ja'" cDropdownItem>
            {{ "layout.header.menu.lang.item.ja" | translate }}
          </a>
        </li>
      </ul>
    </c-dropdown>
  </div>
</div>
<c-container
  class="min-vh-90 container"
  [ngClass]="{'error-container': errorWithEnvSetting}"
  [style.--background-color]="backgroundColor">
  <app-header-guest *ngIf="isGuest && !isRouteNonAccount; else userHeader"></app-header-guest>
  <c-col *ngIf="hideHeader()" class="mb-5 d-flex common-header non-account-header">
    <a routerLink="/login" class="btn-header">
      <img src="./assets/img/logo.png" alt="logo" />
      <h5 class="ms-3 mb-0">{{ "layout.header.title" | translate }}</h5>
    </a>
  </c-col>
  <ng-template #userHeader>
    <app-header-user *ngIf="!isRouteNonAccount"></app-header-user>
  </ng-template>
  <div
    id="content"
    [ngClass]="{
      'non-account-body': isRouteNonAccount,
      'pt-0' : hideHeaderUserRoutingName.includes(route.routeConfig?.path!)
    }">
    <ng-content></ng-content>
  </div>
</c-container>
