import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { AlertService, FirebaseAnalyticService, FormCachesService, FormService, GroupService, UserService } from "@app/services";
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import {
  ACCOUNT_DEFAULT_RESTRICT,
  ACTION_LIST,
  EVENT_LOG_TYPE,
  formatDataDate,
  formatDatePicker,
  IsRedirectTab,
  ITEM_PER_PAGE, LIMIT_HEIGHT_DISPLAY_SHOW_MORE, MAX_HEIGHT_REMARK_BLOCK,
  MemberJoinStatus,
  PERMISSION_GROUP_LIST,
  text
} from "@app/constants";
import { filter, firstValueFrom, from, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { checkAccountRestrictMode, dayFormat, getLocaleDatePicker, getLongText, getPermissionRoleGroupUser, getTotalPropertyValue, onlyUnique } from "@app/helpers";
import * as moment from "moment";
import { Group, IPermission } from "@app/models/group";
import { DomUtil } from "leaflet";
import { Clipboard } from '@angular/cdk/clipboard';
import hasClass = DomUtil.hasClass;
import { TabService } from "@app/services/tab.service";
import { ITab } from "@app/models/tab";
import { IAccountRestrict } from '@app/models';

@Component({
  selector: 'app-form',
  templateUrl: 'form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit {
  @ViewChild('deleteModal') deleteModal: any;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    const width = window.innerWidth;
    if (width < 768) {
      this.blankBlockStyles = 'width: 35px'
    }
  }
  permissionGroupConfig: IPermission = PERMISSION_GROUP_LIST.full;
  accountRestrictConfig: IAccountRestrict = ACCOUNT_DEFAULT_RESTRICT;
  formSearch = new FormGroup<any>({
    querySearch: new FormControl(''),
    color: new FormControl(''),
    startDate: new FormControl(moment().startOf('year').format(formatDatePicker)),
    endDate: new FormControl(moment().endOf('year').format(formatDatePicker)),
    groupId: new FormControl(''),
    tabId: new FormControl(''),
  });
  submitted = false;
  loading = false;
  originalForm: any[] = [];
  formData: any;
  forms: any[] = [];
  p: number = 1;
  id: string = '';
  currentUser: any;
  color: string[] = [];
  visible: boolean = false;
  btnDelete: boolean = false;
  currentDatepickerLocale: string = "";
  currentLanguage = this.translate.currentLang;
  colorTypesSet: any;
  queryParams: any
  visibleSearchBox: boolean = false;
  groups: any;
  blankBlockStyles = 'width: 40px';
  tabStyle: string = '';
  formIds: Array<string> | null = [];
  activePane = 0;
  isLoading: boolean = true
  dataShowToggleReadMore: any = {}
  isCopied: boolean = false;
  formSelected: any
  visibleModalShare: boolean = false;
  tabs: Array<ITab> = [];
  subscription: { [key: string]: Subscription | null } = {
    authUser: null,
    dataCurrentUser: null,
    tab: null,
    groupUser: null,
  }
  listSubscription: { [key: string]: Array<Subscription> | null } = {
    formListByOwner: [],
    formListByGroup: [],
    formListByTab: [],
    groupUserPath: [],
    formCachesGroup: [],
    formDataList: []
  }
  groupPath: string = ''

  constructor(
    private userService: UserService,
    private router: Router,
    private formService: FormService,
    private alertService: AlertService,
    private ref: ChangeDetectorRef,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private groupService: GroupService,
    private renderer2: Renderer2,
    private clipboard: Clipboard,
    private tabService: TabService,
    private formCachesService: FormCachesService
  ) { }

  ngOnInit(): void {
    this.onWindowResize();
    this.getCurrentUser().then();
    this.getColors().then();
    this.setQueryParamFormSearch();
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.translate.onLangChange.subscribe((e) => {
      this.changeLangWithDatePicker(e.lang);
    })
    this.tabStyle = `translateX(0px)`;
  }

  ngAfterViewInit(): void {
    this.changeLangWithDatePicker(this.translate.currentLang);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.checkShowReadMore();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll()
  }

  unsubscribeAll() {
    this.subscription['authUser']?.unsubscribe();
    this.subscription['dataCurrentUser']?.unsubscribe();
    this.subscription['tab']?.unsubscribe();
    this.subscription['groupUser']?.unsubscribe();
    this.subscription['groupUserPath']?.unsubscribe();
    this.subscription['formCaches']?.unsubscribe();
    this.listSubscription['formListByOwner']?.forEach((sub) => sub.unsubscribe());
    this.listSubscription['formListByGroup']?.forEach((sub) => sub.unsubscribe());
    this.listSubscription['formListByTab']?.forEach((sub) => sub.unsubscribe());
    this.listSubscription['groupUserPath']?.forEach((sub) => sub.unsubscribe());
    this.listSubscription['formCachesGroup']?.forEach((sub) => sub.unsubscribe());
    this.listSubscription['formDataList']?.forEach((sub) => sub.unsubscribe());
  }

  async getCurrentUser() {
    this.userService.getCurrentUser().subscribe({
      next: (currentUser) => {
        if (currentUser && currentUser.uid.length) {
          this.subscription['dataCurrentUser'] = this.userService.getValueUserByUid(currentUser?.uid).subscribe({
            next: (data) => {
              this.currentUser = data;
              if (this.currentUser) {
                this.getTabs();
                this.getOwnerForm();
                this.getGroupJoinedList();
              }
            }
          })
        }
      }
    })
  }

  private async getColors() {
    const data = await firstValueFrom(this.formService.getColors());
    this.colorTypesSet = data.map((item: any) => item.payload.doc.data());
  }

  getTabs() {
    this.subscription['tab'] = this.tabService.index(this.currentUser?.uid, true).subscribe((res) => {
      this.tabs = res;
    })
  }

  getGroupJoinedList() {
    this.subscription['groupUser'] = this.groupService.getSubscribeGroupUserPathByEmail(this.currentUser.email).subscribe({
      next: async (data) => {
        const dataGroup = data.map((item: any) => {
          const data = item.payload.doc.data();
          data.path = item.payload.doc.ref.parent.parent.path
          return data
        }).filter((item: any) => item.status === MemberJoinStatus.accepted).map((item) => item.path);
        if (Boolean(dataGroup.length)) {
          this.groups = [];
          let userId = this.currentUser.uid;
          if (this.currentUser.parentUserId && this.currentUser.parentUserId.length) {
            userId = this.currentUser.parentUserId;
          }
          const callApiGroup = await firstValueFrom(this.groupService.getGroupsByEmail(userId, this.currentUser.email))
          const groupsData = callApiGroup.docs.map(async (item) => {
            const groupUsersQuerySnapshot = await item.ref.collection('group_users').get();
            const groupUsers = groupUsersQuerySnapshot.docs.map((groupUserDoc) => groupUserDoc.data());
            const data: any = item.data();
            data.groupUsers = groupUsers
            data.path = item.ref.path;
            return data;
          });
          Promise.all(groupsData).then((data) => {
            this.groups = data.filter((item) => {
              let condition = !item.hidden;
              if (!this.accountRestrictConfig.isCreateGroup) {
                const groupOwnerId = item.path.split('/')[1];
                condition = !item.hidden && groupOwnerId != this.currentUser.uid;
              }
              return condition;
            })
            this.activePane = this.tabs.length + this.groups.length;
            this.ref.detectChanges()
          })
        } else {
          this.activePane = this.tabs.length;
        }
      }
    });
  }

  getOwnerForm() {
    localStorage.setItem('isRedirect', IsRedirectTab.owner);
    const formListByOwner = this.formService.getOwnerFormListData(this.currentUser?.uid).subscribe((response) => {
      this.forms = response;
      this.accountRestrictConfig = checkAccountRestrictMode(this.currentUser, { form: this.forms.length });
      this.originalForm = [...response];
      this.isLoading = false;
      this.checkShowReadMore();
      this.search();
    });
    this.listSubscription['formListByOwner']?.push(formListByOwner);
  }

  getFormByTab() {
    localStorage.setItem('isRedirect', IsRedirectTab.owner);
    const formListByTab = this.formService.getOwnerFormListData(this.currentUser?.uid).subscribe((response) => {
      this.forms = response;
      this.accountRestrictConfig = checkAccountRestrictMode(this.currentUser, { form: this.forms.length });
      this.originalForm = [...response];
      this.isLoading = false;
      this.checkShowReadMore();
      this.search();
    });
    this.listSubscription['formListByTab']?.push(formListByTab);
  }

  async getFormByGroups(path: string) {
    this.forms = [];
    localStorage.setItem('isRedirect', IsRedirectTab.group);
    const formListByGroup = this.groupService.getGroupByPath(path).subscribe({
      next: async (value) => {
        const dataGroup: any = value.payload.data();
        let formCachePromises = [];
        if (dataGroup['formIds']) {
          for (const formId of dataGroup['formIds']) {
            const formCachesGroup = from(this.formCachesService.getFormCache(formId)).subscribe();
            this.listSubscription['formCachesGroup']?.push(formCachesGroup);
            formCachePromises.push(this.formCachesService.getFormCache(formId));
          }
          await Promise.all(formCachePromises).then(async (dataFormCaches) => {
            const userIds = dataFormCaches.map((item: any) => item.length ? item[0]['userid'] : null).filter((item) => item).filter(onlyUnique);
            let formPromises = [];
            for (const userId of userIds) {
              formPromises.push(this.formService.getFormByGroupPaths(userId, path));
            }
            await Promise.all(formPromises).then((response) => {
              if (response.length) {
                this.forms = response.flat(2);
                this.originalForm = [...response.flat(2)];
              } else {
                this.forms = [];
              }
            })
          })
        } else {
          this.forms = [];
        }
      }
    })
    this.listSubscription['formListByGroup']?.push(formListByGroup);
    this.forms = this.forms.filter((item) => !item.tabId || item.tabId === 'null');
    this.isLoading = false
    this.ref.detectChanges()
    this.checkShowReadMore()
    await this.search()
  }

  setQueryParamFormSearch() {
    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
    const color = queryParamMap.get('color');
    const querySearch = queryParamMap.get('query_search');
    const startDate = queryParamMap.get('start_date');
    const endDate = queryParamMap.get('end_date');
    const groupId = queryParamMap.get('group_id');
    const tabId = queryParamMap.get('tab_id');

    if (color) this.formSearch.controls['color'].setValue(color);
    if (querySearch) this.formSearch.controls['querySearch'].setValue(querySearch);
    if (startDate) this.formSearch.controls['startDate'].setValue(startDate);
    if (endDate) this.formSearch.controls['endDate'].setValue(endDate);
    if (tabId) this.formSearch.controls['tabId'].setValue(tabId);
    if (groupId) this.formSearch.controls['groupId'].setValue(groupId); else localStorage.removeItem('permission');
  }

  async search() {
    this.forms = this.originalForm;
    const tabId = this.activatedRoute.snapshot.queryParamMap.get('tab_id');
    if (tabId && tabId.length) {
      this.forms = this.forms.filter((item) => item.tabId === tabId);
    } else {
      this.forms = this.forms.filter((item) => !item.tabId);
    }

    this.searchKeyWord();
    this.searchColor();
    this.searchDate();
    this.p = 1;
    this.ref.detectChanges();
    this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.SEARCH, EVENT_LOG_TYPE.SUCCESS);
  }

  searchKeyWord() {
    const querySearchValue = this.formSearch.value['querySearch'];
    if (querySearchValue?.length) {
      this.forms = this.forms.filter((data: any) => data.title.search(querySearchValue) != -1 || data.remark.search(querySearchValue) != -1);
    }
  }

  searchColor() {
    const colorValue = this.formSearch.value['color'];
    if (colorValue.length) {
      this.forms = this.forms.filter((data: any) => colorValue === data.colorCode);
    }
  }

  searchDate() {
    const startDate = this.formSearch.value['startDate'];
    const endDate = this.formSearch.value['endDate'];
    const dataDateStart = moment(startDate).format(formatDatePicker)
    const dataDateEnd = moment(endDate).format(formatDatePicker)
    if (startDate && endDate) {
      this.forms = this.forms.filter((data: any) => {
        const createdAt = data.createdAt.toDate();
        this.f['startDate'].setValue(dataDateStart);
        this.f['endDate'].setValue(dataDateEnd);
        return moment(createdAt).isBetween(dataDateStart, dataDateEnd, undefined, '[]');
      })
    }
  }

  onUncheckColor() {
    this.f['color'].setValue("");
    this.addParamToUrl('color', '');
  }

  toggleSearchBox() {
    this.visibleSearchBox = !this.visibleSearchBox;
  }

  addParamToUrl(param: string, value: string | string[] | null) {
    this.queryParams = { ...this.queryParams, [param]: value }
    const navigationExtras: NavigationExtras = {
      queryParams: this.queryParams,
    };
    this.router.navigate([], navigationExtras).then();
  }

  changeGroupSearch(selectedGroup: Group | null = null, tabId: string | null = null) {
    this.isLoading = true
    this.p = 1;
    const path = selectedGroup?.path ?? '';
    this.groupPath = path;
    const groupUserPath = this.groupService.getGroupUsersByPath(path).subscribe((res) => {
      res.forEach((item) => {
        const userData: any = item.payload.doc.data();
        if (userData.email === this.currentUser.email) localStorage.setItem('permission', userData.permission);
        this.permissionGroupConfig = getPermissionRoleGroupUser(userData.permission)
      })
    })
    this.listSubscription['groupUserPath']?.push(groupUserPath);

    this.formIds = null;
    this.deleteParamInUrl();

    if (selectedGroup) {
      this.addParamToUrl('group_id', path);
      this.getFormByGroups(path).then();
    } else if (tabId?.length) {
      this.addParamToUrl('tab_id', tabId);
      this.getFormByTab();
      localStorage.removeItem('permission');
    } else {
      this.getOwnerForm();
      localStorage.removeItem('permission');
    }
    this.checkShowReadMore();
    this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.CHANGE_GROUP_TAB, EVENT_LOG_TYPE.SUCCESS);
  }

  shareForm(form: any) {
    this.formSelected = form
    this.visibleModalShare = true
  }

  receiveShareModalStatus(event: boolean) {
    if (!event) {
      this.formSelected = null;
      this.visibleModalShare = false;
      const groupPath = this.activatedRoute.snapshot.queryParamMap.get('group_id');
      if (groupPath) {
        this.getFormByGroups(groupPath);
      }
    }
  }

  changeLangWithDatePicker(lang: string) {
    this.currentDatepickerLocale = getLocaleDatePicker(lang);
    this.f['startDate'].setValue(moment(this.formSearch.controls['startDate'].value ?? moment().startOf('year').format(formatDatePicker)).format(formatDataDate[lang]))
    this.f['endDate'].setValue(moment(this.formSearch.controls['endDate'].value ?? moment().endOf('year').format(formatDatePicker)).format(formatDataDate[lang]))
    this.ref.detectChanges()
  }

  get f() {
    // @ts-ignore
    return this.formSearch.controls;
  }

  deleteParamInUrl() {
    if (this.queryParams) {
      delete this.queryParams['tab_id'];
      delete this.queryParams['group_id'];
      const navigationExtras: NavigationExtras = {
        queryParams: this.queryParams,
      };
      this.router.navigate([], navigationExtras).then();
    }
  }

  getUnitString(form: any) {
    const formNodeUnit = form.nodes.nodes.meta.unit;
    if (formNodeUnit && formNodeUnit[this.translate.currentLang][0]) {
      return formNodeUnit[this.translate.currentLang][0];
    }
    return this.translate.instant('form.inputLabel.count');
  }

  handleLiveDemoChange(event: any) {
    this.visible = event;
    if (!event) this.btnDelete = false;
  }

  toggleModal(id: string) {
    this.id = id;
    this.visible = !this.visible;
  }

  async delete() {
    this.btnDelete = true;
    const currentForm = (await firstValueFrom(this.formService.getValueOwnerFormById(this.currentUser.uid, this.id)));
    if (!currentForm) {
      this.visible = !this.visible;
      this.btnDelete = false;
      this.showAlert(this.translate.instant('alertMessages.form.update.notExist'), false);
    }
    await this.formService.delete(this.currentUser, this.id).then(async () => {
      this.formCachesService.deleteFormCaches(this.currentUser.uid, this.id).then();
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.DELETE, EVENT_LOG_TYPE.SUCCESS);
      this.showAlert(this.translate.instant('alertMessages.form.delete.success'), true);
      if (this.deleteModal?.visible) this.deleteModal.visible = false
      this.btnDelete = false;
      this.visible = false;
      const groupPath = this.activatedRoute.snapshot.queryParamMap.get('group_id');
      if (groupPath) {
        this.getFormByGroups(groupPath);
      } else {
        this.getOwnerForm()
      }
      this.checkPaginate();
    }).catch((error) => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.DELETE, EVENT_LOG_TYPE.ERROR);
      this.showAlert(this.translate.instant('alertMessages.form.delete.fail'), false);
    });
  }

  goToEditForm(id: string) {
    this.router.navigate([`form/edit/${id}`], { state: this.formSearch.value }).then();
  }

  private showAlert(msg: string, errorType: boolean) {
    this.alertService.clear();
    if (errorType) {
      this.alertService.success(msg);
    } else {
      this.alertService.error(msg);
    }
  }

  async saveAsImage(form: any) {
    const formDataSubscription = this.formService.getValueFormDataList(form.user_id, form.doc_id).subscribe({
      next: (listFormData) => {
        let numberOfRecords: number = listFormData.length;
        const isMemberNode = form.nodes.nodes.objects.filter((item: any) => item.name === 'members');
        if (isMemberNode.length) numberOfRecords = getTotalPropertyValue('members', listFormData);
        this.formService.getImageQrUrl(numberOfRecords, form).then(async (dataUrl) => {
          const image = this.formService.getBase64QrCode(dataUrl);
          const link = document.createElement('a');
          link.href = image;
          link.download = `${form.title}_qr`;
          link.click();
          this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.DOWNLOAD_QR_IMAGE, EVENT_LOG_TYPE.SUCCESS);
        }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.DOWNLOAD_QR_IMAGE, EVENT_LOG_TYPE.ERROR))
      }
    })
    this.listSubscription['formDataList']?.push(formDataSubscription);
  }

  toggleReadMore(event: any) {
    const clickedButton = event.target;
    const siblingElement = clickedButton.previousElementSibling;
    siblingElement.classList.toggle('remark-block__open')
    this.translate.stream('common.longText.showMore').subscribe((text) => {
      clickedButton.textContent = text;
    });
    if (hasClass(siblingElement, 'remark-block__open')) {
      this.translate.stream('common.longText.showLess').subscribe((text) => {
        clickedButton.textContent = text;
      });
    }
  }

  pageChange(page: number) {
    this.p = page
    this.checkShowReadMore();
  }

  checkShowReadMore() {
    this.ref.detectChanges()
    const elements = document.getElementsByClassName('remark-block');
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const getDocId = element.getAttribute('data-id');
      if (element.clientHeight && element.clientHeight > LIMIT_HEIGHT_DISPLAY_SHOW_MORE && getDocId) {
        this.dataShowToggleReadMore[getDocId] = true;
      }
      this.renderer2.setStyle(element, 'max-height', MAX_HEIGHT_REMARK_BLOCK + 'px');
    }
  }

  checkPaginate() {
    const listFormLeftOver = Math.ceil(this.forms.length / this.itemPerPage);
    const checkItemLength = this.forms.length > (this.itemPerPage * this.p);
    if (this.p != listFormLeftOver && !checkItemLength) {
      this.p = listFormLeftOver;
    }
  }

  checkOwnerForm(userOwnerId: string) {
    return this.currentUser.uid == userOwnerId
  }

  goToQrLink(form: any) {
    const formDataSubscription = this.formService.getValueFormDataList(form.user_id, form.doc_id).subscribe({
      next: (listFormData) => {
        const isMemberNode = form.nodes.nodes.objects.filter((item: any) => item.name === 'members');
        let numberOfRecords: number = listFormData.length;
        if (isMemberNode.length) numberOfRecords = getTotalPropertyValue('members', listFormData);
        this.formService.getImageQrUrl(numberOfRecords, form, true).then((url) => {
          let a = document.createElement('a');
          a.href = url;
          a.target = "_blank";
          a.click();
          this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.REDIRECT_QR_LINK, EVENT_LOG_TYPE.SUCCESS);
        }).catch(error => this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.REDIRECT_QR_LINK, EVENT_LOG_TYPE.ERROR));
      }
    })
    this.listSubscription['formDataList']?.push(formDataSubscription);
  }

  copyQrLink(form: any) {
    this.clipboard.copy(this.getQrLink(form));
    this.isCopied = true;
  }

  getQrLink(form: any) {
    let hostname = text.defaultDomain;
    if (this.currentUser.hasOwnProperty('hostname') && this.currentUser.hostname) {
      hostname = this.currentUser.hostname;
    }
    return `https://${hostname}/#/form-guest/${form.doc_id}`
  }

  setDefaultCopyText() {
    setTimeout(() => {
      this.isCopied = false;
    }, 300)
  }

  goToCreateForm() {
    this.router.navigate(['form/create'], { state: this.formSearch.value }).then();
  }

  protected readonly getLongText = getLongText;
  protected readonly dayFormat = dayFormat;
  protected readonly Boolean = Boolean;
  protected readonly itemPerPage = ITEM_PER_PAGE
}
