import { Component, OnInit } from '@angular/core';
import {environment} from "@environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-non-account',
  templateUrl: './non-account.component.html',
  styleUrls: ['./non-account.component.scss']
})
export class NonAccountComponent implements OnInit {
  language: string = ''
  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.language = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((e) => {
      this.language = e.lang
    })
  }

  protected readonly environment = environment;
}
