<c-card class="card-no-border account-card">
  <form class="form-create" [formGroup]="formEdit" novalidate>
    <div class="mb-3" *ngIf="formEdit.controls?.['showVideo']">
      <c-col class="mb-1">{{ "formEdit.videoTab.inputLabel.showVideo.title" | translate }}</c-col>
      <c-input-group>
        <c-form-check inline>
          <input cFormCheckInput checked id="showVideoTrue" [value]="true" formControlName="showVideo" type="radio" />
          <label cFormCheckLabel for="showVideoTrue">{{ "formEdit.videoTab.inputLabel.showVideo.use" | translate }}</label>
        </c-form-check>
        <c-form-check inline>
          <input cFormCheckInput id="showVideoFalse" [value]="false" formControlName="showVideo" type="radio" />
          <label cFormCheckLabel for="showVideoFalse">{{ "formEdit.videoTab.inputLabel.showVideo.notUse" | translate }}</label>
        </c-form-check>
      </c-input-group>
    </div>
    <div class="mb-3" *ngIf="formEdit.controls?.['videoDescription']">
      <c-col class="mb-1">{{'formEdit.videoTab.inputLabel.description' | translate }}</c-col>
      <c-input-group>
        <editor formControlName="videoDescription" [init]="initEditor"></editor>
      </c-input-group>
    </div>
    <div formArrayName="videoList">
      <div class="mb-3" *ngFor="let video of formEdit.controls.videoList.controls; let videoIndex = index">
        <c-col class="mb-1">{{ "formEdit.videoTab.inputLabel.video" | translate }}{{ videoIndex + 1 }}</c-col>
        <c-input-group>
          <textarea
            cFormControl
            rows="3"
            [formControlName]="videoIndex"
            (input)="checkVideoError()"
          ></textarea>
        </c-input-group>
        <c-form-check class="text-danger" *ngIf="video?.errors?.required && (submitAllTab || submitted)">
          {{ 'errorMessages.formValidate.videoTab.required' | translate: {index: (videoIndex + 1)} }}
        </c-form-check>
      </div>
    </div>
    <div *ngIf="formEdit.controls?.['useVideo']">
      <c-col class="mb-1">{{ "formEdit.videoTab.inputLabel.useVideo" | translate }}</c-col>
      <c-form-check inline>
        <input
          cFormCheckInput
          [id]="0"
          name="useVideo"
          formControlName="useVideo"
          type="radio"
          [value]="0"
          (change)="checkVideoError()"
        />
        <label cFormCheckLabel [for]="0">{{("formEdit.videoTab.optionLabel.random" | translate)}}</label>
      </c-form-check>
      <c-form-check inline *ngFor="let item of videoList; let i = index">
        <input
          cFormCheckInput
          [id]="i + 1"
          name="useVideo"
          formControlName="useVideo"
          type="radio"
          [value]="i + 1"
          (change)="checkVideoError()"
        />
        <label cFormCheckLabel [for]="i + 1">{{("formEdit.videoTab.inputLabel.video" | translate) + (i + 1) }}</label>
      </c-form-check>
    </div>
    <c-form-check class="text-danger" *ngIf="formEdit.controls.useVideo?.errors?.['requiredAll']&& (submitAllTab || submitted)">{{'errorMessages.formValidate.videoTab.requiredAll' | translate}}</c-form-check>
    <div class="mb-3 d-flex justify-content-end">
      <button (click)="backToFormList()" class="back-btn">
        {{ "form.backButton" | translate }}
      </button>
      <button
        cButton
        type="submit"
        (click)="submit()"
        [class]="{
          'btn-submit': true,
          'btn-submit-disabled': isOutDate
        }"
      >
        {{ "form.inputLabel.submitChange" | translate }}
      </button>
    </div>
  </form>
</c-card>
