import { Component, OnInit } from '@angular/core';
import { FirebaseAnalyticService, FormCachesService, FormService } from "@app/services";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { FormArray, FormBuilder, FormControl } from "@angular/forms";
import {
  DataType, DATA_EXAM_MIN_INDEX, EVENT_LOG_TYPE,
  SHOW_FIELDS_EXAM,
  text, PREFIX_LIST, WATCH_VIDEO_AGAIN_URL, DEFAULT_ALERT_TYPE, ACTION_LIST, STYLE_BUTTON_TBC, STYLE_STRING_TBC
} from "@app/constants";
import {
  checkColorBg,
  compareArrayWithPosition,
  getInfoDataByKey,
  getRandomInt,
  isDisplayTargetDate,
} from "@app/helpers";
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit {
  isDisplayTargetDate = isDisplayTargetDate;
  protected Number = Number;
  protected readonly Boolean = Boolean;
  protected readonly String = String;
  protected readonly DataType = DataType;
  protected readonly SHOW_FIELDS_EXAM = SHOW_FIELDS_EXAM;
  protected readonly WATCH_VIDEO_AGAIN_URL = WATCH_VIDEO_AGAIN_URL;
  protected readonly STYLE_BUTTON_TBC = STYLE_BUTTON_TBC;
  protected readonly STYLE_STRING_TBC = STYLE_STRING_TBC;
  checkColorBg = checkColorBg;
  exam: any;
  form: any;
  formData: any;
  formTest = this.formBuilder.group({
    testResult: this.formBuilder.array<any>([])
  });
  dataShow: Array<{ value: string, key: string }> = [];
  buttonDisabled: boolean = false
  calloutStatus: string = '';
  allowExam: boolean = false;
  prefixForm: string = '';
  currentLang: string = '';
  isFailExam: boolean = false;
  isPassExam: boolean = false;
  authUser: any;
  formId: string | null = null;
  authUserId: string | null = null;

  constructor(
    private formService: FormService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private router: Router,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formCachesService: FormCachesService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser();
    this.currentLang = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = e.lang
    })
  }

  getCurrentUser() {
    this.formId = this.activatedRoute.snapshot.params['formId'];
    // update by Tanaka on 2023/08/23
    this.formCachesService.getDetailFormCache(this.formId ?? '').subscribe({
      next: (formCacheValue: any) => {
        if (formCacheValue.length) {
          this.authUserId = formCacheValue[0]?.['userid'];
          this.initExam();
          this.getFormData();
          }
      }
    })
  }

  initExam() {
    this.formId = this.activatedRoute.snapshot.paramMap.get('formId');
    // update by Tanaka on 2023/08/23
    this.formService.getValueOwnerFormById(this.authUserId ?? '', this.formId ?? '').subscribe((response) => {
      this.form = response;
      this.prefixForm = this.form.nodes.nodes.meta.prefix;
      const selectedExam = this.form.useTest
      let dataRandomIndex: any = []
      if (this.router.url.includes("exam"))
        document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
        document.getElementById('form-guest-body')!.style.backgroundColor = text.defaultBackgroundCode;
        if (event.url.includes("exam"))
          document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
      });
      if (this.form && this.form?.hasOwnProperty('testForm')) {
        if (!Boolean(selectedExam)) {
          const dataRandom = this.form.testForm.filter((item: any) => {
            const hasNonEmptyAnswerList = item.questions.every((question: any) => {
              return (question.answerList && question.answerList.length > 0
                && question.answerList.every((answer: any) => answer.en && answer.ja)
              );
            });
            const hasNonEmptyFields = item.questions.every((question: any) => {
              return question.question.en && question.question.ja && question.correct && question.correct.length > 0;
            });
            return hasNonEmptyAnswerList && hasNonEmptyFields;
          });
          dataRandomIndex = dataRandom.map((item: any) => item.index);
        }
        const indexDataExam = !Boolean(selectedExam) ? dataRandomIndex[getRandomInt(DATA_EXAM_MIN_INDEX, dataRandomIndex.length - 1)] : selectedExam;
        this.exam = this.form.testForm.filter((exam: any) => exam.index == indexDataExam)[0];
        this.exam.questions.forEach((item: any, key: number) => {
          const answers = new FormArray<FormControl>([])
          item.answerList.forEach((_: any, index: number) => {
            answers.push(new FormControl())
          });
          (this.formTest.controls['testResult'] as FormArray).push(answers)
        })
      }
      this.checkValidExam()
    });
  }

  getFormData() {
    const formDataId = this.activatedRoute.snapshot.paramMap.get('formDataId');
    if (formDataId) {
      // update on Tanaka on 2023/08/23
      this.formService.getOwnerFormDataById(this.authUserId ?? '', this.formId ?? '', formDataId).subscribe((response) => {
        this.formData = response;
        SHOW_FIELDS_EXAM.forEach((item: string, index) => {
          this.dataShow[index] = {
            value: this.formData[item],
            key: item
          };
        })
        const listStatus = this.form.nodes.nodes.objects.filter((item: any) => item.name === 'status')?.[0]?.value;
        const statusPassed = listStatus[listStatus.length - 1];
        this.allowExam = (listStatus[0] !== this.formData.status) || this.form.showExam
        const redirectToCertificate = this.formData.status === statusPassed
        const noRedirect = this.activatedRoute.snapshot.queryParamMap.get('not_redirect')
        if ((!this.form.showExam || redirectToCertificate) && !noRedirect?.length) {
          if (!redirectToCertificate) {
            this.formService.updateFormDataStatus(this.form.user_id, this.form.doc_id, this.formData.entryno, { status: statusPassed }).then(() => {
              this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_STATUS, EVENT_LOG_TYPE.SUCCESS);
            }).catch(() => {
              this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_STATUS, EVENT_LOG_TYPE.ERROR);
            })
          }
          this.router.navigate([`plugin-tbc/${this.form.doc_id}/${this.formData.entryno}/certificate`])
        }
        this.isPassExam = statusPassed === this.formData.status;
      })
    }
  }

  checkValidExam() {
    const dataExam: any = this.formTest.controls['testResult'].controls
    const dataAnswers = []
    for (const examKey in dataExam) {
      const answer = dataExam[examKey].value.filter((item: any) => !!item)
      dataAnswers.push(answer)
    }
    this.buttonDisabled = dataAnswers.every(subArray => subArray.length === 0);
  }

  changeDataAnswer(questionIndex: number, datatype: string, answerIndex: number) {
    const dataControl: any = this.formTest.controls['testResult'].controls[questionIndex];
    let newData = []
    if (datatype === DataType.radio) {
      newData = dataControl?.value.map((item: any, index: number) => {
        return answerIndex === index
      });
    } else if (datatype === DataType.check) {
      newData = dataControl?.value.map((item: any, index: number) => {
        return !!item
      });
    }
    dataControl.setValue(newData)
    this.checkValidExam()
  }

  submit() {
    const dataAnswers: Array<any> = this.formTest.controls.testResult.value
    const testResult: { result: any, scores: number, examIndex: number } = {
      result: { ...dataAnswers },
      scores: 0,
      examIndex: this.exam.index
    }
    let scores = 0;
    const listStatus = this.form.nodes.nodes.objects.filter((item: any) => item.name === 'status')?.[0]?.value;
    const statusPassed = listStatus[listStatus.length - 1];
    const formData = this.formData;

    this.exam.questions.forEach((item: any, index: number) => {
      if (compareArrayWithPosition(item.correct, dataAnswers[index])) scores++
    })
    testResult.scores = scores;
    formData.testResult = testResult;
    const scorePercent = scores * 100 / this.exam.questions.length;
    this.isPassExam = scorePercent >= this.form.passingScore || this.formData.status === statusPassed
    formData.status = this.isPassExam ? statusPassed : formData.status;
    formData.passingDate = this.isPassExam ? (this.formData?.passingDate ?? new Date()) : null;

    this.formService.createdOrUpdateExam(this.form, formData).then(() => {
      this.calloutStatus = DEFAULT_ALERT_TYPE.success;
      this.isFailExam = false;
      if (scorePercent < this.form.passingScore) {
        this.calloutStatus = DEFAULT_ALERT_TYPE.error;
        this.isFailExam = true;
      }
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_TEST_RESULT, EVENT_LOG_TYPE.SUCCESS)
    }).catch((e: any) => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.UPDATE_TEST_RESULT, EVENT_LOG_TYPE.ERROR)
    })
  }

  getFormInfo(key: string) {
    return getInfoDataByKey(this.form, key, this.translateService.currentLang)
  }

  protected readonly PREFIX_LIST = PREFIX_LIST;
}
