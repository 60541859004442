import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { showButtonReissue } from "@app/constants";
import { environment } from "@environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-header-guest',
  templateUrl: './header-guest.component.html',
  styleUrls: ['./header-guest.component.scss']
})
export class HeaderGuestComponent implements OnInit {
  @Input() isFormUser: boolean = false;
  isShowReissueButton: boolean = false
  currentLanguage: string = ''

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkActiveRouteShowReissue()
      }
    });
  }

  ngOnInit(): void {
    this.checkActiveRouteShowReissue()
    this.currentLanguage = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLanguage = e.lang
    })
  }

  checkActiveRouteShowReissue() {
    const currentRoute = this.activatedRoute?.routeConfig?.path;
    this.isShowReissueButton = showButtonReissue.includes(currentRoute!)
  }

  protected readonly environment = environment;
}
