import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getFirstError, setValueOfDeepData, synchronizeValuesOfDeepData } from "@app/helpers";
import { RuleValidations, TYPE_INPUT } from "@app/constants";

@Component({
  selector: 'app-link-line',
  templateUrl: './link-line.component.html',
  styleUrls: ['./link-line.component.scss']
})
export class LinkLineComponent implements OnChanges {
  @Input() form: any;
  @Input() submitted: boolean = false;
  showErrors: {[key: string | number]: any} = {}
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.showErrors = this.setValuesToFalse(this.form.value, false);
  }
  setValuesToFalse(obj: any, valueChange: any): any {
    return synchronizeValuesOfDeepData(obj, false)
  }

  changeShowErrors(...keys: any[]) {
    this.showErrors = setValueOfDeepData(this.showErrors, keys, true)
  }

  protected readonly getFirstError = getFirstError;
  protected readonly TYPE_INPUT = TYPE_INPUT;
  protected readonly RuleValidations = RuleValidations;
}
