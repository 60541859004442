import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import {
  AddressSearchService,
  AlertService,
  FirebaseAnalyticService,
  FormCachesService,
  FormService,
  OpenAiService,
  UserService
} from "@app/services";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import {
  ADDRESS_SEARCH_RESULT_LIMIT,
  DataType,
  RuleValidations,
  formatHoursAndSecond,
  formatDatePicker,
  MAIL_TYPE_LIST,
  EVENT_LOG_TYPE,
  text,
  INIT_EDITOR,
  ACTION_LIST,
  PREFIX_LIST,
  MIN_MAX_VALUE_DATA_TYPE_LIST,
  InputType,
  CAR,
  INPUT_TYPE_FILE,
  DataName,
  INPUT_TYPE_DATE_TIME,
  LIMIT_GENERATE_IMAGES_OPEN_AI,
  CHAT_COMPLETION_MODEL,
  ChatCompletionErrorCode,
} from "@app/constants"
import {
  changeInputNum,
  checkDataTypeFile,
  checkInputEnter,
  convertMbToB,
  dataURLtoFile,
  dayFormat,
  filterAddressInPhoneTable,
  formatDateString,
  formatUnitNumber,
  getConditionShowInputError,
  getEmailNode,
  getEntryno,
  getFirstError,
  getLocaleDatePicker, getNumberInString, getPlaceholderForm, getTotalPropertyValue, setMemberRequiredLabel, setMultipleErrors,
  setValidate,
  setValidateMember
} from "@app/helpers";
import * as moment from "moment";
import { MapTranslateTextPipe } from '@app/pipe/map-translate-text.pipe';
import { CustomValidatorsService } from '@app/services/custom-validators.service';

@Component({
  selector: 'app-form-user',
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.scss']
})
export class FormUserComponent implements OnInit, OnDestroy {
  public dropdownList: any[] = []
  public selectedData: any = {};
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'すべて',
    unSelectAllText: 'すべて',
    itemsShowLimit: 3,
    allowSearchFilter: false
  };
  public locales: any;
  public isShowLocales: boolean = false;
  isShowAddressByZipcode: boolean = false;
  submitted = false;
  form: any;
  formId: string = '';
  formNode: any;
  formMeta: any;
  formCreate = new FormGroup<any>({});
  error: boolean = false;
  currentDatepickerLocale: string = "";
  currentLanguage = this.translateService.currentLang;
  isSubmitted: boolean = false;
  dataNodeStatus: any;
  displayName = '';
  searchZipcode = '';
  searchAddress = '';
  locationaddressLatitude = ''
  locationaddressLongitude = ''
  numberUserCar: number = 0;
  totalMemberValidate: number = 1;
  phoneTableList: Array<any> = [];
  prefixForm: string = '';
  queryParams: any;
  formCacheValue: any;
  currentUser: any;
  subscription: { [key: string]: Subscription | null } = {
    formCaches: null,
    form: null,
    formData: null,
    formDataByHowToVisit: null
  }
  numberOfRecords: number = 0;
  visibleTranslateModal: boolean = false;
  isLoadingSubmit: boolean = false;
  isLoadingSendChatCompletion: boolean = false;
  isDisabledSendChatCompletion: boolean = true;
  isLoadingFileSoundToText: boolean = false;
  isDisabledTranscriptionFile: boolean = true;
  isDisabledTranslateText: boolean = true;
  isLoadingGenerateImages: boolean = false;
  isDisabledGenerateImages: boolean = true;
  isDisabledSubmitOnCallOpenAiAction: boolean = false;
  isToggleTranslateButtonColor: boolean = true;
  limitPromptTokens: number = 0;
  isTokenExceedChatCompletion: boolean = false;
  countToken: number = 0;
  chatContent: Array<any> = [];

  constructor(
    private formService: FormService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UserService,
    private addressSearchService: AddressSearchService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formCachesService: FormCachesService,
    private openAiService: OpenAiService,
    private renderer2: Renderer2
  ) {
    this.queryParams = this.router.getCurrentNavigation()?.extras.state?.['queryParams'];
  }

  public ngOnInit() {
    this.generateForm().then();
    this.checkDropdownOptionLang().then();
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.translateService.onLangChange.subscribe((e) => {
      this.checkDropdownOptionLang().then();
      this.changeSelectMultiple()
      this.currentDatepickerLocale = getLocaleDatePicker(e.lang);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscription['formCaches']?.unsubscribe();
    this.subscription['form']?.unsubscribe();
    this.subscription['formDataByHowToVisit']?.unsubscribe();
    this.subscription['formData']?.unsubscribe();
  }

  async generateForm() {
    this.route.params.subscribe(params => {
      this.formId = params['id'];
    })
    this.getPhoneTableList().then();
    this.getDataFormCache();
  }

  async getPhoneTableList() {
    this.phoneTableList = await firstValueFrom(this.formService.getPhoneTableList());
  }

  getDataFormCache() {
    this.subscription['formCaches'] = this.formCachesService.getDetailFormCache(this.formId).subscribe({
      next: (formCacheValue) => {
        if (formCacheValue.length) {
          this.formCacheValue = formCacheValue[0];
          this.getDataCurrentUser().then();
          this.getNumberUseCar();
        }
      }
    })
  }

  async getDataCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.currentUser = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (this.currentUser) this.getDataForm();
    }
  }

  getDataForm() {
    this.subscription['form'] = this.formService.getValueOwnerFormById(this.formCacheValue.userid, this.formId).subscribe(async (response: any) => {
      this.form = response;
      this.formMeta = this.form?.nodes.nodes.meta;
      this.formNode = this.form?.nodes.nodes.objects;
      this.prefixForm = this.formMeta.prefix;
      this.formNode = this.formNode?.filter((data: any) => {
        if (data.name == 'status') this.dataNodeStatus = data;
        return data.name != 'entryno' && data.name != 'status' && !data?.hidden;
      })
      this.getTotalFormData();
      this.checkFormOutDate();
      this.setValidateFormControl();
      const uidNode = this.formNode.filter((item: any) => item.datatype == DataType.uid)[0]
      if (uidNode) {
        this.formCreate.addControl('ownerFormData', new FormControl(this.displayName))
      } else {
        this.formCreate.addControl('userid', new FormControl(this.currentUser.uid))
      }
      setValidateMember('members', this.form, this.formCreate);
    })
  }

  getTotalFormData() {
    this.subscription['formData'] = this.formService.getValueFormDataList(this.formCacheValue.userid, this.formCacheValue.formid).subscribe({
      next: (listFormData) => {
        const isMemberNode = this.formNode.filter((item: any) => item.name === 'members');
        this.numberOfRecords = listFormData.length;
        if (isMemberNode.length) this.numberOfRecords = getTotalPropertyValue('members', listFormData);
      }
    })
  }

  getNumberUseCar() {
    this.subscription['formDataByHowToVisit'] = this.formService.getOwnerFormDataByHowToVisit(this.formCacheValue.userid, this.formId).subscribe({
      next: (formDataValue) => {
        if (formDataValue.length) this.numberUserCar = formDataValue.length;
      }
    })
  }

  translateFormTitle(data: any) {
    if (data.datatype === DataType.radio) {
      data.titleselect = data.candidateData.en;
    }
    if (data.datatype === DataType.check) {
      const dataList: { item_id: any; item_text: any; }[] = [];
      data.candidateData.en.forEach((value: any, key: number) => {
        dataList.push({
          item_id: data.value[key],
          item_text: new MapTranslateTextPipe(this.translateService).transform(value, data.name, data.candidateData)
        });
      });
      this.dropdownList[data.name] = dataList;
    }
  }

  checkFormOutDate() {
    const targetDate = new Date(this.form.targetDate.seconds * 1000);
    const currentDate = new Date();
    const currentMonth = formatUnitNumber(currentDate.getMonth() + 1);
    const targetMonth = formatUnitNumber(targetDate.getMonth() + 1);
    const current = formatDateString(currentDate, currentMonth);
    const target = formatDateString(targetDate, targetMonth);

    if (new Date(current).getTime() > new Date(target).getTime()) {
      this.alertService.error(this.translateService.instant('alertMessages.formUser.outDate'));
      this.error = true;
    }
  }

  setValidateFormControl() {
    this.formNode.forEach((node: any, key: number) => {
      let dataCheckedRadio: any = null
      this.translateFormTitle(node);
      this.translateService.onLangChange.subscribe((e) => {
        this.currentLanguage = e.lang;
        this.translateFormTitle(node);
      });
      if (node.datatype === DataType.radio && (node.required || node.hidden)) {
        dataCheckedRadio = node.titleselect[0];
        if (node.name === 'members') dataCheckedRadio = node.value[0];
      }
      const validate: ValidatorFn | ValidatorFn[] | null | undefined = [];
      if (node.required && !node.readonly) {
        validate.push(Validators.required)
        validate.push(CustomValidatorsService.notAllowedOnlySpace)
      }

      if (node.datatype == DataType.link) {
        validate.push(Validators.pattern(RuleValidations.REGEX_LINK))
      }

      if (node.validationCondition && Boolean(node.validationCondition.length) && (node.datatype == DataType.text || node.datatype === DataType.email) && node.type == DataType.input) {
        validate.push(Validators.pattern(RuleValidations[node.validationCondition as keyof typeof RuleValidations]))
      }

      if (Boolean(node?.validation?.length)) {
        const [minValue, maxValue, minLength, maxLength] = node.validation;
        if ([DataType.input, DataType.label].includes(node.type)) {
          if (minLength >= 0) {
            if (node.datatype === DataType.html) validate.push(CustomValidatorsService.minLengthHtml(minLength));
            else validate.push(Validators.minLength(minLength));
          }

          if (node.datatype != DataType.email && maxLength >= 0) {
            if (node.datatype === DataType.html) validate.push(CustomValidatorsService.maxLengthHtml(maxLength));
            else validate.push(Validators.maxLength(maxLength));
          }
        }

        if (MIN_MAX_VALUE_DATA_TYPE_LIST.includes(node.datatype)) {
          if (minValue >= 0) validate.push(Validators.min(minValue));
          if (maxValue >= 0) validate.push(Validators.max(maxValue));
        }
      }

      if (!node.hidden || (node.hidden && node.datatype == DataType.radio)) {
        if (node.name === 'model') {
          if (dataCheckedRadio === CHAT_COMPLETION_MODEL.gpt4.name) {
            this.limitPromptTokens = CHAT_COMPLETION_MODEL.gpt4.limitTokens;
          } else {
            this.limitPromptTokens = CHAT_COMPLETION_MODEL.gpt3Turbo.limitTokens;
          }
        }
        this.formCreate.addControl(node.name, new FormControl(this.getInitValueForm(node, dataCheckedRadio), { validators: validate }))
      }
    })
  }

  getInitValueForm(data: any, dataCheckedRadio: any) {
    switch (data.datatype) {
      case DataType.radio: {
        return dataCheckedRadio;
      }
      case DataType.uid: {
        this.displayName = this.currentUser.name
        return this.currentUser.uid;
      }
      case DataType.slider: {
        return 0;
      }
      default: {
        return '';
      }
    }
  }

  async submit() {
    if (!this.form) {
      this.router.navigate(['form'], { state: { create: true } }).then(() => {
        this.alertService.error(this.translateService.instant('alertMessages.form.update.notExist'));
      });
      return;
    }
    if (this.form.archive) {
      this.router.navigate([`form/${this.formId}/form-data`]).then();
      this.alertService.error(this.translateService.instant('alertMessages.form.update.archive'));
      return
    }
    this.submitted = true;
    let date = new Date(this.form.targetDate.seconds * 1000)
    this.form.date = `${date.getFullYear()}\/${date.getMonth() + 1}\/${date.getDate()}`;
    const formattedMaxRecord = Number(this.form.maxRecords);
    let dataInsert: any = this.formCreate.value;
    if (this.numberOfRecords >= formattedMaxRecord) {
      this.alertService.error(this.translateService.instant('alertMessages.formUser.exceedMaxNumberRegister'));
      this.error = true;
      return;
    }

    const members = getNumberInString(dataInsert.members);
    if (members > formattedMaxRecord - this.numberOfRecords) {
      this.alertService.error(this.translateService.instant('alertMessages.formUser.exceedMaxNumberRegister'));
      this.isLoadingSubmit = false;
      return;
    }
    if (this.form.maximumNumberOfParkingLots && this.numberUserCar >= this.form.maximumNumberOfParkingLots && this.formCreate.controls['howtovisit'].value === CAR) {
      this.alertService.error(this.translateService.instant("alertMessages.formUser.register.maxNumberParkingLot"));
      this.isLoadingSubmit = false;
      return;
    }

    if (this.formCreate.valid) {
      this.isLoadingSubmit = true;
      this.isDisabledTranscriptionFile = true;
      this.isDisabledTranslateText = true;
      this.isDisabledGenerateImages = true;

      for (const key in this.formNode) {
        if (this.formNode[key].datatype == DataType.check) {
          dataInsert[this.formNode[key].name] = Boolean(dataInsert[this.formNode[key].name]) ? dataInsert[this.formNode[key].name] : []
        } else if (this.formNode[key].datatype == DataType.time) {
          dataInsert[this.formNode[key].name] = dataInsert[this.formNode[key]?.name] ? moment(dataInsert[this.formNode[key]?.name]).format(formatHoursAndSecond) : null
        } else if (this.formNode[key].datatype == DataType.date) {
          dataInsert[this.formNode[key].name] = dataInsert[this.formNode[key]?.name] ? moment(dataInsert[this.formNode[key]?.name]).format(formatDatePicker) : null
        }
      }
      const emailNode = getEmailNode(this.formNode);
      if (emailNode && dataInsert[emailNode.name]) {
        dataInsert[emailNode.name] = dataInsert[emailNode.name].toLowerCase();
      }
      dataInsert.formId = this.formId;
      dataInsert.createdAt = new Date();
      dataInsert.option = Boolean(dataInsert.option?.length) ? dataInsert.option : []
      dataInsert.entryno = getEntryno();
      this.formNode.find((item: any) => {
        if (item.datatype == DataType.radio && item.hidden) {
          dataInsert[item.name] = this.formCreate.value[item.name]
        }
      })
      dataInsert.status = this.dataNodeStatus?.candidateData.en[0] ?? null;
      const hostName = this.currentUser?.hostname ?? text.defaultDomain;
      const image = this.formService.getBase64QrCode(this.formService.dataStringBase64(this.form, dataInsert.entryno, hostName));
      dataInsert.img = await this.formService.uploadFilePublicStorage(this.form, dataInsert.entryno, image, true);
      const inputFileList = this.formNode.filter((item: any) => INPUT_TYPE_FILE.includes(item.datatype));
      if (Boolean(inputFileList.length)) {
        for (const item of inputFileList) {
          if (this.formCreate.controls[item.name].value && typeof this.formCreate.controls[item.name].value != 'string') {
            dataInsert[item.name] = await this.formService.uploadFilePublicStorage(this.form, dataInsert.entryno, this.formCreate.controls[item.name].value);
          }
        }
      }
      if (this.prefixForm === PREFIX_LIST.pluginTbc) {
        dataInsert = {
          ...dataInsert,
          customercode: filterAddressInPhoneTable(this.phoneTableList, dataInsert.address, 'prefectureNo')
        };
      }
      this.router.navigate([`form/${this.formId}/form-data`]).then();
      this.formService.saveFormUserData(dataInsert, this.form?.user_id, this.formId).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.REGISTER_USER_FORM, EVENT_LOG_TYPE.SUCCESS);
        if (emailNode && dataInsert[emailNode.name] && this.form.isSendMailFormRegist) {
          this.formService.sendMailFormUser(dataInsert, this.form, MAIL_TYPE_LIST.mailRegist, dataInsert.entryno, hostName).then(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_MAIL.REGISTER_USER_FORM_DATA, EVENT_LOG_TYPE.SUCCESS);
          }).catch(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_MAIL.REGISTER_USER_FORM_DATA, EVENT_LOG_TYPE.ERROR);
          });
        }
        this.isLoadingSubmit = false;
        this.isDisabledTranscriptionFile = false;
        this.isDisabledTranslateText = false;
        this.isDisabledGenerateImages = false;
        this.isSubmitted = true;
        this.alertService.success(this.translateService.instant('alertMessages.formUser.register.success'));
      }).catch((e) => {
        this.isLoadingSubmit = false;
        this.isDisabledTranscriptionFile = false;
        this.isDisabledTranslateText = false;
        this.isDisabledGenerateImages = false;
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.REGISTER_USER_FORM, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.formUser.register.fail'));
      });
    }
  }

  setErrorMaxLength(event: any) {
    const inputValue = event.target.value;
    const value = this.formCreate.get('value');
    if (inputValue.toString().length >= RuleValidations.maxLength17) {
      value?.setErrors({ maxlength: true });
    }
  }

  async checkDropdownOptionLang() {
    const selectAllText = await firstValueFrom(this.translateService.stream('form.inputLabel.selectAllText'));
    this.dropdownSettings = { ...this.dropdownSettings, selectAllText: selectAllText, unSelectAllText: selectAllText }
  }

  backToIndexForm() {
    this.route.params.subscribe((params: any) => {
      this.router.navigate([`/form/${params.id}/form-data`], { queryParams: this.queryParams }).then();
    })
  }

  changeSelectMultiple() {
    if (this.formNode) {
      const dataAll = this.formNode.filter((item: any) => {
        return item.datatype === DataType.check;
      });

      for (const key in this.selectedData) {
        if (this.selectedData[key]) {
          const dataSelected = this.selectedData[key].map((item: any, index: number) => {
            const dataItem = dataAll.filter((item: any) => item.name == key)[0]
            const keyData = dataItem.value.indexOf(item.item_id);
            const candidateData = this.form.nodes.nodes.objects.filter((item: any) => item.name == key)[0]['candidateData']
            item.item_text = new MapTranslateTextPipe(this.translateService).transform(dataItem.titleselect[keyData], key, candidateData)
            return item;
          });
          this.formCreate.controls[key].setValue(dataSelected);
        }
      }
    }
  }

  handleFile(event: any, formName: string, typeFile: string) {
    this.isDisabledTranscriptionFile = true;
    const node = this.formNode.filter((item: any) => item.name === formName);
    const file: File = event.target.files[0];
    const previewFileName: HTMLElement = event.target.previousElementSibling;
    previewFileName.textContent = file.name;
    let fileHasError = false;
    if (!(new RegExp(RuleValidations.regexFile[typeFile as keyof typeof RuleValidations.regexFile]['mime']).test(file.type))
      && typeFile != DataType.file) {
      this.formCreate.controls[formName].setErrors({ mimetype: true })
      fileHasError = true
    }
    if (this.prefixForm === PREFIX_LIST.pluginWhisper
      && node[0].datatype === DataType.sound
      && file.size > convertMbToB(RuleValidations.regexFile[typeFile as keyof typeof RuleValidations.regexFile]['maxFileSizePluginWhisper'])) {
      this.formCreate.controls[formName].setErrors({ maxSizePluginWhisper: true })
      fileHasError = true
    } else if (file.size > convertMbToB(RuleValidations.regexFile[typeFile as keyof typeof RuleValidations.regexFile]['maxFileSize'])) {
      this.formCreate.controls[formName].setErrors({ maxSize: true })
      fileHasError = true
    }
    if (!fileHasError) {
      this.formCreate.controls[formName].setErrors(null);
      this.formCreate.controls[formName].setValue(file ?? null);
      if (RuleValidations.regexFile['sound']['mime'].test(file.type)) this.isDisabledTranscriptionFile = false;
    }
  }

  async searchLocale(event: any) {
    const searchMapValue = event.target.value
    if (searchMapValue) {
      const locale = await this.addressSearchService.getLocale(searchMapValue);
      this.locales = locale.slice(0, ADDRESS_SEARCH_RESULT_LIMIT);
      this.isShowLocales = true;
      if (!Boolean(locale.length) || locale.findIndex((item: any) => item.properties.title === searchMapValue) == -1) {
        const data = {
          locationaddress: searchMapValue,
          locationaddress_latitude: this.locationaddressLatitude,
          locationaddress_longitude: this.locationaddressLongitude
        };
        this.formCreate.controls['locationaddress'].setValue(data);
      }
    } else {
      this.locales = [];
      this.isShowLocales = false;
    }
  }

  receiveZipcodeInfo(event: any, formName: string) {
    const { zipcode, address } = event
    this.formCreate.controls[formName].setValue(event)
    this.formCreate.controls[formName].setErrors(null);
    if (!new RegExp(RuleValidations['zip']).test(zipcode)) {
      this.formCreate.controls[formName].setErrors({ incorrectZip: true });
    }
    if (address) {
      this.formCreate.controls['address'].setValue(address)
    }
  }

  setDataInputAutoCompete(dataFill: any, formName: string, datatype: string) {
    let dataValue;
    if (datatype === DataType.zipcode) {
      this.isShowAddressByZipcode = false;
      const dataZipcode = this.searchZipcode
      dataValue = {
        zipcode: dataZipcode,
        address: dataFill
      }
      this.formCreate.controls['address'].setValue(dataFill)
      this.formCreate.controls[formName].setErrors(null);
      if (!new RegExp(RuleValidations['zip']).test(dataZipcode)) {
        this.formCreate.controls[formName].setErrors({ incorrectZip: true });
      }
    } else {
      this.isShowLocales = false;
      this.searchAddress = dataFill.properties.title;
      const dataLatLng = dataFill.geometry.coordinates;
      const dataAddress = dataFill.properties.title;
      this.locationaddressLatitude = dataLatLng[1];
      this.locationaddressLongitude = dataLatLng[0]
      dataValue = {
        locationaddress: dataAddress,
        locationaddress_latitude: dataLatLng[1],
        locationaddress_longitude: dataLatLng[0],
      }
    }
    this.formCreate.controls[formName].setValue(dataValue)
  }

  receiveMapInfo(data: any) {
    const mapInput = this.formNode.filter((node: any) => node.datatype == DataType.maplocation)[0]
    if (mapInput) {
      this.formCreate.controls[mapInput.name].setValue(data)
    }
  }

  receiveData(data: any, formName: string) {
    this.formCreate.controls[formName]?.setValue(data)
  }

  changeInputRadio(event: any, formName: string) {
    if (this.prefixForm === PREFIX_LIST.pluginChatGpt) {
      const modelValue = this.formCreate.controls['model'].value;
      if (modelValue === CHAT_COMPLETION_MODEL.gpt4.name) {
        this.limitPromptTokens = CHAT_COMPLETION_MODEL.gpt4.limitTokens;
      } else {
        this.limitPromptTokens = CHAT_COMPLETION_MODEL.gpt3Turbo.limitTokens;
      }
      if (this.countToken > this.limitPromptTokens) {
        this.isDisabledSendChatCompletion = true;
        this.isTokenExceedChatCompletion = true;
      } else {
        this.isDisabledSendChatCompletion = false;
        this.isTokenExceedChatCompletion = false;
      }
    }
    if (formName === 'members') {
      this.totalMemberValidate = getNumberInString(this.formCreate?.get(formName)?.value);
      setValidateMember(formName, this.form, this.formCreate);
    }
    setValidate(this.form, this.formNode, this.formCreate)
  }

  checkValidZipcode(isValid: any, formName: string) {
    setMultipleErrors(this.formCreate.controls[formName], isValid)
  };

  transcriptFile() {
    this.isLoadingFileSoundToText = true;
    this.isDisabledSubmitOnCallOpenAiAction = true;
    const soundNode = this.formNode.filter((item: any) => item.datatype === DataType.sound);
    if (soundNode.length) {
      const formData = new FormData();
      formData.append('file', this.formCreate.controls[soundNode[0].name].value);
      formData.append('model', 'whisper-1');
      this.openAiService.speechToText(formData, this.currentUser.apiKey).then((response: any) => {
        if (response.text) {
          this.formCreate.controls['value'].setValue(response.text);
          this.isLoadingFileSoundToText = false;
          this.isDisabledTranslateText = false;
          this.isDisabledSubmitOnCallOpenAiAction = false;
        }
      }).catch((error) => {
        if (error) {
          this.isLoadingFileSoundToText = false;
          this.isDisabledSubmitOnCallOpenAiAction = false;
          this.alertService.error(this.translateService.instant('alertMessages.formUser.transcription.fail'));
        }
      })
    }
  }

  async translateText(targetTranslate: string) {
    this.isToggleTranslateButtonColor = Boolean(targetTranslate === 'en');
    const textTranslated: any = await this.openAiService.translateText(this.formCreate.controls['value'].value, targetTranslate);
    if (textTranslated && textTranslated.length) {
      this.formCreate.controls['value'].setValue(textTranslated);
      this.visibleTranslateModal = false;
    }
  }

  toggleTranslateModal() {
    this.visibleTranslateModal = !this.visibleTranslateModal;
  }

  changeInputArea(event: any, nodeName: string) {
    const value = event.target.value;
    if (nodeName === 'value' && this.prefixForm === PREFIX_LIST.pluginWhisper) {
      this.isDisabledTranslateText = !value.length;
    }
    if (nodeName === 'prompt' && this.prefixForm === PREFIX_LIST.pluginDalle) {
      this.isDisabledGenerateImages = !value.length;
    }
  }

  changeKeyUpArea(event: any, nodeName: string) {
    const value = event.target.value;
    if (this.prefixForm === PREFIX_LIST.pluginChatGpt) {
      const modelValue = this.formCreate.controls['model'].value;
      const systemValue = this.formCreate.controls['system'].value;
      const assistantValue = this.formCreate.controls['assistant'].value;
      const promptValue = this.formCreate.controls['prompt'].value;
      if (!assistantValue.length) {
        const assistantIndex = this.chatContent.findIndex((item) => item.role === 'assistant');
        if (assistantIndex > -1) this.chatContent = this.chatContent.filter((item) => item.role != 'assistant');
      }
      if (!promptValue.length) {
        const promptIndex = this.chatContent.findIndex((item) => item.role === 'user');
        if (promptIndex > -1) this.chatContent = this.chatContent.filter((item) => item.role != 'user');
      }
      if (!systemValue.length && !assistantValue.length && !promptValue.length) {
        this.isTokenExceedChatCompletion = false;
        this.chatContent = [];
        this.countToken = 0;
        this.isDisabledSendChatCompletion = true;
      } else if (nodeName === 'assistant') {
        const assistantIndex = this.chatContent.findIndex((item) => item.role === 'assistant');
        const chatContentIndex = assistantIndex > -1 ? assistantIndex : this.chatContent.length;
        this.chatContent[chatContentIndex] = { role: 'assistant', content: value };
        this.openAiService.getTokens(this.chatContent, modelValue).then((response: any) => {
          if (response) {
            this.countToken = response.tokens;
            if (this.countToken > this.limitPromptTokens) {
              this.isDisabledSendChatCompletion = true;
              this.isTokenExceedChatCompletion = true;
            } else {
              this.isTokenExceedChatCompletion = false;
            }
          }
        });
      } else if (nodeName === 'prompt') {
        const promptIndex = this.chatContent.findIndex((item) => item.role === 'user');
        const chatContentIndex = promptIndex > -1 ? promptIndex : this.chatContent.length;
        this.chatContent[chatContentIndex] = { role: 'user', content: value };
        this.openAiService.getTokens(this.chatContent, modelValue).then((response: any) => {
          if (response) {
            this.countToken = response.tokens;
            if (this.countToken > this.limitPromptTokens) {
              this.isDisabledSendChatCompletion = true;
              this.isTokenExceedChatCompletion = true;
            } else {
              this.isDisabledSendChatCompletion = false;
              this.isTokenExceedChatCompletion = false;
            }
          }
        });
      }
    }
  }

  changeInputText(event: any, nodeName: string) {
    const value = event.target.value;
    if (this.prefixForm === PREFIX_LIST.pluginChatGpt) {
      const modelValue = this.formCreate.controls['model'].value;
      const systemValue = this.formCreate.controls['system'].value;
      const assistantValue = this.formCreate.controls['assistant'].value;
      const promptValue = this.formCreate.controls['prompt'].value;
      if (!systemValue.length) {
        const systemIndex = this.chatContent.findIndex((item) => item.role === 'system');
        if (systemIndex > -1) this.chatContent = this.chatContent.filter((item) => item.role != 'system');
      }
      if (!systemValue.length && !assistantValue.length && !promptValue.length) {
        this.isTokenExceedChatCompletion = false;
        this.chatContent = [];
        this.countToken = 0;
        this.isDisabledSendChatCompletion = true;
      } else if (nodeName === 'system') {
        const systemIndex = this.chatContent.findIndex((item) => item.role === 'system');
        const chatContentIndex = systemIndex > -1 ? systemIndex : this.chatContent.length;
        this.chatContent[chatContentIndex] = { role: 'system', content: value };
        this.openAiService.getTokens(this.chatContent, modelValue).then((response: any) => {
          if (response) {
            this.countToken = response.tokens;
            if (this.countToken > this.limitPromptTokens) {
              this.isDisabledSendChatCompletion = true;
              this.isTokenExceedChatCompletion = true;
            } else {
              this.isTokenExceedChatCompletion = false;
            } 
          }
        });
      }
    }
  }

  async sendChatCompletion() {
    this.isLoadingSendChatCompletion = true;
    this.isDisabledSubmitOnCallOpenAiAction = true;

    const modelValue = this.formCreate.controls['model'].value;
    const systemValue = this.formCreate.controls['system'].value;
    const promptValue = this.formCreate.controls['prompt'].value;
    const assistantValue = this.formCreate.controls['assistant'].value;

    const messages: any = [];
    if (systemValue.length) messages.push({ role: 'system', content: systemValue });
    if (assistantValue.length) messages.push({ role: 'assistant', content: assistantValue });
    if (promptValue.length) messages.push({ role: 'user', content: promptValue });
    const data = {
      model: modelValue,
      messages: messages,
    }
    this.openAiService.chatCompletion(data, this.currentUser.apiKey).then((response: any) => {
      if (response) {
        this.formCreate.controls['response'].setValue(response['choices'][0]['message']['content']);
        this.isLoadingSendChatCompletion = false;
        this.isDisabledSubmitOnCallOpenAiAction = false;
      }
    }).catch((error) => {
      if (error) {
        this.isLoadingSendChatCompletion = false;
        this.isDisabledSubmitOnCallOpenAiAction = false;
        const errorCode = error.error.error.code;
        if (errorCode === ChatCompletionErrorCode.invalidApiKey) {
          this.alertService.error(this.translateService.instant('alertMessages.formUser.transcription.fail'));
        }
        if (errorCode === ChatCompletionErrorCode.tokenExceed) {
          this.alertService.error(this.translateService.instant('alertMessages.formUser.sendChatCompletion.tokenExceed'));
        }
      }
    });
  }

  generateImages() {
    this.isLoadingGenerateImages = true;
    this.isDisabledSubmitOnCallOpenAiAction = true;
    const nodeImages = this.formNode.filter((item: any) => item.datatype === DataType.image).map((item: any) => item.name);
    const numberImageGenerate = this.formMeta.n;
    const imageSize = this.formMeta.imagesize;
    const data = {
      prompt: this.formCreate.controls['prompt'].value,
      n: numberImageGenerate > LIMIT_GENERATE_IMAGES_OPEN_AI ? LIMIT_GENERATE_IMAGES_OPEN_AI : numberImageGenerate,
      size: imageSize
    }
    const previewFileNameNodeList = document.getElementsByClassName('preview-file-name');
    const previewImageNodeList = document.getElementsByClassName('preview-image');
    this.openAiService.generateImages(data, this.currentUser.apiKey).then((response: any) => {
      if (response && response.data.length) {
        for (const [index, image] of response.data.entries()) {
          const fileName = `image_open_ai_${index}.png`;
          const file = dataURLtoFile(image.b64_json, fileName);
          this.formCreate.controls[nodeImages[index]].setValue(file);
          previewFileNameNodeList[index].textContent = fileName;
          this.renderer2.setAttribute(previewImageNodeList[index], 'src', URL.createObjectURL(file));
          this.renderer2.setStyle(previewImageNodeList[index], 'display', 'block');
        }
        this.isLoadingGenerateImages = false;
        this.isDisabledSubmitOnCallOpenAiAction = false;
      }
    }).catch((error) => {
      if (error) {
        this.isLoadingGenerateImages = false;
        this.isDisabledSubmitOnCallOpenAiAction = false;
        this.alertService.error(this.translateService.instant('alertMessages.formUser.transcription.fail'));
      }
    });
  }

  protected readonly changeInputNum = changeInputNum;
  protected readonly checkDataTypeFile = checkDataTypeFile;
  protected readonly checkInputEnter = checkInputEnter;
  protected readonly getFirstError = getFirstError;
  protected readonly setMemberRequiredLabel = setMemberRequiredLabel;
  protected readonly getPlaceholderForm = getPlaceholderForm;
  protected readonly dayFormat = dayFormat;
  protected readonly getConditionShowInputError = getConditionShowInputError;
  protected readonly DataType = DataType;
  protected readonly DataName = DataName;
  protected readonly InputType = InputType;
  protected readonly INIT_EDITOR = INIT_EDITOR;
  protected readonly RuleValidations = RuleValidations;
  protected readonly INPUT_TYPE_FILE = INPUT_TYPE_FILE;
  protected readonly INPUT_TYPE_DATE_TIME = INPUT_TYPE_DATE_TIME;
  protected readonly PREFIX_LIST = PREFIX_LIST;
}
