<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="justify-content-center mb-5">
    <h3>{{ 'portal.title' | translate }}</h3>
  </c-col>
  <c-col class="mb-5">
    <c-nav variant="underline">
      <c-nav-item *ngIf="!!parentUser">
        <a [active]="false" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="0" cNavLink>
          {{ getLongText(parentUser?.name ?? '', MAX_LENGTH_TEXT_DISPLAY) }}
        </a>
      </c-nav-item>
      <c-nav-item>
        <a [active]="true" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="!!parentUser ? 1 : 0" cNavLink>
          {{ 'portal.home' | translate }}
        </a>
      </c-nav-item>
    </c-nav>
    <c-tab-content #tabContent="cTabContent" [activeTabPaneIdx]="!!parentUser ? 1 : 0">
      <c-tab-pane class="pt-3 pb-3" *ngIf="!!parentUser">
        <div class="remark-block" [innerHTML]="parentPortal | safeHtml"></div>
      </c-tab-pane>
      <c-tab-pane class="pt-3 pb-3">
        <div class="remark-block" [innerHTML]="currentPortal | safeHtml"></div>
      </c-tab-pane>
    </c-tab-content>
  </c-col>
</c-container>
