import { NgModule } from '@angular/core';
import { AsyncPipe, HashLocationStrategy, LocationStrategy, NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbPaginationModule, NgbAlertModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { InputMaskModule } from '@ngneat/input-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  ButtonModule,
  CardModule,
  DropdownModule,
  FormModule,
  GridModule,
  ModalModule,
  SpinnerModule,
  BadgeModule, PopoverModule
} from '@coreui/angular';
import { LoginComponent } from './views/pages/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { AlertComponent } from './components/alert/alert.component';
import { InputPasswordComponent } from './components/input/input-password/input-password.component';
import { LayoutsModule } from './views/layouts/layouts.module';
import { AccountComponent } from './views/pages/account/account.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './views/pages/update-password/update-password.component';
import { NotificationComponent } from './views/pages/notification/notification.component';
import { CreditCardInfoComponent } from './views/pages/credit-card-info/credit-card-info.component';
import { NonAccountComponent } from './views/pages/non-account/non-account.component';
import { FormCreateComponent } from './views/pages/form-create/form-create.component';
import { FormComponent } from "@app/views/pages/form/form.component";
import { FormDataEditComponent } from "@app/views/pages/list-form-data/edit/form-data-edit.component"
import {
  CollapseModule,
  DatePickerModule,
  LoadingButtonModule,
  SmartTableModule,
  TableModule, TabsModule, NavModule,
  TimePickerModule,
  UtilitiesModule, MultiSelectModule, CalloutModule, ListGroupModule, ButtonGroupModule, AlertModule, OffcanvasModule
} from "@coreui/angular-pro";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormUserComponent } from "@app/views/pages/form-user/form-user.component";
import { FormEditComponent } from "@app/views/pages/form-edit/form-edit.component";
import { ListFormDataComponent } from './views/pages/list-form-data/list-form-data.component';
import { TextTransformPipe } from './pipe/change-text.pipe';
import { ReissueComponent } from './views/pages/reissue/reissue.component';
import { MapTranslateTextPipe } from './pipe/map-translate-text.pipe';
import { FormGuestComponent } from './views/pages/form-guest/form-guest.component';
import { ExportComponent } from '@app/views/pages/list-form-data/export/export.component';
import { NgxColorsModule } from "ngx-colors";
import { RouterService } from "@app/services";
import { BasicEditComponent } from './views/pages/form-edit/basic-edit/basic-edit.component';
import { AdvancedEditComponent } from './views/pages/form-edit/advanced-edit/advanced-edit.component';
import { EmailEditComponent } from './views/pages/form-edit/email-edit/email-edit.component';
import { VideoEditComponent } from './views/pages/form-edit/video-edit/video-edit.component';
import { GroupsComponent } from './views/pages/groups/groups.component';
import { CreateComponent } from './views/pages/groups/create/create.component';
import { TestEditComponent } from './views/pages/form-edit/test-edit/test-edit.component';
import { InviteComponent } from './views/pages/groups/invite/invite.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EditNameComponent } from './views/pages/groups/edit-name/edit-name.component';
import { OwnerGroupComponent } from '@app/views/pages/groups/owner-group/owner-group.component';
import { MemberGroupComponent } from './views/pages/groups/member-group/member-group.component';
import { MapComponent } from "@app/components/input/map-address/map.component";
import { ShareFormComponent } from './views/pages/form/share-form/share-form.component';
import { BlankBlockComponent } from './components/blank-block/blank-block.component';
import { TabOwnerComponent } from './views/pages/groups/tab-owner/tab-owner.component';
import { TabMemberComponent } from './views/pages/groups/tab-member/tab-member.component';
import { EditorModule } from "@tinymce/tinymce-angular";
import { SafeHtmlPipe } from "@app/pipe/safe-html.pipe";
import { WebViewComponent } from './views/pages/web-view/web-view.component';
import { ExamComponent } from './views/pages/web-view/exam/exam.component';
import { NgxLoadingModule } from "ngx-loading";
import { CertificateComponent } from './views/pages/web-view/certificate/certificate.component';
import { TabComponent } from './views/pages/tab/tab.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ListDevicesComponent } from './views/pages/list-form-data/list-devices/list-devices.component';
import { EditDevicesComponent } from './views/pages/list-form-data/list-devices/edit-devices/edit-devices.component';
import { EditTabComponent } from './views/pages/tab/edit-tab/edit-tab.component';
import { CertificateEditComponent } from './views/pages/form-edit/certificate-edit/certificate-edit.component';
import { ZipcodeComponent } from '@app/components/input/zipcode/zipcode.component';
import { PageNotFoundComponent } from './views/pages/page-not-found/page-not-found.component';
import { PortalComponent } from './views/pages/portal/portal.component';
import { CreatePortalComponent } from './views/pages/portal/create/create.component';
import { GenerateUidComponent } from './components/modal/generate-uid/generate-uid.component';
import { InputLinkComponent } from '@app/components/input/input-link/input-link.component';
import { InputLabelComponent } from '@app/components/input/input-label/input-label.component';
import { InputLabelHtmlComponent } from '@app/components/input/input-label-html/input-label-html.component';
import { ListTemplateComponent } from './views/pages/list-template/list-template.component';
import { CreateTemplateComponent } from './views/pages/list-template/create/create-template.component';
import { TestDetailComponent } from './views/pages/form-edit/test-edit/test-detail/test-detail.component';
import { TemplateSettingComponent } from '@app/views/pages/list-template/template-setting/template-setting.component';
import { BasicTabComponent } from './views/pages/list-template/template-setting/basic-tab/basic-tab.component';
import { ColorPickerComponent } from './components/input/color-picker/color-picker.component';
import { TemplateItemsEditComponent } from './views/pages/list-template/template-items-edit/template-items-edit.component';
import { TemplateDetailItemEditComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/template-detail-item-edit.component';
import { AddressComponent } from './components/input/address/address.component';
import { DisplayListTabComponent } from './views/pages/list-template/template-setting/display-list-tab/display-list-tab.component';
import { SingleLineFormComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/single-line-form/single-line-form.component';
import { SingleMultipleSelectComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/single-multiple-select/single-multiple-select.component';
import { InputFileComponent } from './components/input/input-file/input-file.component';
import { FileSelectComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/file-select/file-select.component';
import { MapLocationZipCodeComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/map-location-zip-code/map-location-zip-code.component';
import { DateTimeSingleLineComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/date-time-single-line/date-time-single-line.component';
import { EditorLineComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/editor-line/editor-line.component';
import { LinkLineComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/link-line/link-line.component';
import { UniqueLineComponent } from './views/pages/list-template/template-items-edit/template-detail-item-edit/unique-line/unique-line.component';
import { ListAccountComponent } from './views/pages/list-account/list-account.component';
import { PreviewItemFormDataComponent } from '@app/views/pages/list-template/template-items-edit/preview-item-form-data/preview-item-form-data.component';
import { ExportButtonComponent } from './components/button/export-button/export-button.component';
import { CreateAccountComponent } from './views/pages/list-account/create-account/create-account.component';
import { ChartjsModule } from '@coreui/angular-chartjs';
import { LineChartFormDataComponent } from './views/pages/list-form-data/line-chart-form-data/line-chart-form-data.component';
import { BarChartFormDataComponent } from './views/pages/list-form-data/bar-chart-form-data/bar-chart-form-data.component';
import { LoadingBlockComponent } from './components/loading-block/loading-block.component';
import { ImagesChartFormDataComponent } from './views/pages/list-form-data/images-chart-form-data/images-chart-form-data.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MonthlyChartFormDataComponent } from './views/pages/list-form-data/monthly-chart-form-data/monthly-chart-form-data.component';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from 'chart.js';
import { WeeklyChartFormDataComponent } from './views/pages/list-form-data/weekly-chart-form-data/weekly-chart-form-data.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
Chart.register(zoomPlugin);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    AlertComponent,
    InputPasswordComponent,
    AccountComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    NotificationComponent,
    CreditCardInfoComponent,
    NonAccountComponent,
    FormCreateComponent,
    FormComponent,
    FormUserComponent,
    FormEditComponent,
    ListFormDataComponent,
    ReissueComponent,
    TextTransformPipe,
    MapTranslateTextPipe,
    SafeHtmlPipe,
    FormDataEditComponent,
    FormGuestComponent,
    ExportComponent,
    WebViewComponent,
    BasicEditComponent,
    AdvancedEditComponent,
    EmailEditComponent,
    VideoEditComponent,
    GroupsComponent,
    CreateComponent,
    TestEditComponent,
    InviteComponent,
    EditNameComponent,
    OwnerGroupComponent,
    MemberGroupComponent,
    MapComponent,
    ShareFormComponent,
    BlankBlockComponent,
    TabOwnerComponent,
    TabMemberComponent,
    ExamComponent,
    CertificateComponent,
    TabComponent,
    ListDevicesComponent,
    EditDevicesComponent,
    EditTabComponent,
    CertificateEditComponent,
    ZipcodeComponent,
    PageNotFoundComponent,
    GenerateUidComponent,
    PortalComponent,
    CreatePortalComponent,
    InputLinkComponent,
    InputLabelComponent,
    ListTemplateComponent,
    CreateTemplateComponent,
    InputLabelHtmlComponent,
    TestDetailComponent,
    TemplateSettingComponent,
    BasicTabComponent,
    ColorPickerComponent,
    TemplateItemsEditComponent,
    TemplateDetailItemEditComponent,
    AddressComponent,
    DisplayListTabComponent,
    SingleLineFormComponent,
    SingleMultipleSelectComponent,
    InputFileComponent,
    FileSelectComponent,
    MapLocationZipCodeComponent,
    DateTimeSingleLineComponent,
    EditorLineComponent,
    LinkLineComponent,
    UniqueLineComponent,
    ListAccountComponent,
    PreviewItemFormDataComponent,
    ExportButtonComponent,
    CreateAccountComponent,
    LineChartFormDataComponent,
    BarChartFormDataComponent,
    LoadingBlockComponent,
    ImagesChartFormDataComponent,
    MonthlyChartFormDataComponent,
    WeeklyChartFormDataComponent
  ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        ButtonModule,
        CardModule,
        DropdownModule,
        FormModule,
        GridModule,
        ModalModule,
        NavModule,
        SpinnerModule,
        BadgeModule,
        HttpClientModule,
        NgbPaginationModule,
        NgbModule,
        NgbAlertModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutsModule,
        InputMaskModule,
        InputMaskModule.forRoot({ inputSelector: 'input', isAsync: true }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireMessagingModule,
        AngularFireAuthModule,
        AngularFireAnalyticsModule,
        HttpClientModule,
        DatePickerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
                },
                deps: [HttpClient]
            }
        }),
        NgMultiSelectDropDownModule.forRoot(),
        NgxPaginationModule,
        PopoverModule,
        SmartTableModule,
        TableModule,
        UtilitiesModule,
        ButtonModule,
        LoadingButtonModule,
        TimePickerModule,
        NgxColorsModule,
        NgbPopoverModule,
        NgOptimizedImage,
        CollapseModule,
        TabsModule,
        AngularEditorModule,
        MultiSelectModule,
        EditorModule,
        CalloutModule,
        NgxLoadingModule,
        DragDropModule,
        ListGroupModule,
        ButtonGroupModule,
        AlertModule,
        OffcanvasModule,
        ChartjsModule,
        FullCalendarModule,
        NgxTippyModule,
        BsDatepickerModule.forRoot(),
    ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AsyncPipe,
    RouterService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
