<c-offcanvas placement="start" id="elementDetailForm" [scroll]="false" [visible]="visible" (visibleChange)="toggleOffCanvas($event)">
  <c-offcanvas-header>
    <p></p>
    <button
      cButtonClose
      class="text-reset"
      cOffcanvasToggle="elementDetailForm"
      aria-label="Close"
    ></button>
  </c-offcanvas-header>
  <c-offcanvas-body>
    <form *ngIf="form" [formGroup]="form">
      <app-single-line-form
        *ngIf="SINGLE_LINE_FORM.includes(form.controls['datatype'].value) && !UNIQUE_LINE_NAME.includes(form.controls['name'].value)"
        [form]="form"
        [submitted]="submitted"
        [validationListIndex]="validationListIndex"
      ></app-single-line-form>
      <app-single-multiple-select
        *ngIf="INPUT_SELECT.includes(form.controls['datatype'].value)"
        [form]="form"
        [submitted]="submitted"
      ></app-single-multiple-select>
      <app-file-select
        *ngIf="INPUT_TYPE_FILE.includes(form.controls['datatype'].value)"
        [form]="form"
        [submitted]="submitted"
      ></app-file-select>
      <app-map-location-zip-code
        *ngIf="INPUT_TYPE_LOCATION.includes(form.controls['datatype'].value)"
        [form]="form"
        [submitted]="submitted"
      ></app-map-location-zip-code>
      <app-date-time-single-line
        *ngIf="INPUT_TYPE_DATE_TIME.includes(form.controls['datatype'].value)"
        [form]="form"
        [submitted]="submitted"
      ></app-date-time-single-line>
      <app-editor-line
        *ngIf="form.controls['datatype'].value === INPUT_TYPE.html"
        [form]="form"
        [submitted]="submitted"
        [formIndex]="formIndex"
      ></app-editor-line>
      <app-link-line
        *ngIf="form.controls['datatype'].value === INPUT_TYPE.link"
        [form]="form"
        [submitted]="submitted"
      ></app-link-line>
      <app-unique-line
        *ngIf="UNIQUE_LINE_NAME.includes(form.controls['name'].value)"
        [form]="form"
        [submitted]="submitted"
      ></app-unique-line>
    </form>
  </c-offcanvas-body>
</c-offcanvas>
