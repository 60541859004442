<app-layout-auth [backgroundColor]="getFormInfo('backgroundCode')" [stringColor]="getFormInfo('stringCodeForm')">
  <header class="form-user-header">
    <a id="logo">
      <div>{{ getFormInfo("organizationName") }}</div>
    </a>
  </header>
  <div class="custom-container"
    *ngIf="!isLoading"
    [style.--string-color]="getFormInfo('stringCodeForm')"
    [style.--radio-color]="checkColorBg(getFormInfo('backgroundCode'))"
    [style.--background-color]="getFormInfo('backgroundCode')">
    <c-col class="justify-content-center mb-5">
      <h3 [class]="{'tbc-title': prefixForm === PREFIX_LIST.pluginTbc}">{{ getFormInfo("title") }}</h3>
      <p *ngIf="isDisplayTargetDate(prefixForm)">{{ ('form.inputLabel.deadlineForTesting' | translate) + '：' + getFormInfo("targetDate") }}</p>
      <div [innerHTML]="getFormInfo('certificateDescription') | safeHtml"></div>
    </c-col>
    <c-card *ngIf="form?.showCertificate" class="certificate-page">
      <c-card class="container-page">
        <div class="layout-page" id="doc"
          [ngStyle]="{
            width: doc.internal.pageSize.getWidth() + 'pt',
            height: doc.internal.pageSize.getHeight() + 'pt'
          }">
          <div class="form-info-card">
            <div class="img-header-list">
              <div class="img__logo">
                <img src="assets/img/certificate/logo.png"/>
              </div>
              <div class="img__title">
                <div>
                  <img src="assets/img/certificate/subtitle.png"/>
                </div>
                <div>
                  <img class="title" src="assets/img/certificate/title.png"/>
                </div>
              </div>
            </div>
            <c-col class="company-info mb-5">
              <span class="text-title">受 講 修 了 証</span>
              <c-row class="text__organization-name mt-3 mb-3">
                <c-col class="text">
                  <span>会社名</span>
                </c-col>
                <c-col>
                  <span class="data-underline organization-name">{{ getFormUserInfo('company') }}</span>
                </c-col>
                <c-col></c-col>
              </c-row>
              <div class="text__form-title">
                <span>{{ getFormUserInfo('name') }}</span>
                <span>殿</span>
              </div>
              <div class="d-flex text__entry mt-5">
                <div>
                  <span>講習受講日</span>
                  <span class="data-underline">{{ getFormUserInfo('passingDateString') }}</span>
                </div>
                <div>
                  <span>発行番号</span>
                  <span class="data-underline">{{ getFormUserInfo('entryno') }}</span>
                </div>
              </div>
            </c-col>
            <div class="form-info-card__subtitle">
              あなたは「エフ-1」の商品知識、施工上の取扱について<br/>
              基本技術を取得されましたことをここに証明します
            </div>
            <div class="form-info-card__footer">
              <div>
                <span>株式会社タブチ</span>
                <div>
                  <img src="assets/img/certificate/title.png"/>
                </div>
              </div>
              <div class="img__stamp">
                <img src="assets/img/certificate/stamp.png"/>
              </div>
            </div>
          </div>
          <div class="contact-card">
            <c-col class="contact-card__body">
              <div class="d-flex">
                <c-col class="text-phone__default" lg="4" md="4">
                  <span>お問い合わせ電話番号</span>
                  <span>お問い合わせ受付時間</span>
                </c-col>
                <c-col class="text-phone__data">
                  <span>{{ phoneNumber }}</span>
                  <span>　平日９時～１２時、１３時～１７時（土日祝、弊社休業日は除く）</span>
                </c-col>
              </div>
              <div class="text__qr-code">
                <span>製品仕様、施工要領書 等はこちらから</span>
                <img class="arrow" src="assets/svg/common/caret-down-white.svg"/>
                <div class="img__qr-default">
                  <img src="assets/img/certificate/default-qr.png"/>
                </div>
              </div>
              <div class="default-image">
                <img src="assets/img/certificate/form_certificate_default.png" />
              </div>
              <div class="contact-card__footer">
                <span>株式会社タブチ</span>
                <div>
                  <img src="assets/img/certificate/title.png"/>
                </div>
              </div>
            </c-col>
          </div>
        </div>
      </c-card>
    </c-card>
    <c-card *ngIf="form?.showCertificate" class="mt-3 btn-list">
      <button cButton class="btn-download" (click)="downloadPdf()"
        [ngStyle]="{
          'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC : getFormInfo('stringCodeForm'),
          'backgroundColor': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC : getFormInfo('backgroundCode')
        }">
        {{ 'webview.certificate.btnLabel.download' | translate }}
      </button>
    </c-card>
    <div class="text-center mt-5 pb-2 text-footer" [innerHTML]="getFormInfo('copyright') | safeHtml"></div>
  </div>
  <c-card-body *ngIf="isLoading">
    <app-loading-block [isLoading]="isLoading"></app-loading-block>
  </c-card-body>
</app-layout-auth>
