import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from "@angular/forms";
import { AlertService, FirebaseAnalyticService, FormCachesService, FormService } from "@app/services";
import {filter, firstValueFrom} from "rxjs";
import { TranslateService} from "@ngx-translate/core";
import { ACTION_LIST, EVENT_LOG_TYPE, MAIL_TYPE_LIST, PREFIX_LIST, STYLE_BUTTON_TBC, STYLE_STRING_TBC, RuleValidations, text } from '@app/constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getInfoDataByKey, isDisplayTargetDate, getFirstError } from "@app/helpers";

@Component({
  selector: 'app-reissue',
  templateUrl: './reissue.component.html',
  styleUrls: ['./reissue.component.scss']
})
export class ReissueComponent implements OnInit {
  isDisplayTargetDate = isDisplayTargetDate;
  text = text
  submitted = false;
  isClickLoading = false;
  formData: any;
  form: any;
  prefixForm: string = '';
  getFirstError = getFirstError;
  protected formReissue!: FormGroup;
  protected readonly STYLE_BUTTON_TBC = STYLE_BUTTON_TBC;
  protected readonly STYLE_STRING_TBC = STYLE_STRING_TBC;
  constructor(
    private formService: FormService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formCachesService: FormCachesService
  ) { }

  ngOnInit(): void {
    this.formReissue = this.formBuilder.group({
      name: new FormControl('', { validators: [Validators.required] }),
      email: new FormControl('', { validators: [Validators.required, Validators.pattern(RuleValidations.emailPattern)] }),
    });
    this.generateForm();
  }
  get f() {
    return this.formReissue.controls;
  }
  getFormInfo(key: string) {
    return getInfoDataByKey(this.form, key, this.translateService.currentLang)
  }

  private async generateForm() {
    const { id: formId } = await firstValueFrom(this.route.params);
    this.formCachesService.getDetailFormCache(formId).subscribe({
      next: (formCacheValue: any) => {
        if (formCacheValue.length) {
          const userId = formCacheValue[0]?.['userid'];
          this.formService.getValueOwnerFormById(userId, formId).subscribe({
            next: (formValue) => {
              this.form = formValue;
              this.prefixForm = this.form.nodes.nodes.meta.prefix;
              document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
              this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
                if (event.url.includes("reissue")) {
                  document.getElementById('form-guest-body')!.style.backgroundColor = this.getFormInfo("backgroundCode");
                } else {
                  document.getElementById('form-guest-body')!.style.backgroundColor = text.defaultBackgroundCode;
                }
              });
            }
          })
        }
      }
    })
  }

  async submit() {
    try {
      this.submitted = true;
      this.isClickLoading = true;
      if (this.formReissue.valid) {
        const dataForm = await this.getDataForm();
        if (dataForm) {
          if (!dataForm.status) {
            this.alertService.error(this.translateService.instant('alertMessages.reissue.sendMailQr.fail'));
            this.submitted = false;
            this.isClickLoading = false;
            return;
          }
          if (!this.form.isSendMailReissueQr) {
            this.alertService.error(this.translateService.instant('alertMessages.reissue.sendMailQr.disableSend'));
            this.isClickLoading = false;
            this.submitted = false;
            return;
          }

          // update by Tanaka on 2023/08/25
          const hostName = this.form?.hostname ?? text.defaultDomain;
          await this.formService.sendMailFormUser(dataForm.formData, this.form, MAIL_TYPE_LIST.mailReissueQr, '', hostName);
          this.alertService.success(this.translateService.instant('alertMessages.reissue.sendMailQr.success', {email: this.f['email'].value}));
          this.submitted = false;
          this.isClickLoading = false;
          this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_MAIL.REISSUE_FORM_DATA, EVENT_LOG_TYPE.SUCCESS);
        }
      }else {
        this.isClickLoading = false;
      }
    } catch (e) {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.SEND_MAIL.REISSUE_FORM_DATA, EVENT_LOG_TYPE.ERROR);
    }
  }

  async getDataForm() {
    let result = {
      status: false,
      formData: {}
    }
    const listFormData = await this.formService.getOwnerFormDataByEmailAndName(this.form.user_id, this.form.doc_id, this.formReissue.value);
    if (listFormData.length) {
      result = {
        status: true,
        formData: listFormData[0]
      }
    }
    return result;
  }

  protected readonly PREFIX_LIST = PREFIX_LIST;
}
