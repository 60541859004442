import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService, NotificationService, UserService, FirebaseAnalyticService } from '@app/services';
import { INotification as INotificationBase } from '@app/models';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { getDataNotice } from "@app/helpers";
import { ACTION_LIST, EVENT_LOG_TYPE, NOW } from "@app/constants";

interface INotification extends INotificationBase {
  isReadMore?: boolean;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  private currentPage = 0;
  private readonly perPage = 10;
  private data: INotification[][] = [];
  notifications: INotification[] = [];
  isDisableNextPage = false;
  isDisablePreviousPage = false;
  isOnePage = true;
  currentLanguage: string = ''
  textNow: string = NOW
  dataAllNotification: INotification[] = [];
  authUser: any;
  subscription: { [key: string]: Subscription | null } = {
    notificationList: null
  }

  constructor(
    private notificationService: NotificationService,
    private angularFireAuth: AngularFireAuth,
    private userService: UserService,
    private alertService: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLanguage = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLanguage = e.lang
    })
    this.getCurrentUser().then();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscription['notificationList']?.unsubscribe();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.authUser = authUser;
      this.subscription['notificationList'] = this.notificationService.getAll(this.authUser.email, this.authUser.uid).subscribe({
        next: (response) => {
          const dataResponse = response.map(item => {
            const data = item.payload.doc.data() as INotification;
            data.id = item.payload.doc.id;
            data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
            this.translateService.onLangChange.subscribe(() => {
              data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
            });
            data.isReadMore = this.isReadMore(data);
            return data;
          });
          this.dataAllNotification = dataResponse;
          this.data = [];
          for (let i = 0; i < dataResponse.length; i += this.perPage) {
            this.data.push(dataResponse.slice(i, i + this.perPage));
          }
          this.isOnePage = this.data.length <= 1;
          if (this.currentPage === 0) {
            this.isDisablePreviousPage = true;
          } else if (!this.data[this.currentPage]) {
            this.currentPage--;
          }
          this.notifications = this.data[this.currentPage];
        }
      });
    } 
  }

  delete(notification: INotification) {
    try {
      this.notificationService.delete(this.authUser.email, this.authUser.uid, notification.id).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.NOTIFICATION.DELETE, EVENT_LOG_TYPE.SUCCESS)
        this.showAlert(this.translateService.instant('notification.removedNotification'));
      });
    } catch (e) {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.NOTIFICATION.DELETE, EVENT_LOG_TYPE.ERROR)
    }
  }

  private showAlert(msg: string) {
    this.alertService.clear();
    this.alertService.success(msg);
  }

  private isReadMore(notification: INotification) {
    let item: INotification | undefined;
    for (let index = 0; index < this.data.length; index++) {
      item = this.data[index].find((item) => item.id === notification.id);
      if (item) {
        break;
      }
    }
    return !!item?.isReadMore;
  }

  toggleReadMore(notification: INotification) {
    notification.isReadMore = !notification.isReadMore;
  }

  readMark(notification: INotification) {
    this.notificationService.readMark(this.authUser.email, this.authUser.uid, notification.id).then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.NOTIFICATION.MARK_AS_READ, EVENT_LOG_TYPE.SUCCESS);
    }).catch(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.NOTIFICATION.MARK_AS_READ, EVENT_LOG_TYPE.ERROR);
    });
  }

  readMarkAll() {
    try {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.NOTIFICATION.MARK_ALL_AS_READ, EVENT_LOG_TYPE.SUCCESS);
      this.dataAllNotification.forEach(notification => {
        if (!notification.isRead) {
          this.readMark(notification);
        }
      });
    } catch (e) {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.NOTIFICATION.MARK_ALL_AS_READ, EVENT_LOG_TYPE.ERROR);
    }
  }

  nextPage() {
    this.isDisablePreviousPage = false;
    if (this.currentPage < (this.data.length - 1)) {
      this.currentPage++;
      this.notifications = this.data[this.currentPage];
    }
    if (this.currentPage == (this.data.length - 1)) {
      this.isDisableNextPage = true;
      this.notifications = this.data[this.currentPage];
    }
  }

  previousPage() {
    this.isDisableNextPage = false;
    if ((this.data.length - 1) >= this.currentPage) {
      this.currentPage--;
      this.notifications = this.data[this.currentPage];
    }
    if (this.currentPage == 0) {
      this.isDisablePreviousPage = true;
      this.notifications = this.data[this.currentPage];
    }
  }

  protected readonly getDataNotice = getDataNotice;
}
