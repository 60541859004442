import { Component, HostListener, OnInit } from '@angular/core';
import { ACTION_LIST, EVENT_LOG_TYPE, GROUP_PER_PAGE, ITEM_PER_PAGE, MAX_WIDTH_MOBILE_DEVICES, MODE_DEVICE } from "@app/constants";
import { displayPageIndex } from "@app/helpers";
import * as moment from "moment/moment";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { FirebaseAnalyticService, FormService } from "@app/services";
import { ICamera } from "@app/models/camera";
import { CameraService } from "@app/services/camera.service";
import { getAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-list-devices',
  templateUrl: './list-devices.component.html',
  styleUrls: ['./list-devices.component.scss']
})
export class ListDevicesComponent implements OnInit {
  protected readonly ITEM_PER_PAGE = ITEM_PER_PAGE;
  protected readonly MAX_WIDTH_MOBILE_DEVICES = MAX_WIDTH_MOBILE_DEVICES;
  protected readonly GROUP_PER_PAGE = GROUP_PER_PAGE;
  pageNumber = 1;
  screeWidth: number = 0;
  formId: string = '';
  protected readonly displayPageIndex = displayPageIndex;
  protected readonly MODE_DEVICE = MODE_DEVICE;

  devices: any = [];
  form: any;
  formData: any;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screeWidth = window.outerWidth
  }
  constructor(
    private route: ActivatedRoute,
    private formService: FormService,
    private cameraService: CameraService,
    private firebaseAnalyticService: FirebaseAnalyticService
  ) { }

  ngOnInit(): void {
    this.screeWidth = window.outerWidth
    this.getForm().then();
  }

  async getForm() {
    const params = await firstValueFrom(this.route.params);
    this.formId = params['id']
    const auth = getAuth()
    this.cameraService.index(this.formId).subscribe((response) => {
      const cameras: Array<ICamera> = response.map((item) => item.payload.doc.data())

      this.formService.getFormData(auth.currentUser?.uid ?? '', this.formId).subscribe((res) => {
        this.generateData(res)
        const newestData = [ ...this.devices ]
        if (!!cameras.length) {
          this.syncDataCameras(newestData, cameras)
          const existingCameraIds = new Set(this.devices.map((item: ICamera) => item.camera_id));

          for (const camera of cameras) {
            if (existingCameraIds.has(camera.camera_id)) {
              const deviceIndex = this.devices.findIndex((item: ICamera) => item.camera_id === camera.camera_id);
              this.devices[deviceIndex] = camera;
            } else {
              this.devices.push(camera);
            }
          }
        }
      })
    })
  }

  async syncDataCameras(newestData: Array<ICamera>, cameras: Array<ICamera>) {
    const dataUpdate = cameras.map(camera => {
      const matchingObject = newestData.find(newCamera => newCamera.camera_id === camera.camera_id);
      if (matchingObject && matchingObject.name !== camera.name) {
        return { ...camera, name: matchingObject.name };
      }
      return null;
    }).filter((item) => item !== null);
    if (!!dataUpdate.length) {
      await this.cameraService.batchUpdateCamera(dataUpdate, this.formId)
    }
  }

  generateData(data: any) {
    this.devices = this.getLatestObjects(data).map((item: any) => {
      return {
        width: item.width ?? null,
        height: item.height ?? null,
        inference: item.inference ?? null,
        mode: item.mode ?? null,
        name: item.name ?? null,
        camera_id: item.camera_id ?? null,
        bright: item.bright ?? null,
        image_id: item.image_id ?? null,
      }
    });
  }

  async changeMode(device: ICamera, mode: number) {
    const cameraInfo = { ...device, mode: mode }
    await this.cameraService.createOrUpdateCamera(cameraInfo, this.formId).then(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.CAMERA.UPDATE_CAMERA_MODE, EVENT_LOG_TYPE.SUCCESS);
    }).catch(() => {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.CAMERA.UPDATE_CAMERA_MODE, EVENT_LOG_TYPE.ERROR);
    });
  }

  getLatestObjects(array: Array<any>) {
    const tempObj: any = {};
    for (let obj of array) {
      const id = obj.camera_id;
      const createdAt = moment(obj.createdAt);

      if (!tempObj[id] || createdAt.isAfter(tempObj[id].createdAt)) {
        tempObj[id] = obj;
      }
    }
    return Object.values(tempObj);
  }
}
