import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService, UserService, FirebaseAnalyticService } from '@app/services';
import { ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from '@app/constants';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "@environments/environment";
import { getAuth, signInWithEmailAndPassword } from '@angular/fire/auth';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  formRegister!: UntypedFormGroup;
  submitted = false;
  loading = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.formRegister = this.fb.group(
      {
        email: ['', [Validators.required, Validators.pattern(RuleValidations.emailPattern)]],
        password: ['', [Validators.required, Validators.pattern(RuleValidations.passwordPattern)]],
      }
    );
  }

  get f() {
    return this.formRegister.controls;
  }

  private onValidate() {
    this.submitted = true;
    return this.formRegister.valid;
  }

  async register() {
    const auth = getAuth();
    this.alertService.clear();
    if (this.onValidate() && !this.loading) {
      this.loading = true;
      const { email, password } = this.formRegister.value
      this.userService.register(email, password).then((response: any) => {
        if (response['uid'].length) {
          this.loading = false;
          this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.REGISTER, EVENT_LOG_TYPE.SUCCESS)
          this.router.navigate(['account'], { state: { register: true } }).then();
        }
      }).catch(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.REGISTER, EVENT_LOG_TYPE.ERROR)
        this.loading = false;
        this.alertService.error(this.translateService.instant('alertMessages.register.fail', { email: email }));
      })
    }
  }

  protected readonly environment = environment;
}
