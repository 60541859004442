import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { INPUT_TYPE, INPUT_TYPE_FILE } from "@app/constants";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { dayFormat, getLocaleDatePicker } from "@app/helpers";

@Component({
  selector: 'app-preview-item-form-data',
  templateUrl: './preview-item-form-data.component.html',
  styleUrls: ['./preview-item-form-data.component.scss']
})
export class PreviewItemFormDataComponent implements OnInit {
  form: any = [];
  currentLang: string = '';
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'すべて',
    unSelectAllText: 'すべて',
    itemsShowLimit: 3,
    allowSearchFilter: false
  };
  currentDatepickerLocale: string = "";
  templateId: string = ''

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.form = this.router.getCurrentNavigation()?.extras.state?.['form'];
  }

  ngOnInit(): void {
    this.templateId = this.activatedRoute.snapshot.params['templateId'];
    this.currentLang = this.translateService.currentLang
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLang);
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = e.lang
      this.currentDatepickerLocale = getLocaleDatePicker(this.currentLang);
    })
  }

  getTitleInput(input: any) {
    return (input[this.currentLang === 'ja' ? 'title_ja' : 'title_eng']
      ? input[this.currentLang === 'ja' ? 'title_ja' : 'title_eng']
      : ('template.formItemsEdit.inputType.' + input.type + input.datatype) + (input.hasOwnProperty('name') && !!input.name ? input['name'] : ''))
  }

  getPlaceholder(input: any) {
    return input['placeholder_' + (this.currentLang === 'ja' ? 'ja' : 'eng')]
  }

  protected readonly dayFormat = dayFormat;
  protected readonly INPUT_TYPE = INPUT_TYPE;
  protected readonly INPUT_TYPE_FILE = INPUT_TYPE_FILE;
}
