import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ITab } from "@app/models/tab";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from "@app/constants";
import { TabService } from "@app/services/tab.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, FirebaseAnalyticService } from "@app/services";

@Component({
  selector: 'app-edit-tab',
  templateUrl: './edit-tab.component.html',
  styleUrls: ['./edit-tab.component.scss']
})
export class EditTabComponent implements OnChanges {
  @Input('visible') visible: boolean = false;
  @Input('tab') tab: ITab = {};
  @Input('authUser') authUser: any;
  @Output() modalStatus = new EventEmitter<boolean>
  @ViewChild('editTabName') editTabName: any;
  protected readonly RuleValidations = RuleValidations;
  
  formEditTab = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(RuleValidations.maxLength255)])
  })
  isSubmitted: boolean = false
  constructor(
    private tabService: TabService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('tab') && !!this.tab.name?.length) {
      this.formEditTab.controls.name.setValue(this.tab.name)
    }
  }

  emittedModalToggle() {
    if (this.editTabName) this.modalStatus.emit(this.editTabName.visible)
  }

  updateTabName() {
    this.isSubmitted = true
    if (this.formEditTab.valid && this.formEditTab.value.name) {
      const newDataTab: ITab = { ...this.tab, name: this.formEditTab.value.name }
      this.tabService.update(this.authUser.uid, [newDataTab]).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TAB.UPDATE_NAME, EVENT_LOG_TYPE.SUCCESS);
        this.editTabName.visible = false;
        this.alertService.success(this.translateService.instant('alertMessages.tab.update.success'))
      }).catch((e) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TAB.UPDATE_NAME, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.tab.update.fail'))
      })
    }
  }
}
