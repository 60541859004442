import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  DATA_NONE,
  DataType,
  formatDatePicker,
  MAIL_CONTENT,
  MAIL_TYPE_LIST,
  RuleValidations,
  text,
  CAR,
  PREFIX_LIST,
  INDIVIDUAL_ROLE,
  MIN_MAX_VALUE_DATA_TYPE_LIST,
  INDIVIDUAL_JA_ROLE
} from '@app/constants';
import { IForm } from '@app/models';
import { MapTranslateTextPipe } from '@app/pipe/map-translate-text.pipe';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, take } from 'rxjs';
import { ulid } from "ulid";
import { AlertService } from './alert.service';
import { UserService } from './user.service';
import { checkStringContainsHTMLTags, filterAddressInPhoneTable, formatStringToDate, getEmailNode, getEntryno, getNumberInString, removeItem, replaceAllForString } from "@app/helpers";
import * as moment from 'moment';
import firebase from 'firebase/compat/app';
import { Timestamp } from "firebase/firestore";
import { getStorage, ref } from 'firebase/storage';
import { getApp } from 'firebase/app';
import * as qrcode from "qrcode"
import { getAuth } from "@firebase/auth";
import { getDownloadURL, uploadString } from "@angular/fire/storage";
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(
    private db: AngularFirestore,
    private translateService: TranslateService,
    private userService: UserService,
    private storage: AngularFireStorage,
    private alertService: AlertService,
    private apiService: ApiService
  ) { }
  getAll(id: string) {
    return this.db.collection('users')
      .doc(id).collection('form', ref => ref
        .orderBy('createdAt', 'desc'))
      .valueChanges({ idField: 'id' });
  }

  getOwnerFormListData(userId: string) {
    return this.db.collection('users').doc(userId).collection('form', ref => ref.orderBy('createdAt', 'desc')).valueChanges();
  }

  getValueOwnerFormById(userId: string, formId: string) {
    return this.db.collection('users').doc(userId).collection('form').doc(formId).valueChanges()
  }

  getOwnerFormDataById(userId: string, formId: string, formDataId: string) {
    return this.db.collection('users').doc(userId).collection('form').doc(formId).collection('form-data').doc(formDataId).valueChanges();
  }

  getOwnerFormDataByEmailAndName(userId: string, formId: string, formValue: any) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(userId).collection('form').doc(formId).collection('form-data', ref => ref.where('email', '==', formValue.email).where('name', '==', formValue.name)).get().subscribe({
        next: (formDataValue) => {
          if (formDataValue) {
            let data: any[] = [];
            if (formDataValue.docs?.length) {
              formDataValue.docs.forEach((item: any) => {
                data.push(item.data())
              })
            }
            resolve(data);
          }
        },
        error: (error) => reject('api fail')
      })
    })
  }

  getOwnerFormDataByHowToVisit(userId: string, formId: string) {
    return this.db.collection('users').doc(userId).collection('form').doc(formId).collection('form-data', ref => ref.where('howtovisit', '==', CAR)).valueChanges();
  }

  getFormDataEntryNo(userId: string, formId: string, entryno: string) {
    return this.db.collection('users').doc(userId).collection('form').doc(formId).collection('form-data').doc(entryno).valueChanges();
  }

  // Promiseを使う方法
  sleepByPromise(sec: number) {

    return new Promise(resolve => setTimeout(resolve, sec*1000));

  }

  async wait(sec: number, item: any, config: any, a1: any, a2: any, a3: any) {

    await this.sleepByPromise(sec);

    this.sendMailFormUser(item, config, a1, a2, a3);

//    console.log("sendMailFormUser");

  }

  async waitBatch(sec: number, batch: any) {

    await this.sleepByPromise(sec);

    await batch.commit();

//    console.log("commit");

  }

  async waitOnly(sec: number) {

    await this.sleepByPromise(sec);

  }

  // Create form
  async create(form: IForm, user: any) {
    const id = ulid();
    let dataCreate: any = {
      ...form,
      bodyMailFormRegist: MAIL_CONTENT.mailFormRegist.body,
      bodyMailReissueQr: MAIL_CONTENT.mailReissue.body,
      bodyMailUpdateFormData: MAIL_CONTENT.mailUpdateFormData.body,
      titleMailFormRegist: MAIL_CONTENT.mailFormRegist.title,
      titleMailReissueQr: MAIL_CONTENT.mailReissue.title,
      titleMailUpdateFormData: MAIL_CONTENT.mailUpdateFormData.title,
      isSendMailFormRegist: false,
      isSendMailReissueQr: false,
      isSendMailUpdateFormData: false,
      useQrCode: false,
      registFormWithoutLogin: false,
      doc_id: id,
      user_id: user.uid,
    }
    if (typeof user.hostname !== 'undefined') dataCreate = { ...dataCreate, hostname: user.hostname };
    Promise.all([
      this.db.collection('users').doc(user.uid).collection('form').doc(id).set(dataCreate),
      this.db.collection('users').doc(user.uid).collection('formCaches').doc(id).set({ userid: user.uid, formid: id })
    ]).then();
  }
  async update(dataUpdate: any, uid: any, id: string) {
    await this.db.collection('users').doc(uid).collection('form').doc(id).update(dataUpdate);
  }

  getColors() {
    return this.db.collection('colors').snapshotChanges()
  }
  async saveFormUserData(param: any, uid: string, formId: string) {
    await this.db.collection('users').doc(uid).collection('form').doc(formId).collection('form-data').doc(param.entryno).set(param);
  }
  async updateFormUserData(param: any, uid: string, formId: string, entryno: string) {
    await this.db.collection('users').doc(uid).collection('form').doc(formId).collection('form-data').doc(entryno).update(param);
  }

  async sendMailFormUser(formUser: any, form: any, mailType: string, entryno: string = '', hostname: string | null = null) {
    const batch = this.db.firestore.batch();
    const dataNodes = form?.nodes?.nodes?.objects;
    const dataEmailSynchronous = form?.emailSynchronous ? form?.emailSynchronous?.split(',') : [];
    const emailNode = getEmailNode(form.nodes.nodes.objects);
    const dataEmail = [formUser[emailNode.name], ...dataEmailSynchronous];
    let emailContent = '';
    let emailTitle: string = '';
    let emailBody: string = '';
    switch (mailType) {
      case MAIL_TYPE_LIST.mailRegist: {
        emailTitle = form.titleMailFormRegist;
        emailBody = form.bodyMailFormRegist;
        break;
      }
      case MAIL_TYPE_LIST.mailUpdateFormData: {
        emailTitle = form.titleMailUpdateFormData;
        emailBody = form.bodyMailUpdateFormData;
        break;
      }
      default: {
        emailTitle = form.titleMailReissueQr;
        emailBody = form.bodyMailReissueQr;
      }
    }
    let mailTitle = emailTitle;
    let tempBody = decodeURI(emailBody);
    let formPrefix = form.nodes.nodes.meta.prefix;
    if (!hostname) {
      hostname = text.defaultDomain;
    }
    if (!formPrefix) {
      formPrefix = text.defaultFormPrefix;
    }
    if (formUser?.entryno) {
      entryno = formUser?.entryno;
    }
    const formDataUrl = `https://${hostname}/#/${formPrefix}/${form?.doc_id}/${entryno}`;
    if (tempBody.includes('formDataUrl')) tempBody = replaceAllForString('={formDataUrl}=', formDataUrl, tempBody);
    if (tempBody.includes('entryno') && entryno) tempBody = replaceAllForString('={entryno}=', entryno, tempBody);
    if (tempBody.includes('entrydate') && !formUser.hasOwnProperty('entrydate')) tempBody = replaceAllForString('={entrydate}=', '', tempBody);
    for (const key in form) {
      if (!form[key]) form[key] = "";
      if (form[key] instanceof firebase.firestore.Timestamp) {
        form[key] = moment(form[key].toDate()).format(formatDatePicker);
      }
      if (emailTitle.includes(key)) {
        mailTitle = replaceAllForString(`={${key}}=`, form[key], mailTitle);
      }
      if (emailBody.includes(key)) {
        tempBody = replaceAllForString(`={${key}}=`, form[key], tempBody);
      }
    }

    for (const key in formUser) {
      const dataNodesByKey = dataNodes.filter((item: any) => item.name == key)[0];
      if (dataNodesByKey && dataNodesByKey.datatype == 'radio') {
        formUser[key] = (new MapTranslateTextPipe(this.translateService).transform(formUser[key], key, dataNodesByKey.candidateData, 'ja')) ?? ''
      }
      if (dataNodesByKey && formUser[key] && typeof formUser[key] === 'object' && formUser?.[key]?.[0]?.hasOwnProperty('item_text')) {
        let optionList = '';
        const itemTexts = formUser[key].map((item: any) => {
          return new MapTranslateTextPipe(this.translateService).transform(item.item_text, key, dataNodesByKey.candidateData, 'ja')
        });
        optionList = itemTexts.join(', ');
        formUser[key] = optionList
      }

      if (formUser[key] instanceof firebase.firestore.Timestamp) {
        formUser[key] = moment(formUser[key].toDate()).format(formatDatePicker);
      }

      if (emailBody.includes(key)) {
        const dataNode = dataNodes.filter((element: any) => element.name === key);
        if (Boolean(dataNode.length) && dataNode[0]['datatype'] == DataType.zipcode && typeof formUser[key] === 'object' && formUser.hasOwnProperty('address'))
          tempBody = replaceAllForString(`={${key}}=`, formUser[key][key], tempBody);
        else tempBody = replaceAllForString(`={${key}}=`, formUser[key], tempBody);
      }
    }
    if (emailBody.includes('={entrydate}=') && !formUser.hasOwnProperty('entrydate')) tempBody = tempBody.replace('&#38283;&#20652;&#26085;: ={entrydate}=', '');
    dataNodes.forEach((item: any, index: number) => {
      switch (item.name) {
        case 'entryno':
        case 'entrydate': {
          emailContent += '';
          break;
        }
        case 'status': {
          emailContent += Boolean(formUser[item.name]?.length) ? `<p>${item.title_ja}: ${formUser[item.name]}</p>` : '';
          break;
        }
        case 'members': {
          emailContent += Boolean(formUser[item.name]?.length) ? `<p>${item.title_ja}: ${formUser[item.name].charAt(0)}名</p>` : '';
          break;
        }
        case 'zipcode': {
          emailContent += typeof formUser[item.name] === 'object' && Boolean(formUser[item.name][item.name]?.length) ? `<p>${item.title_ja}: ${formUser[item.name][item.name]}</p>` : '';
          break;
        }
        default: {
          if ((typeof formUser[item.name] === 'string' && formUser[item.name]?.length) || typeof formUser[item.name] === 'number') {
            emailContent += `<p>${item.title_ja}: ${formUser[item.name]}</p>`;
          } else {
            emailContent += '';
          }
        }
      }

      if (index === dataNodes.length - 1) {
        emailContent += '<br/>'
      }
    })

    const mailBody = tempBody.replace('={form-data}=', emailContent);
    dataEmail.forEach((email: string) => {
      const newDocRef = this.db.collection('mail').doc().ref;
      const dataEmail: any = {
        "to": email,
        "message": {
          "subject": mailTitle,
          "html": mailBody
        }
      };
      if (form.useQrCode) {
        dataEmail['message']['attachments'] = [{
          filename: 'qr.png',
          path: formUser?.img
        }];
      }
      batch.set(newDocRef, dataEmail);
    })
    await batch.commit();
  }

  async delete(currentUser: any, formId: string) {
    const batch = this.db.firestore.batch();
    const formData = await this.db.collection('users').doc(currentUser.uid).collection('form').doc(formId).collection('form-data').get().toPromise();
    formData?.forEach((query: any) => {
      batch.delete(query.ref)
    })
    if (currentUser.parentUserId && currentUser.parentUserId.length) {
      const groups = await this.db.collection('users').doc(currentUser.parentUserId).collection('groups', ref => ref.where('formIds', 'array-contains', formId)).get().toPromise()
      groups?.forEach((query) => {
        const data: any = query.data();
        data.formIds = removeItem(data.formIds, formId);
        const newDocRef = query.ref;
        batch.update(newDocRef, data);
      })
    }
    const formRef = this.db.collection('users').doc(currentUser.uid).collection('form').doc(formId).ref
    batch.delete(formRef)
    await batch.commit();
  }
  getFormData(uid: string, formId: string) {
    return this.db.collection('users').doc(uid).collection('form').doc(formId).collection('form-data', ref => ref.orderBy('createdAt', 'desc')).valueChanges();
  }

  async deleteFormData(form: any, id: string) {
    await this.db.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).collection('form-data').doc(id).delete();
  }

  async deleteAllFormData(form: any, listId: any) {
    const batch = this.db.firestore.batch();
    listId.forEach((id: string) => {
      const newDocRef = this.db.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).collection('form-data').doc(id).ref;
      batch.delete(newDocRef);
    });
    await batch.commit();
  }

  getBase64QrCode(data: string) {
    let dataUrl = "";
    qrcode.toDataURL(data, { width: 300, margin: 1 }, (error, url) => {
      if (!error) dataUrl = url
    });
    return dataUrl;
  }

  dataStringBase64(form: any, entryno: string, hostName: string) {
    const prefix = form.nodes.nodes.meta.prefix ? form.nodes.nodes.meta.prefix + "/" : "";
    return `https://${hostName}/#/${prefix}${form.doc_id}/${entryno}`;
  }

  async deleteFile(filePath: string) {
    return new Promise<any>(() => {
      this.storage.refFromURL(filePath).delete();
    });
  }

  upload(form: any, entryno: string, img: string) {
    return new Promise<any>((resolve, reject) => {
      const path = `/${form.user_id}/${form.doc_id}/${entryno}/${entryno}.jpg`
      this.storage.ref(path).putString(img.split(/,(.+)/)[1], 'base64', { contentType: 'image/png' }).then((snapshot) => {
        resolve(snapshot.ref.getDownloadURL())
      }).catch((err) => {
        reject(err);
      })
    });
  }

  uploadFile(form: any, entryno: string, file: any) {
    return new Promise<any>((resolve, reject) => {
      const path = `/${form.user_id}/${form.doc_id}/${entryno}/${file.name}`
      this.storage.ref(path).put(file, {
        contentType: file.type,
        contentDisposition: "attachment"
      }).then((snapshot) => {
        resolve(snapshot.ref.getDownloadURL())
      }).catch((err) => {
        reject(err);
      })
    });
  }

  uploadFilePublicStorage(form: any, entryno: string, file: any, isUploadQrImage: boolean = false) {
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp, environment.publicStorageBucket);
    return new Promise<any>((resolve, reject) => {
      let data: any;
      if (isUploadQrImage) {
        const path = `/${form.user_id}/${form.doc_id}/${entryno}/${entryno}.jpg`;
        const storageRef = ref(storage, path);
        data = this.storage.refFromURL(storageRef.toString()).putString(file.split(/,(.+)/)[1], 'base64', { contentType: 'image/png' });
      } else {
        const path = `/${form.user_id}/${form.doc_id}/${entryno}/${file.name}`;
        const storageRef = ref(storage, path);
        data = this.storage.refFromURL(storageRef.toString()).put(file, {
          contentType: file.type,
          contentDisposition: "attachment"
        })
      }
      data.then((snapshot: any) => {
        resolve(snapshot.ref.getDownloadURL())
      }).catch((err: any) => {
        reject(err);
      })
    });
  }

  async getObjectFileStorage(url: any) {
    const dataUrl = typeof url === 'object' ? url.url : url;
    return new Promise((resolve, reject) => {
      this.storage.refFromURL(dataUrl).getMetadata().pipe(take(1)).subscribe({
        next: (data) => resolve(data),
        error: (() => reject(null))
      })
    }).catch(() => {
      return null;
    });
  }

  async checkDocFormDataExist(form: any, entryno: any) {
    const data = await firstValueFrom(this.db.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).collection('form-data').doc(entryno).get());
    return data.exists;
  }

  private getLastIndexDuplicateRecord(dataImport: any) {
    return dataImport.reverse().reduce((unique: any, record: any) => {
      if (!unique.some((obj: any) => {
        if (!record.entryno.length || record.entryno == "''") return false;
        return obj.entryno === record.entryno;
      })) unique.push(record);
      return unique;
    }, []);
  }

  async uploadMultipleFileOnStorage(files: any, form: any) {
    const promises = [];
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp, environment.publicStorageBucket);
    for (let i = 0; i < files.length; i++) {
      const item = files[i]
      const file = item.image;
      const metadata = {
        contentType: "image/jpeg",
      };
      const path = `/${form.user_id}/${form.doc_id}/${item.entryno}/${item.entryno}.jpg`;
      const storageRef = ref(storage, path);
      promises.push(uploadString(storageRef, file.split(/,(.+)/)[1], 'base64', metadata).then(async uploadResult => {
        return {
          url: await getDownloadURL(uploadResult.ref),
          entryno: item.entryno
        }
      }))
    }
    return await Promise.all(promises)
  }

  removeDoubleQuoteFile(dataFile: any) {
    const dataUser = dataFile.data.map((data: any) => {
      const item: any = {};
      for (const key in data) {
        item[key] = data[key].replace(RuleValidations.regexDoubleQuote, '');
        if (checkStringContainsHTMLTags(data[key])) item[key] = data[key];
      }
      return item;
    });
    return dataUser;
  }

  private formatMultipleLineInCellToObject(listFormData: Array<any>, dataNodesOfObjectValue: any) {
    for (const formData of listFormData) {
      for (const node of dataNodesOfObjectValue) {
        const dataValue: any = {};
        if (formData.hasOwnProperty(node.name) && Boolean(formData[node.name].length)) {
          formData[node.name].split('\n').forEach((line: any) => {
            const [key, value] = line.split(': ');
            dataValue[key.toLowerCase()] = value;
            if (new RegExp(RuleValidations.regexFloatNumber).test(value)) {
              dataValue[key.toLowerCase()] = parseFloat(value);
            }
          });
        }
        formData[node.name] = dataValue;
      }
    }
  }

  private setDefaultStatusFormData(dataImport: Array<any>, statusNode: any) {
    if (statusNode.value?.length) {
      return dataImport.map((formData: any) => {
        if (!formData.status) formData.status = statusNode.value[0];
        return formData;
      })
    }
    return dataImport;
  }

  async createOrUpdateFormData(formConfig: any, dataFile: any, isCurrentUser: boolean, hostName: string) {
    try {
      const batch = this.db.firestore.batch();
      const currentUser: any = firebase.auth().currentUser;
      const responseUser = await firstValueFrom(this.userService.getUserInById(currentUser.uid));
      const currentUserData: any = responseUser.payload.data();
      const dataNodesOfObjectValue = formConfig.node.filter((node: any) => [DataType.maplocation, DataType.locationaddress].includes(node.datatype))
      let isValidDataImport = true;
      const dataUpdate: Array<any> = [];
      const dataCreate: Array<any> = [];
      const listFormData = this.removeDoubleQuoteFile(dataFile);
      const isMissingField = this.checkMissingFieldImport(dataFile, formConfig);
      if (isMissingField) return;

      this.formatMultipleLineInCellToObject(listFormData, dataNodesOfObjectValue);
      let dataImport = this.getLastIndexDuplicateRecord(listFormData);
      if (formConfig.statusNode) {
        dataImport = this.setDefaultStatusFormData(dataImport, formConfig.statusNode);
      }

      for (const formData of dataImport) {
        const docId = formData.entryno;
        isValidDataImport = this.validateFormData(formConfig, formData);
        if (!isValidDataImport) break;
        await this.formatDataImport(formData, formConfig, currentUserData);
        const formDataDocRef = this.db.collection('users').doc(formConfig.data.user_id).collection('form').doc(formConfig.data.doc_id).collection('form-data').doc(formData['entryno']).ref;
        await formDataDocRef.get().then(async (doc) => {
          if (!doc.exists) {
            formData.formId = formConfig.data.doc_id;
            formData.createdAt = Timestamp.now();
            dataCreate.push(formData)
          } else {
            this.getFormDataEntryNo(formConfig.data.user_id, formConfig.data.doc_id, docId).subscribe(async (formDataValue) => {
              this.deleteFileImport(formDataValue, formData);
            })
            if (isCurrentUser) {
              batch.update(formDataDocRef, formData);
              this.getOwnerFormDataById(formConfig.data.user_id, formConfig.data.doc_id, formData.entryno).subscribe((response: any) => {
                formData['img'] = response.img;
              });
              dataUpdate.push(formData)
            }
          }
        })
      }
      if (isValidDataImport) {
        if (dataCreate.length) {
          const dataUploadImage = dataCreate.map((item) => {
            this.waitOnly(1);
//            console.log("waitOnly");
            return {
              image: this.getBase64QrCode(this.dataStringBase64(formConfig.data, item['entryno'], hostName)),
              entryno: item['entryno']
            }
          })
          await this.uploadMultipleFileOnStorage(dataUploadImage, formConfig.data).then((res) => {
            dataCreate.forEach((item) => {
              const dataImage = res.filter((image) => item.entryno == image.entryno)[0]
              item.img = dataImage?.url ?? null
              const newDocRef = this.db.collection('users').doc(formConfig.data.user_id).collection('form').doc(formConfig.data.doc_id).collection('form-data').doc(item['entryno']).ref;
              batch.set(newDocRef, item);
              if (formConfig.data.isSendMailFormRegist) {
                const minValue = 1;
                const maxValue = 60;
                const randomInt = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
                this.wait(randomInt, item, formConfig.data, MAIL_TYPE_LIST.mailRegist, '', currentUserData.hostname ?? '');

//                this.sendMailFormUser(item, formConfig.data, MAIL_TYPE_LIST.mailRegist, '', currentUserData.hostname ?? '');
              }
            })
          })
        }
        dataUpdate.forEach((item) => {
          if (formConfig.data.isSendMailUpdateFormData) {
            this.sendMailFormUser(item, formConfig.data, MAIL_TYPE_LIST.mailUpdateFormData)
          }
        })
        await this.waitBatch(60, batch);
//        await batch.commit();
        this.alertService.success(this.translateService.instant('alertMessages.formData.import.success'));
      } else {
        this.alertService.error(this.translateService.instant('alertMessages.formData.import.fail'));
      }
    } catch (e) {
      this.alertService.error(this.translateService.instant('alertMessages.formData.import.fail'));
    }
  }

  private async formatDataImport(formData: any, formConfig: any, currentUserData: any) {
    if (formConfig.uidNode) {
      formData.userid = currentUserData.uid ?? null;
      formData.ownerFormData = currentUserData.displayName;
    }
    if (formConfig.isPrefixPluginTbc) {
      const passingDate = formatStringToDate(formData['passingDate']);
      if (passingDate) formData['passingDate'] = Timestamp.fromDate(passingDate);
      const phoneTableList = await firstValueFrom(this.getPhoneTableList());
      if (formData['address'].length) formData['customercode'] = filterAddressInPhoneTable(phoneTableList, formData['address'], 'prefectureNo');
    }
    for (const node of formConfig.nonHiddenNode) {
      const key = node.name;
      if (node.name === 'entryno' && (new RegExp(RuleValidations.regexSingleAndDoubleQuote).test(formData[key]) || !formData[node.name].length)) {
        formData[key] = getEntryno()
      }
      if (typeof formData[key] === 'string') {
        formData[key] = formData[key];
        if (!checkStringContainsHTMLTags(formData[key])) formData[key] = formData[key].replace(RuleValidations.regexSingleAndDoubleQuote, "");
      }
      if ([DataType.number, DataType.float, DataType.slider].includes(node.datatype) && formData[key]) {
        formData[key] = getNumberInString(formData[key]);
      }
      if (node.datatype === DataType.zipcode && formData.hasOwnProperty('address')) {
        formData[key] = {
          zipcode: formData['zipcode'],
          address: formData['address']
        };
      }
      if (node.datatype === DataType.radio && formData[key]) {
        let candidateData: any = node['candidateData']['ja'];
        if (new RegExp(RuleValidations.regexOptionText).test(formData[key])) {
          candidateData = node['candidateData']['en'];
        }
        const dataCompare = candidateData.map((item: string) => item.toLowerCase());
        const index = dataCompare.indexOf(formData[key].trim().toLowerCase());
        if (index >= 0) {
          formData[key] = node['candidateData']['en'][index];
        }
        const unit = node['candidateData']['en'][0].replace(RuleValidations.extractNumberInString, "");
        if (key === 'members' && !(new RegExp(RuleValidations.getNumberInString).test(formData[key]))) {
          formData[key] = `${formData[key]}${unit}`;
        }
      }
      if (typeof formData[key] === 'undefined' && node.datatype === DataType.check) {
        formData[key] = '';
      }
      if (typeof formData[key] === 'undefined' || formData[key] === DATA_NONE) {
        formData[key] = null;
      }
      if (node.datatype === DataType.check && formData[key]) {
        let dataOptionImport: any = [];
        const dataOption = formData[key].split(',');
        let candidateData: any = node['candidateData']['ja'];
        if (new RegExp(RuleValidations.regexOptionText).test(formData[key])) {
          candidateData = node['candidateData']['en'];
        }
        const dataCompare = candidateData.map((item: string) => item.toLowerCase());
        dataOption.forEach((option: string) => {
          const index = dataCompare.indexOf(option.trim().toLowerCase());
          if (index >= 0) {
            const data = {
              item_id: node['candidateData']['en'][index],
              item_text: node['candidateData']['en'][index]
            }
            dataOptionImport.push(data);
          }
        })
        formData[key] = dataOptionImport;
      }
      if (typeof formData[key] === 'string' && !formData[key]?.length) formData[key] = null;
    }
  }

  private checkMissingFieldImport(dataFile: any, formConfig: any) {
    let isMissingField = false;
    const dataImportField = dataFile.meta.fields.filter((item: string) => item != 'ownerFormData');
    const missingFieldRequired = [];
    for (const node of formConfig.node) {
      if (!dataImportField.includes(node.name) && node.required) {
        missingFieldRequired.push(node.name);
      }
    }
    if (missingFieldRequired.length) {
      const listFieldName = missingFieldRequired.join();
      if (missingFieldRequired.length === 1) {
        this.alertService.error(this.translateService.instant('alertMessages.formData.import.missingSingleField', { listFieldName: listFieldName }));
      } else {
        this.alertService.error(this.translateService.instant('alertMessages.formData.import.missingMultipleField', { listFieldName: listFieldName }));
      }
      isMissingField = true;
    }
    return isMissingField;
  }

  private validateFormData(formConfig: any, formData: any) {
    let valid = true;
    if (formConfig.prefix && formConfig.prefix.length) {
      if (formConfig.prefix === PREFIX_LIST.pluginAttendance || formConfig.prefix === PREFIX_LIST.pluginNaruo) {
        valid = this.validateMemberDataImport(formData);
      }
      if (formConfig.prefix === PREFIX_LIST.pluginTbc && formData['passingDate'].length && !(new RegExp(RuleValidations.regexDateString).test(formData['passingDate']))) {
        valid = false;
        this.alertService.error(this.translateService.instant('errorMessages.formDataImport.passingDate.pattern'));
      }
    }
    for (const node of formConfig.node) {
      const key = node.name;
      let value = formData[key];
      if (typeof formData[key] === 'string') {
        value = formData[key].replace(RuleValidations.regexSingleAndDoubleQuote, "");
        if (checkStringContainsHTMLTags(formData[key])) value = formData[key];
      }
      if (node) {
        if (node.required && !Boolean(value?.length) && !value && key != 'entryno') {
          if ((key === 'company' || key === 'branch') && [INDIVIDUAL_ROLE, INDIVIDUAL_JA_ROLE].includes(formData['role'])) {
            valid = true;
          } else {
            valid = false;
            this.alertService.error(this.translateService.instant('errorMessages.formDataImport.required', { name: key }));
          }
        }
        if (key === 'members' && value.length) {
          const dataCompare = node['candidateData']['en'].concat(node['candidateData']['ja']);
          const filterDataMember = dataCompare.filter((item: any) => item.includes(value.trim()));
          if (!filterDataMember.length) {
            valid = false;
            this.alertService.error(this.translateService.instant('errorMessages.formDataImport.pattern', { name: key }));
            break;
          }
        }
        if (node.datatype === DataType.radio && key != 'members') {
          let candidateData: any = node['candidateData']['ja'];
          let unit = value;
          if (value.includes(' ')) {
            unit = value.split(' ')[1];
          }
          if (new RegExp(RuleValidations.regexOptionText).test(unit)) {
            candidateData = node['candidateData']['en'];
          }
          const dataCompare = candidateData.map((item: string) => item.toLowerCase());
          if (!!value.trim().toLowerCase()?.length || node.required) {
            const dataCheckValid = dataCompare.includes(value.trim().toLowerCase());
            if (!dataCheckValid) {
              valid = false;
              this.alertService.error(this.translateService.instant('errorMessages.formDataImport.pattern', { name: key }));
              break;
            }
          }
        }
        if (node.datatype === DataType.check) {
          const dataOption = formData[key].split(',');
          let candidateData: any = node['candidateData']['ja'];
          if (new RegExp(RuleValidations.regexOptionText).test(value)) {
            candidateData = node['candidateData']['en'];
          }
          const dataCompare = candidateData.map((item: string) => item.toLowerCase())
          const dataCheckValid = dataOption.filter((item: string) => !dataCompare.includes(item.trim().toLowerCase()))
          if (!!value.trim().toLowerCase()?.length || node.required) {
            if (dataCheckValid.length) {
              valid = false;
              this.alertService.error(this.translateService.instant('errorMessages.formDataImport.pattern', { name: key }));
              break;
            }
          }
        }
        if (key === 'entryno' && Boolean(value.length) && !new RegExp(RuleValidations.regexEntryNo).test(value)) {
          valid = false;
          this.alertService.error(this.translateService.instant('errorMessages.formDataImport.entryno.pattern'));
          break;
        }

        if (node.validation && node.validation.length && value) {
          const [minValue, maxValue, minLength, maxLength] = node.validation;
          if ([DataType.input, DataType.label].includes(node.type) && [DataType.text, DataType.email, DataType.link, DataType.html].includes(node.datatype)) {
            if (!(new RegExp(RuleValidations[node['validationCondition'] as keyof typeof RuleValidations]).test(value))) {
              valid = false;
              this.alertService.error(this.translateService.instant('errorMessages.formDataImport.pattern', { name: key }));
              break;
            }
            if (node.datatype === DataType.link && value.length && !(new RegExp(RuleValidations['REGEX_LINK']).test(value))) {
              valid = false;
              this.alertService.error(this.translateService.instant('errorMessages.formDataImport.pattern', { name: key }));
              break;
            }
            if (value.length < minLength) {
              valid = false;
              this.alertService.error(this.translateService.instant('errorMessages.formDataImport.minlength', { name: key, minlength: minLength }));
              break;
            }
            if (value.length > maxLength && maxLength > 0 && node.name != 'entryno') {
              valid = false;
              this.alertService.error(this.translateService.instant('errorMessages.formDataImport.maxlength', { name: key, maxlength: maxLength }));
              break;
            }
          }
          if (MIN_MAX_VALUE_DATA_TYPE_LIST.includes(node.datatype)) {
            if (Number(value) < minValue) {
              valid = false;
              this.alertService.error(this.translateService.instant('errorMessages.formDataImport.min', { name: key, minValue: minValue }));
              break;
            }
            if (Number(value) > maxValue && maxValue > 0) {
              valid = false;
              this.alertService.error(this.translateService.instant('errorMessages.formDataImport.max', { name: key, maxValue: maxValue }));
              break;
            }
          }
        }
        if (node.required && node.datatype == DataType.zipcode && !(new RegExp(RuleValidations.zip).test(value))) {
          valid = false;
          this.alertService.error(this.translateService.instant('errorMessages.formDataImport.pattern', { name: key }));
          break;
        }
      } else {
        valid = false;
      }
    }
    return valid;
  }

  validateMemberDataImport(formData: any) {
    if (formData['members'].length) {
      const totalMemberValidate: number = getNumberInString(formData['members']);
      for (let memberIndex = 1; memberIndex <= totalMemberValidate; memberIndex++) {
        const member = `member${memberIndex}`;
        const memberNumber = `member${memberIndex}number`;
        const memberSex = `member${memberIndex}sex`;
        const memberAge = `member${memberIndex}age`;
        const memberKane = `member${memberIndex}kane`;
        if (formData.hasOwnProperty(member) && !formData[member].length) {
          this.alertService.error(this.translateService.instant('errorMessages.formDataImport.required', { name: member }));
          return false;
        }
        if (formData.hasOwnProperty(memberNumber) && !formData[memberNumber].length) {
          this.alertService.error(this.translateService.instant('errorMessages.formDataImport.required', { name: memberNumber }));
          return false;
        }
        if (formData.hasOwnProperty(memberSex) && !formData[memberSex].length) {
          this.alertService.error(this.translateService.instant('errorMessages.formDataImport.required', { name: memberSex }));
          return false;
        }
        if (formData.hasOwnProperty(memberAge) && !formData[memberAge].length) {
          this.alertService.error(this.translateService.instant('errorMessages.formDataImport.required', { name: memberAge }));
          return false;
        }
        if (formData.hasOwnProperty(memberKane) && !formData[memberKane].length) {
          this.alertService.error(this.translateService.instant('errorMessages.formDataImport.required', { name: memberKane }));
          return false;
        }
      }
      return true;
    }
    return false;
  }

  deleteFileImport(formDataValue: any, formData: any) {
    const deleteUrl: Array<string> = [];
    if (formDataValue) {
      for (const key in formData) {
        if (['fileupload', 'imageupload', 'movieupload', 'soundupload'].includes(key) &&
          formDataValue[key] && Boolean(formDataValue[key].length) && !Boolean(formData[key])) {
          deleteUrl.push(formDataValue[key]);
        }
      }
      for (const item of deleteUrl) {
        this.deleteFile(item);
      }
    }
  }

  async createdOrUpdateExam(form: any, formData: any) {
    await this.db.collection('users').doc(form.user_id).collection('form').doc(formData.formId).collection('form-data').doc(formData.entryno).set(formData);
  }

  async updateFormDataStatus(userId: string, formId: string, formDataId: string, data: any) {
    await this.db.collection('users').doc(userId).collection('form').doc(formId).collection('form-data').doc(formDataId).update(data);
  }

  async getImageQrUrl(numberOfRecords: number, form: any, isRedirectFormGuest: boolean = false) {
    let imageQrUrl: string = "";
    let hostname: string = "";
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      const currentUser: any = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (currentUser) {
        const formId = form.doc_id ?? form.id;
        if (currentUser.hasOwnProperty('hostname') && currentUser.hostname) {
          hostname = currentUser.hostname;
        } else {
          hostname = text.defaultDomain;
        }
        if (numberOfRecords >= form?.maxRecords && !isRedirectFormGuest) {
          imageQrUrl = await firstValueFrom(this.translateService.stream('alertMessages.formUser.reachMaxNumberRegister'));
        } else {
          imageQrUrl = `https://${hostname}/#/form-guest/${formId}`;
        }
      }
    }
    return imageQrUrl;
  }

  getFormUserByCameraId(form: any, cameraId: string) {
    return this.db.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).collection('form-data', ref => ref.where('camera_id', '==', cameraId).orderBy('createdAt', 'desc')).valueChanges()
  }

  getPhoneTableList() {
    return this.db.collection('phonetables').valueChanges();
  }

  async getFormByGroupPaths(userId: string, groupPath: string) {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users').doc(userId).collection('form', ref => ref.where('groupPaths', 'array-contains', groupPath).orderBy('createdAt', 'desc')).valueChanges().subscribe((forms) => {
        resolve(forms)
      })
    })
  }

  async updateGroupPathInForm(form: any, groupPaths: Array<string>) {
    const dataUpdate = {
      ...form,
      groupPaths: groupPaths.length ? groupPaths : null
    }
    await this.db.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).set(dataUpdate);
  }

  getFormsByTabId(userId: string, tabId: string) {
    return this.db.collection('users').doc(userId).collection('form', ref => ref.where('tabId', '==', tabId)).valueChanges();
  }

  getValueFormDataList(userId: string, formId: string) {
    return this.db.collection('users').doc(userId).collection('form').doc(formId).collection('form-data', ref => ref.orderBy('createdAt', 'desc')).valueChanges();
  }

  async updateMultipleGroupPathForm(listForm: Array<any>, groupPath: string) {
    const batch = this.db.firestore.batch();
    for (const form of listForm) {
      if (form.groupPaths && form.groupPaths.length) {
        let newGroupPaths: Array<string> = removeItem(form.groupPaths, groupPath);
        const formRef = this.db.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).ref;
        batch.update(formRef, { groupPaths: newGroupPaths.length ? newGroupPaths : null })
      }
    }
    await batch.commit();
  }

  getFileDownloadUrl(fileUrl: string) {
    return new Promise((resolve, reject) => {
      this.storage.refFromURL(fileUrl).getDownloadURL().subscribe({
        next: (data) => resolve(data),
        error: (error) => reject(error)
      })
    })
  }
}
