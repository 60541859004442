import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  EVENT_LOG_TYPE,
  INIT_EDITOR,
  RuleValidations, SELECT_TEMPLATE,
  text,
  VIDEO_CONTENT,
  DEFAULT_MAX_NUMBER_OF_PARKING_LOT,
  PREFIX_LIST,
  ACTION_LIST,
  LAYOUT_FORM_LIST,
  ACCOUNT_DEFAULT_RESTRICT
} from "@app/constants";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AlertService, FirebaseAnalyticService, FormService, TemplateService, UserService } from "@app/services";
import { firstValueFrom, Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { checkAccountRestrictMode, checkInputEnter, dayFormat, formatSystemTemplateList, getFirstError, getLocaleDatePicker } from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';
import { EditorOptions } from "tinymce";
import { CustomValidatorsService } from '@app/services/custom-validators.service';
import { IAccountRestrict } from '@app/models';

@Component({
  selector: 'app-form-create',
  templateUrl: './form-create.component.html',
  styleUrls: ['./form-create.component.scss']
})
export class FormCreateComponent implements OnInit, OnDestroy {
  @ViewChild('editor') editor: any;
  colorTypesSet: any[] | undefined;
  minDate = new Date(this.getYesterday());
  systemTemplates: any;
  submitted = false;
  loading = false;
  currentUser: any;
  templateType: string = '';
  copyRight: string = '';
  ruleValidations = RuleValidations;
  currentDatepickerLocale: string = "";
  currentLanguage = this.translate.currentLang;
  unitString: null | string = null;
  formNodeUnit: any;
  content = '<p>This is the initial content of the editor</p>';
  initEditor: Partial<EditorOptions> = INIT_EDITOR
  selectedTemplate: any = {};
  formCreate = new FormGroup({
    formName: new FormControl(null, { validators: [Validators.required, Validators.maxLength(this.ruleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace] }),
    note: new FormControl('', { validators: [Validators.maxLength(this.ruleValidations.maxLength4096)] }),
    member: new FormControl(1, { validators: [Validators.required, Validators.min(this.ruleValidations.minNumber1), Validators.max(this.ruleValidations.maxNumber99999999)] }),
    eventDate: new FormControl(null, { validators: [Validators.required] }),
    systemTemplate: new FormControl(null),
    templateId: new FormControl(null),
    layout: new FormControl(LAYOUT_FORM_LIST[0], { validators: [Validators.required] }),
    colorCode: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    backgroundCode: new FormControl(''),
    stringCodeForm: new FormControl(''),
  });
  defaultTemplateSelect = 0
  templates: any;
  maxRecords: number = this.ruleValidations.maxNumber99999999;
  queryParams: any;
  accountRestrictConfig: IAccountRestrict = ACCOUNT_DEFAULT_RESTRICT;
  subscription: { [key: string]: Subscription | null } = {
    template: null,
    systemTemplate: null
  }

  constructor(
    private formService: FormService,
    private userService: UserService,
    private alertService: AlertService,
    private router: Router,
    private translate: TranslateService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private templateService: TemplateService
  ) {
    this.queryParams = this.router.getCurrentNavigation()?.extras.state;
  }

  public ngOnInit() {
    this.getSystemTemplates();
    this.getCurrentUser().then();
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.translate.onLangChange.subscribe((e) => {
      this.currentDatepickerLocale = getLocaleDatePicker(e.lang);
      if (this.formNodeUnit != undefined) {
        this.unitString = this.formNodeUnit[e.lang][0];
      }
    });
    this.getColors();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscription['template']?.unsubscribe();
    this.subscription['systemTemplate']?.unsubscribe();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.currentUser = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (this.currentUser) {
        this.accountRestrictConfig = checkAccountRestrictMode(this.currentUser);
        this.getTemplate();
      }
    }
  }

  getTemplate() {
    this.subscription['template'] = this.templateService.getValueTemplateList(this.currentUser?.uid).subscribe({
      next: (data) => {
        if (data.length) {
          this.templates = data.map((item: any) => {
            const template = {
              ...item,
              nodes: JSON.parse(item.nodes)
            }
            return template;
          }).sort((prev, next) => prev.displayIndex - next.displayIndex).filter((item: any) => item.nodes.meta.publish);
          let defaultTemplateSelect = SELECT_TEMPLATE.SYSTEM_TEMPLATE;
          if ((this.templates?.length || !!this.currentUser.hostname) && this.accountRestrictConfig.isDisplayTemplateList) defaultTemplateSelect = SELECT_TEMPLATE.TEMPLATE
          this.changeTemplateType(defaultTemplateSelect);
        }
      }
    })
  }

  private getSystemTemplates() {
    this.subscription['systemTemplate'] = this.templateService.getSnapshotSystemTemplateList().subscribe({
      next: (data) => {
        this.systemTemplates = formatSystemTemplateList(data);
      }
    })
  }

  backToIndexForm() {
    this.queryParams = {
      ...this.queryParams,
      query_search: this.queryParams.querySearch ?? '',
      start_date: this.queryParams.startDate,
      end_date: this.queryParams.endDate
    }
    this.router.navigate(['form'], { queryParams: this.queryParams }).then();
  }

  changeTemplateType(type: number) {
    this.defaultTemplateSelect = type;
    this.formCreate.controls.templateId.setValue(null);
    this.formCreate.controls.templateId.setValidators(null);
    this.formCreate.controls.systemTemplate.setValue(null);
    this.formCreate.controls.systemTemplate.setValidators(null);
    if (SELECT_TEMPLATE.TEMPLATE === type && this.accountRestrictConfig.isDisplayTemplateList) {
      this.formCreate.controls.systemTemplate.disable();
      this.formCreate.controls.templateId.enable();
      this.formCreate.controls.templateId.setValidators([Validators.required]);
    } else {
      this.formCreate.controls.templateId.disable()
      this.formCreate.controls.systemTemplate.enable()
      this.formCreate.controls.systemTemplate.setValidators([Validators.required]);
    }
    this.formCreate.controls.templateId.updateValueAndValidity();
    this.formCreate.controls.systemTemplate.updateValueAndValidity();
  }

  private async getColors() {
    const data = await firstValueFrom(this.formService.getColors())
    this.colorTypesSet = data.map((item: any) => {
      return item.payload.doc.data()
    })
    this.formCreate.controls['colorCode'].setValue(this.colorTypesSet[0].colorCode)
  }

  getYesterday() {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - 1));
  }

  ignoreString($event: any) {
    const keycode = $event?.keyCode
    if (keycode < 48 || keycode > 57) {
      $event?.preventDefault()
    }
  }

  increaseValueInputNumber($event: any) {
    const member = this.formCreate.controls['member'];
    if (member.value) {
      member.setValue(Number(member.value) + 1)
    } else {
      member.setValue(1)
    }
  }

  decreaseValueInputNumber($event: any) {
    const member = this.formCreate.controls['member'];
    if (member.value) {
      member.setValue(Number(member.value) - 1)
    } else {
      member.setValue(1)
    }
  }

  async submit() {
    this.submitted = true;
    if (this.formCreate.valid) {
      const templateDetail = (this.defaultTemplateSelect === SELECT_TEMPLATE.TEMPLATE ? this.templates : this.systemTemplates).filter((item: any) => {
        return item.docId == (this.defaultTemplateSelect === SELECT_TEMPLATE.TEMPLATE ? this.formCreate.value.templateId : this.formCreate.value.systemTemplate);
      })[0];
      let dataInsert: any = {
        systemTemplateId: this.formCreate.value.systemTemplate ?? null,
        templateTitle: templateDetail.title ?? '',
        templateId: this.formCreate.value.templateId ?? null,
        title: this.formCreate.value.formName,
        templateType: this.templateType,
        displayIndex: null,
        colorCode: this.formCreate.value.colorCode,
        remark: this.formCreate.value?.note,
        maxRecords: this.formCreate.value.member,
        createdAt: new Date(),
        targetDate: this.formCreate.value.eventDate,
        nodes: null,
        backgroundCode: text.defaultBackgroundCode,
        catcheyeImage: null,
        layout: this.formCreate.value.layout,
        organizationName: this.currentUser?.organizationName,
        copyright: this.copyRight,
        stringCodeForm: text.defaultStringCodeForm,
        secretKey: this.currentUser?.secretKey ?? null,
      }
      if (templateDetail) {
        dataInsert.nodes = templateDetail;
      }
      const dataMeta = templateDetail.nodes.meta;
      if (dataMeta.prefix === PREFIX_LIST.pluginAttendance) {
        dataInsert.maximumNumberOfParkingLots = DEFAULT_MAX_NUMBER_OF_PARKING_LOT
      }
      if (dataMeta.hasOwnProperty('prefix') && dataMeta['prefix'] === PREFIX_LIST.pluginTbc) {
        const videoList = dataMeta.videos?.map((element: any) => VIDEO_CONTENT.replace('{videoUrl}', element));
        const dataExam = dataMeta.exams?.map((test: any, testIndex: number) => {
          const dataTest: any = {
            index: test.index + 1,
            name: {
              ja: test.name.ja,
              en: test.name.en,
            },
            questions: [],
            examDescription: {
              ja: null,
              en: null
            }
          };
          test.exam.forEach((question: any, questionIndex: number) => {
            const dataExam: any = {
              question: {
                ja: question.question['ja'][0],
                en: question.question['en'][0]
              },
              datatype: question.datatype,
            }
            dataExam['correct'] = [];
            dataExam['answerList'] = []
            question.answer['en'].forEach((answer: string, answerIndex: number) => {
              let correctValue: boolean = false;
              if (question.correct.find((correct: string) => correct === answer)) {
                correctValue = true;
              }
              dataExam['answerList'][answerIndex] = {
                ja: 'A' + (answerIndex + 1),
                en: 'A' + (answerIndex + 1),
              }
              dataExam.correct.push(correctValue)
            })
            dataTest.questions.push(dataExam);
          })
          return dataTest;
        })
        dataInsert['passingScore'] = dataMeta.passingscore;
        dataInsert['useTest'] = dataMeta.useexam;
        dataInsert['testForm'] = dataExam;
        dataInsert['showCertificate'] = true;
        dataInsert['showExam'] = true;
        dataInsert['showVideo'] = true;
        dataInsert['useVideo'] = 0;
        dataInsert['videoList'] = videoList;
      }
      this.formService.create(dataInsert, this.currentUser).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.CREATE, EVENT_LOG_TYPE.SUCCESS)
        this.alertService.success(this.translate.instant('alertMessages.form.create.success'));
      }).catch((e) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.CREATE, EVENT_LOG_TYPE.ERROR)
        this.alertService.error(this.translate.instant('alertMessages.form.create.fail'));
      });
      this.router.navigate(['form']).then();
    }
  }

  handledChangeTemplate(event: any, type: number) {
    const dataTemplates = type === SELECT_TEMPLATE.TEMPLATE ? [...this.templates] : [...this.systemTemplates];
    const selectedTemplate = dataTemplates.filter((item) => item.docId == event.target.value)[0];
    const { maxRecords, nodes, templateType }: any = selectedTemplate;
    this.formNodeUnit = nodes.meta.unit;
    this.unitString = this.formNodeUnit[this.translate.currentLang][0];
    this.copyRight = nodes.meta.copyRight;
    this.templateType = templateType;
    this.formCreate.controls.member.setValue(maxRecords);
    if (selectedTemplate) this.selectedTemplate = selectedTemplate;
  }
  
  protected readonly checkInputEnter = checkInputEnter;
  protected readonly getFirstError = getFirstError;
  protected readonly dayFormat = dayFormat;
  protected readonly LAYOUT_FORM_LIST = LAYOUT_FORM_LIST;
  protected readonly SELECT_TEMPLATE = SELECT_TEMPLATE;
}
