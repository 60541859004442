import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AlertService, FirebaseAnalyticService, FormService, GroupService, NotificationService, UserService } from "@app/services";
import { FormControl, FormGroup } from "@angular/forms";
import { getFirstError, onlyUnique, removeItem } from "@app/helpers";
import { TranslateService } from "@ngx-translate/core";
import firebase from 'firebase/compat/app';
import { Group } from "@app/models/group";
import { ACTION_LIST, EVENT_LOG_TYPE } from "@app/constants";
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.scss']
})
export class ShareFormComponent implements OnChanges, OnDestroy {
  @Input() form: any;
  @Input() groups: any;
  @Input() activePane: any;
  @Input() visibleModalShare: boolean = false;
  @ViewChild('shareForm') shareForm: any;
  @Output() modalStatus = new EventEmitter<boolean>;
  protected readonly getFirstError = getFirstError;
  dropdownSettings: IDropdownSettings = {
    searchPlaceholderText: '',
    singleSelection: false,
    idField: 'path',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: '',
    enableCheckAll: false,
    noDataAvailablePlaceholderText: ''
  };
  formShare = new FormGroup({
    groupIds: new FormControl<any[]>([])
  });
  submitted: boolean = false;
  selectedGroups: any;
  selectedGroupsRaw: any;
  isEmptySelectedGroup: boolean = false;
  isLoading: boolean = false;
  currentUser: any;
  childUserList: Array<any> = [];
  parentUser: any;
  subscriptionList: { [key: string]: Array<Subscription> | null  } = {
    childUserList: []
  }

  constructor(
    private groupService: GroupService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private changeDetectRef: ChangeDetectorRef,
    private userService: UserService,
    private notificationService: NotificationService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formService: FormService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activePane']) {
      this.getSelectedGroups()
    }
    this.dataSettingDropdown();
    this.getCurrentUser().then();
    this.translateService.onLangChange.subscribe(() => {
      this.dataSettingDropdown()
    });
    this.changeDetectRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();  
  }

  unsubscribeAll() {
    this.subscriptionList['childUserList']?.forEach((sub) => sub.unsubscribe());
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) {
      this.currentUser = await firstValueFrom(this.userService.getValueUserByUid(authUser.uid));
      if (this.currentUser) {
        let userId = this.currentUser.uid;
        if (this.currentUser.parentUserId && this.currentUser.parentUserId.length) {
          userId = this.currentUser.parentUserId;
          this.parentUser = await firstValueFrom(this.userService.getValueUserByUid(userId));
        }
        const childUserListSubscription = this.userService.getValueChildUserList(userId).subscribe({
          next: (data) => {
            this.childUserList = data;
            if (this.parentUser) this.childUserList.push(this.parentUser);
          }
        })
        this.subscriptionList['childUserList']?.push(childUserListSubscription);
      }
    }
  }

  checkEmptySelectedGroup(event: any) {
    if (!this.selectedGroupsRaw.length) this.isEmptySelectedGroup = !event.length;
  }

  getSelectedGroups() {
    if (this.groups && this.form) {
      this.selectedGroups = this.groups.filter((group: any) => group.formIds && group.formIds.includes(this.form.doc_id));
      this.selectedGroupsRaw = this.selectedGroups
    }
  }

  dataSettingDropdown() {
    this.dropdownSettings = {
      ...this.dropdownSettings,
      searchPlaceholderText: this.translateService.instant('shareForm.placeholder')
    }
  }

  async submit() {
    this.submitted = true;
    if (this.formShare.valid) {
      const dataGroupSelect = this.selectedGroups.map((group: any) => {
        return group.path
      })
      let listShareGroups: any = [],
        listUnShareGroups: any = [];
      const dataGroupsSelected = (this.formShare.value.groupIds ?? []).map((data: any) => data?.path);
      const emailCurrentUser = firebase.auth().currentUser?.email;
      for (let index = 0; index < this.groups.length; index++) {
        const group = this.groups[index]
        if (dataGroupsSelected.includes(group.path) && !group.formIds?.includes(this.form.doc_id)) {
          const usersReceiveNotice = this.processDataUser(group.emails, emailCurrentUser)
          listShareGroups.push({
            users: usersReceiveNotice,
            groupName: group.name,
            formName: this.form.title
          })
        } else if (!dataGroupsSelected.includes(group.path) && group.formIds?.includes(this.form.doc_id)) {
          const usersReceiveNotice = this.processDataUser(group.emails, emailCurrentUser)
          listUnShareGroups.push({
            users: usersReceiveNotice,
            groupName: group.name,
            formName: this.form.title
          })
        }
      }
      listShareGroups = listShareGroups.filter((item: any) => item.users.length)
      listUnShareGroups = listUnShareGroups.filter((item: any) => item.users.length)
      const dataFormIds = this.groups.map((group: Group) => {
        if (dataGroupSelect.includes(group.path)) {
          group.formIds = [...(group.formIds ?? []), this.form.doc_id].filter(onlyUnique);
        } else {
          group.formIds = group.formIds ? removeItem(group.formIds, this.form.doc_id) : []
        }
        return {
          path: group?.path,
          formIds: group.formIds,
          name: group.name,
          createdAt: group.createdAt,
          emails: group?.emails
        }
      })
      this.isLoading = true;
      this.formService.updateGroupPathInForm(this.form, dataGroupsSelected).then(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.UPDATE_GROUP_PATH, EVENT_LOG_TYPE.SUCCESS);
      }).catch(() => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.UPDATE_GROUP_PATH, EVENT_LOG_TYPE.ERROR);
      });
      await this.groupService.shareFormToGroups(dataFormIds).then(() => {
        this.alertService.success(this.translateService.instant('alertMessages.group.share.success'));
        if (listShareGroups.length) {
          listShareGroups.forEach((item: any) => {
            this.notificationService.sendNoticeGroup(item.users, 'shareForm', {
              groupName: item.groupName,
              formName: item.formName
            })
          })
        }
        if (listUnShareGroups.length) {
          listUnShareGroups.forEach((item: any) => {
            this.notificationService.sendNoticeGroup(item.users, 'unShareForm', {
              groupName: item.groupName,
              formName: item.formName
            })
          })
        }
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.SHARE, EVENT_LOG_TYPE.SUCCESS);
        this.isLoading = false;
      }).catch((errors) => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM.SHARE, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.group.share.fail'));
      })
      this.shareForm.visible = false
    }
  }

  processDataUser(emails: Array<string>, emailCurrentUser: string | undefined | null) {
    const listEmail = emails.filter((item: string) => item !== emailCurrentUser)
    return listEmail
      .filter((item: any) => item.email !== emailCurrentUser)
      .map((email: any) => {
        const user = this.childUserList.filter((user: any) => user.email === email)[0]
        return {
          email: user?.email,
          id: user?.uid,
          tokens: user?.tokens ?? [],
          active: user?.active
        }
      }).filter((user) => !!user.active);
  }

  visibleChange(e: any) {
    if (!e) {
      this.modalStatus.emit(this.shareForm.visible)
      if (!this.submitted) {
        this.selectedGroups = this.selectedGroupsRaw
      }
      this.submitted = false;
    }
  }
}
