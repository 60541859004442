import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class RouterService {
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  constructor(
    private router: Router
  ) { }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  getPreviousUrl() {
    return this.previousUrl.getValue()
  }

  // TODO: back to previous url with query value
  redirectKeepUrl(urlRedirect: string = '') {
    let prevUrl = this.getPreviousUrl();
    if (!prevUrl.length || !prevUrl.includes('?')) {
      prevUrl = urlRedirect;
    }
    this.router.navigateByUrl(prevUrl);
  }
}
