<app-layout-auth [backgroundColor]="getFormInfo('backgroundCode')" [stringColor]="getFormInfo('stringCodeForm')">
  <header class="form-user-header">
    <a id="logo">
      <div>{{ getFormInfo("organizationName") }}</div>
    </a>
  </header>
  <div class="custom-container"
    [style.--string-color]="getFormInfo('stringCodeForm')"
    [style.--radio-color]="checkColorBg(getFormInfo('stringCodeForm'))"
    [style.--background-color]="getFormInfo('backgroundCode')">
    <c-col class="justify-content-center mb-5">
      <h3 [class]="{'tbc-title': prefixForm === PREFIX_LIST.pluginTbc}">{{ getFormInfo("title") }}</h3>
      <p *ngIf="isDisplayTargetDate(prefixForm)">{{ ('form.inputLabel.deadlineForTesting' | translate) + '：' + getFormInfo("targetDate") }}</p>
      <div [innerHTML]="exam?.examDescription[currentLang] ?? '' | safeHtml"></div>
    </c-col>
    <div *ngIf="allowExam">
      <c-card class="form-info mb-3" *ngIf="formData">
        <c-col *ngFor="let data of dataShow; let i = index"
          [ngStyle]="{ 'color': getFormInfo('stringCodeForm') }"
          [class]="{ 'mb-3': i !== (dataShow.length - 1) }">
          <c-col class="mb-1 mt-1" *ngIf="SHOW_FIELDS_EXAM.includes(String(data?.key))">
            {{ ("webview.inputLabel." + data.key) | translate }}
          </c-col>
          <c-input-group>
            <input *ngIf="SHOW_FIELDS_EXAM.includes(String(data?.key))" [readOnly]="true" cFormControl
                   [value]="data.value"/>
          </c-input-group>
        </c-col>
      </c-card>
      <c-card class="pb-3">
        <c-card-body [ngStyle]="{ 'color': getFormInfo('stringCodeForm') }">
          <h3>{{exam.name[currentLang]}}</h3>
          <form cForm [formGroup]="formTest">
            <div formArrayName="testResult">
              <div *ngFor="let question of exam.questions let questionIndex = index" class="mb-4" [formArrayName]="questionIndex">
                <p>{{question.question[currentLang]}}</p>
                <div *ngIf="question.datatype === DataType.check">
                  <div *ngFor="let answer of question.answerList; index as answerIndex">
                    <c-form-check>
                      <input
                        type="checkbox"
                        [formControlName]="answerIndex"
                        cFormCheckInput
                        [value]="!!answer"
                        (change)="changeDataAnswer(questionIndex, question.datatype, answerIndex)"
                        [id]="'answer' + questionIndex + answerIndex"
                      />
                      <label cFormCheckLabel [for]="'answer' + questionIndex + answerIndex">{{ answer[currentLang] }}</label>
                    </c-form-check>
                  </div>
                </div>
                <div *ngIf="question.datatype === DataType.radio">
                  <div *ngFor="let answer of question.answerList; index as answerIndex">
                    <c-form-check>
                      <input
                        type="radio"
                        cFormCheckInput
                        (change)="changeDataAnswer(questionIndex, question.datatype, answerIndex)"
                        [value]="!!answer"
                        [id]="'answer' + questionIndex + answerIndex"
                        [formControlName]="String(answerIndex)"
                      />
                      <label cFormCheckLabel [for]="'answer' + questionIndex + answerIndex">{{ answer[currentLang] }}</label>
                    </c-form-check>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end block-button">
              <button
                class="btn-answer"
                [class]="{
                  'btn-common': true,
                  'disabled-btn': buttonDisabled
                }"
                [ngStyle]="{
                   'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC : getFormInfo('stringCodeForm'),
                   'backgroundColor': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC : getFormInfo('backgroundCode')
                }"
                [disabled]="buttonDisabled" (click)="submit()">
                {{'webview.exam.button.answer' | translate}}
              </button>
            </div>
          </form>
        </c-card-body>
      </c-card>
      <c-callout [color]="calloutStatus" class="callout-status" [ngClass]="'callout-status-' + calloutStatus">
        <span
          [class]="{'tbc-alert': prefixForm === PREFIX_LIST.pluginTbc}"
          [innerHTML]="('webview.exam.messages.' + calloutStatus) | translate: {
            correctAnswer: formData?.testResult?.scores ?? 0,
            answerList: exam.questions.length ?? 0
          }"
        ></span>
      </c-callout>
      <c-card class="mt-3" *ngIf="isPassExam || isFailExam">
        <c-card-body>
          <div class="d-flex justify-content-end block-button">
            <a class="btn-second me-2 mt-0 ms-0"
              target="_blank"
              *ngIf="isFailExam"
              [ngStyle]="{
                'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC : getFormInfo('stringCodeForm'),
                'backgroundColor': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC : getFormInfo('backgroundCode')
              }"
              [href]="WATCH_VIDEO_AGAIN_URL"
            >
              {{'webview.exam.button.video' | translate}}
            </a>
            <a [ngStyle]="{
                  'color': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_STRING_TBC : getFormInfo('stringCodeForm'),
                  'backgroundColor': prefixForm === PREFIX_LIST.pluginTbc ? STYLE_BUTTON_TBC : getFormInfo('backgroundCode')
                }"
               *ngIf="isPassExam"
               class="btn-certificate btn-common ms-0 mt-0 d-flex align-items-center"
               target="_blank"
               routerLink="/{{prefixForm}}/{{formData.formId}}/{{formData.entryno}}/certificate">
               {{ form['showCertificate'] ? ("webview.btnLabel.issueCertificate" | translate) : ('webview.btnLabel.finishCourse' | translate) }}
            </a>
          </div>
        </c-card-body>
      </c-card>
    </div>
    <div *ngIf="!allowExam" class="error-page">
      <span>{{'webview.exam.messages.notAllow' | translate}}</span>
    </div>
    <div class="text-center mt-5 pb-2 text-footer" [innerHTML]="getFormInfo('copyright') | safeHtml"></div>
  </div>
</app-layout-auth>
