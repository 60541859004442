export const LAYOUT_FORM_LIST = ['standard', 'linegraph', 'bargraph', 'images', 'monthlycalender', 'weeklycalendar'];
export enum LayoutFormList {
  standard = 'standard',
  linegraph = 'linegraph',
  bargraph = 'bargraph',
  images = 'images',
  monthlycalender = 'monthlycalender',
  weeklycalendar = 'weeklycalendar'
}
export const LAYOUT_DISPLAY_DATA = ['name', 'date', 'time', 'value'];
export const LAYOUT_BACKGROUND_COLOR_LIST = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(255, 205, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(201, 203, 207, 0.2)'
];
export const LAYOUT_BORDER_COLOR_LIST = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)'
];
export const BAR_CHART_BORDER_WIDTH = 1;
export const LINE_CHART_DOT_RADIUS = 4;
export enum LimitTextDisplay {
  lineLegendName = 20,
  lineTooltipName = 10,
  barLabelName = 10,
  barTooltipName = 10,
  imageName = 25
}
export enum CalendarEventColor {
  background = '#fff',
  text = 'black',
  border = '#ec4a0a'
}
export const LIMIT_LINE_CHART_LEGEND_DISPLAY_SCROLL = 25;
export const CALENDAR_EVENT_LIMIT_PER_DAY = 10;
export const DATE_PICKER_THEME = 'theme-orange';
