import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { checkInputEnter } from "@app/helpers";
import { AddressLocationObject, ADDRESS_SEARCH_RESULT_LIMIT } from "@app/constants";
import { AddressSearchService } from "@app/services";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, AfterViewInit {
  @Input() node: any;
  @Input() dataLocation: string = '';
  @Input() isDisabled: boolean = false
  @Input() placeholder: string | null = ''
  @Output() addressInfo = new EventEmitter<any>();
  locales: Array<any> = [];
  isShowLocales: boolean = false;
  currentLanguage = this.translateService.currentLang;
  info: any = {};
  constructor(
    private addressSearchService: AddressSearchService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getAddressInfo();
  }

  async getAddressInfo() {
    if (this.dataLocation.length) {
      const locale = await this.addressSearchService.getLocale(this.dataLocation);
      const filterLocale = locale.filter((item: any) => item.properties.title === this.dataLocation);
      if (filterLocale.length) {
        const [lng, lat] = filterLocale[0]['geometry']['coordinates'];
        this.info[AddressLocationObject.location] = this.dataLocation;
        this.info[AddressLocationObject.lat] = lat;
        this.info[AddressLocationObject.lng] = lng
        this.addressInfo.emit(this.info);
      }
    }
  }

  async searchLocale(event: any) {
    const searchMapValue = event.target.value
    if (searchMapValue) {
      const locale = await this.addressSearchService.getLocale(searchMapValue);
      this.locales = locale.slice(0, ADDRESS_SEARCH_RESULT_LIMIT);
      this.isShowLocales = true;
    } else {
      this.locales = [];
      this.isShowLocales = false;
    }
  }

  setDataInputAutoCompete(locale: any) {
    if (!this.node) this.dataLocation = locale.properties.title;
    else {
      const [lng, lat] = locale.geometry.coordinates;
      this.isShowLocales = false;
      this.info[AddressLocationObject.location] = locale.properties.title;
      this.info[AddressLocationObject.lat] = lat;
      this.info[AddressLocationObject.lng] = lng;
      this.addressInfo.emit(this.info);
      this.dataLocation = locale.properties.title;
    }
  }

  protected readonly checkInputEnter = checkInputEnter;
}
