<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="mb-5 d-flex box-head-form">
    <c-col [xl]="6" [lg]="6" [md]="7">
      <h2 class="mb-0">{{ 'groups.title' | translate }}</h2>
    </c-col>
    <c-col [xl]="6" [lg]="6" [md]="5" class="d-flex justify-content-end">
      <app-create-group *ngIf="accountRestrictConfig.isCreateGroup"></app-create-group>
    </c-col>
  </c-col>
  <c-col class="mb-5">
    <c-col>
      <c-card class="card-no-border">
        <c-card-body>
          <form cForm [formGroup]="formSearch">
            <div class="mb-3">
              <label cLabel for="keywords">{{ 'groups.search' | translate }}</label>
              <div class="box-input-search">
                <input cFormControl id="keywords" formControlName="keywords" type="text">
                <img src="assets/svg/flight-record/icon-search.svg" alt="">
              </div>
            </div>
            <div class="block-button-search">
              <button class="btn-common" (click)="search()">
                {{ 'groups.search' | translate }}
              </button>
            </div>
          </form>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
  <c-col>
    <c-nav variant="underline">
      <c-nav-item>
        <a [active]="currentTab === 1" [cTabContent]="tabContent" (click)="changeTab(1)" [routerLink] [tabPaneIdx]="1" cNavLink>
          {{ 'groups.subText.tabMember'| translate }}
        </a>
      </c-nav-item>
      <c-nav-item *ngIf="accountRestrictConfig.isCreateGroup">
        <a [active]="currentTab === 0" (click)="changeTab(0)" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="0" cNavLink>
          {{ 'groups.subText.tabOwner' | translate }}
        </a>
      </c-nav-item>
    </c-nav>
    <c-tab-content #tabContent="cTabContent" [activeTabPaneIdx]="1">
      <c-tab-pane class="content-tab">
        <app-tab-owner
          (pageOwner)="receivePageOwner($event)"
          [groups]="groups"
          [page]="pageOwner"
          [users]="users"
          [delete]="delete"
          [getSubTextGroup]="getSubTextGroup"
          [checkOwnerGroup]="checkOwnerGroup"
        ></app-tab-owner>
      </c-tab-pane>
      <c-tab-pane class="content-tab">
        <app-tab-member
          (pageMember)="receivePageMember($event)"
          [groups]="groups"
          [users]="users"
          [delete]="delete"
          [getSubTextGroup]="getSubTextGroup"
          [checkOwnerGroup]="checkOwnerGroup"
        ></app-tab-member>
      </c-tab-pane>
    </c-tab-content>
  </c-col>
</c-container>
