<div *ngFor="let group of groups | paginate: { id: 'member', itemsPerPage: itemPerPage, currentPage: page }; index as index"  class="mb-3">
  <c-card *ngIf="group && group.path != groupDeletePath">
    <c-card-body>
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <span *ngIf="group && group.path != groupDeletePath && groups.length &&!checkOwnerGroup(group)" class="group-name">{{group.name}}</span>
          </div>
        </div>
        <div class="data-group-user">
          <div class="user-member-info">
            <div *ngFor="let user of group.groupUsers; index as userIndex">
              <div *ngIf="userIndex < itemPerPage" class="user-item mt-3">
                <div class="info-text name-member-section">
                  <span class="role-section member-long-text" *ngIf="(user.name?.length < 9) || !user.name" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.name">
                    <img *ngIf="user.permission" class="role-section__image" src="assets/img/groups/{{user.permission}}.png" />
                    {{ getLongText(user.name, textNameDisplay) }}
                  </span>
                  <span class="role-section member-long-text" *ngIf="(user.name?.length >= 9) && !!user.name" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.name">
                    <img *ngIf="user.permission" class="role-section__image" src="assets/img/groups/{{user.permission}}.png" />
                    {{ getLongText(user.name, textNameDisplay) }}
                  </span>
                </div>
                <div class="info-text email-member-section">
                  <span class="info-pc" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.email">{{ getLongText(user.email, textEmailDisplay) }}</span>
                  <span class="info-mb" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.email">{{ getLongText(user.email, textEmailDisplay) }}</span>
                </div>
                <div class="info-subtext info-text">{{getSubTextGroup(user)}}</div>
                <div class="button-action">
                  <app-member-group *ngIf="groups.length && !checkOwnerGroup(group) && group.path != groupDeletePath" [user]="user" [users]="users" [group]="group"></app-member-group>
                </div>
              </div>
              <div  [visible]="visibleItems[index]" cCollapse>
                <div *ngIf="userIndex >= itemPerPage" class="user-item mt-3">
                  <div>
                    <span *ngIf="(user.name?.length < 9) || !user.name" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.name">
                      <img *ngIf="user.permission" class="role-section__image" src="assets/img/groups/{{user.permission}}.png" />
                      {{ getLongText(user.name, textNameDisplay) }}
                    </span>
                    <span *ngIf="(user.name?.length >= 9) && !!user.name" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.name">
                      <img *ngIf="user.permission" class="role-section__image" src="assets/img/groups/{{user.permission}}.png" />
                      {{ getLongText(user.name, textNameDisplay) }}
                    </span>
                  </div>
                  <div class="info-text">
                    <span class="info-pc" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.email">{{ getLongText(user.email, textEmailDisplay) }}</span>
                    <span class="info-mb" [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="user.email">{{ getLongText(user.email, textEmailDisplay) }}</span>
                  </div>
                  <div  class="info-text info-subtext">{{getSubTextGroup(user)}}</div>
                  <div>
                    <app-member-group *ngIf="groups.length && !checkOwnerGroup(group) && group.path != groupDeletePath" [user]="user" [users]="users" [group]="group"></app-member-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span *ngIf="group.groupUsers.length > itemPerPage" (click)="toggleCollapse(index)" class="button-accordion">
            {{ ('groups.toggleUserList.' + !!visibleItems[index]) | translate }}
            <img class="caret-down" [class]="{'caret-down-open': visibleItems[index]}" height="20" width="20" src="assets/svg/common/caret-down-orange-600.svg" alt="">
          </span>
        </div>
      </div>
    </c-card-body>
  </c-card>
</div>
<c-col class="justify-content-center d-flex">
  <pagination-controls id="member" (pageChange)="changePage($event)" previousLabel="" nextLabel="" [autoHide]="true"></pagination-controls>
</c-col>
