export const LIST_COLUMN_ALLOW: Array<string> = ['name', 'email', 'organizationName', 'memberCode', 'active'];

export const VALIDATE_DATA = [
  {
    name: "name",
    required: true
  },
  {
    name: "email",
    required: true
  },
  {
    name: "organizationName",
    required: true
  },
  {
    name: "memberCode",
    required: false
  },
  {
    name: "active",
    required: true
  }
];

export const ACCOUNT_EXPORT_HEADERS = ['name', 'email', 'organizationName', 'memberCode', 'accountType', 'accountTypeName', 'active'];
export const ACCOUNT_EXPORT_FILE_NAME = 'ユーザー一覧-';
export const ACCOUNT_DEFAULT_RESTRICT = {
  isDisabledCreateForm: false,
  isCreateGroup: true,
  isDisplayTemplateList: true,
  isDisplayChildAccountList: true,
  isDisplayCameraSetting: true,
  isDisabledCreateChildAccount: false
}
export const ACCOUNT_PAGE_RESTRICT = {
  pageTemplate: ['template_list', 'template_create', 'template_basic_setting', 'template_item_edit', 'template_preview'],
  pageCamera: ['camera_list', 'camera_setting_edit'],
  pageChildAccount: ['child_account_list', 'child_account_create'],
  pageFormCreate: 'form_create'
}
export enum AccountPlanType {
  free = 'free',
  basic = 'basic',
  standard = 'standard',
  premium = 'premium',
  premiumhd = 'premiumhd',
  premiumpro = 'premiumpro'
}
export enum MaximumChildAccountCreated {
  standard = 10,
  premium = 50,
  premiumhd = 100,
  premiumpro = 1000
}
export const MAXIMUM_FORM_CREATED_FREE_PLAN = 10;
export const RANDOM_UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const RANDOM_LOWERCASE = 'abcdefghijklmnopqrstuvwxyz';
export enum AccountStatus {
  inactiveByParent = 'inactiveByParent',
  isDeletedByParent = 'isDeletedByParent',
  isDeletedByOwner = 'isDeletedByOwner',
  isUpdatePassword = 'isUpdatePassword'
}

export enum TokenAction {
  add, remove
}
