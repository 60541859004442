import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { INIT_EDITOR, RuleValidations } from "@app/constants";
import { EditorOptions } from "tinymce";

@Component({
  selector: 'app-certificate-edit',
  templateUrl: './certificate-edit.component.html',
  styleUrls: ['./certificate-edit.component.scss']
})
export class CertificateEditComponent implements OnChanges {
  @Input('isOutDate') isOutDate: boolean = false;
  @Input('formEdit') formEdit: any;
  @Input('submitAllTab') submitAllTab: any;
  @Input('form') form: any;
  @Output() submittedData = new EventEmitter<Boolean>();
  @Output() isBackToFormList = new EventEmitter<Boolean>();
  protected readonly INIT_EDITOR = INIT_EDITOR;
  protected readonly RuleValidations = RuleValidations;
  initEditor: Partial<EditorOptions> = INIT_EDITOR
  submitted: boolean = false
  ruleValidations = RuleValidations;
  constructor( ) { }

  ngOnChanges(changes: SimpleChanges): void { }

  backToFormList() {
    this.isBackToFormList.emit(true);
  }

  changeEditor(event: any) {
    const rawText: string = event.editor.getContent({ format: 'text' })
    const certificateDescription = this.formEdit.get('certificateDescription')
    certificateDescription.setErrors(null)
    if (rawText.length > this.ruleValidations.maxLength4096) certificateDescription.setErrors({maxlength: true})
  }

  submit() {
    this.submitted = true
    if (this.formEdit.valid && this.form) {
      this.submittedData.emit(true)
    }
  }
}
