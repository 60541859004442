<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="mb-5 d-flex box-head-form">
    <c-col [xl]="6" [lg]="6" [md]="7">
      <h2 class="mb-0">{{ "accountManagement.listUser.title" | translate }}</h2>
    </c-col>
    <c-col [xl]="6" [lg]="6" [md]="5" class="d-flex justify-content-end">
      <a class="btn-common" routerLink="/list-account/create"
        [state]="this.formSearch.value"
        [ngClass]="{'btn-common--disabled': accountRestrictConfig.isDisabledCreateChildAccount}">
        <img src="assets/svg/flight-record/icon-add.svg" alt="" />
        <span class="ms-2">{{ "accountManagement.listUser.btnLabel.create" | translate }}</span>
      </a>
    </c-col>
  </c-col>
  <c-col class="mb-5">
    <c-card class="card-no-border">
      <c-card-body>
        <form cForm [formGroup]="formSearch" (ngSubmit)="search()">
          <c-col class="mb-3 d-flex justify-content-between">
            <span>{{ "accountManagement.listUser.inputLabel.search" | translate }}</span>
            <span class="button-collapse" (click)="openSearchSection()">
              <img class="caret-down" height="20" width="20"
                src="assets/svg/common/caret-down.svg"
                [class]="{ 'caret-down-open': visibleSearchSection }"
              />
            </span>
          </c-col>
          <div [visible]="visibleSearchSection" cCollapse>
            <div>
              <c-col class="mb-2 d-flex align-items-center col-border">
                <c-input-group class="d-flex align-items-center">
                  <label for="querySearch">
                    <img src="assets/svg/flight-record/icon-search.svg"/>
                  </label>
                  <input
                    cFormControl
                    formControlName="querySearch"
                    placeholder=""
                    name="querySearch"
                    id="querySearch"
                    class="input-search"
                    [placeholder]="'accountManagement.listUser.placeholder.search' | translate"
                  />
                </c-input-group>
              </c-col>
              <c-col>
                <c-col class="mt-3">
                  <div class="d-flex justify-content-end mt-3">
                    <button class="btn-common button-clear" type="submit">
                      {{ "accountManagement.listUser.btnLabel.search" | translate }}
                    </button>
                  </div>
                </c-col>
              </c-col>
            </div>
          </div>
        </form>
      </c-card-body>
    </c-card>
  </c-col>
  <c-col>
    <c-col class="block-action">
      <div class="action-data-file" *ngIf="!!userFileImport">
        <button class="btn-second" (click)="previewDataFile()">{{ 'accountManagement.listUser.btnLabel.preview' | translate }}</button>
        <button class="btn-second" (click)="cancelFile()">{{ 'accountManagement.listUser.btnLabel.cancel' | translate }}</button>
        <button class="btn-save" cLoadingButton cButton [disabledOnLoading]="true" [loading]="isSaveLoading" (click)="importUser()">{{ 'accountManagement.listUser.btnLabel.save' | translate }}</button>
      </div>
      <app-export-button
        [btnTitle]="'accountManagement.listUser.btnLabel.export' | translate"
        [columns]="ACCOUNT_EXPORT_HEADERS"
        [dataExport]="accounts"
        [fileName]="ACCOUNT_EXPORT_FILE_NAME"
        [isDisabledExportButton]="!accounts?.length || showPreview"
        [messageExportSuccess]="'alertMessages.accountManagement.export.success' | translate"
        [messageExportFail]="'alertMessages.accountManagement.export.fail' | translate"
        [firebaseActionLogName]="ACTION_LIST.ACCOUNT.EXPORT_LIST_CHILD_ACCOUNT"
      ></app-export-button>
      <button cLoadingButton class="btn-second btn-import"
        [disabled]="showPreview || isValidateFile || accountRestrictConfig.isDisabledCreateChildAccount"
        [loading]="isSaveLoading || isValidateFile"
        [class]="{'btn-import-disabled': showPreview || isValidateFile || isSaveLoading || accountRestrictConfig.isDisabledCreateChildAccount}"
        (click)="triggerInputFile()"
      >
        {{ 'accountManagement.listUser.btnLabel.import' | translate }}
        <input #fileInput type="file" [hidden]="true" (input)="uploadFileImport($event)">
      </button>
    </c-col>
    <c-col *ngIf="!!accounts || !!userFileImport">
      <c-col class="mb-5">
        <div *ngIf="showPreview" class="item-preview">
          <c-card *ngFor="let account of userFileImport; index as accountIndex" class="card-no-border" [class]="{'mt-3': !!accountIndex}">
            <c-card-body class="d-flex align-items-start">
              <c-col class="d-flex">
                <c-col>
                  <c-col class="d-flex justify-content-end">
                    <c-col class="mb-1">
                      <div class="w-100 align-items-center data-section">
                      <span #nameSection class="form-title d-flex name-section">
                        {{ getLongText(account.name, textNameDisplay) }}
                      </span>
                        <span class="form-title d-flex email-section">
                        {{ getLongText(account.email, textNameDisplay) }}
                      </span>
                      <span class="block-action switch-button">
                        <c-form-check [switch]="true">
                          <input cFormCheckInput [id]="'active' + accountIndex" [checked]="account.active === 'true'" type="checkbox" [disabled]="true" />
                          <label cFormCheckLabel [for]="'active' + accountIndex">{{ 'accountManagement.listUser.btnLabel.active' | translate }}</label>
                        </c-form-check>
                      </span>
                      </div>
                    </c-col>
                  </c-col>
                </c-col>
              </c-col>
            </c-card-body>
          </c-card>
        </div>
        <div *ngFor="let account of accounts | paginate : { itemsPerPage: itemPerPage, currentPage: pageNumber }; index as accountIndex" class="item-account">
          <c-card class="card-no-border mt-3">
            <c-card-body class="d-flex align-items-start">
              <c-col class="d-flex">
                <c-col>
                  <c-col class="d-flex justify-content-end">
                    <c-col class="mb-1">
                      <div class="w-100 align-items-center data-section">
                        <span #nameSection class="form-title d-flex name-section">
                          {{ getLongText(account.name, textNameDisplay) }}
                        </span>
                          <span class="form-title d-flex email-section">
                          {{ getLongText(account.email, textNameDisplay) }}
                        </span>
                        <div class="table-action">
                          <span class="switch-button">
                            <c-form-check [switch]="true">
                              <input cFormCheckInput [id]="'active' + accountIndex" [checked]="account.active" type="checkbox" [disabled]="showPreview" (change)="updateAccountActive(account, $event)" />
                              <label cFormCheckLabel [for]="'active' + accountIndex">{{ 'accountManagement.listUser.btnLabel.active' | translate }}</label>
                            </c-form-check>
                          </span>
                          <span (click)="toggleDeleteChildAccountModal(account)">
                            <img class="icon-action" src="assets/svg/delete.svg"/>
                          </span>
                        </div>
                      </div>
                    </c-col>
                  </c-col>
                </c-col>
              </c-col>
            </c-card-body>
          </c-card>
        </div>
      </c-col>
      <c-col class="justify-content-center d-flex">
        <pagination-controls
          (pageChange)="pageChange($event)"
          previousLabel=""
          nextLabel=""
          [autoHide]="true"
        ></pagination-controls>
      </c-col>
    </c-col>
    <c-col *ngIf="!accounts?.length && !userFileImport?.length && !isLoading">
      {{ "form.noData" | translate }}
    </c-col>
    <c-col *ngIf="isLoading && accounts && !accounts.length">
      <c-card>
        <c-card-body class="loading">
          <app-loading-block [isLoading]="isLoading"></app-loading-block>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
</c-container>
<c-modal id="deleteModal" [visible]="visibleDeleteChildAccountModal" class="custom-modal custom-modal__no-body">
  <c-modal-header>
    <h5>
      <b>{{ "accountManagement.modal.delete.title" | translate: { email: dataDeleteAccount?.email ?? '' } }}</b>
    </h5>
    <button cButtonClose (click)="toggleDeleteChildAccountModal()"></button>
  </c-modal-header>
  <c-modal-footer>
    <button cButton class="btn-close-modal" (click)="toggleDeleteChildAccountModal()">
      {{ "accountManagement.modal.delete.no" | translate }}
    </button>
    <button cButton class="btn-confirm-modal" (click)="deleteChildAccount()" [disabled]="isDeleteAccount">
      {{ "accountManagement.modal.delete.yes" | translate }}
    </button>
  </c-modal-footer>
</c-modal>
