import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-blank-block',
  templateUrl: './blank-block.component.html',
})
export class BlankBlockComponent implements OnInit {
  @Input() condition: boolean = false
  @Input() styles: string = ''
  @Input() classes: string = ''
  @Input() objectStyles: any = {}
  constructor() { }

  ngOnInit(): void {
  }

}
