import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OpenAiService {
  private openAiApiUrl = environment.openAiApiUrl;
  private translateTextApiUrl = environment.translateTextApiUrl;
  private translateTextApiKey = environment.translateTextApiKey;
  private baseUrl = environment.baseUrl;
  constructor(
    private apiService: ApiService
  ) { }
  
  speechToText(data: any, apiKey: string) {
    const config = {
      isThirdParty: true,
      header: {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      },
    };
    return this.apiService.post(`${this.openAiApiUrl}/audio/transcriptions`, data, config);
  }

  chatCompletion(data: any, apiKey: string) {
    const config = {
      isThirdParty: true,
      header: {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      },
    };
    return this.apiService.post(`${this.openAiApiUrl}/chat/completions`, data, config);
  }

  async translateText(value: string, targetTranslate: string) {
    const url = `${this.translateTextApiUrl}?key=${this.translateTextApiKey}`;
    const data = {
      q: [value],
      target: targetTranslate
    }
    return new Promise((resolve, reject) => {
      this.apiService.post(url, data, { isThirdParty: true }).then((res: any) => {
        if (res.data.translations && res.data.translations.length) {
          const textTranslated = res.data.translations[0].translatedText;
          const div = document.createElement('div');
          div.innerHTML = textTranslated;
          resolve(div.innerText);
        }
      }).catch((err) => reject(err));
    })
  }

  async generateImages(data: any, apiKey: string) {
    const config = {
      isThirdParty: true,
      header: {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      },
    };
    const dataApi = {...data, response_format: 'b64_json'};
    return this.apiService.post(`${this.openAiApiUrl}/images/generations`, dataApi, config);
  }

  async getTokens(messages: any, modelValue: string) {
    const data = {
      chat: messages,
      model: modelValue
    }
    return await this.apiService.post(`${this.baseUrl}/get-tokens-open-ai`, data);
  }
}
