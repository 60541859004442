import { environment } from "@environments/environment";

export const ACTION_LIST = {
  SEND_NOTIFICATION: {
    DELETE_ACCOUNT: 'send_notification_delete_account',
    DELETE_GROUP: 'send_notification_delete_group',
    REMOVE_USER_FROM_GROUP: 'send_notification_remove_user_from_group',
    CHANGE_PASSWORD: 'send_notification_change_password_account',
    INACTIVE_ACCOUNT: 'send_notification_inactive_account'
  },
  SEND_MAIL: {
    REGISTER_USER_FORM_DATA: 'send_mail_user_form_data',
    REGISTER_GUEST_FORM_DATA: 'send_mail_guest_form_data',
    UPDATE_FORM_DATA: 'send_mail_update_form_data',
    REISSUE_FORM_DATA: 'send_mail_reissue_form_data'
  },
  ACCOUNT: {
    REGISTER: 'register',
    LOGIN: 'login',
    LOGIN_WITH_APPLE: 'login_with_apple',
    LOGIN_WITH_GOOGLE: 'login_with_google',
    LOGOUT: 'logout',
    FORGOT_PASSWORD: 'forgot_password',
    DELETE: 'delete_account',
    UPDATE: 'update_account_info',
    UPDATE_AUTHENTICATE_STATUS: 'update_authenticate_status',
    EXPORT_LIST_CHILD_ACCOUNT: 'export_list_child_account',
    SEARCH: 'search_account',
    UPDATE_ACCOUNT_ACTIVE_STATUS: 'update_account_active_status',
    IMPORT_LIST_CHILD_ACCOUNT: 'import_list_child_account',
    CREATE_CHILD_ACCOUNT: 'create_child_account',
    DELETE_CHILD_ACCOUNT: 'delete_child_account',
    UPDATE_PORTAL_HTML: 'update_portal_html_account',
    UPDATE_PASSWORD: 'update_password'
  },
  FORM: {
    SEARCH: 'search_form',
    SHARE: 'share_form',
    UPDATE_GROUP_PATH: 'update_group_path_in_form',
    CHANGE_GROUP_TAB: 'change_group_tab_in_form',
    DELETE: 'delete_form',
    DOWNLOAD_QR_IMAGE: 'download_qr_image_form',
    REDIRECT_QR_LINK: 'redirect_qr_link_form',
    CREATE: 'create_form',
    UPDATE: 'update_form',
  },
  FORM_DATA: {
    REGISTER_GUEST_FORM: 'register_guest_form_data',
    REGISTER_USER_FORM: 'register_user_form_data',
    UPDATE: 'update_form_data',
    UPLOAD_FILE_TO_STORAGE: 'upload_file_to_storage',
    EXPORT: 'export_list_form_data',
    SEARCH: 'search_form_data',
    IMPORT: 'import_form_data',
    DELETE: 'delete_form_data',
    DELETE_ALL_FORM_DATA: 'delete_all_form_data',
    UPDATE_STATUS: 'update_status_form_data',
    UPDATE_TEST_RESULT: 'update_test_result_form_data',
    DOWNLOAD_CERTIFICATE: 'download_certificate'
  },
  GROUP: {
    CREATE: 'create_group',
    DELETE: 'delete_group',
    UPDATE_NAME: 'update_group_name',
    INVITE_USER: 'invite_user_to_group',
    JOIN: 'user_join_group',
    REMOVE_USER_FROM_GROUP: 'remove_user_from_group',
  },
  CAMERA: {
    UPDATE: 'update_camera_data',
    UPDATE_GAUGE: 'update_gauge_data',
    UPDATE_CAMERA_MODE: 'update_camera_mode'
  },
  TEMPLATE: {
    CREATE: 'create_template',
    UPDATE_FORM_ITEMS_EDIT: 'update_form_items_edit_template',
    UPDATE_BASIC_SETTING: 'update_basic_setting_template',
    SEARCH: 'search_template',
    DELETE: 'delete_template'
  },
  NOTIFICATION: {
    DELETE: 'delete_notification',
    MARK_AS_READ: 'mark_as_read_notification',
    MARK_ALL_AS_READ: 'mark_all_as_read_notification'
  },
  TAB: {
    CREATE: 'create_tab',
    UPDATE_NAME: 'update_tab_name',
    UPDATE_INDEX: 'update_tab_index',
    DELETE: 'delete_tab'
  }
}

export const CONTENT_TYPE = 'web';

export const TYPE = environment.environment_develop;

export const EVENT_LOG_TYPE = {
  ERROR: 'error',
  INIT: 'init',
  SUCCESS: 'success'
}
