import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss']
})
export class InputLabelComponent implements OnInit {
  @Input() placeholder: any = { ja: '', en: '' };
  @Input() value: any = null;
  @Input() readonly: boolean = false;
  @Output() dataLabel = new EventEmitter<string | null>();
  currentLang: string = '';
  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = e.lang
    })
  }
  changeInputLabel(event: any) {
    this.dataLabel.emit(event.target.value);
  }
}
