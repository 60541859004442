import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  INPUT_SELECT, INPUT_TYPE,
  INPUT_TYPE_DATE_TIME,
  INPUT_TYPE_FILE,
  INPUT_TYPE_LOCATION,
  MIN_MAX_LENGTH_INDEX_LIST,
  MIN_MAX_VALUE_DATA_TYPE_LIST,
  MIN_MAX_VALUE_INDEX_LIST,
  SINGLE_LINE_FORM, UNIQUE_LINE_NAME
} from "@app/constants";

@Component({
  selector: 'app-template-detail-item-edit',
  templateUrl: './template-detail-item-edit.component.html',
  styleUrls: ['./template-detail-item-edit.component.scss']
})
export class TemplateDetailItemEditComponent implements OnChanges {
  @Input() form: FormGroup<any> | null = null;
  @Input() visible: boolean = false;
  @Input() submitted: boolean = false;
  @Input() formIndex: number = 0;
  @Output() visibleChange = new EventEmitter<boolean>();
  validationListIndex: any = new FormArray([]);
  isNumberInput: boolean = false;
  constructor(
    
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form'] && this.form) {
      this.isNumberInput = (MIN_MAX_VALUE_DATA_TYPE_LIST.includes(this.form.controls['datatype'].value));
      this.validationListIndex = MIN_MAX_LENGTH_INDEX_LIST;
      if (this.isNumberInput) {
        this.validationListIndex = MIN_MAX_VALUE_INDEX_LIST;
      }
    } 
  }

  toggleOffCanvas(visible: boolean) {
    this.visibleChange.emit(visible);
  }

  protected readonly SINGLE_LINE_FORM = SINGLE_LINE_FORM;
  protected readonly INPUT_SELECT = INPUT_SELECT;
  protected readonly INPUT_TYPE_FILE = INPUT_TYPE_FILE;
  protected readonly INPUT_TYPE_LOCATION = INPUT_TYPE_LOCATION;
  protected readonly INPUT_TYPE_DATE_TIME = INPUT_TYPE_DATE_TIME;
  protected readonly INPUT_TYPE = INPUT_TYPE;
  protected readonly UNIQUE_LINE_NAME = UNIQUE_LINE_NAME;
}
