<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="mb-5 d-flex box-head-form">
    <c-col [xl]="6" [lg]="6" [md]="7">
      <h2 class="mb-0">{{ "form.index.title" | translate }}</h2>
    </c-col>
    <c-col [xl]="6" [lg]="6" [md]="5" class="d-flex justify-content-end">
      <button class="btn-common" 
        [ngClass]="{'btn-common--disabled': accountRestrictConfig.isDisabledCreateForm}"
        [disabled]="accountRestrictConfig.isDisabledCreateForm"
        (click)="goToCreateForm()">
        <img src="assets/svg/flight-record/icon-add.svg" alt="" />
        <span class="ms-2">{{ "form.index.btn_create" | translate }}</span>
      </button>
    </c-col>
  </c-col>
  <c-col class="mb-5">
    <c-col>
      <c-card class="card-no-border">
        <c-card-body>
          <form cForm [formGroup]="formSearch" (ngSubmit)="search()">
            <c-col class="mb-3 d-flex justify-content-between">
              <span>{{ "form.index.searchTitle" | translate }}</span>
              <span class="button-collapse" (click)="toggleSearchBox()">
                <img class="caret-down" [class]="{ 'caret-down-open': visibleSearchBox }" height="20" width="20" src="assets/svg/common/caret-down.svg"/>
              </span>
            </c-col>
            <div [visible]="visibleSearchBox" cCollapse>
              <div>
                <c-col class="mb-2 d-flex align-items-center col-border">
                  <c-input-group class="d-flex align-items-center">
                    <label for="querySearch">
                      <img src="assets/svg/flight-record/icon-search.svg"/>
                    </label>
                    <input
                      cFormControl
                      formControlName="querySearch"
                      placeholder=""
                      name="querySearch"
                      id="querySearch"
                      class="input-search"
                    />
                  </c-input-group>
                </c-col>
                <c-col class="d-flex">
                  <c-col class="mb-6">
                    <c-col class="mb-3">{{ "form.index.startDate" | translate }}</c-col>
                    <c-col class="select-date">
                      <c-date-picker
                        [locale]="currentDatepickerLocale"
                        placeholder="{{ 'form.placeholder.date' | translate }}"
                        class="box-select-date"
                        formControlName="startDate"
                        [dayFormat]="dayFormat"
                      ></c-date-picker>
                    </c-col>
                  </c-col>
                  <c-col class="mb-6">
                    <c-col class="mb-3">{{ "form.index.endDate" | translate }}</c-col>
                    <c-col class="select-date">
                      <c-date-picker
                        [locale]="currentDatepickerLocale"
                        placeholder="{{'form.placeholder.date' | translate}}"
                        formControlName="endDate"
                        [dayFormat]="dayFormat"
                        class="box-select-date"
                      ></c-date-picker>
                    </c-col>
                  </c-col>
                </c-col>
                <c-col>
                  <c-col class="mt-3 box-color">
                    <div class="btn-left">
                      <button
                        type="button"
                        class="button-all"
                        [ngClass]="{
                          'check-all-color': f['color'].value,
                          'not-check-all-color': !f['color'].value
                        }"
                        (click)="onUncheckColor()"
                      >
                        {{ "form.inputLabel.all" | translate }}
                      </button>
                      <div class="colors d-flex flex-wrap">
                        <c-form-check class="color-option" *ngFor="let item of colorTypesSet; let i = index">
                          <input
                            formControlName="color"
                            class="check-box-color d-none"
                            id="color{{ item.colorCode }}"
                            [value]="item.colorCode"
                            name="color"
                            cFormCheckInput
                            type="radio"
                          />
                          <label
                            cFormCheckLabel
                            for="color{{ item.colorCode }}"
                            [style.--border-color]="item.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                            [style.--color]="item.colorCode"
                            [style.border]="item.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                            class="color-icon"
                          ></label>
                        </c-form-check>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                      <button class="btn-common button-clear" type="submit">
                        {{ "form.index.btn_search" | translate }}
                      </button>
                    </div>
                  </c-col>
                </c-col>
              </div>
            </div>
          </form>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
  <section class="tabs-container">
    <c-nav variant="underline" class="tabs" [ngStyle]="{ transform: tabStyle }">
      <a
        *ngFor="let group of groups; let i = index"
        class="tab"
        [cTabContent]="tabContent"
        [routerLink]
        [tabPaneIdx]="i"
        [disabled]="isLoading"
        cNavLink
        (click)="changeGroupSearch(group)">
        {{ getLongText(group.name, 25) }}
      </a>
      <a
        *ngFor="let tab of tabs; let index = index"
        class="tab"
        [cTabContent]="tabContent"
        [routerLink]
        [tabPaneIdx]="index + groups?.length"
        [disabled]="isLoading"
        (click)="changeGroupSearch(null, tab.docId)"
        cNavLink>
        {{ getLongText(tab.name, 25) }}
      </a>
      <a
        class="tab"
        [cTabContent]="tabContent"
        [routerLink]
        [tabPaneIdx]="groups?.length + tabs.length"
        [disabled]="isLoading"
        cNavLink
        (click)="changeGroupSearch(null, '')">
        {{ "form.forms" | translate }}
      </a>
    </c-nav>
  </section>
  <c-tab-content #tabContent="cTabContent" [activeTabPaneIdx]="activePane">
    <c-tab-pane class="p-3" *ngFor="let group of groups; let i = index">
      <c-col class="mb-5" *ngIf="forms && Boolean(forms.length)">
        <c-col>
          <c-card class="card-no-border mt-3" *ngFor="let form of forms | paginate : { itemsPerPage: itemPerPage, currentPage: p }">
            <c-card-body class="d-flex align-items-start">
              <c-col class="d-flex">
                <c-col>
                  <c-col class="d-flex justify-content-end">
                    <c-col class="mb-1">
                      <div class="d-flex w-100 align-items-start">
                      <span class="col-5 form-title d-flex">
                        <span
                          [style.--border-color]="form.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                          [style.--color]="form.colorCode"
                          [style.border]="form.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                          class="color-icon icon-color-item"
                        ></span>
                        <a routerLink="/form/{{ form ? form.doc_id : '' }}/form-data">{{ form.title }}</a>
                      </span>
                        <c-col class="d-flex justify-content-end col-7 align-items-center block-action">
                          <span class="title-info">{{ form.templateType }}</span>
                          <div class="d-flex justify-content-between btn-list">
                            <div class="d-flex">
                              <div>
                                <button *ngIf="form?.registFormWithoutLogin" class="btn-flight-action me-2" (click)="goToQrLink(form)">
                                  <img class="icon-action" src="assets/svg/link.svg" alt=""/>
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button
                                  *ngIf="form?.registFormWithoutLogin"
                                  class="btn-flight-action me-2"
                                  [cPopover]="popoverHtml"
                                  cPopoverPlacement="top"
                                  [cPopoverTrigger]="'hover'"
                                  cButton
                                  (click)="copyQrLink(form)"
                                  (mouseout)="setDefaultCopyText()">
                                  <img class="icon-action" src="assets/svg/copy.svg" alt=""/>
                                  <ng-template #popoverHtml>
                                    <div class="popover-body">
                                      {{ isCopied ? ('alertMessages.form.copyQrUrl.copied' | translate) : ('alertMessages.form.copyQrUrl.notCopy' | translate)}}
                                    </div>
                                  </ng-template>
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button *ngIf="form?.useQrCode" class="me-2 btn-flight-action" (click)="saveAsImage(form)">
                                  <img class="icon-action" src="assets/svg/icon-qr.svg"/>
                                </button>
                                <app-blank-block
                                  styles="width: 40px"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-share me-2" (click)="shareForm(form)">
                                  <img class="icon-action" src="assets/svg/common/share.svg" alt="">
                                </button>
                              </div>
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-flight-action me-2" (click)="goToEditForm(form.doc_id)">
                                  <img class="icon-action" src="assets/svg/edit.svg"/>
                                </button>
                              </div>
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-flight-action" (click)="toggleModal(form.doc_id)">
                                  <img class="icon-action" src="assets/svg/delete.svg"/>
                                </button>
                              </div>
                            </div>
                          </div>
                        </c-col>
                      </div>
                    </c-col>
                  </c-col>
                  <c-col class="mt-3 form-info">
                    <c-col class="d-flex justify-content-start">
                    <span>{{ form.targetDate.toDate() | date : "yyyy/MM/dd" }}
                      <img src="assets/svg/dot.svg" class="icon-dot" />
                    </span>
                    {{ form.maxRecords + " " + getUnitString(form) }}
                    </c-col>
                    <c-col class="d-flex justify-content-start">
                    <span>{{ "form.layout." + form.layout | translate }}</span>
                    </c-col>
                    <c-col class="d-flex justify-content-start">
                      <span>{{ form.templateTitle }}</span>
                    </c-col>
                  </c-col>
                  <c-col class="d-flex justify-content-end mt-3">
                    <c-col class="d-flex justify-content-start">
                      <div>
                        <div
                          class="remark-block"
                          [attr.data-id]="form.doc_id"
                          [innerHTML]="form.remark | safeHtml"
                        ></div>
                        <span *ngIf="dataShowToggleReadMore[form.doc_id]" (click)="toggleReadMore($event)" class="read-more">
                          {{ "common.longText.showMore" | translate }}
                        </span>
                      </div>
                    </c-col>
                  </c-col>
                </c-col>
              </c-col>
            </c-card-body>
          </c-card>
        </c-col>
      </c-col>
      <c-col *ngIf="forms && !Boolean(forms.length) && !isLoading">
        {{ "form.noData" | translate }}
      </c-col>
      <c-col class="justify-content-center d-flex" *ngIf="forms && Boolean(forms.length)">
        <pagination-controls
          (pageChange)="pageChange($event)"
          previousLabel=""
          nextLabel=""
          [autoHide]="true"
        ></pagination-controls>
      </c-col>
      <c-col *ngIf="isLoading && !forms.length">
        <c-card>
          <c-card-body class="loading">
            <app-loading-block [isLoading]="isLoading"></app-loading-block>
          </c-card-body>
        </c-card>
      </c-col>
    </c-tab-pane>
    <c-tab-pane class="p-3" *ngFor="let tab of tabs; let i = index">
      <c-col class="mb-5" *ngIf="forms && Boolean(forms.length)">
        <c-col>
          <c-card class="card-no-border mt-3" *ngFor="let form of forms | paginate : { itemsPerPage: itemPerPage, currentPage: p }">
            <c-card-body class="d-flex align-items-start">
              <c-col class="d-flex">
                <c-col>
                  <c-col class="d-flex justify-content-end">
                    <c-col class="mb-1">
                      <div class="d-flex w-100 align-items-start">
                      <span class="col-5 form-title d-flex">
                        <span
                          [style.--border-color]="form.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                          [style.--color]="form.colorCode"
                          [style.border]="form.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                          class="color-icon icon-color-item"
                        ></span>
                        <a routerLink="/form/{{ form ? form.doc_id : '' }}/form-data">{{ form.title }}</a>
                      </span>
                        <c-col class="d-flex justify-content-end col-7 align-items-center block-action">
                          <span class="title-info">{{ form.templateType }}</span>
                          <div class="d-flex justify-content-between btn-list">
                            <div class="d-flex">
                              <div>
                                <button *ngIf="form?.registFormWithoutLogin" class="btn-flight-action me-2" (click)="goToQrLink(form)">
                                  <img class="icon-action" src="assets/svg/link.svg" alt=""/>
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button
                                  *ngIf="form?.registFormWithoutLogin"
                                  class="btn-flight-action me-2"
                                  [cPopover]="popoverHtml"
                                  cPopoverPlacement="top"
                                  [cPopoverTrigger]="'hover'"
                                  cButton
                                  (click)="copyQrLink(form)"
                                  (mouseout)="setDefaultCopyText()">
                                  <img class="icon-action" src="assets/svg/copy.svg" alt=""/>
                                  <ng-template #popoverHtml>
                                    <div class="popover-body">
                                      {{ isCopied ? ('alertMessages.form.copyQrUrl.copied' | translate) : ('alertMessages.form.copyQrUrl.notCopy' | translate)}}
                                    </div>
                                  </ng-template>
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button *ngIf="form?.useQrCode" class="me-2 btn-flight-action" (click)="saveAsImage(form)">
                                  <img class="icon-action" src="assets/svg/icon-qr.svg"/>
                                </button>
                                <app-blank-block
                                  styles="width: 40px"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-share me-2" (click)="shareForm(form)">
                                  <img class="icon-action" src="assets/svg/common/share.svg" alt="">
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-flight-action me-2" (click)="goToEditForm(form.doc_id)">
                                  <img class="icon-action" src="assets/svg/edit.svg"/>
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-flight-action" (click)="toggleModal(form.doc_id)">
                                  <img class="icon-action" src="assets/svg/delete.svg"/>
                                </button>
                                <app-blank-block
                                  styles="width: 40px"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                            </div>
                          </div>
                        </c-col>
                      </div>
                    </c-col>
                  </c-col>
                  <c-col class="mt-3 form-info">
                    <c-col class="d-flex justify-content-start">
                    <span>{{ form.targetDate.toDate() | date : "yyyy/MM/dd" }}
                      <img src="assets/svg/dot.svg" class="icon-dot" />
                    </span>
                      {{ form.maxRecords + " " + getUnitString(form) }}
                    </c-col>
                    <c-col class="d-flex justify-content-start">
                      <span>{{ "form.layout." + form.layout | translate }}</span>
                    </c-col>
                    <c-col class="d-flex justify-content-start">
                      <span>{{ form.templateTitle }}</span>
                    </c-col>
                  </c-col>
                  <c-col class="d-flex justify-content-end mt-3">
                    <c-col class="d-flex justify-content-start">
                      <div>
                        <div
                          class="remark-block"
                          [attr.data-id]="form.doc_id"
                          [innerHTML]="form.remark | safeHtml"
                        ></div>
                        <span *ngIf="dataShowToggleReadMore[form.doc_id]" (click)="toggleReadMore($event)" class="read-more">
                          {{ "common.longText.showMore" | translate }}
                        </span>
                      </div>
                    </c-col>
                  </c-col>
                </c-col>
              </c-col>
            </c-card-body>
          </c-card>
        </c-col>
      </c-col>
      <c-col *ngIf="forms && !Boolean(forms.length) && !isLoading">
        {{ "form.noData" | translate }}
      </c-col>
      <c-col class="justify-content-center d-flex" *ngIf="forms && Boolean(forms.length)">
        <pagination-controls
          (pageChange)="pageChange($event)"
          previousLabel=""
          nextLabel=""
          [autoHide]="true"
        ></pagination-controls>
      </c-col>
      <c-col *ngIf="isLoading && !forms.length">
        <c-card>
          <c-card-body class="loading">
            <app-loading-block [isLoading]="isLoading"></app-loading-block>
          </c-card-body>
        </c-card>
      </c-col>
    </c-tab-pane>
    <c-tab-pane class="p-3">
      <c-col *ngIf="forms && !Boolean(forms.length) && !isLoading">
        {{ "form.noData" | translate }}
      </c-col>
      <c-col class="mb-5">
        <c-col *ngIf="forms && Boolean(forms.length) && !isLoading">
          <c-card class="card-no-border mt-3" *ngFor="let form of forms | paginate : { itemsPerPage: itemPerPage, currentPage: p }">
            <c-card-body class="d-flex align-items-start">
              <c-col class="d-flex">
                <c-col>
                  <c-col class="d-flex justify-content-end">
                    <c-col class="mb-1">
                      <div class="d-flex w-100 align-items-start">
                      <span class="col-5 form-title d-flex">
                        <span
                          [style.--border-color]="form.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                          [style.--color]="form.colorCode"
                          [style.border]="form.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                          class="color-icon icon-color-item"
                        ></span>
                        <a routerLink="/form/{{ form ? form.doc_id : '' }}/form-data">{{ form.title }}</a>
                      </span>
                        <c-col class="d-flex justify-content-end col-7 align-items-center block-action">
                          <span class="title-info">{{ form.templateType }}</span>
                          <div class="d-flex justify-content-between btn-list">
                            <div class="d-flex">
                              <div>
                                <button *ngIf="form?.registFormWithoutLogin" class="btn-flight-action me-2" (click)="goToQrLink(form)">
                                  <img class="icon-action" src="assets/svg/link.svg" alt=""/>
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button
                                  *ngIf="form?.registFormWithoutLogin"
                                  class="btn-flight-action me-2"
                                  [cPopover]="popoverHtml"
                                  cPopoverPlacement="top"
                                  [cPopoverTrigger]="'hover'"
                                  cButton
                                  (click)="copyQrLink(form)"
                                  (mouseout)="setDefaultCopyText()">
                                  <img class="icon-action" src="assets/svg/copy.svg" alt=""/>
                                  <ng-template #popoverHtml>
                                    <div class="popover-body">
                                      {{ isCopied ? ('alertMessages.form.copyQrUrl.copied' | translate) : ('alertMessages.form.copyQrUrl.notCopy' | translate)}}
                                    </div>
                                  </ng-template>
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button *ngIf="form?.useQrCode" class="me-2 btn-flight-action" (click)="saveAsImage(form)">
                                  <img class="icon-action" src="assets/svg/icon-qr.svg"/>
                                </button>
                                <app-blank-block
                                  styles="width: 40px"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-share me-2" (click)="shareForm(form)">
                                  <img class="icon-action" src="assets/svg/common/share.svg" alt="">
                                </button>
                                <app-blank-block
                                  styles="width: 40px"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-flight-action me-2" (click)="goToEditForm(form.doc_id)">
                                  <img class="icon-action" src="assets/svg/edit.svg" alt=""/>
                                </button>
                                <app-blank-block
                                  [styles]="blankBlockStyles"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                              <div>
                                <button *ngIf="checkOwnerForm(form.user_id)" class="btn-flight-action" (click)="toggleModal(form.doc_id)">
                                  <img class="icon-action" src="assets/svg/delete.svg" alt=""/>
                                </button>
                                <app-blank-block
                                  styles="width: 40px"
                                  classes="me-2"
                                  [condition]="!checkOwnerForm(form.user_id)"
                                ></app-blank-block>
                              </div>
                            </div>
                          </div>
                        </c-col>
                      </div>
                    </c-col>
                  </c-col>
                  <c-col class="mt-3 form-info">
                    <c-col class="d-flex justify-content-start">
                    <span>{{ form.targetDate.toDate() | date : "yyyy/MM/dd" }}
                      <img src="assets/svg/dot.svg" alt=""class="icon-dot"/>
                    </span>
                    {{ form.maxRecords + " " + getUnitString(form) }}
                    </c-col>
                    <c-col class="d-flex justify-content-start">
                    <span>{{ "form.layout." + form.layout | translate }}</span>
                    </c-col>
                    <c-col class="d-flex justify-content-start">
                      <span>{{ form.templateTitle }}</span>
                    </c-col>
                  </c-col>
                  <c-col class="d-flex justify-content-end mt-3">
                    <c-col class="d-flex justify-content-start">
                      <div>
                        <div class="remark-block" [attr.data-id]="form.doc_id" [innerHTML]="form.remark | safeHtml"></div>
                        <span *ngIf="dataShowToggleReadMore[form.doc_id]" (click)="toggleReadMore($event)" class="read-more">
                          {{ "common.longText.showMore" | translate }}
                        </span>
                      </div>
                    </c-col>
                  </c-col>
                </c-col>
              </c-col>
            </c-card-body>
          </c-card>
        </c-col>
        <c-col *ngIf="isLoading">
          <c-card>
            <c-card-body class="loading">
              <app-loading-block [isLoading]="isLoading"></app-loading-block>
            </c-card-body>
          </c-card>
        </c-col>
      </c-col>
      <c-col class="justify-content-center d-flex" *ngIf="forms && Boolean(forms.length)">
        <pagination-controls
          (pageChange)="pageChange($event)"
          previousLabel=""
          nextLabel=""
          [autoHide]="true"
        ></pagination-controls>
      </c-col>
    </c-tab-pane>
  </c-tab-content>
  <c-modal id="deleteModal" class="custom-modal custom-modal__no-body" [visible]="visible" (visibleChange)="handleLiveDemoChange($event)">
    <c-modal-header>
      <h5>
        <b>{{ "form.modal.delete.title" | translate }}</b>
      </h5>
      <button cButtonClose (click)="toggleModal('')"></button>
    </c-modal-header>
    <c-modal-footer>
      <button cButton color="secondary" class="btn-close-modal" (click)="toggleModal('')">
        {{ "form.modal.delete.back" | translate }}
      </button>
      <button cButton class="btn-confirm-modal" (click)="delete()" [disabled]="btnDelete">
        {{ "form.modal.delete.ok" | translate }}
      </button>
    </c-modal-footer>
  </c-modal>
</c-container>
<app-share-form
  *ngIf="formSelected && checkOwnerForm(formSelected.user_id)"
  [form]="formSelected"
  [groups]="groups ?? []"
  [activePane]="groups?.length"
  [visibleModalShare]="visibleModalShare"
  (modalStatus)="receiveShareModalStatus($event)"
></app-share-form>
