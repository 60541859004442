<app-layout-auth [isGuest]="false">
  <img id="logo" src="./assets/svg/logo-update-password.svg" alt="logo" />
  <h1>{{'update-password.title'|translate}}</h1>
  <form [formGroup]="formUpdatePassword" (ngSubmit)="updatePassword()" novalidate>
    <!-- Old Password -->
    <app-input-password
      class="input-group-container"
      [formControlNameInput]="'oldPassword'"
      [placeHolderInput]="'update-password.old_password'|translate"
      [ngClass]="{
        'is-invalid': (submitted || f['oldPassword'].touched) && f['oldPassword'].invalid,
        'is-valid': f['oldPassword'].touched && f['oldPassword'].valid
      }"
    ></app-input-password>
    <c-form-feedback *ngIf="(submitted || f['oldPassword'].touched) && f['oldPassword'].errors">
      <div *ngIf="f['oldPassword'].errors['required']">{{'errorMessages.password.required'|translate}}</div>
      <div *ngIf="f['oldPassword'].errors['pattern']">{{'errorMessages.password.pattern'|translate}}</div>
    </c-form-feedback>
    <!-- New Password -->
    <app-input-password
      class="input-group-container"
      [formControlNameInput]="'password'"
      [placeHolderInput]="'update-password.new_password'|translate"
      [ngClass]="{
        'is-invalid': (submitted || f['password'].touched) && f['password'].invalid,
        'is-valid': f['password'].touched && f['password'].valid
      }"
    ></app-input-password>
    <c-form-feedback *ngIf="(submitted || f['password'].touched) && f['password'].errors">
      <div *ngIf="f['password'].errors['required']">{{'errorMessages.password.required'|translate}}</div>
      <div *ngIf="f['password'].errors['pattern']">{{'errorMessages.password.pattern'|translate}}</div>
    </c-form-feedback>
    <!-- Confirm Password -->
    <app-input-password
      class="input-group-container"
      [formControlNameInput]="'confirmPassword'"
      [placeHolderInput]="'update-password.re_password'|translate"
      [ngClass]="{
        'is-invalid': (submitted || f['confirmPassword'].touched) && formUpdatePassword.errors,
        'is-valid': f['confirmPassword'].touched && !formUpdatePassword.errors
      }"
    ></app-input-password>
    <c-form-feedback *ngIf="(submitted || f['confirmPassword'].touched) && f['confirmPassword'].errors">
      <div *ngIf="f['confirmPassword'].errors['required']">{{'errorMessages.confirmPassword.passwordMismatch'|translate}}</div>
    </c-form-feedback>
    <button cButton [disabled]="loading" type="submit">{{'update-password.btn_update'|translate}}</button>
  </form>
</app-layout-auth>
