export const ELEMENT_TYPE_LIST = [
  { type: "input", datatype: "text" },
  { type: "input", datatype: "area" },
  { type: "input", datatype: "number" },
  { type: "input", datatype: "link" },
  { type: "input", datatype: "slider" },
  { type: "input", datatype: 'radio' },
  { type: "input", datatype: 'check' },
  { type: "input", datatype: 'image' },
  { type: "input", datatype: "movie" },
  { type: "input", datatype: "sound" },
  { type: "input", datatype: "file" },
  { type: "input", datatype: "map" },
  { type: "input", datatype: "location" },
  { type: "input", datatype: "zip" },
  { type: "input", datatype: "date" },
  { type: "input", datatype: "time" },
  { type: "label", datatype: "text" },
  { type: "label", datatype: "html" },
  { type: "input", datatype: "html" },
  { type: "input", datatype: "uid", name: 'userid' },
  { type: "input", datatype: "text", name: 'entryno' },
];

export const INPUT_TYPE = {
  text: 'text',
  number: 'number',
  area: "area",
  slider: "slider",
  map: "map",
  location: "location",
  zip: "zip",
  uid: "uid",
  check: "check",
  radio: "radio",
  float: "float",
  email: "email",
  image: "image",
  movie: "movie",
  sound: "sound",
  file: "file",
  html: "html",
  date: "date",
  time: "time",
  link: "link",
};

export const TYPE_INPUT = {
  label: "label",
}

export const UNIQUE_LINE_NAME = ['userid', 'entryno']

export const INPUT_TYPE_FILE = ['image', 'movie', 'sound', 'file'];
export const INPUT_TYPE_LOCATION = ['map', 'location', 'zip']
export const INPUT_SELECT = ['check', 'radio']
export const SINGLE_LINE_FORM = ['text', 'number', 'area', 'slider', 'email', 'float'];
export const INPUT_TYPE_DATE_TIME = ['date', 'time']
export const MIN_MAX_LENGTH_INDEX_LIST = [2, 3];
export const MIN_MAX_VALUE_INDEX_LIST = [0, 1];
export const MIN_VALUE_LENGTH_INDEX_LIST = [0, 2];
export const MAX_VALUE_LENGTH_INDEX_LIST = [1, 3];
export const MIN_MAX_VALUE_DATA_TYPE_LIST = ['number', 'float', 'slider'];
