<c-col class="d-flex align-items-center col-border">
  <c-input-group>
    <input
      cFormControl
      type="text"
      [class]="{'readonly-data': node ? node.readonly : false}"
      [readOnly]="node ? node.readonly : false"
      [disabled]="isDisabled"
      autocomplete="off"
      [placeholder]="placeholder ?? ''"
      [value]="dataLocation"
      (keypress)="checkInputEnter($event)"
      (keyup)="searchLocale($event)"
      (click)="searchLocale($event)"
      (focus)="checkInputEnter($event)"
    />
  </c-input-group>
</c-col>
<ul cListGroup class="list-group scroll-list-locale" *ngIf="isShowLocales">
  <li class="list-group-item cursor-pointer" cListGroupItem
    *ngFor="let locale of locales"
    (click)="setDataInputAutoCompete(locale)"
  >
    {{ locale.properties.title }}
  </li>
</ul>
