import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RuleValidations } from "@app/constants";
import { getFirstError, setValueOfDeepData } from "@app/helpers";

@Component({
  selector: 'app-date-time-single-line',
  templateUrl: './date-time-single-line.component.html',
  styleUrls: ['./date-time-single-line.component.scss']
})
export class DateTimeSingleLineComponent implements OnChanges {
  @Input() form: any
  @Input() submitted: boolean = false;
  showErrors: {[key: string]: boolean} = {}
  protected readonly RuleValidations = RuleValidations;
  protected readonly getFirstError = getFirstError;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(this.form.controls).forEach((item: string) => {
      this.showErrors[item] = false
    })
  }

  changeShowErrors(...keys: any[]) {
    this.showErrors = setValueOfDeepData(this.showErrors, keys, true)
  }
}
