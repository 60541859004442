import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { onlyUnique } from '@app/helpers';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/services';
import * as moment from 'moment';
import { CalendarEventColor, DATE_PICKER_THEME, DAY_OF_WEEK_TEXT, formatDateChartJa, formatDatePicker, formatDatePickerUs } from '@app/constants';
import tippy from "tippy.js";
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { jaLocale } from 'ngx-bootstrap/locale';
defineLocale('ja', jaLocale);

@Component({
  selector: 'app-weekly-chart-form-data',
  templateUrl: './weekly-chart-form-data.component.html',
  styleUrls: ['./weekly-chart-form-data.component.scss']
})
export class WeeklyChartFormDataComponent implements OnInit, OnChanges {
  @Input() listFormData: any;
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    plugins: [timeGridPlugin],
    displayEventTime: false,
    contentHeight: 'auto',
    expandRows: true,
    allDaySlot: false,
    eventMinHeight: 40,
    eventMaxStack: 3,
    headerToolbar: {
      start: 'title',
      center: '',
      end: ''
    },
  };
  datePickerConfig: any = {};
  date = new Date();
  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private bsLocaleService: BsLocaleService,
    private renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    this.generateDatePickerConfig();
    this.changeLocale();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateCalendarOptions();
    this.generateChartData().then();
  }

  generateCalendarOptions() {
    this.calendarOptions = {
      ...this.calendarOptions,
      eventBackgroundColor: CalendarEventColor.background,
      eventBorderColor: CalendarEventColor.border,
      eventTextColor: CalendarEventColor.text,
      eventDidMount: (info) => {
        tippy(info.el, { content: info.event.title })
      },
      dayHeaderContent: (renderProps, createElement) => {
        const date = renderProps.date;
        const weekdayEn = date.toLocaleDateString('en-US', { weekday: 'long' });
        const weekdayJa = date.toLocaleDateString('ja-JP', { weekday: 'long' }).replace(DAY_OF_WEEK_TEXT, '');
        const day = date.toLocaleDateString('en-US', {day: 'numeric'});
        const weekday = this.translateService.currentLang === 'ja' ? weekdayJa : weekdayEn;
        return { html: `<span>${weekday}</span><br/><span>${day}</span>` };
      },
      moreLinkClassNames: 'weekly__more-link-section',
      moreLinkDidMount: (moreLinkConfig) => {
        this.renderer2.setProperty(moreLinkConfig.el, 'title', '');
      }
    }
  }

  generateDatePickerConfig() {
    this.datePickerConfig = {
      containerClass: DATE_PICKER_THEME
    }
  }

  async generateChartData() {
    const userIds = this.listFormData.map((item: any) => item.userid).filter(onlyUnique);
    const dataUserPromises = [];
    for (const userId of userIds) {
      if (userId && userId.length) {
        dataUserPromises.push(new Promise((resolve, reject) => {
          this.userService.getValueUserByUid(userId).subscribe({ next: (data) => resolve(data) });
        }))
      }
    }
    Promise.all(dataUserPromises).then((listUser) => {
      const dataUserList = listUser.flat();
      if (dataUserList.length) {
        this.getCalendarEvents(dataUserList);
        this.translateService.onLangChange.subscribe((e: LangChangeEvent) => {
          this.getCalendarEvents(dataUserList);
        })
      }
    })
  }

  getCalendarEvents(dataUserList: Array<any>) {
    const events = [];
    for (const [index, formData] of this.listFormData.entries()) {
      const data = { title: '', date: `${moment(formData.date).format(formatDateChartJa)} ${formData.time}` };
      const formDataValue = `${formData.name}: ${formData.value}`;
      let userName = '';
      if (!formData.userid) {
        userName = this.translateService.instant('formUser.notLogin');
      } else {
        const dataUserFilter: any = dataUserList.filter((item: any) => item.uid === formData.userid);
        if (dataUserFilter[0].name) userName = dataUserFilter[0].name;
      }
      data.title = `${formDataValue} ${userName && userName.length ? '/' : ''} ${userName}`;
      events[index] = data;
    }
    this.calendarOptions.events = events;
  }

  searchDate(event: any) {
    if (this.calendarComponent) {
      const calendarApi = this.calendarComponent.getApi();
      calendarApi.gotoDate(event);
    }
  }

  private changeLocale() {
    this.calendarOptions.locale = this.translateService.currentLang;
    this.bsLocaleService.use(this.translateService.currentLang);
    this.changeDatePickerLocale(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.calendarOptions.locale = langChangeEvent.lang;
      this.bsLocaleService.use(langChangeEvent.lang);
      this.changeDatePickerLocale(langChangeEvent.lang);
    });
  }

  private changeDatePickerLocale(lang: string) {
    this.datePickerConfig = {
      ...this.datePickerConfig,
      dateInputFormat: lang === 'ja' ? formatDatePicker : formatDatePickerUs
    }
  }
}
