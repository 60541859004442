import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { CONTENT_TYPE, TYPE } from "@app/constants";

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticService {

  constructor(private analytics: AngularFireAnalytics) {}

  logEvent(actionName: string, type: string) {
    this.analytics.logEvent(type, {
      'content_type': CONTENT_TYPE,
      'develop_type': TYPE,
      'action': actionName
    });
  }
}
