import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Pipe({name: 'mapTranslateText', pure: false})
export class MapTranslateTextPipe implements PipeTransform {
  text: string = '';
  formName: string = '';
  transformedText: string = '';

  constructor(
    private translateService: TranslateService,
  ) { }

  transform(text: string, formName: any = 'status', dataOption: any = {}, useLang: string | boolean = false): string {
    if (formName === 'entrydate') {
      this.transformedText = text;
    }
    if (dataOption.hasOwnProperty('value') && dataOption.hasOwnProperty('candidateData')) {
      const index = dataOption?.value.findIndex((item: string) => item.toLowerCase() === text.toLowerCase());
      const currentLang = this.translateService.currentLang;
      this.transformedText = dataOption['candidateData'][currentLang][index];
      if (useLang && typeof useLang == 'string') {
        this.transformedText = dataOption['candidateData'][useLang][index];
      }
      return this.transformedText;  
    }
    
    if (dataOption.hasOwnProperty('en') || dataOption.hasOwnProperty('ja')) {
      const currentLang = this.translateService.currentLang;
      const keyOfText = dataOption['en'].map((item: string) => item?.toLowerCase()).indexOf(text?.toLowerCase()) >= 0 ? dataOption['en'].map((item: string) => item?.toLowerCase()).indexOf(text?.toLowerCase()) : dataOption['ja'].map((item: string) => item?.toLowerCase()).indexOf(text?.toLowerCase());
      this.transformedText = dataOption[currentLang][keyOfText];
      if (useLang && typeof useLang == 'string') {
        this.transformedText = dataOption[useLang][keyOfText];
      }
    }
    return this.transformedText;
  }
}
