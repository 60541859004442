<c-card class="card-no-border account-card">
  <form class="form-create" [formGroup]="formEdit" novalidate>
    <div class="mb-3">
      <c-col class="mb-1">
        {{ "formEdit.advancedTab.inputLabel.title" | translate }}</c-col>
      <c-input-group>
        <input cFormControl formControlName="organizationName" (keypress)="checkInputEnter($event)" />
      </c-input-group>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.organizationName?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.input." + getFirstError(formEdit!.controls!.organizationName!.errors)
          | translate : { maxlength: ruleValidations.maxLength32 }
        }}
      </c-form-check>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">{{ "formEdit.advancedTab.inputLabel.copyright" | translate }}</c-col>
      <c-input-group>
        <editor formControlName="copyright" [init]="initEditor"></editor>
      </c-input-group>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.copyright?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.input." + getFirstError(formEdit!.controls!.copyright!.errors)
          | translate : { maxlength: ruleValidations.maxLength1024 }
        }}
      </c-form-check>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">
        {{ "formEdit.advancedTab.inputLabel.formCategory" | translate }}
        <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
      </c-col>
      <c-input-group>
        <input cFormControl formControlName="templateType" (keypress)="checkInputEnter($event)" />
      </c-input-group>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.templateType?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.input.required" | translate }}</c-form-check>
    </div>
    <c-col class="d-flex box-multiple-input">
      <c-col class="mb-6 box-input">
        <c-col class="mb-1">{{"formEdit.advancedTab.inputLabel.stringColorCode" | translate}}</c-col>
        <c-input-group>
          <div class="box-color-picker">
            <div class="picker">
              <ngx-colors
                acceptLabel="{{ 'form.inputLabel.buttonColor.accept' | translate }}"
                cancelLabel="{{ 'form.inputLabel.buttonColor.cancel' | translate }}"
                format="hex"
                class="w-100"
                [formControl]="formEdit.controls.stringCodeForm"
                (change)="changeColor($event, 'stringCodeForm')"
                ngx-colors-trigger
              >
              </ngx-colors>
            </div>
            <span>{{ dataColors.stringCodeForm }}</span>
          </div>
        </c-input-group>
      </c-col>
      <c-col class="mb-6 box-input">
        <c-col class="mb-1">{{ "formEdit.advancedTab.inputLabel.backgroundColorCode" | translate }}</c-col>
        <c-input-group>
          <div class="box-color-picker mb-3">
            <div class="picker">
              <ngx-colors
                [formControl]="formEdit.controls.backgroundCode"
                acceptLabel="{{ 'form.inputLabel.buttonColor.accept' | translate }}"
                cancelLabel="{{ 'form.inputLabel.buttonColor.cancel' | translate }}"
                format="hex"
                class="w-100"
                (change)="changeColor($event, 'backgroundCode')"
                ngx-colors-trigger
              >
              </ngx-colors>
            </div>
            <span>{{ dataColors.backgroundCode }}</span>
          </div>
        </c-input-group>
      </c-col>
    </c-col>
    <div class="mb-3">
      <c-col class="mb-1"
        >{{ "formEdit.advancedTab.inputLabel.formId" | translate }}
      </c-col>
      <c-input-group>
        <input cFormControl [value]="id" [disabled]="true" />
      </c-input-group>
    </div>
    <div class="mb-3">
      <c-col class="mb-1"
        >{{ "formEdit.advancedTab.inputLabel.secretKey" | translate }}
      </c-col>
      <c-col class="d-flex justify-content-end secretkey">
        <input
          cFormControl
          type="text"
          placeholder="{{
            'formEdit.advancedTab.inputLabel.secretKey' | translate
          }}"
          formControlName="secretKey"
          readonly="readonly"
        />
        <div class="btn-genid" (click)="toggleGenerateUidModal()">
          {{ "account.btn_gen_id" | translate }}
        </div>
      </c-col>
      <c-col *ngIf="submitted && formEdit.controls['secretKey'].invalid" class="invalid-feedback">
        <c-col *ngIf="formEdit.controls['secretKey'].errors && formEdit.controls['secretKey'].errors['required']">
          {{ "errorMessages.common.required" | translate }}
        </c-col>
      </c-col>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">
        {{ "formEdit.advancedTab.inputLabel.useQrCode" | translate }}
      </c-col>
      <div class="d-flex">
        <c-form-check class="radio-block" inline>
          <input
            cFormCheckInput
            checked
            id="useQrCode-1"
            name="useQrCode"
            type="radio"
            formControlName="useQrCode"
            [value]="true"
          />
          <label cFormCheckLabel for="useQrCode-1">{{ "formEdit.advancedTab.optionLabel.use" | translate }}</label>
        </c-form-check>
        <c-form-check class="radio-block" inline>
          <input
            cFormCheckInput
            id="useQrCode-2"
            name="useQrCode"
            type="radio"
            formControlName="useQrCode"
            [value]="false"
          />
          <label cFormCheckLabel for="useQrCode-2">
            {{ "formEdit.advancedTab.optionLabel.notUse" | translate }}
          </label>
        </c-form-check>
      </div>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">
        {{ "formEdit.advancedTab.inputLabel.registrationFormWithoutLogin" | translate }}
      </c-col>
      <div class="d-flex">
        <c-form-check inline class="radio-block">
          <input
            cFormCheckInput
            checked
            id="useRegistrationFormWithoutLogin"
            name="registFormWithoutLogin"
            formControlName="registFormWithoutLogin"
            type="radio"
            [value]="true"
          />
          <label cFormCheckLabel for="useRegistrationFormWithoutLogin">
            {{ "formEdit.advancedTab.optionLabel.use" | translate }}</label>
        </c-form-check>
        <c-form-check inline class="radio-block">
          <input
            cFormCheckInput
            id="notUseRegistrationFormWithoutLogin"
            name="registFormWithoutLogin"
            formControlName="registFormWithoutLogin"
            type="radio"
            [value]="false"
          />
          <label cFormCheckLabel for="notUseRegistrationFormWithoutLogin">
            {{ "formEdit.advancedTab.optionLabel.notUse" | translate }}</label>
        </c-form-check>
      </div>
    </div>
    <div class="mb-3 d-flex justify-content-end">
      <button (click)="backToFormList()" class="back-btn">
        {{ "form.backButton" | translate }}
      </button>
      <button
        cButton
        type="submit"
        (click)="submit()"
        [class]="{
          'btn-submit': true,
          'btn-submit-disabled': isOutDate
        }"
      >
        {{ "form.inputLabel.submitChange" | translate }}
      </button>
    </div>
  </form>
  <app-modal-generate-uid [visibleGenerateUid]="visibleGenerateUid" (getUid)="receiveUid($event)"></app-modal-generate-uid>
</c-card>
