<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3>{{ "form.editFormTitle" | translate }}</h3>
    </c-col>
    <c-nav variant="underline">
      <c-nav-item>
        <a [active]="!isRedirectFromTestDetail" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="0" cNavLink>
          {{ "formEdit.tabName.basic" | translate }}
        </a>
      </c-nav-item>
      <c-nav-item>
        <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="1" cNavLink>
          {{ "formEdit.tabName.advanced" | translate }}
        </a>
      </c-nav-item>
      <c-nav-item>
        <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="2" cNavLink>
          {{ "formEdit.tabName.email" | translate }}
        </a>
      </c-nav-item>
      <c-nav-item *ngIf="isDisplayTab.getValue()">
        <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="3" cNavLink>
          {{ "formEdit.tabName.video" | translate }}
        </a>
      </c-nav-item>
      <c-nav-item *ngIf="isDisplayTab.getValue()">
        <a [active]="isRedirectFromTestDetail" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="4" cNavLink>
          {{ "formEdit.tabName.test" | translate }}
        </a>
      </c-nav-item>
      <c-nav-item *ngIf="isDisplayTab.getValue()">
        <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="5" cNavLink>
          {{ "formEdit.tabName.certificate" | translate }}
        </a>
      </c-nav-item>
    </c-nav>
    <c-tab-content #tabContent="cTabContent" [activeTabPaneIdx]="1">
      <c-tab-pane class="p-3">
        <app-basic-edit
          [isOutDate]="isOutDate"
          [form]="form"
          [submitAllTab]="submitAllTab"
          [formEdit]="formEditBasic"
          [minNumberParking]="minNumberParking"
          [currentUser]="currentUser"
          (submittedData)="recipeSubmitted()"
          (isBackToFormList)="backToFormList($event)"
        ></app-basic-edit>
      </c-tab-pane>
      <c-tab-pane class="p-3">
        <app-advanced-edit
          [isOutDate]="isOutDate"
          [form]="form"
          [submitAllTab]="submitAllTab"
          [formEdit]="formEditAdvanced"
          (submittedData)="recipeSubmitted()"
          (isBackToFormList)="backToFormList($event)"
        ></app-advanced-edit>
      </c-tab-pane>
      <c-tab-pane class="p-3">
        <app-email-edit
          [isOutDate]="isOutDate"
          [form]="form"
          [submitAllTab]="submitAllTab"
          [formEdit]="formEditEmail"
          (submittedData)="recipeSubmitted()"
          (isBackToFormList)="backToFormList($event)"
        ></app-email-edit>
      </c-tab-pane>
      <c-tab-pane class="p-3" *ngIf="isDisplayTab">
        <app-video-edit
          [isOutDate]="isOutDate"
          [formEdit]="formEditVideo"
          [submitAllTab]="submitAllTab"
          [videoList]="videoList"
          (submittedData)="recipeSubmitted()"
          (isBackToFormList)="backToFormList($event)"
        ></app-video-edit>
      </c-tab-pane>
      <c-tab-pane class="p-3" *ngIf="isDisplayTab">
        <app-test-edit
          [isOutDate]="isOutDate"
          [formEdit]="formEditExam"
          [examList]="examList"
          [form]="form"
          [submitAllTab]="submitAllTab"
          (dataTestForm)="receiveDataTestForm($event)"
          (submittedData)="recipeSubmitted()"
          (isBackToFormList)="backToFormList($event)"
        ></app-test-edit>
      </c-tab-pane>
      <c-tab-pane class="p-3" *ngIf="isDisplayTab">
        <app-certificate-edit
          [isOutDate]="isOutDate"
          [form]="form"
          [formEdit]="formEditCertificate"
          [submitAllTab]="submitAllTab"
          (submittedData)="recipeSubmitted()"
          (isBackToFormList)="backToFormList($event)"
        ></app-certificate-edit>
      </c-tab-pane>
    </c-tab-content>
  </div>
</c-container>
