export const DATA_VARIABLES_EMAIL: Array<string> = [
  'name',
  'email',
  'organizationName',
  'memberCode',
  'password'
]

export const EMAIL_KEY = {
  IMPORT: "import",
  SIGN_UP: "signup"
}
