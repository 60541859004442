import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ACTION_LIST, BRIGHTNESS, COLOR_METER_LIST, COLOR_METER_POINT_LIST, DEFAULT_CANVAS_CONFIG, DEFAULT_INFERENCE_DATA, DOT_RADIUS, EVENT_LOG_TYPE, formatResetCanvasTime, MODE_DEVICE, RuleValidations, SHAPE_LINE_WIDTH, SHAPE_TYPE } from '@app/constants';
import { changeInputDecimal, drawDot, drawOval, drawRectangle, getFirstError } from '@app/helpers';
import { ICanvasConfig, IGauge } from '@app/models';
import { AlertService, FirebaseAnalyticService, FormService, UserService } from '@app/services';
import { CameraService } from '@app/services/camera.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Timestamp } from 'firebase/firestore';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-edit-devices',
  templateUrl: './edit-devices.component.html',
  styleUrls: ['./edit-devices.component.scss']
})
export class EditDevicesComponent implements OnInit {
  @ViewChild("canvas") canvasRef: any;
  @HostListener('window:touchstart', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  @HostListener('window:touchend', ['$event'])
  @HostListener('window:orientationchange')
  onOrientationChange() {
    if (!this.canvasConfig.oval.isDrawing && !this.canvasConfig.rect.isDrawing) this.drawCanvas(true);
  }
  formUpdate = this.formBuilder.group({
    width: new FormControl(0),
    height: new FormControl(0),
    bright: new FormControl(100),
    rotate: new FormControl(0),
    reversal_width: new FormControl(false),
    reversal_height: new FormControl(false),
    gauges: this.formBuilder.array([]),
  });
  cameraId: string = '';
  form: any;
  formUser: any;
  submitted: boolean = false;
  gaugeData: any;
  visibleResetDataModal: boolean = false;
  isFilePathExistInStorage: any;
  cameraData: any;
  inferenceData: any = [];
  canvas: any;
  context: any;
  isOvalDraw: boolean = false;
  isRectangleDraw: boolean = false;
  isResetData: boolean = false;
  canvasDrawList: any = [];
  prevCanvasWidth: number = 0;
  prevCanvasHeight: number = 0;
  canvasConfig: ICanvasConfig = DEFAULT_CANVAS_CONFIG;
  currentLanguage = this.translateService.currentLang;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private formService: FormService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private cameraService: CameraService,
    private router: Router,
    private storage: AngularFireStorage,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getCameraData().then();
    this.translateService.onLangChange.subscribe((e: LangChangeEvent) => this.currentLanguage = e.lang)
  }

  get getFormGauges() {
    return this.formUpdate.controls['gauges'] as FormArray;
  }

  async getCameraData() {
    const authUser = this.userService.getCurrentUserAuth();
    this.canvasConfig = { ...DEFAULT_CANVAS_CONFIG };
    const params = await firstValueFrom(this.route.params);
    const formId = params['id'];
    this.cameraId = params['camera_id'];
    this.inferenceData = DEFAULT_INFERENCE_DATA;
    this.formUpdate.controls.width.disable();
    this.formUpdate.controls.height.disable();
    
    if (authUser) {
      this.formService.getValueOwnerFormById(authUser?.uid, formId).subscribe(async (response) => {
        this.form = response;
        this.cameraData = await firstValueFrom(this.cameraService.getCameraData(this.form, this.cameraId));
        this.drawCanvas();
      })
    }
  }

  async checkFilePathExistInStorage() {
    let path = this.cameraData?.path;
    if (this.isResetData || !this.cameraData?.path) path = this.formUser?.path;
    if (path) {
      const ref = this.storage.refFromURL(path);
      return new Promise((resolve, reject) => {
        ref.getDownloadURL().subscribe({
          next: () => resolve(true),
          error: () => {
            resolve(false);
            this.alertService.error(this.translateService.instant('alertMessages.formCanvas.imagePathNotExist'));
          }
        });
      });
    }
    return false;
  }

  async setCameraDataForm() {
    this.formUpdate.controls.width.setValue(this.canvasConfig.originalImage.value?.width!);
    this.formUpdate.controls.height.setValue(this.canvasConfig.originalImage.value?.height!);
    if (this.cameraData) {
      this.formUpdate.controls.bright.setValue(this.cameraData.bright);
      this.canvasConfig.bright = this.cameraData.bright;
      if (this.cameraData.hasOwnProperty('rotate')) {
        this.canvasConfig.rotate = this.cameraData.rotate;
        this.formUpdate.controls.rotate.setValue(this.cameraData.rotate);
      }
      if (this.cameraData.hasOwnProperty('reversal_width')) {
        this.formUpdate.controls.reversal_width.setValue(this.cameraData.reversal_width);
        if (this.cameraData.reversal_width) this.canvasConfig.scaleX = -1;
      }
      if (this.cameraData.hasOwnProperty('reversal_height')) {
        this.formUpdate.controls.reversal_height.setValue(this.cameraData.reversal_height);
        if (this.cameraData.reversal_height) this.canvasConfig.scaleY = -1;
      }
    }
  }

  async setGaugeDataForm() {
    const formatResetAt = this.cameraData?.resetAt ? moment(this.cameraData?.resetAt?.toDate()).format(formatResetCanvasTime) : null;
    const callApiGauges = await firstValueFrom(this.cameraService.getGaugeData(this.form, this.cameraData?.camera_id, formatResetAt));
    this.gaugeData = callApiGauges.map((res: any) => {
      const docId = res.payload.doc.id;
      return { ...res.payload.doc.data(), doc_id: docId };
    })
    if (this.gaugeData.length) {
      let formId = 0;
      this.gaugeData.forEach((gauge: IGauge) => {
        if (gauge.inference) {
          gauge = {
            ...gauge,
            isTypeNoneOrCircle: ['none', 'circle'].includes(gauge.inference)
          };
        }
        this.addFormMeter(gauge);
        if (gauge.start_x! > 0 && gauge.start_y! > 0) {
          const startDotData = {
            x: gauge.start_x! / this.canvasConfig.originalImage.ratioX!,
            y: gauge.start_y! / this.canvasConfig.originalImage.ratioY!,
            color: COLOR_METER_POINT_LIST.startPoint,
            type: SHAPE_TYPE.dot,
            gaugeId: gauge.doc_id,
            formId: formId
          };
          this.canvasDrawList.push(startDotData);
        }
        if (gauge.end_x! > 0 && gauge.end_y! > 0) {
          const endDotData = {
            x: gauge.end_x! / this.canvasConfig.originalImage.ratioX!,
            y: gauge.end_y! / this.canvasConfig.originalImage.ratioY!,
            color: COLOR_METER_POINT_LIST.endPoint,
            type: SHAPE_TYPE.dot,
            gaugeId: gauge.doc_id,
            formId: formId
          };
          this.canvasDrawList.push(endDotData);
        }
        if (gauge.center_x! > 0 && gauge.center_y! > 0) {
          const centerDotData = {
            x: gauge.center_x! / this.canvasConfig.originalImage.ratioX!,
            y: gauge.center_y! / this.canvasConfig.originalImage.ratioY!,
            color: COLOR_METER_POINT_LIST.centerPoint,
            type: SHAPE_TYPE.dot,
            gaugeId: gauge.doc_id,
            formId: formId
          };
          this.canvasDrawList.push(centerDotData);
        }
        if (gauge.box_x! > 0 && gauge.box_y! > 0 && gauge.box_width! > 0 && gauge.box_height! > 0) {
          const rectData = {
            startX: gauge.box_x! / this.canvasConfig.originalImage.ratioX!,
            startY: gauge.box_y! / this.canvasConfig.originalImage.ratioY!,
            width: gauge.box_width! / this.canvasConfig.originalImage.ratioX!,
            height: gauge.box_height! / this.canvasConfig.originalImage.ratioY!,
            color: COLOR_METER_POINT_LIST.box,
            type: SHAPE_TYPE.rect,
            gaugeId: gauge.doc_id,
            formId: formId
          };
          this.canvasDrawList.push(rectData);
        }
        if (gauge.center_x! > 0 && gauge.center_x! > 0 && gauge.radius! > 0) {
          const ovalData = {
            centerX: gauge.center_x! / this.canvasConfig.originalImage.ratioX!,
            centerY: gauge.center_y! / this.canvasConfig.originalImage.ratioY!,
            radiusX: gauge.radius! / this.canvasConfig.originalImage.ratioX!,
            color: COLOR_METER_POINT_LIST.size,
            type: SHAPE_TYPE.oval,
            gaugeId: gauge.doc_id,
            formId: formId
          };
          this.canvasDrawList.push(ovalData);
        }
        formId++;
      })
    } else {
      this.addFormMeter();
    }
  }

  addFormMeter(gauge: any = null) {
    const validators = [Validators.maxLength(RuleValidations.maxLength16)];
    if ((this.cameraData?.mode && this.cameraData?.mode == MODE_DEVICE.MANUAL)
      || (this.formUser?.mode && this.formUser.mode == MODE_DEVICE.MANUAL)) validators.push(Validators.required);

    const formGauge = new FormGroup<any>({
      doc_id: new FormControl(gauge?.doc_id ?? null),
      name: new FormControl(gauge?.name ?? '', { validators: validators }),
      inference: new FormControl(gauge?.inference ?? '', { validators: [Validators.required] }),
      center_x: new FormControl(gauge?.center_x ?? 0),
      center_y: new FormControl(gauge?.center_y ?? 0),
      radius: new FormControl(gauge?.radius ?? 0),
      start_x: new FormControl(gauge?.start_x ?? 0),
      start_y: new FormControl(gauge?.start_y ?? 0),
      end_x: new FormControl(gauge?.end_x ?? 0),
      end_y: new FormControl(gauge?.end_y ?? 0),
      startValue: new FormControl(gauge?.startValue ?? 0, { validators: validators }),
      endValue: new FormControl(gauge?.endValue ?? 0, { validators: validators }),
      box_x: new FormControl(gauge?.box_x ?? 0),
      box_y: new FormControl(gauge?.box_y ?? 0),
      box_width: new FormControl(gauge?.box_width ?? 0),
      box_height: new FormControl(gauge?.box_height ?? 0),
      createdAt: new FormControl(gauge?.createdAt ?? Timestamp.now()),
      isDeleted: new FormControl<boolean>(false),
      isTypeNoneOrCircle: new FormControl(gauge?.isTypeNoneOrCircle ?? true)
    });
    this.getFormGauges.push(formGauge);
  }

  removeFormMeter(index: any) {
    this.getFormGauges.at(index).get('isDeleted')?.setValue(true);
    this.canvasDrawList = this.canvasDrawList.filter((canvasDraw: any) => !(canvasDraw.formId == index));
    this.redraw();
  }

  setCanvasSizeByWindow() {
    this.canvas = this.canvasRef?.nativeElement;
    this.context = this.canvas?.getContext("2d");
    const deviceWidth = window.innerWidth;
    let canvasWidth = 1000;
    let canvasHeight = 750;
    if (deviceWidth < 1200) {
      canvasWidth = 750;
      canvasHeight = 500;
    }
    if (deviceWidth < 991) {
      canvasWidth = 500;
      canvasHeight = 250;
    }
    if (this.canvas) {
      this.canvas.width = canvasWidth;
      this.canvas.height = canvasHeight;
    }
  }

  async drawCanvas(isDrawOrient: boolean = false) {
    const params = await firstValueFrom(this.route.params);
    const cameraId = params['camera_id'];
    const formUserList = await firstValueFrom(this.formService.getFormUserByCameraId(this.form, cameraId));
    if (formUserList.length) {
      this.formUser = formUserList[0];
    }
    this.canvasConfig.originalImage.value = new Image();
    this.isFilePathExistInStorage = await this.checkFilePathExistInStorage();
    this.canvasConfig.originalImage.value.src = this.cameraData?.path && this.isFilePathExistInStorage ? this.cameraData?.path : this.formUser?.path;
    this.canvasConfig.originalImage.value!.onload = async () => {
      this.setOriginalImageRatio();
      this.drawImage();
      if (!this.isResetData && !isDrawOrient) {
        await this.setCameraDataForm();
        await this.setGaugeDataForm();
      }
      if (isDrawOrient) this.setOrientRatio();
      this.redraw();
      if (this.canvas && !isDrawOrient) {
        this.prevCanvasWidth = this.canvas.width;
        this.prevCanvasHeight = this.canvas.height;
      }
    }
  }

  setOrientRatio() {
    this.canvasConfig.orientation.ratioX = this.canvas.width / this.prevCanvasWidth;
    this.canvasConfig.orientation.ratioY = this.canvas.height / this.prevCanvasHeight;
  }

  setOriginalImageRatio() {
    this.canvas = this.canvasRef?.nativeElement
    this.context = this.canvas?.getContext("2d");
    const dpr = window.devicePixelRatio || 1;
    this.context?.scale(dpr, dpr);
    this.setCanvasSizeByWindow();
    if (this.canvasConfig.originalImage.value && this.canvas) {
      const originalImageWidth = this.canvasConfig.originalImage.value.width;
      const originalImageHeight = this.canvasConfig.originalImage.value.height;
      if (originalImageWidth > originalImageHeight) {
        this.canvasConfig.image.ratioY = originalImageWidth / originalImageHeight;
        this.canvasConfig.image.width = this.canvas.width;
        this.canvasConfig.image.height = this.canvas.width / this.canvasConfig.image.ratioY;
      } else if (originalImageWidth < originalImageHeight) {
        this.canvasConfig.image.ratioX = originalImageHeight / originalImageWidth;
        this.canvasConfig.image.width = this.canvas.height;
        this.canvasConfig.image.height = this.canvas.height * this.canvasConfig.image.ratioX;
      }
    }
    this.canvasConfig.originalImage.ratioX = this.canvasConfig.originalImage.value!.width / this.canvasConfig.image.width!;
    this.canvasConfig.originalImage.ratioY = this.canvasConfig.originalImage.value!.height / this.canvasConfig.image.height!;
    if (this.canvas) {
      this.canvas.width = this.canvasConfig.image.width;
      this.canvas.height = this.canvasConfig.image.height;
    }
  }

  changeDrawType(meter: any, formIndex: number, docId: string) {
    this.isOvalDraw = false;
    this.isRectangleDraw = false;
    if (meter.type === SHAPE_TYPE.dot) {
      this.canvasConfig.dot.color = meter.colorCode;
      this.canvasConfig.dot.formId = formIndex;
      this.canvasConfig.dot.gaugeId = docId.length ? docId : formIndex.toString();
    }
    if (meter.type === SHAPE_TYPE.oval) {
      this.canvasConfig.oval.color = meter.borderColor;
      this.canvasConfig.oval.formId = formIndex;
      this.canvasConfig.oval.gaugeId = docId.length ? docId : formIndex.toString();
      this.isOvalDraw = true;
    }
    if (meter.type === SHAPE_TYPE.rect) {
      this.canvasConfig.rect.color = meter.borderColor;
      this.canvasConfig.rect.formId = formIndex;
      this.canvasConfig.rect.gaugeId = docId.length ? docId : formIndex.toString();
      this.isRectangleDraw = true;
    }
  }

  drawImage() {
    this.context?.drawImage(this.canvasConfig.originalImage.value, 0, 0, this.canvasConfig.image.width, this.canvasConfig.image.height);
  }

  drawDot(event: MouseEvent) {
    if (!this.isOvalDraw && !this.isRectangleDraw
      && this.canvasConfig.dot.color?.length
      && this.canvasDrawList.findIndex((item: any) => item.gaugeId === this.canvasConfig.dot.gaugeId
      && item.color === this.canvasConfig.dot.color
      && item.type === SHAPE_TYPE.dot) < 0) {
      const x = event.offsetX;
      const y = event.offsetY;
      if (this.canvasConfig.dot.color !== COLOR_METER_POINT_LIST.centerPoint) {
        drawDot(this.context, x, y, this.canvasConfig.dot.color);
        this.canvasDrawList.push({
          x: x,
          y: y,
          color: this.canvasConfig.dot.color,
          type: SHAPE_TYPE.dot,
          formId: this.canvasConfig.dot.formId,
          gaugeId: this.canvasConfig.dot.gaugeId
        });
      }

      let data = {};
      if (this.canvasConfig.dot.color === COLOR_METER_POINT_LIST.startPoint) {
        data = {
          start_x: x * this.canvasConfig.originalImage.ratioX!,
          start_y: y * this.canvasConfig.originalImage.ratioY!
        };
      }
      if (this.canvasConfig.dot.color === COLOR_METER_POINT_LIST.endPoint) {
        data = {
          end_x: x * this.canvasConfig.originalImage.ratioX!,
          end_y: y * this.canvasConfig.originalImage.ratioY!
        };
      }
      this.getFormGauges.at(this.canvasConfig.dot.formId ?? 0).patchValue(data);
    }
  }

  setCoordStartDrawing(offsetX: number, offsetY: number) {
    const ovalIndex = this.canvasDrawList.findIndex((item: any) => item.gaugeId == this.canvasConfig.oval.gaugeId && item.color === COLOR_METER_POINT_LIST.centerPoint);
    const rectIndex = this.canvasDrawList.findIndex((item: any) => item.gaugeId == this.canvasConfig.rect.gaugeId && item.type === SHAPE_TYPE.rect);
    if (this.isOvalDraw && ovalIndex < 0) {
      this.canvasConfig.oval.isDrawing = true;
      this.canvasConfig.oval.startX = offsetX;
      this.canvasConfig.oval.startY = offsetY;
    }
    if (this.isRectangleDraw && rectIndex < 0) {
      this.canvasConfig.rect.isDrawing = true;
      this.canvasConfig.rect.startX = offsetX;
      this.canvasConfig.rect.startY = offsetY;
    }
  }

  startDrawing(event: MouseEvent) {
    this.setCoordStartDrawing(event.offsetX, event.offsetY);
  }

  startDrawingTablet(event: TouchEvent) {
    const element = event.target as HTMLElement;
    const rect = element.getBoundingClientRect();
    let offsetX = event.touches[0].clientX - rect.left;
    let offsetY = event.touches[0].clientY - rect.top;
    if (offsetX >= 0 && offsetY >= 0) {
      this.setCoordStartDrawing(offsetX, offsetY);
    }
  }

  setCoordDrawShape(offsetX: number, offsetY: number) {
    if (this.canvasConfig.oval.isDrawing) {
      this.redraw();
      // Get the current mouse position
      this.canvasConfig.oval.endX = offsetX;
      this.canvasConfig.oval.endY = offsetY;
      // Calculate the center point and radius of the oval
      if (this.canvasConfig.oval.startX && this.canvasConfig.oval.startY && this.canvasConfig.oval.endX && this.canvasConfig.oval.endY) {
        const centerX = (this.canvasConfig.oval.startX + this.canvasConfig.oval.endX) / 2;
        const centerY = (this.canvasConfig.oval.startY + this.canvasConfig.oval.endY) / 2;
        const radiusX = Math.abs(this.canvasConfig.oval.endX - this.canvasConfig.oval.startX) / 2;
        const radiusY = Math.abs(this.canvasConfig.oval.endY - this.canvasConfig.oval.startY) / 2;
        this.canvasConfig.oval.centerX = centerX;
        this.canvasConfig.oval.centerY = centerY;
        this.canvasConfig.oval.radiusX = radiusX;
        this.canvasConfig.oval.radiusY = radiusY;
        // Draw the oval
        this.context.lineWidth = SHAPE_LINE_WIDTH;
        drawOval(this.context, centerX, centerY, radiusX, radiusX, this.canvasConfig.oval.color);
      }
    }
    if (this.canvasConfig.rect.isDrawing) {
      this.redraw();
      if (this.canvasConfig.rect.startX && this.canvasConfig.rect.startY) {
        this.canvasConfig.rect.width = offsetX - this.canvasConfig.rect.startX;
        this.canvasConfig.rect.height = offsetY - this.canvasConfig.rect.startY;
        this.canvasConfig.rect.endX = offsetX;
        this.canvasConfig.rect.endY = offsetY;
        this.context.lineWidth = SHAPE_LINE_WIDTH;
        drawRectangle(this.context, this.canvasConfig.rect.startX, this.canvasConfig.rect.startY, this.canvasConfig.rect.width, this.canvasConfig.rect.height, this.canvasConfig.rect.color!);
      }
    }
  }

  drawShape(event: MouseEvent) {
    if (this.canvas) {
      this.setCoordDrawShape(event.offsetX, event.offsetY);
    }
  }

  drawShapeTablet(event: TouchEvent) {
    const element = event.target as HTMLElement;
    const rect = element.getBoundingClientRect();
    let offsetX = event.touches[0].clientX - rect.left;
    let offsetY = event.touches[0].clientY - rect.top;
    if (this.canvas && offsetX >= 0 && offsetY >= 0 && offsetX <= this.canvas.width && offsetY <= this.canvas.height) {
      this.setCoordDrawShape(offsetX, offsetY);
    }
  }

  stopDrawing(event: MouseEvent | TouchEvent) {
    if (this.canvasConfig.oval.isDrawing) {
      this.canvasConfig.oval.isDrawing = false;
      drawDot(this.context, this.canvasConfig.oval.centerX!, this.canvasConfig.oval.centerY!, COLOR_METER_POINT_LIST.centerPoint);
      const ovalData = {
        center_x: this.canvasConfig.oval.centerX! * this.canvasConfig.originalImage.ratioX!,
        center_y: this.canvasConfig.oval.centerY! * this.canvasConfig.originalImage.ratioY!,
        radius: this.canvasConfig.oval.radiusX! * this.canvasConfig.originalImage.ratioX!
      };
      this.getFormGauges.at(this.canvasConfig.oval.formId ?? 0).patchValue(ovalData);
      this.canvasDrawList.push({
        x: this.canvasConfig.oval.centerX!,
        y: this.canvasConfig.oval.centerY!,
        color: COLOR_METER_POINT_LIST.centerPoint,
        type: SHAPE_TYPE.dot,
        formId: this.canvasConfig.oval.formId,
        gaugeId: this.canvasConfig.oval.gaugeId
      });
      this.canvasDrawList.push({
        centerX: this.canvasConfig.oval.centerX,
        centerY: this.canvasConfig.oval.centerY,
        radiusX: this.canvasConfig.oval.radiusX,
        radiusY: this.canvasConfig.oval.radiusY,
        color: this.canvasConfig.oval.color,
        type: SHAPE_TYPE.oval,
        formId: this.canvasConfig.oval.formId,
        gaugeId: this.canvasConfig.oval.gaugeId
      });
    }
    if (this.canvasConfig.rect.isDrawing) {
      this.canvasConfig.rect.isDrawing = false;
      const rectWidth = this.canvasConfig.rect.width;
      const rectHeight = this.canvasConfig.rect.height;
      const startX = this.canvasConfig.rect.startX;
      const startY = this.canvasConfig.rect.startY;
      const endX = this.canvasConfig.rect.endX;
      const endY = this.canvasConfig.rect.endY;
      let boxX = startX;
      let boxY = startY;
      if (rectWidth && rectHeight && startX && startY && endX && endY) {
        if (rectWidth < 0) {
          boxX = startX - Math.abs(rectWidth);
          boxY = startY;
        }
        if (rectHeight < 0) {
          boxX = startX;
          boxY = startY - Math.abs(rectHeight);
        }
        if (rectWidth < 0 && rectHeight < 0) {
          boxX = endX;
          boxY = endY;
        }
      }

      const rectData = {
        box_x: boxX! * this.canvasConfig.originalImage.ratioX!,
        box_y: boxY! * this.canvasConfig.originalImage.ratioY!,
        box_width: Math.abs(this.canvasConfig.rect.width!) * this.canvasConfig.originalImage.ratioX!,
        box_height: Math.abs(this.canvasConfig.rect.height!) * this.canvasConfig.originalImage.ratioY!
      };
      this.getFormGauges.at(this.canvasConfig.rect.formId ?? 0).patchValue(rectData);
      this.canvasDrawList.push({
        startX: this.canvasConfig.rect.startX,
        startY: this.canvasConfig.rect.startY,
        endX: this.canvasConfig.rect.endX,
        endY: this.canvasConfig.rect.endY,
        width: this.canvasConfig.rect.width,
        height: this.canvasConfig.rect.height,
        color: this.canvasConfig.rect.color,
        type: SHAPE_TYPE.rect,
        formId: this.canvasConfig.rect.formId,
        gaugeId: this.canvasConfig.rect.gaugeId
      });
    }
  }

  resetCoordinateCanvas() {
    this.context?.setTransform(1, 0, 0, 1, 0, 0);
  }

  redraw() {
    this.context?.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawImage();
    if (this.canvasConfig.rotate != 0) this.setRotateCanvas();
    if (this.canvasConfig.scaleX && this.canvasConfig.scaleX < 0) this.setHorizontalCanvas();
    if (this.canvasConfig.scaleY && this.canvasConfig.scaleY < 0) this.setVerticalCanvas();
    this.resetCoordinateCanvas();
    this.redrawShape();
  }

  redrawShape() {
    if (this.canvasDrawList.length) {
      this.context.lineWidth = SHAPE_LINE_WIDTH;
      for (const shape of this.canvasDrawList) {
        if (this.canvasConfig.orientation.ratioX && this.canvasConfig.orientation.ratioY) {
          if (shape.type === SHAPE_TYPE.dot)
            drawDot(this.context,
              shape.x * this.canvasConfig.orientation.ratioX,
              shape.y * this.canvasConfig.orientation.ratioY,
              shape.color
            );
          if (shape.type === SHAPE_TYPE.oval)
            drawOval(this.context,
              shape.centerX * this.canvasConfig.orientation.ratioX,
              shape.centerY * this.canvasConfig.orientation.ratioY,
              shape.radiusX * this.canvasConfig.orientation.ratioX,
              shape.radiusX * this.canvasConfig.orientation.ratioX,
              shape.color
            );
          if (shape.type === SHAPE_TYPE.rect)
            drawRectangle(this.context,
              shape.startX * this.canvasConfig.orientation.ratioX,
              shape.startY * this.canvasConfig.orientation.ratioY,
              shape.width * this.canvasConfig.orientation.ratioX,
              shape.height * this.canvasConfig.orientation.ratioY, shape.color
            );
        }
      }
    }
  }

  flipHorizontalCanvas() {
    this.canvasConfig.scaleX = -(this.canvasConfig.scaleX ?? 1);
    this.formUpdate.controls.reversal_width.setValue(this.canvasConfig.scaleX < 0);
    this.setHorizontalCanvas();
  }

  setHorizontalCanvas() {
    if (this.canvasConfig.rotate != 0) this.setRotateCanvas();
    if (this.canvasConfig.scaleY && this.canvasConfig.scaleY < 0) this.flipVerticalCoordinate();
    this.flipHorizontalCoordinate();
    this.resetCoordinateCanvas();
    this.redrawShape();
  }

  flipHorizontalCoordinate() {
    if (this.canvasConfig.scaleX) {
      this.context.scale(this.canvasConfig.scaleX, 1);
      let startX = 0;
      if (this.canvasConfig.scaleX < 0 && this.canvasConfig.image.width && this.canvasConfig.image.height) {
        const checkImageWidthLarge = this.canvasConfig.image.width > this.canvasConfig.image.height;
        const checkImageHeightLarge = this.canvasConfig.image.height > this.canvasConfig.image.width;
        const checkCanvasWidthLarge = this.canvas.width > this.canvas.height;
        const checkCanvasHeightLarge = this.canvas.height > this.canvas.width;
        if ((checkCanvasWidthLarge && checkImageWidthLarge) || (checkCanvasHeightLarge && checkImageHeightLarge)) {
          startX = -this.canvas.width;
        }
        if ((checkCanvasHeightLarge && checkImageWidthLarge) || (checkCanvasWidthLarge && checkImageHeightLarge)) {
          startX = -this.canvas.height;
        }
      }
      this.context.translate(startX, 0);
      this.drawImage();
    }
  }

  flipVerticalCanvas() {
    this.canvasConfig.scaleY = -(this.canvasConfig.scaleY ?? 1);
    this.formUpdate.controls.reversal_height.setValue(this.canvasConfig.scaleY < 0);
    this.setVerticalCanvas();
  }

  setVerticalCanvas() {
    if (this.canvasConfig.rotate != 0) this.setRotateCanvas();
    if (this.canvasConfig.scaleX! < 0) this.flipHorizontalCoordinate();
    this.flipVerticalCoordinate();
    this.resetCoordinateCanvas();
    this.redrawShape();
  }

  flipVerticalCoordinate() {
    if (this.canvasConfig.scaleY) {
      this.context.scale(1, this.canvasConfig.scaleY);
      let startY = 0;
      if (this.canvasConfig.scaleY < 0 && this.canvasConfig.image.width && this.canvasConfig.image.height) {
        const checkImageWidthLarge = this.canvasConfig.image.width > this.canvasConfig.image.height;
        const checkImageHeightLarge = this.canvasConfig.image.height > this.canvasConfig.image.width;
        const checkCanvasWidthLarge = this.canvas.width > this.canvas.height;
        const checkCanvasHeightLarge = this.canvas.height > this.canvas.width;
        if ((checkImageWidthLarge && checkCanvasHeightLarge) || (checkImageHeightLarge && checkCanvasWidthLarge)) {
          startY = -this.canvas.width;
        }
        if ((checkImageWidthLarge && checkCanvasWidthLarge) || (checkImageHeightLarge && checkCanvasHeightLarge)) {
          startY = -this.canvas.height;
        }
      }
      this.context.translate(0, startY);
      this.drawImage();
    }
  }

  rotateImage() {
    this.canvasConfig.rotate = (this.canvasConfig.rotate ?? 0) + 90;
    if (this.canvasConfig.rotate > 270) this.canvasConfig.rotate = 0;
    this.formUpdate.controls.rotate.setValue(this.canvasConfig.rotate);
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.setRotateCanvas();
    if (this.canvasConfig.scaleX! < 0) this.flipHorizontalCoordinate();
    if (this.canvasConfig.scaleY! < 0) this.flipVerticalCoordinate();
    this.resetCoordinateCanvas();
    this.redrawShape();
  }

  setRotateCanvas() {
    switch (this.canvasConfig.rotate) {
      case 90: {
        this.canvas.width = this.canvasConfig.image.height;
        this.canvas.height = this.canvasConfig.image.width;
        this.context.rotate((90 * Math.PI) / 180);
        this.context.translate(0, -this.canvas.width);
        break;
      }
      case 180: {
        this.canvas.width = this.canvasConfig.image.width;
        this.canvas.height = this.canvasConfig.image.height;
        this.context.rotate((180 * Math.PI) / 180);
        this.context.translate(-this.canvas.width, -this.canvas.height);
        break;
      }
      case 270: {
        this.canvas.width = this.canvasConfig.image.height;
        this.canvas.height = this.canvasConfig.image.width;
        this.context.rotate((270 * Math.PI) / 180);
        this.context.translate(-this.canvas.height, 0);
        break;
      }
      default: {
        this.canvas.width = this.canvasConfig.image.width;
        this.canvas.height = this.canvasConfig.image.height;
      }
    }
    this.removeShapeOutsideAfterRotate();
    this.drawImage();
  }

  removeShapeOutsideAfterRotate() {
    let shapeOvalOutside: any = [];
    let shapeOutside: any = [];
    if (this.canvas.height! > this.canvas.width!) {
      shapeOvalOutside = this.canvasDrawList.filter((shape: any) => shape.type === SHAPE_TYPE.oval && (shape.centerX + shape.radiusX > this.canvas.width));
      shapeOutside = this.canvasDrawList.filter((shape: any) => this.checkShapeOutsideCanvas(shape, 'x', 'centerX', 'startX', shapeOvalOutside, 'width'));
    }
    if (this.canvas.width! > this.canvas.height!) {
      shapeOvalOutside = this.canvasDrawList.filter((shape: any) => shape.type === SHAPE_TYPE.oval && (shape.centerY + shape.radiusX > this.canvas.height));
      shapeOutside = this.canvasDrawList.filter((shape: any) => this.checkShapeOutsideCanvas(shape, 'y', 'centerY', 'startY', shapeOvalOutside, 'height'));
    }
    if (shapeOutside.length) {
      shapeOutside.map((shape: any) => {
        const data = this.getDefaultShapeData(shape);
        this.getFormGauges.at(shape.formId ?? 0).patchValue(data);
      });
    }
    this.canvasDrawList = this.canvasDrawList.filter((item: any) => !shapeOutside.includes(item));
  }

  checkShapeOutsideCanvas(shape: any, dotCoord: string = '', ovalCoord: string = '', rectCoord: string = '', shapeOvalOutside: any, sizeName: string) {
    return (shape.type === SHAPE_TYPE.dot && shape[dotCoord] && (shape[dotCoord] > this.canvas[sizeName] || shapeOvalOutside.find((oval: any) => oval[ovalCoord] == shape[dotCoord] && shape.color === COLOR_METER_POINT_LIST.centerPoint)))
      || (shape.type === SHAPE_TYPE.oval && shape[ovalCoord] && shape.radiusX && (shape[ovalCoord] > this.canvas[sizeName] || (shape[ovalCoord] + shape.radiusX > this.canvas[sizeName])))
      || (shape.type === SHAPE_TYPE.rect && shape[rectCoord] && (shape[rectCoord] > this.canvas[sizeName] || (shape[rectCoord] + shape[sizeName] > this.canvas[sizeName])));
  }

  changeBrightness(event: any) {
    const value = event.target.value;
    this.canvasConfig.bright = value;
    this.redraw();
  }

  undoDraw() {
    if (this.canvasDrawList.length) {
      const lastShapeDraw = this.canvasDrawList[this.canvasDrawList.length - 1];
      const data = this.getDefaultShapeData(lastShapeDraw);
      this.canvasDrawList.pop();
      this.getFormGauges.at(lastShapeDraw.formId ?? 0).patchValue(data);
      this.redraw();
    }
  }

  getDefaultShapeData(shape: any) {
    let data: any = {};
    switch (shape.type) {
      case SHAPE_TYPE.rect: {
        data = {
          box_x: 0,
          box_y: 0,
          box_width: 0,
          box_height: 0
        };
        break;
      }
      case SHAPE_TYPE.oval: {
        data = {
          center_x: 0,
          center_y: 0,
          radius: 0
        };
        break;
      }
      default: {
        if (shape.color === COLOR_METER_POINT_LIST.startPoint) {
          data = { start_x: 0, start_y: 0 };
        }
        if (shape.color === COLOR_METER_POINT_LIST.endPoint) {
          data = { end_x: 0, end_y: 0 };
        }
      }
    }
    return data;
  }

  toggleResetDataModal() {
    this.visibleResetDataModal = !this.visibleResetDataModal;
  }

  async resetData() {
    this.isFilePathExistInStorage = await this.checkFilePathExistInStorage();
    this.isResetData = true;
    this.getFormGauges.clear();
    this.addFormMeter();
    this.canvasConfig.originalImage.value!.src = this.formUser?.path;
    this.canvasConfig.bright = 100;
    this.canvasConfig.rotate = 0;
    this.canvasConfig.scaleX = 1;
    this.canvasConfig.scaleY = 1;
    this.canvasDrawList = [];

    this.canvasConfig.originalImage.value!.onload = () => {
      this.redraw();
      if (this.canvasConfig.originalImage.value?.width && this.canvasConfig.originalImage.value?.height) {
        const defaultCameraData = {
          rotate: 0,
          bright: 100,
          width: this.canvasConfig.originalImage.value?.width,
          height: this.canvasConfig.originalImage.value?.height,
          reversal_width: false,
          reversal_height: false,
          gauges: this.formUpdate.controls.gauges.value,
        };
        this.formUpdate.setValue(defaultCameraData);
      }
    }
    this.visibleResetDataModal = false;
    this.cameraData = { ...this.cameraData, resetAt: Timestamp.now() };
  }

  checkLargeImage() {
    return this.canvasConfig.originalImage.value?.width && this.canvasConfig.originalImage.value.width > 850 || window.innerWidth <= 1200;
  }

  async submit() {
    this.submitted = true;
    if (this.formUpdate.valid) {
      let imageId = this.formUser?.['entryno'];
      let path: string = this.formUser?.['path'] ?? null;
      if (this.cameraData) {
        this.cameraId = this.cameraData.camera_id;
        imageId = this.cameraData?.image_id;
        if (!this.cameraData.hasOwnProperty('resetAt') && this.cameraData.path) path = this.cameraData.path;
      }
      if (this.cameraData && this.cameraData.hasOwnProperty('resetAt') && !this.cameraData.resetAt) delete this.cameraData.resetAt;
      let cameraUpdate: any = {
        ...this.cameraData,
        image_id: imageId,
        path: path,
        camera_id: this.cameraId,
        mode: this.cameraData?.mode ?? this.formUser?.mode,
        width: this.formUpdate.controls.width.value,
        height: this.formUpdate.controls.height.value,
        bright: this.formUpdate.controls.bright.value,
        rotate: this.formUpdate.controls.rotate.value,
        reversal_width: this.formUpdate.controls.reversal_width.value,
        reversal_height: this.formUpdate.controls.reversal_height.value,
        createdAt: Timestamp.now()
      };
      await this.cameraService.updateCameraData(this.form, cameraUpdate).then(async () => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.CAMERA.UPDATE, EVENT_LOG_TYPE.SUCCESS);
        let gaugeData = this.formUpdate.controls.gauges.value.map((item: any) => {
          const { isTypeNoneOrCircle: isTypeNoneOrCircle, ...rest } = item;
          return { ...rest, image_id: imageId };
        });
        if (this.isResetData && this.gaugeData.length) {
          this.gaugeData = this.gaugeData?.map((gauge: any) => {
            return { ...gauge, isDeleted: true };
          });
          gaugeData = this.gaugeData.concat(gaugeData);
        }
        if (this.cameraId) {
          await this.cameraService.updateOrDeleteGaugeData(this.form, this.cameraId, gaugeData).then(() => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.CAMERA.UPDATE_GAUGE, EVENT_LOG_TYPE.SUCCESS);
            this.router.navigate([`form/${this.form.doc_id}/devices`]).then();
            this.alertService.success(this.translateService.instant('alertMessages.formCanvas.update.success'));
          }).catch((e) => {
            this.firebaseAnalyticService.logEvent(ACTION_LIST.CAMERA.UPDATE_GAUGE, EVENT_LOG_TYPE.ERROR);
          });
        }
      }).catch((e) => {
        this.alertService.error(this.translateService.instant('alertMessages.formCanvas.update.fail'));
        this.firebaseAnalyticService.logEvent(ACTION_LIST.CAMERA.UPDATE, EVENT_LOG_TYPE.ERROR);
      });
    }
  }

  changeTypeCamera(event: any, formIndex: number) {
    const isTypeNoneOrCircle = ['none', 'circle'].includes(event.target.value);
    this.getFormGauges.controls[formIndex].get('isTypeNoneOrCircle')?.setValue(isTypeNoneOrCircle);
    if (!isTypeNoneOrCircle) {
      const listOvalDotShape = this.canvasDrawList.filter((shape: any) => shape.formId === formIndex && [SHAPE_TYPE.oval, SHAPE_TYPE.dot].includes(shape.type));
      for (const shape of listOvalDotShape) {
        const data = this.getDefaultShapeData(shape);
        this.getFormGauges.at(shape.formId ?? 0).patchValue(data);
      }
      // TODO: filter canvas draw list remove type oval and dot
      this.canvasDrawList = this.canvasDrawList.filter((canvasDraw: any) => !listOvalDotShape.some((ovalDotShape: any) => ovalDotShape.formId === canvasDraw.formId && ovalDotShape.type === canvasDraw.type));
      this.redraw();
    }
  }

  protected readonly getFirstError = getFirstError;
  protected readonly changeInputDecimal = changeInputDecimal;
  protected readonly ruleValidations = RuleValidations;
  protected readonly colorMeterList = COLOR_METER_LIST;
  protected readonly dotRadius = DOT_RADIUS;
  protected readonly brightness = BRIGHTNESS;
}
