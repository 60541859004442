import { Injectable } from '@angular/core';
import { initializeApp } from "@angular/fire/app";
import { environment } from "@environments/environment";
import { getAuth } from "@firebase/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ICamera } from "@app/models/camera";
import { ulid } from 'ulid';
import { IGauge } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class CameraService {
  private app = initializeApp(environment.firebaseConfig);
  private auth = getAuth(this.app);
  constructor(private firestore: AngularFirestore) { }

  index(formId: string) {
    return this.firestore.collection('users').doc(this.auth.currentUser?.uid).collection('form').doc(formId).collection('cameras').snapshotChanges()
  }

  createOrUpdateCamera(cameraInfo: ICamera, formId: string) {
    return this.firestore.collection('users').doc(this.auth.currentUser?.uid)
      .collection('form').doc(formId)
      .collection('cameras').doc(cameraInfo.camera_id)
      .set(cameraInfo);
  }

  async batchUpdateCamera(dataUpdate: Array<ICamera | null>, formId: string) {
    const batch = this.firestore.firestore.batch();
    dataUpdate.forEach((item) => {
      if (item) {
        const ref = this.firestore.collection('users').doc(this.auth.currentUser?.uid)
          .collection('form').doc(formId)
          .collection('cameras').doc(item?.camera_id).ref;
        batch.update(ref, item)
      }
    })
    await batch.commit()
  }

  async updateCameraData(form: any, data: any) {
    await this.firestore.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).collection('cameras').doc(data.camera_id).set(data);
  }

  async updateOrDeleteGaugeData(form: any, cameraId: string, gaugeData: any) {
    const batch = this.firestore.firestore.batch();
    const gaugeCollection = this.firestore.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).collection('cameras').doc(cameraId).collection('gauges');
    gaugeData.forEach((gauge: IGauge) => {
      const gaugeId = ulid();
      if (!gauge.doc_id) gauge.doc_id = gaugeId;
      const gaugeRef = gaugeCollection.doc(gauge.doc_id).ref;
      if (gauge.isDeleted) {
        batch.delete(gaugeRef);
      } else {
        const {isDeleted, ...dataUpdate} = gauge;
        batch.set(gaugeRef, dataUpdate);
      }
    })
    await batch.commit();
  }

  getCameraData(form: any, cameraId: string) {
    return this.firestore.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).collection('cameras').doc(cameraId).valueChanges();
  }

  getGaugeData(form: any, cameraId: string, resetAt: string | null) {
    return this.firestore.collection('users').doc(form.user_id).collection('form').doc(form.doc_id).collection('cameras').doc(cameraId).collection('gauges').snapshotChanges();
  }
}
