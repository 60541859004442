import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService, AlertService, FirebaseAnalyticService, NotificationService } from '@app/services';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from '@app/constants';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from "rxjs";

@Component({
  selector: 'app-register',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  formForgotPassword!: UntypedFormGroup;
  submitted = false;
  checkPass = false;
  loading = false;

  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private angularFireAuth: AngularFireAuth,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService,
    private notification: NotificationService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.formForgotPassword = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(RuleValidations.emailPattern)]],
    });
  }

  get f() {
    return this.formForgotPassword.controls;
  }

  private onValidate() {
    this.submitted = true;
    return this.formForgotPassword.valid;
  }

  async forgotPassword() {
    this.alertService.clear();
    if (this.onValidate() && !this.loading) {
      this.loading = true;
      const email = this.formForgotPassword.value.email;
      await this.emailValidate(email).then();
      if (!this.checkPass) {
        this.loading = false;
        this.alertService.error(this.translateService.instant('alertMessages.forgotPassword.emailNotRegister'));
        return;
      }
      this.userService.forgotPassword(email).then(res => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.FORGOT_PASSWORD, EVENT_LOG_TYPE.SUCCESS);
        this.alertService.success(this.translateService.instant('alertMessages.forgotPassword.emailSendPasswordReset', { email: email }));
        // update by Tanaka on 2023/08/24
/*
        this.userService.getUserByEmailApi(email).then((response: any) => {
          if (response['user'].length) {
            const user = response['user'][0];
            this.notification.sendNoticeForOtherDevices(user, 'forgotPassword').then()
          }
        });
*/
      }).catch(error => {
        this.firebaseAnalyticService.logEvent(ACTION_LIST.ACCOUNT.FORGOT_PASSWORD, EVENT_LOG_TYPE.ERROR);
        this.alertService.error(this.translateService.instant('alertMessages.forgotPassword.emailNotRegister'));
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      });
    }
  }

  private async emailValidate(email: string) {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);
    if (user) {
      user.forEach((value) => {
        if (value == 'password') {
          this.checkPass = true;
        }
      });
    }
  }
}
