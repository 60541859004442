import { ICanvasConfig } from "@app/models";

export const DOT_RADIUS = 8;
export const SHAPE_LINE_WIDTH = 5;
export const BRIGHTNESS = {
  min: 0,
  max: 200
};
export const SHAPE_TYPE = {
  dot: 'dot',
  oval: 'oval',
  rect: 'rect'
};
export const DEFAULT_INFERENCE_DATA = [
  'none', 'circle', 'digital', 'green'
];
export const DEFAULT_CANVAS_CONFIG: ICanvasConfig =  {
  orientation: {
    ratioX: 1,
    ratioY: 1
  },
  bright: 100,
  scaleX: 1,
  scaleY: 1,
  rotate: 0,
  originalImage: {
    value: null,
    ratioX: 1,
    ratioY: 1
  },
  image: {
    width: 0,
    height: 0,
    ratioX: 1,
    ratioY: 1
  },
  dot: {
    color: '',
  },
  oval: {
    isDrawing: false,
    color: '',
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
    centerX: 0,
    centerY: 0,
    radiusX: 0,
    radiusY: 0,
  },
  rect: {
    isDrawing: false,
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
    width: 0,
    height: 0
  }
};

export const DEVICE_WIDTH = {
  phone: { min: 320, average: 414, max: 736 },
  tablet: { min: 768, average: 834, max: 1024, expand: 1180 },
  laptop: { min: 1200, max: 1600 }
}
