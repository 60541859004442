<div class="map-container">
  <div class="map-frame">
    <div [id]="idContainer"></div>
    <div class="block-search">
      <input
        type="text"
        [disabled]="disabled"
        (input)="searchLocale($event)"
        (keypress)="searchByEnter($event)"
        [placeholder]="placeholder"
        [value]="dataMapLocation"
      />
      <ul cListGroup class="list-group scroll-list-locale" *ngIf="isShowLocales">
        <li *ngFor="let locale of locales" class="list-group-item" cListGroupItem (click)="setLocale(locale)">
          {{ locale.properties.title }}
        </li>
      </ul>
    </div>
    <div class="data-info">
      <div class="info">
        <div>
          <span *ngIf="info.maplocation_latitude">{{ ("map.lat" | translate) + ": " + info.maplocation_latitude }}</span>
          <span class="ms-3" *ngIf="info.maplocation_longitude">{{ ("map.lng" | translate) + ": " + info.maplocation_longitude }}</span>
        </div>
        <span *ngIf="info.maplocation">{{ ("map.address" | translate) + ": " + info.maplocation }}</span>
      </div>
    </div>
  </div>
</div>
