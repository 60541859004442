<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="mb-5 d-flex box-head-form">
    <c-col [xl]="6" [lg]="6" [md]="7">
      <h2 class="mb-0">{{ "template.listTitle" | translate }}</h2>
    </c-col>
    <c-col [xl]="6" [lg]="6" [md]="5" class="d-flex justify-content-end">
      <a routerLink="/list-template/create">
        <button class="btn-common">
          <img src="assets/svg/flight-record/icon-add.svg" alt="" />
          <span class="ms-2">{{ "template.btnLabel.create" | translate }}</span>
        </button>
      </a>
    </c-col>
  </c-col>
  <c-col class="mb-5">
    <c-col>
      <c-card class="card-no-border">
        <c-card-body>
          <form cForm [formGroup]="formSearch" (ngSubmit)="search()">
            <c-col class="mb-3 d-flex justify-content-between">
              <span>{{ "template.inputLabel.search" | translate }}</span>
              <span class="button-collapse" (click)="openSearchSection()">
                <img class="caret-down" height="20" width="20"
                  src="assets/svg/common/caret-down.svg"
                  [class]="{ 'caret-down-open': visibleSearchSection }"
                />
              </span>
            </c-col>
            <div [visible]="visibleSearchSection" cCollapse>
              <div>
                <c-col class="mb-2 d-flex align-items-center col-border">
                  <c-input-group class="d-flex align-items-center">
                    <label for="querySearch">
                      <img src="assets/svg/flight-record/icon-search.svg"/>
                    </label>
                    <input
                      cFormControl
                      formControlName="querySearch"
                      placeholder=""
                      name="querySearch"
                      id="querySearch"
                      class="input-search"
                    />
                  </c-input-group>
                </c-col>
                <c-col>
                  <c-col class="mt-3 box-color">
                    <div class="btn-left">
                      <button
                        type="button"
                        class="button-all"
                        [ngClass]="{
                          'check-all-color': formSearch.controls['color'].value,
                          'not-check-all-color': !formSearch.controls['color'].value
                        }"
                        (click)="onUncheckColor()"
                      >
                        {{ "form.inputLabel.all" | translate }}
                      </button>
                      <div class="colors d-flex flex-wrap">
                        <c-form-check class="color-option" *ngFor="let item of colorTypesSet; let i = index">
                          <input
                            formControlName="color"
                            class="check-box-color d-none"
                            id="color{{ item.colorCode }}"
                            [value]="item.colorCode"
                            name="color"
                            cFormCheckInput
                            type="radio"
                          />
                          <label
                            cFormCheckLabel
                            for="color{{ item.colorCode }}"
                            [style.--border-color]="item.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                            [style.--color]="item.colorCode"
                            [style.border]="item.colorCode === '#FFFFFF' ? '1px solid #667085' : ''"
                            class="color-icon"
                          ></label>
                        </c-form-check>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                      <button class="btn-common button-clear" type="submit">
                        {{ "template.btnLabel.search" | translate }}
                      </button>
                    </div>
                  </c-col>
                </c-col>
              </div>
            </div>
          </form>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
  <c-col>
    <c-col *ngIf="templates && Boolean(templates.length)">
      <c-col class="mb-5">
        <c-card class="card-no-border mt-3" *ngFor="let template of templates | paginate : { itemsPerPage: itemPerPage, currentPage: pageNumber }">
          <c-card-body class="d-flex align-items-start">
            <c-col class="d-flex">
              <c-col>
                <c-col class="d-flex justify-content-end">
                  <c-col class="mb-1">
                    <div class="d-flex w-100 align-items-start">
                    <span class="col-10 form-title d-flex">
                      <span
                        [style.--border-color]="template.nodes.meta.color === '#FFFFFF' ? '1px solid #667085' : ''"
                        [style.--color]="template.nodes.meta.color"
                        [style.border]="template.nodes.meta.color === '#FFFFFF' ? '1px solid #667085' : ''"
                        class="color-icon icon-color-item"
                      ></span>
                      {{ template.nodes.meta.name }}
                    </span>
                      <c-col class="block-action">
                        <c-dropdown>
                          <button class="btn-common" cButton cDropdownToggle>
                            {{ 'template.btnLabel.setting' | translate }}
                          </button>
                          <ul cDropdownMenu>
                            <li>
                              <a cDropdownItem
                                [routerLink]="'/list-template/basic-setting/' + template?.docId"
                                [state]="{
                                  query_search: formSearch.controls['querySearch'].value,
                                  color: formSearch.controls['color'].value
                                }">{{ 'template.btnLabel.basicSetting' | translate }}
                              </a>
                            </li>
                            <li>
                              <a cDropdownItem
                                [routerLink]="'/list-template/form-items-edit/' + template?.docId"
                                [state]="{
                                  query_search: formSearch.controls['querySearch'].value,
                                  color: formSearch.controls['color'].value
                                }">{{ 'template.btnLabel.formItemEdit' | translate }}
                              </a>
                            </li>
                          </ul>
                        </c-dropdown>
                        <button class="btn-second" (click)="toggleDeleteModal(template.docId)">
                          {{ 'template.btnLabel.delete' | translate }}
                        </button>
                      </c-col>
                    </div>
                  </c-col>
                </c-col>
                <c-col class="mt-3 form-info">
                  <c-col class="d-flex justify-content-start">
                    <span>{{ template.createdAt.toDate() | date : currentFormatDate }}</span>
                  </c-col>
                </c-col>
                <c-col class="d-flex justify-content-end mt-3">
                  <c-col class="d-flex justify-content-start">
                    <div>
                      <div
                        class="remark-block"
                        [attr.data-id]="template.docId"
                        [innerHTML]="template.remark ?? '' | safeHtml"
                      ></div>
                      <span *ngIf="dataShowToggleReadMore[template.docId]" (click)="toggleReadMore($event)" class="read-more">
                        {{ "common.longText.showMore" | translate }}
                      </span>
                    </div>
                  </c-col>
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
        </c-card>
      </c-col>
      <c-col class="justify-content-center d-flex">
        <pagination-controls
          (pageChange)="pageChange($event)"
          previousLabel=""
          nextLabel=""
          [autoHide]="true"
        ></pagination-controls>
      </c-col>
    </c-col>
    <c-col *ngIf="templates && !Boolean(templates.length) && !isLoading">
      {{ "form.noData" | translate }}
    </c-col>
    <c-col *ngIf="isLoading && templates && !templates.length">
      <c-card>
        <c-card-body class="loading">
          <app-loading-block [isLoading]="isLoading"></app-loading-block>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
  <c-modal id="deleteModal" [visible]="visibleDeleteModal" class="custom-modal custom-modal__no-body">
    <c-modal-header>
      <h5>
        <b>{{ "template.modal.delete.title" | translate }}</b>
      </h5>
      <button cButtonClose (click)="toggleDeleteModal()"></button>
    </c-modal-header>
    <c-modal-footer>
      <button cButton (click)="toggleDeleteModal()" class="btn-close-modal">
        {{ "template.modal.delete.no" | translate }}
      </button>
      <button cButton (click)="deleteTemplate()" [disabled]="isDisabledBtnDelete" class="btn-confirm-modal">
        {{ "template.modal.delete.yes" | translate }}
      </button>
    </c-modal-footer>
  </c-modal>
</c-container>
