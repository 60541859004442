<c-card class="card-no-border account-card">
  <form class="form-create" [formGroup]="formEdit" novalidate>
    <div class="mb-3">
      <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.formName" | translate }}
        <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
      </c-col>
      <c-input-group>
        <input cFormControl formControlName="title" (keypress)="checkInputEnter($event)" />
      </c-input-group>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.title?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.formName." + getFirstError(formEdit!.controls!.title!.errors) | translate: 
          { maxlength: ruleValidations.maxLength255 }
        }}
      </c-form-check>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.description" | translate }}</c-col>
      <editor formControlName="remark" [init]="initEditor" (onSelectionChange)="setRawTextRemarkError($event)"></editor>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.remark?.errors?.['maxlength'] && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.input.maxlength" | translate : { maxlength: ruleValidations.maxLength4096 } }}
      </c-form-check>
    </div>
    <div class="mb-3 form-select-date">
      <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.dataStorageRegistrationName" | translate }}
        <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
      </c-col>
      <c-col lg="12">
        <c-date-picker
          placeholder="{{'form.placeholder.date' | translate}}"
          cFormControl
          class="input-date"
          [minDate]="minDate"
          [maxDate]="maxDate"
          formControlName="targetDate"
          [locale]="currentDatepickerLocale"
          [dayFormat]="dayFormat"
          (keypress)="checkInputEnter($event)"
          (dateChange)="changeDate($event)"
        ></c-date-picker>
      </c-col>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.targetDate?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.select.required" | translate }}
      </c-form-check>
    </div>
    <div class="mb-3">
      <div class="multiple-select">
        <div class="block-template">
          <c-col class="mb-1">
            <label for="radioOne1">
              {{ "form.inputLabel.template" | translate }}
              <c-badge *ngIf="defaultTemplateSelect === SELECT_TEMPLATE.TEMPLATE" color="danger"> {{ "form.inputLabel.must" | translate }}</c-badge>
            </label>
          </c-col>
          <c-input-group>
            <label for="radioOne1" cInputGroupText class="label-select-radio">
              <div>
                <input
                  cFormCheckInput
                  [checked]="!!form?.templateId"
                  [disabled]="true"
                  (change)="changeTemplateType(SELECT_TEMPLATE.TEMPLATE)"
                  [value]="SELECT_TEMPLATE.TEMPLATE"
                  id="radioOne1" name="use" type="radio"
                />
              </div>
            </label>
            <select cFormControl cSelect disabled>
              <option [value]="null" [hidden]="true"></option>
              <option *ngFor="let item of templates" [selected]="form?.templateId == item.docId" [value]="item.docId">
                {{ item.nodes.meta.name }}
              </option>
            </select>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.templateId?.errors && submitted">
            {{ "errorMessages.formValidate.select.required" | translate }}
          </c-form-check>
        </div>
        <div *ngIf="!currentUser?.hostname">
          <c-col class="mb-1">
            <label for="radioOne">
              {{ "form.inputLabel.systemTemplate" | translate }}
              <c-badge *ngIf="defaultTemplateSelect === SELECT_TEMPLATE.SYSTEM_TEMPLATE" color="danger"> {{ "form.inputLabel.must" | translate }}</c-badge>
            </label>
          </c-col>
          <c-input-group>
            <label for="radioOne" cInputGroupText class="label-select-radio">
              <div>
                <input
                  cFormCheckInput
                  [disabled]="true"
                  [checked]="!!form?.systemTemplateId"
                  [value]="SELECT_TEMPLATE.SYSTEM_TEMPLATE"
                  (change)="changeTemplateType(SELECT_TEMPLATE.SYSTEM_TEMPLATE)"
                  id="radioOne" name="use" type="radio"
                />
              </div>
            </label>
            <select cFormControl cSelect disabled>
              <option [value]="null" [hidden]="true"></option>
              <option *ngFor="let item of systemTemplates" [selected]="form?.systemTemplateId == item.docId" [value]="item.docId">
                {{ item.title }}
              </option>
            </select>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.systemTemplateId?.errors && submitted">
            {{ "errorMessages.formValidate.select.required" | translate }}
          </c-form-check>
        </div>
      </div>
    </div>
    <div class="mb-3" *ngIf="(this.prefix == SELECT_TEMPLATE_PLUGIN_ATTENDANCE)">
      <c-col class="d-flex box-multiple-input">
        <c-col class="mb-6 box-input">
          <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.maximumRegistrationNumber" | translate }} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></c-col>
          <div class="d-flex justify-content-start align-items-center box-input-row ">
            <c-input-group class="box-input-number">
              <div class="d-flex justify-content-between box-input-num w-85 ">
                <img
                  class="button-change"
                  src="assets/svg/create-form/minus-square.svg"
                  (click)="decreaseValueInputNumber($event,'maxRecords')"
                  alt="minus-square"
                />
                <input
                  aria-label="maxRecords"
                  cFormControl
                  class="no-border text-center"
                  type="text"
                  (keypress)="ignoreString($event)"
                  formControlName="maxRecords"
                />
                <img
                  class="button-change"
                  src="assets/svg/create-form/add-square.svg"
                  (click)="increaseValueInputNumber($event, 'maxRecords')"
                  alt="add-square"
                />
              </div>
            </c-input-group>
            <label>{{ unitString != "" ? unitString : ("form.inputLabel.count" | translate) }}</label>
          </div>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.maxRecords?.errors && (submitAllTab || submitted)">
            {{ "errorMessages.formValidate.member." + getFirstError(formEdit!.controls!.maxRecords!.errors) | translate : { max: ruleValidations.maxNumber99999999, min: minRecord } }}
          </c-form-check>
        </c-col>
        <c-col  class="mb-6 box-input">
          <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.numberParkingLot" | translate }} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></c-col>
          <div class="d-flex justify-content-start align-items-center box-input-row">
            <c-input-group class="box-input-number">
              <div class="d-flex justify-content-between box-input-num w-85">
                <img
                  class="button-change"
                  src="assets/svg/create-form/minus-square.svg"
                  (click)="decreaseValueInputNumber($event,'maximumNumberOfParkingLots')"
                  alt="maximumNumberOfParkingLots"
                />
                <input
                  aria-label="maxRecords"
                  cFormControl
                  class="no-border text-center"
                  type="text"
                  (keypress)="ignoreString($event)"
                  formControlName="maximumNumberOfParkingLots"
                />
                <img
                  class="button-change"
                  src="assets/svg/create-form/add-square.svg"
                  (click)="increaseValueInputNumber($event,'maximumNumberOfParkingLots')"
                  alt="add-square"
                />
              </div>
            </c-input-group>
            <label>{{ ("form.inputLabel.count" | translate) }}</label>
          </div>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.maximumNumberOfParkingLots?.errors && (submitAllTab || submitted)">
            {{ "errorMessages.formValidate.maximumNumberOfParkingLots." + getFirstError(formEdit!.controls!.maximumNumberOfParkingLots!.errors) | translate : { max: ruleValidations.maxNumberCar99999, min: minNumberParking } }}
          </c-form-check>
        </c-col>
      </c-col>
    </div>
    <div class="mb-3" *ngIf="!(this.prefix == SELECT_TEMPLATE_PLUGIN_ATTENDANCE)">
      <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.maximumRegistrationNumber" | translate }}
        <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
      </c-col>
      <div class="d-flex justify-content-start align-items-center box-input-row">
        <c-input-group class="box-input-number">
          <div class="d-flex justify-content-between box-input-num">
            <img
              class="button-change"
              src="assets/svg/create-form/minus-square.svg"
              (click)="decreaseValueInputNumber($event,'maxRecords')"
              alt="minus-square"
            />
            <input
              aria-label="maxRecords"
              cFormControl
              class="no-border text-center"
              type="text"
              (keypress)="ignoreString($event)"
              formControlName="maxRecords"
            />
            <img
              class="button-change"
              src="assets/svg/create-form/add-square.svg"
              (click)="increaseValueInputNumber($event, 'maxRecords')"
              alt="add-square"
            />
          </div>
        </c-input-group>
        <label>{{ unitString != "" ? unitString : ("form.inputLabel.count" | translate) }}</label>
      </div>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.maxRecords?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.member." + getFirstError(formEdit!.controls!.maxRecords!.errors) | translate : { max: ruleValidations.maxNumber99999999, min: minRecord } }}
      </c-form-check>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.layout" | translate }}
        <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
      </c-col>
      <select cFormControl formControlName="layout" cSelect>
        <option [selected]="true" hidden>
          {{ "form.placeHolder.select" | translate }}
        </option>
        <option *ngFor="let layout of LAYOUT_FORM_LIST" [value]="layout">
          {{ 'form.layout.' + layout | translate }}
        </option>
      </select>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.layout?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.select.required" | translate }}
      </c-form-check>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.displayIndex" | translate }}</c-col>
      <c-input-group>
        <input (keypress)="checkInputOnlyNumber($event)" cFormControl formControlName="displayIndex"/>
      </c-input-group>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.displayIndex?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.input.required" | translate }}
      </c-form-check>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.color" | translate }}
        <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
      </c-col>
      <div class="d-flex">
        <div *ngFor="let item of colorTypesSet" class="me-3">
          <input
            formControlName="colorCode"
            class="check-box-color d-none"
            id="color{{ item.colorCode }}"
            [value]="item.colorCode"
            name="colorCode"
            cFormCheckInput
            type="radio"
          />
          <label
            cFormCheckLabel
            for="color{{ item.colorCode }}"
            [style.--border-color]="item.colorCode === '#FFFFFF' ? '1px solid #667085' : '' "
            [style.--color]="item.colorCode"
            [style.border]="item.colorCode === '#FFFFFF' ? '1px solid #667085' : '' "
            class="color-icon"
          ></label>
        </div>
      </div>
      <c-form-check class="text-danger" *ngIf="formEdit?.controls?.colorCode?.errors && (submitAllTab || submitted)">
        {{ "errorMessages.formValidate.color.required" | translate }}</c-form-check>
    </div>
    <div class="mb-3">
      <c-col class="mb-1">{{ "formEdit.basicTab.inputLabel.displayTab" | translate }}</c-col>
      <select aria-label="Default select example" cSelect (change)="changeTab($event)">
        <option [value]="null" [selected]="form?.tabId == null">{{ 'form.forms' | translate }}</option>
        <option *ngFor="let tab of tabs" [value]="tab.docId" [selected]="form?.tabId == tab.docId" [disabled]="tab.isDisable">{{ tab.name }}</option>
      </select>
    </div>
    <div class="mb-3 switch-button">
      <c-form-check [switch]="true">
        <input cFormCheckInput type="checkbox" formControlName="archive" id="archive" class="switch-input"/>
        <label class="ms-2" for="archive" cFormCheckLabel>{{ "formEdit.basicTab.inputLabel.archive" | translate }}</label>
      </c-form-check>
    </div>
    <div class="mb-3 d-flex justify-content-end">
      <button (click)="backToFormList()" class="back-btn">
        {{ "form.backButton" | translate }}
      </button>
      <button
        cButton
        type="submit"
        (click)="submit()"
        [class]="{
          'btn-submit': true,
          'btn-submit-disabled': isOutDate
        }"
      >
        {{ "form.inputLabel.submitChange" | translate }}
      </button>
    </div>
  </form>
</c-card>
