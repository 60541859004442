<app-layout-auth>
  <div *ngIf="environment.sing_up_link">
    <img id="logo" src="./assets/img/logo/5.png" alt="logo" style="display: block; margin: auto;" />
    <h1>{{ 'register.signup' | translate }}</h1>
    <form [formGroup]="formRegister" (ngSubmit)="register()" novalidate>
      <c-input-group
        [ngClass]="{ 'is-invalid': (submitted || f['email'].touched) && f['email'].errors }"
        class="input-group__mail"
      >
        <input
          cFormControl
          [ngClass]="{
            'is-invalid': (submitted || f['email'].touched) && f['email'].errors,
            'is-valid': f['email'].touched && f['email'].valid
          }"
          formControlName="email"
          id="email"
          type="email"
          autoComplete="email"
          [placeholder]="'register.email' | translate"
        />
      </c-input-group>
      <c-form-feedback *ngIf="(submitted || f['email'].touched) && f['email'].errors">
        <div *ngIf="f['email'].errors?.['required']">
          {{ 'errorMessages.email.required' | translate }}
        </div>
        <div *ngIf="f['email'].errors?.['pattern']">
          {{ 'errorMessages.email.email' | translate }}
        </div>
      </c-form-feedback>
      <app-input-password
        class="input-group-container"
        [formControlNameInput]="'password'"
        [placeHolderInput]="'register.password' | translate"
        [ngClass]="{
        'is-invalid': (submitted || f['password'].touched) && f['password'].errors,
        'is-valid': f['password'].touched && f['password'].valid
        }"
      ></app-input-password>
      <c-form-feedback *ngIf="(submitted || f['email'].touched) && f['password'].errors">
        <div *ngIf="f['password'].errors?.['required']">
          {{ 'errorMessages.password.required' | translate }}
        </div>
        <div *ngIf="f['password'].errors?.['pattern']">
          {{ 'errorMessages.password.pattern' | translate }}
        </div>
      </c-form-feedback>
      <button cButton type="submit" [disabled]="loading">{{'register.signup' | translate}}</button>
    </form>
    <div id="notice">
      <div>{{ 'register.or' | translate }}</div>
      <div>
        {{ 'register.description' | translate}}
        <a routerLink="/login">{{ 'register.login' | translate }}</a>
      </div>
    </div>
  </div>
  <div *ngIf="!environment.sing_up_link" class="w-100">
    <c-col>
      <c-card class="card-no-border">
        <c-card-body class="card-border-bottom">
          <c-col class="mb-4">
            <h4 class="mb-0">{{ "errorMessagePage.title" | translate }}</h4>
          </c-col>
          <c-col>
            <span>{{ "alertMessages.formData.pageCannotBeDisplay" | translate }}</span>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </div>
</app-layout-auth>
