<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3>{{ "template.create.title" | translate }}</h3>
    </c-col>
    <c-card class="card-no-border account-card">
      <form class="form-create" [formGroup]="formCreate" novalidate>
        <div class="mb-3">
          <c-col class="mb-1">
            {{ "template.create.inputLabel.copyFromSystemTemplate" | translate }}
            <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
          </c-col>
          <c-input-group>
            <select cSelect formControlName="copySystemTemplateId" (change)="selectSystemTemplate($event)">
              <option *ngFor="let systemTemplate of systemTemplates" [value]="systemTemplate.docId">{{ systemTemplate.title }}</option>
            </select>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.['copySystemTemplateId']?.errors && submitted">
            {{ "errorMessages.template.copySystemTemplateId." + getFirstError(formCreate.controls['copySystemTemplateId'].errors) | translate }}
          </c-form-check>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">
            {{ "template.create.inputLabel.templateName" | translate }}
            <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge>
          </c-col>
          <c-input-group>
            <input cFormControl formControlName="name" (keypress)="checkInputEnter($event)"/>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.['name']?.errors && submitted">
            {{ "errorMessages.template.name." + getFirstError(formCreate.controls['name'].errors) | translate: { maxlength: ruleValidations.maxLength255 } }}
          </c-form-check>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">{{ "template.create.inputLabel.remark" | translate }}</c-col>
          <editor formControlName="remark" [init]="initEditor"></editor>
          <c-form-check class="text-danger" *ngIf="formCreate?.controls?.['remark']?.errors && submitted">
            {{ "errorMessages.template.remark." + getFirstError(formCreate.controls['remark'].errors) | translate: { maxlength: ruleValidations.maxLength4096 } }}
          </c-form-check>
        </div>
        <div class="mb-3 d-flex justify-content-end">
          <button class="back-btn" (click)="backToListTemplate()">{{ "template.create.btnLabel.back" | translate }}</button>
          <button cButton type="submit" (click)="submit()" class="btn-submit">
            {{ "template.create.btnLabel.create" | translate }}
          </button>
        </div>
      </form>
    </c-card>
  </div>
</c-container>
