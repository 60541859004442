export enum MemberJoinStatus {
  pending = 1,
  accepted = 2
}
export const GROUP_PER_PAGE = 5
export const ROLE_STATUS = {
  readonly: 'readonly',
  readAndAdd: 'read+add',
  full: 'full'
}
export const PERMISSION_GROUP_LIST = {
  readonly: {
    isCreateFormData: false,
    isImportFormData: false,
    isDisableEditFormData: true,
    isDeleteFormData: false,
  },
  readAndAdd: {
    isCreateFormData: true,
    isImportFormData: true,
    isDisableEditFormData: true,
    isDeleteFormData: false,
  },
  full: {
    isCreateFormData: true,
    isImportFormData: true,
    isDisableEditFormData: false,
    isDeleteFormData: true,
  }
}
