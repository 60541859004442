import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataType, RuleValidations } from '@app/constants';
import { convertMbToB } from '@app/helpers';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {
  @Input() id: string = '';
  @Input() typeFile: string = '';
  @Input() formControlName: string = ''
  @Input() titleInput: string = ''
  @Input() isRequired: boolean = false
  @Input() form: FormGroup = new FormGroup<any>({})
  @Input() errorMessage: string = ''
  @Input() showErrorMessages: boolean = false
  @Output() dataFile = new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void { }

  handleFile(event: any) {
    const file: File = event.target.files[0];
    const previewFileName: HTMLElement = event.target.previousElementSibling;
    previewFileName.textContent = file?.name;
    this.dataFile.emit(file ?? null);
    if (!file) {
      this.showErrorMessages = false;
      this.form.controls[this.formControlName]?.setErrors(null);
    } else {
      if (!(new RegExp(RuleValidations.regexFile[this.typeFile as keyof typeof RuleValidations.regexFile]['mime']).test(file?.type)) 
        && this.typeFile != DataType.file
      ) {
        this.form.controls[this.formControlName]?.setErrors({ mimetype: true });
        this.showErrorMessages = true;
      }
      if (file.size > convertMbToB(RuleValidations.regexFile[this.typeFile as keyof typeof RuleValidations.regexFile]['maxFileSize'])) {
        this.form.controls[this.formControlName]?.setErrors({ maxSize: true });
        this.showErrorMessages = true;
      }
    }    
  }
}
