<div>
  <div class="table-fixed-header">
    <table class="table table-striped table-hover">
      <thead>
        <tr cTableColor="dark">
          <th>{{ "formEdit.emailTab.tableHeader.field" | translate }}</th>
          <th>{{ "formEdit.emailTab.tableHeader.data" | translate }}</th>
          <th>{{ "formEdit.emailTab.tableHeader.variable" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of referenceData; let i = index">
          <td>{{ item.field }}</td>
          <td class="data-variables" [innerHTML]="item.data | safeHtml"></td>
          <td>{{ item.variable }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <c-card class="card-no-border account-card">
    <form
      class="form-create"
      [formGroup]="formEdit"
      (ngSubmit)="submit()"
    >
      <c-col>
        <div class="mb-3">
          <c-col>
            <span>{{ "formEdit.emailTab.emailForFormRegist" | translate }}</span>
          </c-col>
          <div class="d-flex">
            <c-form-check inline class="radio-block">
              <input
                formControlName="isSendMailFormRegist"
                cFormCheckInput
                checked
                id="sendMailFormRegist"
                name="isSendMailFormRegist"
                type="radio"
                [value]="true"
                (change)="changeSendEmail('isSendMailFormRegist')"
              />
              <label cFormCheckLabel for="sendMailFormRegist">{{ "formEdit.emailTab.optionLabel.send" | translate }}</label>
            </c-form-check>
            <c-form-check inline class="radio-block">
              <input
                formControlName="isSendMailFormRegist"
                cFormCheckInput
                id="notSendMailFormRegist"
                name="isSendMailFormRegist"
                type="radio"
                [value]="false"
                (change)="changeSendEmail('isSendMailFormRegist')"
              />
              <label cFormCheckLabel for="notSendMailFormRegist">{{ "formEdit.emailTab.optionLabel.notSend" | translate }}</label>
            </c-form-check>
          </div>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">{{ "formEdit.emailTab.inputLabel.title" | translate }}</c-col>
          <c-input-group>
            <textarea cFormControl rows="3" formControlName="titleMailFormRegist"></textarea>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.titleMailFormRegist?.errors && (submitAllTab || submitted)">
            {{ 'errorMessages.formValidate.emailTab.title' | translate }}
          </c-form-check>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">{{ "formEdit.emailTab.inputLabel.body" | translate }}</c-col>
          <c-input-group>
            <editor formControlName="bodyMailFormRegist" [init]="initEditor"></editor>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.bodyMailFormRegist?.errors && (submitAllTab || submitted)">
            {{'errorMessages.formValidate.emailTab.body' | translate}}
          </c-form-check>
        </div>
      </c-col>
      <c-col>
        <div class="mb-3">
          <c-col>
            <span>{{ "formEdit.emailTab.emailForReissueQr" | translate }}</span>
          </c-col>
          <div class="d-flex">
            <c-form-check inline class="radio-block">
              <input
                formControlName="isSendMailReissueQr"
                cFormCheckInput
                checked
                id="sendMailFormReissueQr"
                name="isSendMailReissueQr"
                type="radio"
                [value]="true"
                (change)="changeSendEmail('isSendMailReissueQr')"
              />
              <label cFormCheckLabel for="sendMailFormReissueQr">{{ "formEdit.emailTab.optionLabel.send" | translate }}</label>
            </c-form-check>
            <c-form-check inline class="radio-block">
              <input
                formControlName="isSendMailReissueQr"
                cFormCheckInput
                id="notSendMailFormReissueQr"
                name="isSendMailReissueQr"
                type="radio"
                [value]="false"
                (change)="changeSendEmail('isSendMailReissueQr')"
              />
              <label cFormCheckLabel for="notSendMailFormReissueQr">{{ "formEdit.emailTab.optionLabel.notSend" | translate }}</label>
            </c-form-check>
          </div>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">{{ "formEdit.emailTab.inputLabel.title" | translate }}</c-col>
          <c-input-group>
            <textarea cFormControl rows="3" formControlName="titleMailReissueQr"></textarea>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.titleMailReissueQr?.errors && (submitAllTab || submitted)">{{'errorMessages.formValidate.emailTab.title' | translate}}</c-form-check>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">{{ "formEdit.emailTab.inputLabel.body" | translate }}</c-col>
          <c-input-group>
            <editor formControlName="bodyMailReissueQr" [init]="initEditor"></editor>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.bodyMailReissueQr?.errors && (submitAllTab || submitted)">{{'errorMessages.formValidate.emailTab.body' | translate}}</c-form-check>
        </div>
      </c-col>
      <c-col>
        <div class="mb-3">
          <c-col>
            <span>{{ "formEdit.emailTab.emailForUpdateFormData" | translate }}</span>
          </c-col>
          <div class="d-flex">
            <c-form-check inline class="radio-block">
              <input
                formControlName="isSendMailUpdateFormData"
                cFormCheckInput
                checked
                id="sendMailUpdateFormData"
                name="isSendMailUpdateFormData"
                type="radio"
                [value]="true"
                (change)="changeSendEmail('isSendMailUpdateFormData')"
              />
              <label cFormCheckLabel for="sendMailUpdateFormData">{{ "formEdit.emailTab.optionLabel.send" | translate }}</label>
            </c-form-check>
            <c-form-check inline class="radio-block">
              <input
                formControlName="isSendMailUpdateFormData"
                cFormCheckInput
                id="notSendMailUpdateFormData"
                name="isSendMailUpdateFormData"
                type="radio"
                [value]="false"
                (change)="changeSendEmail('isSendMailUpdateFormData')"
              />
              <label cFormCheckLabel for="notSendMailUpdateFormData">{{ "formEdit.emailTab.optionLabel.notSend" | translate }}</label>
            </c-form-check>
          </div>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">{{ "formEdit.emailTab.inputLabel.title" | translate }}</c-col>
          <c-input-group>
            <textarea cFormControl rows="3" formControlName="titleMailUpdateFormData"></textarea>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.titleMailUpdateFormData?.errors && (submitAllTab || submitted)">{{ 'errorMessages.formValidate.emailTab.title' | translate }}</c-form-check>
        </div>
        <div class="mb-3">
          <c-col class="mb-1">{{ "formEdit.emailTab.inputLabel.body" | translate }}</c-col>
          <c-input-group>
            <editor formControlName="bodyMailUpdateFormData" [init]="initEditor"></editor>
          </c-input-group>
          <c-form-check class="text-danger" *ngIf="formEdit?.controls?.bodyMailUpdateFormData?.errors && (submitAllTab || submitted)">{{'errorMessages.formValidate.emailTab.body' | translate}}</c-form-check>
        </div>
      </c-col>
      <div class="mb-3">
        <c-col class="mb-1">
          <span class="email-synchronous">{{ "form.inputLabel.emailSynchronous" | translate }}</span>
          <span
            [cPopoverTrigger]="['hover']"
            class="ms-2"
            [cPopover]="contentPopover"
            cPopoverPlacement="top"
          >
            <img
              width="15"
              height="15"
              src="assets/svg/form-edit/question-mark-circle.svg"
              alt=""
            />
            <ng-template #contentPopover>
              <div class="popover-body custom-popover">
                {{ "form.inputLabel.textEmailSynchronous" | translate }}
              </div>
            </ng-template>
          </span>
        </c-col>
        <c-input-group>
          <input
            cFormControl
            formControlName="emailSynchronous"
            (keypress)="checkInputEnter($event)"
          />
        </c-input-group>
        <c-form-check class="text-danger" *ngIf="formEdit?.controls?.emailSynchronous?.errors && (submitAllTab || submitted)">
          {{ "errorMessages.formValidate.email.pattern" | translate }}
        </c-form-check>
      </div>
      <div class="mb-3 d-flex justify-content-end">
        <button (click)="backToFormList()" class="back-btn">
          {{ "form.backButton" | translate }}
        </button>
        <button
          cButton
          type="submit"
          [class]="{
            'btn-submit': true,
            'btn-submit-disabled': isOutDate
          }"
        >
          {{ "form.inputLabel.submitChange" | translate }}
        </button>
      </div>
    </form>
  </c-card>
</div>
