import { Component } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { CreditCardInfoText, FormText } from '@app/constants';

@Component({
  selector: 'app-credit-card-info',
  templateUrl: './credit-card-info.component.html',
  styleUrls: ['./credit-card-info.component.scss']
})
export class CreditCardInfoComponent {
  creditCardInfoText = CreditCardInfoText;
  formText = FormText;
  submitted: boolean = false;
  constructor(
    public angularFireAuth: AngularFireAuth,
  ) { }

  ngOnInit() {}
}
