import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PREFIX_LIST, RuleValidations } from '@app/constants';
import { CustomValidatorsService } from '@app/services/custom-validators.service';
import { getNumberInString } from './common';

export function clearAllErrors(control: AbstractControl): void {
  if (control instanceof FormGroup || control instanceof FormArray) {
    Object.values(control.controls).forEach((childControl: AbstractControl) => {
      clearAllErrors(childControl);
    });
  } else {
    control.setErrors(null);
  }
}

export function setValidateMember(formName: string, form: any, formGroup: any) {
  const formPrefix = form.nodes.nodes.meta.prefix;
  const formNode = form.nodes.nodes.objects;
  if ((formPrefix === PREFIX_LIST.pluginAttendance || formPrefix === PREFIX_LIST.pluginNaruo) && formName === 'members' && formGroup.value.hasOwnProperty('members')) {
    const totalMemberValidate: number = getNumberInString(formGroup?.get(formName)?.value);
    const totalMember = formNode.filter((node: any) => node.name === formName)[0]['candidateData']['en'].length;
    for (let memberIndex = 1; memberIndex <= totalMember; memberIndex++) {
      const memberForm = formGroup.get(`member${memberIndex}`);
      const memberNumberForm = formGroup.get(`member${memberIndex}number`);
      const memberSexForm = formGroup.get(`member${memberIndex}sex`);
      const memberAgeForm = formGroup.get(`member${memberIndex}age`);
      const memberKanaForm = formGroup.get(`member${memberIndex}kana`);
      setValidateEachMember(memberForm, memberIndex, totalMemberValidate);
      setValidateEachMember(memberNumberForm, memberIndex, totalMemberValidate);
      setValidateEachMember(memberSexForm, memberIndex, totalMemberValidate);
      setValidateEachMember(memberAgeForm, memberIndex, totalMemberValidate);
      setValidateEachMember(memberKanaForm, memberIndex, totalMemberValidate);
    }
  }
}

function setValidateEachMember(formControl: FormControl, memberIndex: number, totalMemberValidate: number) {
  if (formControl) {
    formControl?.removeValidators([Validators.required, CustomValidatorsService.notAllowedOnlySpace]);
    if (memberIndex <= totalMemberValidate) formControl?.addValidators([Validators.required, CustomValidatorsService.notAllowedOnlySpace]);
    formControl?.updateValueAndValidity();
  }
}

export function setMemberRequiredLabel(formName: string, totalMemberValidate: number) {
  for (let memberIndex = 1; memberIndex <= totalMemberValidate; memberIndex++) {
    if (formName.includes('member') && formName.includes(`${memberIndex}`)) {
      return true;
    }
  }
  return false;
}
