<c-col>
  <form class="form-create form-edit-test" [formGroup]="formEdit" novalidate>
    <c-card class="card-no-border question-card">
      <div class="mb-3" *ngIf="formEdit.controls?.['showExam']">
        <c-col class="mb-1">{{ "formEdit.testTab.inputLabel.showExam.title" | translate }}</c-col>
        <c-input-group>
          <c-form-check inline>
            <input cFormCheckInput checked id="showExamTrue" [value]="true" formControlName="showExam" type="radio"/>
            <label cFormCheckLabel for="showExamTrue">{{ "formEdit.testTab.inputLabel.showExam.use" | translate }}</label>
          </c-form-check>
          <c-form-check inline>
            <input cFormCheckInput id="showExamFalse" [value]="false" formControlName="showExam" type="radio"/>
            <label cFormCheckLabel for="showExamFalse">{{ "formEdit.testTab.inputLabel.showExam.notUse" | translate }}</label>
          </c-form-check>
        </c-input-group>
      </div>
      <div class="mb-3">
        <c-col class="mb-1">{{ "formEdit.testTab.inputLabel.useTest" | translate }}</c-col>
        <c-form-check inline *ngFor="let item of testList; let i = index">
          <input
            cFormCheckInput
            [id]="'useTest' + item.index"
            name="useTest"
            type="radio"
            formControlName="useTest"
            [value]="item.index"/>
          <label cFormCheckLabel [for]="'useTest' + item.index">
            {{ item.name ? item.name[currentLang] : ("formEdit.testTab.optionLabel.random" | translate) }}
          </label>
        </c-form-check>
        <div>
          <c-form-check class="text-danger" *ngIf="useTestControl.errors && (submitAllTab || submitted)">
            {{ 'alertMessages.form.testTab.' + getFirstError(useTestControl.errors) | translate: {testIndex: formEdit.controls['useTest'].value } }}
          </c-form-check>
        </div>
      </div>
      <div class="mb-3">
        <c-col class="mb-1">{{ "formEdit.testTab.inputLabel.passingScore" | translate }} <c-badge color="danger">{{ "form.inputLabel.must" | translate }}</c-badge></c-col>
        <c-input-group>
          <input cFormControl formControlName="passingScore" (input)="changeScores($event)"/>
          <span cInputGroupText>%</span>
        </c-input-group>
        <c-form-check class="text-danger" *ngIf="formEdit.controls?.['passingScore'].errors && (submitAllTab || submitted)">
          {{'errorMessages.formValidate.tabExam.passingScore.' + getFirstError(formEdit.controls?.['passingScore'].errors) | translate }}
        </c-form-check>
      </div>
    </c-card>

    <c-card class="mb-3">
      <c-card-body>
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let exam of examList; index as index" cdkDrag>
            <span>{{ exam.name[currentLang] }}</span>
            <div class="btn-list">
              <a [routerLink]="'test/' + exam.index" [state]="{testForm: examList, userId: form.user_id, exam: exam}">
                <img src="/assets/svg/edit.svg" alt=''>
              </a>
              <div class="d-flex button-block">
                <div (click)="moveUp(exam)">
                  <img [class]="{'d-none': !index}" src="./assets/svg/common/down-circle.svg" alt="">
                </div>
                <div (click)="moveDown(exam)">
                  <img [class]="{'d-none': index === (examList.length - 1)}" src="./assets/svg/common/down-circle.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </c-card-body>
    </c-card>
    <div class="mb-3 d-flex justify-content-end">
      <button (click)="backToFormList()" class="back-btn">
        {{ "form.backButton" | translate }}
      </button>
      <button
        cButton
        type="submit"
        (click)="submit()"
        [class]="{
          'btn-submit': true,
          'btn-submit-disabled': isOutDate
        }">
        {{ "form.inputLabel.submitChange" | translate }}
      </button>
    </div>
  </form>
</c-col>
