<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container" *ngIf="!isFilePathExistInStorage">
    <c-col class="header-list__mb">
      <span>{{ cameraData?.name ?? formUser?.name }}</span>
      <div class="btn-list__mb">
        <button (click)="toggleResetDataModal()" cButton>{{ 'devices.meters.btnLabel.reset' | translate }}</button>
        <a class="btn-common btn-back" routerLink="/form/{{form?.doc_id}}/devices">{{ "form.backButton" | translate }}</a>
      </div>
    </c-col>
  </div>
  <div class="custom-container" *ngIf="isFilePathExistInStorage">
    <form [formGroup]="formUpdate" (ngSubmit)="submit()">
      <c-row class="canvas-row" [ngClass]="{
        'flex-column': checkLargeImage()
      }">
        <c-col>
          <c-col class="header-list__mb" *ngIf="checkLargeImage()">
            <span>{{ cameraData?.name ?? formUser?.name }}</span>
            <div class="btn-list__mb">
              <span (click)="undoDraw()">
                <img src="assets/svg/list-form-data/undo-draw-icon.svg" alt="">
              </span>
              <button cButton type="submit">{{ 'devices.meters.btnLabel.save' | translate }}</button>
              <button (click)="toggleResetDataModal()" cButton>{{ 'devices.meters.btnLabel.reset' | translate }}</button>
              <a class="btn-common btn-back" routerLink="/form/{{form?.doc_id}}/devices">{{ "form.backButton" | translate }}</a>
            </div>
          </c-col>
          <c-col class="btn-add-form__mb" *ngIf="checkLargeImage()">
            <span (click)="addFormMeter()">
              <img width="50" height="50" src="assets/svg/create-form/add-square.svg" alt="">
            </span>
          </c-col>
          <c-col class="btn-add-list__pc" *ngIf="!checkLargeImage()">
            <span (click)="undoDraw()">
              <img src="assets/svg/list-form-data/undo-draw-icon.svg" alt="">
            </span>
            <button cButton type="submit">{{ 'devices.meters.btnLabel.save' | translate }}</button>
            <span (click)="addFormMeter()">
              <img width="50" height="50" src="assets/svg/create-form/add-square.svg" alt="">
            </span>
          </c-col>
          <c-card class="mt-3 card-form" formArrayName="gauges" *ngFor="let meter of formUpdate.controls.gauges?.controls; let formIndex = index">
            <c-card-body class="form-canvas" [formGroupName]="formIndex" *ngIf="!getFormGauges.controls[formIndex].get('isDeleted')?.value" >
              <c-col class="btn-close-form" *ngIf="formIndex != 0">
                <button cButtonClose (click)="removeFormMeter(formIndex)"></button>
              </c-col>
              <div class="input-row" [ngClass]="{ 'input-row-eng': currentLanguage == 'en' }">
                <div class="input-row__item">
                  <div>
                    <label>{{ 'devices.meters.inputLabel.name' | translate }}</label>
                    <input cFormControl formControlName="name" type="text"/>
                  </div>
                  <c-form-check class="text-danger" *ngIf="getFormGauges.controls[formIndex].get('name')?.invalid && submitted">
                    {{ "errorMessages.formCanvas.name." + getFirstError(getFormGauges.controls[formIndex].get('name')?.errors) | translate : { maxlength: ruleValidations.maxLength16 } }}
                  </c-form-check>
                </div>
                <div class="input-row__item">
                  <div>
                    <label>{{ 'devices.meters.inputLabel.type' | translate }}</label>
                    <select cSelect formControlName="inference" (change)="changeTypeCamera($event, formIndex)">
                      <option selected value="">{{ 'devices.placeHolder.chooseAType' | translate }}</option>
                      <option [value]="item" *ngFor="let item of inferenceData">{{item}}</option>
                    </select>
                  </div>
                  <c-form-check class="text-danger" *ngIf="getFormGauges.controls[formIndex].get('inference')?.invalid && submitted">
                    {{ "errorMessages.formCanvas.inference." + getFirstError(getFormGauges.controls[formIndex].get('inference')?.errors) | translate }}
                  </c-form-check>
                </div>
                <div class="input-row__item" *ngIf="getFormGauges.controls[formIndex].get('isTypeNoneOrCircle')?.value">
                  <div>
                    <label>{{ 'devices.meters.inputLabel.startValue' | translate }}</label>
                    <input formControlName="startValue" cFormControl type="text" (keypress)="changeInputDecimal($event, true)"/>
                  </div>
                  <c-form-check class="text-danger" *ngIf="getFormGauges.controls[formIndex].get('startValue')?.errors?.['required'] 
                    || getFormGauges.controls[formIndex].get('startValue')?.errors?.['maxlength'] && submitted">
                    {{ "errorMessages.formCanvas.angle.maxlength" | translate: {maxlength: ruleValidations.maxLength16} }}
                  </c-form-check>
                </div>
                <div class="input-row__item input-start-end" [ngClass]="{ 'input-start-end': currentLanguage == 'en' }" *ngIf="getFormGauges.controls[formIndex].get('isTypeNoneOrCircle')?.value">
                  <div>
                    <label>{{ 'devices.meters.inputLabel.endValue' | translate }}</label>
                    <input formControlName="endValue" cFormControl type="text" (keypress)="changeInputDecimal($event, true)"/>
                  </div>
                  <c-form-check class="text-danger" *ngIf="getFormGauges.controls[formIndex].get('endValue')?.errors?.['required'] 
                    || getFormGauges.controls[formIndex].get('endValue')?.errors?.['maxlength'] && submitted">
                    {{ "errorMessages.formCanvas.angle.maxlength" | translate: {maxlength: ruleValidations.maxLength16} }}
                  </c-form-check>
                </div>
                <div class="input-row__item single-square-block" *ngIf="!getFormGauges.controls[formIndex].get('isTypeNoneOrCircle')?.value">
                  <label>{{ 'devices.meters.inputLabel.' + colorMeterList[0].label | translate }}</label>
                  <div>
                    <input
                      class="check-box-color d-none"
                      [ngClass]="{
                        'square-input': colorMeterList[0].type === 'rect'
                      }"
                      id="color{{ colorMeterList[0].colorCode }}{{ formIndex }}"
                      [value]="colorMeterList[0].colorCode"
                      name="color"
                      cFormCheckInput
                      type="radio"
                      (change)="changeDrawType(colorMeterList[0], formIndex, getFormGauges.controls[formIndex].get('docId')?.value ?? '')"
                    />
                    <label
                      cFormCheckLabel
                      for="color{{ colorMeterList[0].colorCode }}{{ formIndex }}"
                      [style.--border-color]="colorMeterList[0].borderColor"
                      [style.--color]="colorMeterList[0].colorCode"
                      class="color-icon"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="input-row input-color" *ngIf="getFormGauges.controls[formIndex].get('isTypeNoneOrCircle')?.value">
                <div class="color-option" *ngFor="let item of colorMeterList; let i = index">
                  <label>{{ 'devices.meters.inputLabel.' + item.label | translate }}</label>
                  <div>
                    <input
                      class="check-box-color d-none"
                      [ngClass]="{
                        'square-input': item.type === 'rect'
                      }"
                      id="color{{ item.colorCode }}{{ formIndex }}"
                      [value]="item.colorCode"
                      name="color"
                      cFormCheckInput
                      type="radio"
                      (change)="changeDrawType(item, formIndex, getFormGauges.controls[formIndex].get('docId')?.value ?? '')"
                    />
                    <label
                      cFormCheckLabel
                      for="color{{ item.colorCode }}{{ formIndex }}"
                      [style.--border-color]="item.borderColor"
                      [style.--color]="item.colorCode"
                      class="color-icon"
                    ></label>
                  </div>
                </div>
              </div>
            </c-card-body>
          </c-card>
        </c-col>
        <c-col class="canvas-col">
          <c-col class="btn-reset__pc" *ngIf="!checkLargeImage()">
            <span>{{ cameraData?.name }}</span>
            <div class="btn-save__pc">
              <button (click)="toggleResetDataModal()" cButton>{{ 'devices.meters.btnLabel.reset' | translate }}</button>
              <a class="btn-common btn-back" routerLink="/form/{{form?.doc_id}}/devices">{{ "form.backButton" | translate }}</a>
            </div>
          </c-col>
          <c-card class="canvas-setting mt-3">
            <c-card-body class="canvas-card-body">
              <div class="canvas-section" 
                [ngStyle]="{ 
                  height: canvasConfig.image.width! / canvasConfig.image.height! > 1 ? (canvasConfig.image.width + 'px') : (canvasConfig.image.height + 'px'),
                  filter: 'brightness(' + canvasConfig.bright + '%)'
                }">
                  <canvas #canvas
                    id="canvasId"
                    (click)="drawDot($event)"
                    (touchstart)="startDrawingTablet($event)"
                    (touchmove)="drawShapeTablet($event)"
                    (touchend)="stopDrawing($event)"
                    (mousedown)="startDrawing($event)"
                    (mousemove)="drawShape($event)"
                    (mouseout)="drawShape($event)"
                    (mouseup)="stopDrawing($event)">
                  </canvas>
                </div>
            </c-card-body>
          </c-card>
          <c-card class="mt-3">
            <c-card-body>
              <div>
                <c-col>
                  <label cLabel>{{ 'devices.meters.inputLabel.imageSize' | translate }}</label>
                  <c-row>
                    <c-col>
                      <label cLabel>Width</label>
                      <input type="number" cFormControl formControlName="width" />
                    </c-col>
                    <c-col>
                      <label cLabel>Height</label>
                      <input type="number" cFormControl formControlName="height" />
                    </c-col>
                  </c-row>
                 </c-col>
                 <c-col>
                  <label cLabel>{{ 'devices.meters.inputLabel.imageBrightness' | translate }}</label>
                  <div class="d-flex brightness-bar">
                    <span>{{ brightness.min }}</span>
                    <span>{{ brightness.max }}</span>
                  </div>
                  <input formControlName="bright" cFormControl id="customRange1" type="range" (input)="changeBrightness($event)" [min]="brightness.min" [max]="brightness.max" />
                 </c-col>
                 <c-col class="d-flex btn-canvas-list">
                  <span (click)="flipVerticalCanvas()">
                    <img src="assets/svg/list-form-data/flip-vertical.svg" alt="">
                  </span>
                  <span (click)="flipHorizontalCanvas()">
                    <img src="assets/svg/list-form-data/flip-horizontal.svg" alt="">
                  </span>
                  <span (click)="rotateImage()">
                    <img src="assets/svg/list-form-data/rotate-img-icon.svg" alt="">
                  </span>
                 </c-col>
              </div>
            </c-card-body>
          </c-card>
        </c-col>
      </c-row>
    </form>
  </div>
  <c-modal [visible]="visibleResetDataModal">
    <c-modal-header>
      <h5>
        <b>{{ 'devices.modal.resetData.title' | translate }}</b>
      </h5>
      <button cButtonClose class="btn-close-reset-data" (click)="toggleResetDataModal()"></button>
    </c-modal-header>
    <c-modal-footer>
      <button cButton color="secondary" (click)="toggleResetDataModal()">
        {{ 'devices.modal.resetData.no' | translate }}
      </button>
      <button cButton class="btn-modal-reset" (click)="resetData()">
        {{ 'devices.modal.resetData.yes' | translate }}
      </button>
    </c-modal-footer>
  </c-modal>
</c-container>
