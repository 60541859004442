<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="d-flex justify-content-between">
    <h3>{{ 'devices.title' | translate }}</h3>
    <a class="btn-common btn-back" [routerLink]="'/form/' + formId + '/form-data'">{{ 'devices.backButton' | translate }}</a>
  </div>
  <c-card class="mt-5">
    <c-card-body>
      <table responsive borderless cAlign="middle"  class="table-data" cTable>
        <thead>
          <tr>
            <th>{{ 'devices.tableTitle.no' | translate }}</th>
            <th>{{ 'devices.tableTitle.name' | translate }}</th>
            <th>{{ 'devices.tableTitle.mode' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="devices?.length">
          <tr *ngFor="let device of devices | paginate: { itemsPerPage: ITEM_PER_PAGE, currentPage: pageNumber }; index as index">
            <td>{{displayPageIndex(pageNumber, index)}}</td>
            <td class="camera-name"><a [routerLink]="device.camera_id">{{device.name}}</a></td>
            <td class="button-change-mode">
              <c-button-group [vertical]="screeWidth <= MAX_WIDTH_MOBILE_DEVICES">
                <input [name]='"mode" + device.camera_id'
                       class="btn-check"
                       [disabled]="!device.camera_id"
                       (change)="changeMode(device, MODE_DEVICE.MANUAL)"
                       [checked]="device.mode == MODE_DEVICE.MANUAL"
                       [id]="'camera' + device.camera_id + MODE_DEVICE.MANUAL"
                       type="radio" />
                <label class="label-mode" cButton [for]="'camera' + device.camera_id + MODE_DEVICE.MANUAL" variant="outline">
                  {{ 'devices.modeType.manual' | translate }}
                </label>
                <input [name]='"mode" + device.camera_id'
                       class="btn-check"
                       [disabled]="!device.camera_id"
                       (change)="changeMode(device, MODE_DEVICE.AUTO)"
                       [checked]="device.mode == MODE_DEVICE.AUTO"
                       [id]="'camera' + device.camera_id + MODE_DEVICE.AUTO"
                       type="radio" />
                <label class="label-mode" cButton [for]="'camera' + device.camera_id + MODE_DEVICE.AUTO" variant="outline">
                  {{ 'devices.modeType.auto' | translate }}
                </label>
              </c-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card-body>
  </c-card>
  <c-col class="justify-content-center d-flex mt-5">
    <pagination-controls
      [maxSize]="GROUP_PER_PAGE"
      (pageChange)="pageNumber = $event"
      previousLabel=""
      nextLabel=""
      [autoHide]="true">
    </pagination-controls>
  </c-col>
</c-container>
