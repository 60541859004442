<app-layout-auth>
  <div class="custom-container">
    <c-col>
      <c-card class="card-no-border">
        <c-card-body class="card-border-bottom">
          <c-col class="mb-4">
            <h4 class="mb-0">{{ "errorMessagePage.title" | translate }}</h4>
          </c-col>
          <c-col>
            <span>{{'webview.exam.messages.notAllow' | translate}}</span>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </div>
</app-layout-auth>
