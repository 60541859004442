import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, Subscription } from 'rxjs';
import { AlertService, FirebaseAnalyticService, FormCachesService, FormService, RouterService, UserService } from "@app/services";
import { FormControl, FormGroup } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { checkDataTypeFormUser, checkInputMedia, dayFormat, deepClone, getFileImageDisplay, getLocaleDatePicker } from "@app/helpers";
import { formatUnitNumber } from '@app/helpers';
import * as moment from 'moment';
import {
  ACTION_LIST,
  COLUMNS_EXPORT_FORM_TBC,
  DataType, EVENT_LOG_TYPE,
  formatDataDate,
  formatDatePicker,
  formatPassingDateExport,
  formatSearchTime,
  KEYCODE_ENTER,
  NEW_LINE_CELL,
  PREFIX_LIST,
  RuleValidations,
  TEXT_HOW_TO_VISIT,
  USE_PARKING
} from '@app/constants';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-form-data-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit, AfterViewInit, OnDestroy {
  objectFile: { [key: string]: any } = {};
  pageNumber: number = 1;
  dataShow: string[] = [];
  dataTableTitle: string[] = [];
  currentDatepickerLocale: string = "";
  disabledExportButton: boolean = false;
  formSearch = new FormGroup({
    keyword: new FormControl("", { nonNullable: true }),
    startDate: new FormControl(moment().format(formatDatePicker), { nonNullable: true }),
    startTime: new FormControl(moment().startOf('day').format(formatSearchTime), { nonNullable: true }),
    endDate: new FormControl(moment().format(formatDatePicker), { nonNullable: true }),
    endTime: new FormControl(moment().endOf('day').format(formatSearchTime), { nonNullable: true })
  });
  visibleSearchBox: boolean = false;
  dataExport: {columns: string[], data: any} = {
    columns: [],
    data: [],
  }
  formCacheValue: any;
  formConfig: any = {
    id: "",
    data: {},
    node: {},
    dataTitle: {},
    howToVisitNode: {},
    statusNode: {},
    isFormDelete: false
  }
  formDataList: any = [];
  formDataOriginalList: any = [];
  subscription: { [key: string]: Subscription | null } = {
    formCaches: null,
    form: null,
    listFormData: null
  }

  constructor(
    private route: ActivatedRoute,
    private formService: FormService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private routerService: RouterService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private formCachesService: FormCachesService
  ) { }

  ngOnInit() {
    this.generateTable();
    this.changeDatePickerLocale();
  }

  ngAfterViewInit(): void {
    this.changeLangWithDatePicker(this.translateService.currentLang);
  }

  ngOnDestroy(): void {
    this.subscription['formCaches']?.unsubscribe();
  }

  private async generateTable() {
    this.route.params.subscribe({
      next: (params) => {
        this.formConfig.id = params['id'];
      }
    })
    this.getDataFormCache()
  }

  getDataFormCache() {
    this.subscription['formCaches'] = this.formCachesService.getDetailFormCache(this.formConfig.id).subscribe({
      next: (formCacheValue) => {
        if (formCacheValue.length) {
          this.formCacheValue = formCacheValue[0];
          this.getDataForm();
          this.checkFormExist();
        }
      }
    })
  }

  getDataForm() {
    this.subscription['form'] = this.formService.getValueOwnerFormById(this.formCacheValue.userid, this.formCacheValue.formid).subscribe({
      next: (form: any) => {
        const dataNode = form.nodes.nodes.objects;
        this.formConfig = {
          data: form,
          node: dataNode,
          prefix: form.nodes.nodes.meta.prefix,
          dataTitle: form.nodes.nodes.meta.data,
          howToVisitNode: dataNode.filter((item: any) => item.name === TEXT_HOW_TO_VISIT),
          statusNode: dataNode.filter((item: any) => item.name == 'status')[0]
        }
        this.getColumnTableDisplay();
        this.getColumnsExport();
        
        this.subscription['listFormData'] = this.formService.getFormData(this.formCacheValue.userid, this.formCacheValue.formid).subscribe((res) => {
          this.formDataList = res.filter((item: any) => !item.deletedAt).map((item: any) => {
            for (const key in item) {
              const node = this.formConfig.node.filter((item: any) => item.name === key);
              item[key] = checkDataTypeFormUser(node, item, key);
            }
            item.carpark = false;
            if (item.option) {
              item.option.forEach((data: any) => {
                if (data.item_id == USE_PARKING.ja) {
                  item.carpark = true;
                }
              });
            }
            return item;
          });

          this.formDataOriginalList = this.formDataList;
          this.searchDate().then();
          this.checkEmptyDataExport();
          this.getObjectFile();
          this.getDataExport();
        });
      }
    })
  }

  getColumnTableDisplay() {
    const dataTitleJapan = this.formConfig.dataTitle.map((item: any) => item.title_ja);
    const dataTitleEnglish = this.formConfig.dataTitle.map((item: any) => item.title_eng === 'carpark' ? USE_PARKING.en : item.title_eng);
    this.formConfig.dataTitle.forEach((item: any, index: number) => {
      this.dataShow[index] = item.key;
    });

    this.dataTableTitle = this.translateService.currentLang === 'en' ? dataTitleEnglish : dataTitleJapan;
    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.dataTableTitle = langChangeEvent.lang === 'en' ? dataTitleEnglish : dataTitleJapan;
    });
  }

  get f() {
    return this.formSearch.controls;
  }

  toggleSearchBox() {
    this.visibleSearchBox = !this.visibleSearchBox
  }

  changeLangWithDatePicker(lang: string) {
    this.f['startDate'].setValue(moment(this.formSearch.controls['startDate'].value).format(formatDataDate[lang]));
    this.f['endDate'].setValue(moment(this.formSearch.controls['endDate'].value).format(formatDataDate[lang]));
    this.ref.detectChanges();
  }

  async search() {
    this.firebaseAnalyticService.logEvent(ACTION_LIST.FORM_DATA.SEARCH, EVENT_LOG_TYPE.SUCCESS);
    this.checkFormExist()
    await this.searchKeyword();
    await this.searchDate();
    this.checkEmptyDataExport();
    this.getDataExport();
  }

  async searchKeyword() {
    const keywordValue = this.f['keyword'].value;
    if (keywordValue) {
      this.formDataList = this.formDataOriginalList.filter((data: any) => {
        return (data.hasOwnProperty('name') && data.name?.search(keywordValue) != -1)
          || (data.hasOwnProperty('email') && data.email?.search(keywordValue) != -1)
          || (data.hasOwnProperty('tel') && data.tel?.search(keywordValue) != -1);
      });
    } else {
      this.formDataList = this.formDataOriginalList;
    }
  }

  async backToIndexForm() {
    this.checkFormExist();
    if (!this.formConfig.isFormDelete) {
      let prevUrl = this.routerService.getPreviousUrl();
      let formId = '';
      this.route.params.subscribe((data: any) => {
        if (data.id) formId = data.id;
      });
      if (!Boolean(prevUrl.length) || prevUrl.includes('form-data-edit')) {
        prevUrl = `/form/${formId}/form-data`
      }
      await this.router.navigateByUrl(prevUrl)
    }
  }

  async searchDate() {
    const startDateValue = this.f['startDate'].value;
    let startTimeValue = this.f['startTime'].value;
    const endDateValue = this.f['endDate'].value;
    let endTimeValue = this.f['endTime'].value;

    if (startDateValue && startTimeValue && endDateValue && endTimeValue) {
      if (typeof startTimeValue != 'string') {
        startTimeValue = moment(startTimeValue).format(formatSearchTime)
      }
      if (typeof endTimeValue != 'string') {
        endTimeValue = moment(endTimeValue).format(formatSearchTime)
      }
      const formatStartDate = moment(startDateValue).format(formatDatePicker);
      const formatEndDate = moment(endDateValue).format(formatDatePicker);
      const startDateTime = `${formatStartDate} ${startTimeValue}`;
      const endDateTime = `${formatEndDate} ${endTimeValue}`;

      this.formDataList = this.formDataList.filter((data: any) => {
        if (data.createdAt instanceof Timestamp)
          return moment(data.createdAt?.toDate()).isBetween(startDateTime, endDateTime, undefined, '[]');
        return false;
      });
    }
  }

  private changeDatePickerLocale() {
    this.currentDatepickerLocale = getLocaleDatePicker(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.currentDatepickerLocale = getLocaleDatePicker(langChangeEvent.lang);
    });
  }

  private checkEmptyDataExport() {
    this.disabledExportButton = !Boolean(this.formDataList.length);
  }

  optionStringValue(option: { item_id: string, item_text: string }[]) {
    let dataOptions = '';
    if (Boolean(option.length)) {
      const itemTexts = option.map((item: any) => item.item_text);
      dataOptions = itemTexts.join(', ');
    }
    return dataOptions;
  }

  getColumnsExport() {
    let columns = this.formConfig.node.filter((item: any) => !(item.hasOwnProperty('hidden') && item.hidden && item.name !== 'status'))
      .map((element: any) => element.name);
    if (columns.includes('userid')) columns.push('ownerFormData');
    if (this.formConfig.prefix === PREFIX_LIST.pluginTbc) {
      columns = columns.concat(COLUMNS_EXPORT_FORM_TBC)
    }
    this.dataExport.columns = columns;
  }

  async getDataExport() {
    const deepCloneListData: any[] = deepClone(this.formDataList);
    const listDataExport = deepCloneListData.map((formData) => {
      delete formData.createdAt;
      delete formData.img;
      delete formData.carpark;
      delete formData.formId;
      const data: any = {};
      for (const key in formData) {
        if (!formData[key]) {
          data[key] = "";
          formData[key] = "";
        } else {
          data[key] = formData[key];
        }
        switch (key) {
          case 'passingDate': {
            if (typeof formData['passingDate'].toDate === 'function') {
              data[key] = moment(formData['passingDate']?.toDate()).format(formatPassingDateExport);
            }
            break;
          }
          case 'userid': {
            data.ownerFormData = formData?.ownerFormData ?? '';
            break;
          }
          case 'locationaddress': {
            if (typeof formData[key] === 'object') {
              data[key] = 'locationaddress: ' + formData.locationaddress.locationaddress + NEW_LINE_CELL +
                'locationaddress_latitude: ' + formData.locationaddress.locationaddress_latitude + NEW_LINE_CELL +
                'locationaddress_longitude: ' + formData.locationaddress.locationaddress_longitude;
            } else {
              data[key] = "";
            }
            break;
          }
          case 'maplocation': {
            if (typeof formData[key] === 'object') {
              data[key] = 'maplocation: ' + formData.maplocation.maplocation + NEW_LINE_CELL +
                'maplocation_latitude: ' + formData.maplocation.maplocation_latitude + NEW_LINE_CELL +
                'maplocation_longitude: ' + formData.maplocation.maplocation_longitude;
            } else {
              data[key] = ""
            }
            break;
          }
          case 'zipcode': {
            data[key] = typeof formData[key] === 'object' ? formData[key][key] : '';
            break;
          }
          default: {
            const dataNode = this.formConfig.node.filter((element: any) => element.name === key);
            const formDataByKey = this.formConfig.node.filter((item: any) => item.name === key && item.datatype === 'check')[0];
            if (typeof formData[key] === 'object' && formData[key].hasOwnProperty('isMedia') && formData[key]['isMedia']) {
              data[key] = formData[key]['url'] ?? "";
            }
            if (formDataByKey && Array.isArray(formData[key])) {
              formData[key] = this.optionStringValue(formData[key]);
            }
            if (formData[key] && !isNaN(formData[key]) && key != 'members') {
              data[key] = "'" + formData[key] + "'";
            }
            if (dataNode.length && dataNode[0]['candidateData']?.hasOwnProperty('en') && dataNode[0]['candidateData']['en'].length) {
              const currentData = dataNode[0]['candidateData'][this.translateService.currentLang];
              data[key] = this.getRadioCheckTranslateData(dataNode, currentData, formData[key]);
              this.translateService.onLangChange.subscribe((e) => {
                const currentData = dataNode[0]['candidateData'][e.lang];
                data[key] = this.getRadioCheckTranslateData(dataNode, currentData, formData[key]);
              })
            }
          }
        }
      }
      return data;
    });
    this.dataExport.data = listDataExport;
  }

  getRadioCheckTranslateData(dataNode: any, currentData: any, dataCheck: string) {
    let dataInsert: string = '';
    if (dataNode[0].datatype === DataType.radio && dataCheck.length) {
      const index = dataNode[0]['candidateData']['en'].findIndex((element: string) => element.toLowerCase().includes(dataCheck.toLowerCase()));
      if (index >= 0) {
        dataInsert = currentData[index];
      }
    }
    if (dataNode[0].datatype === DataType.check && dataCheck.length) {
      const tempData = [];
      for (const dataSplit of dataCheck.split(',')) {
        const index = dataNode[0]['candidateData']['en'].findIndex((element: string) => element.includes(dataSplit.trim()));
        if (index >= 0) {
          tempData.push(currentData[index]);
        }
      }
      dataInsert = tempData.join();
    }
    return dataInsert;
  }

  checkFormExist() {
    if (!this.formConfig.data) {
      this.formConfig.isFormDelete = true
      this.router.navigate(['form'], { state: { create: true } }).then(() => {
        this.alertService.error(this.translateService.instant('alertMessages.form.update.notExist'));
      });
    }
  }

  clearSearchData() {
    this.checkFormExist();
    this.formSearch.reset();
    this.search().then();
  }

  async redirectToDetailFormData(entryno: string) {
    const formId = (await firstValueFrom(this.route.params))['id'];
    this.router.navigate([`/form/${formId}/form-data-edit/${entryno.replace(RuleValidations.removeAllDoubleQuoteString, '')}`], { queryParams: { 'type': 'export' } }).then()
  }

  async getObjectFile() {
    for (const formData of this.formDataList) {
      for (const key of this.dataShow) {
        if (formData[key] && checkInputMedia(key)['isMedia'] && formData[key]['url']?.length) {
          this.objectFile[formData.entryno + key] = await this.formService.getObjectFileStorage(formData[key])
        }
      }
    }
  }

  getFileIcon(key: string) {
    return getFileImageDisplay('', this.objectFile[key]);
  }

  searchByEnter(event: any) {
    if (event.keyCode == KEYCODE_ENTER) {
      event.preventDefault();
      this.search();
    }
  }

  protected readonly formatUnitNumber = formatUnitNumber;
  protected readonly checkInputMedia = checkInputMedia;
  protected readonly dayFormat = dayFormat;
  protected readonly RuleValidations = RuleValidations;
  protected readonly ACTION_LIST = ACTION_LIST;
  protected readonly DataType = DataType;
}
