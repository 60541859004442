<c-modal #shareForm alignment="center" [visible]="visibleModalShare" [id]="'shareForm' + form?.doc_id + activePane" (visibleChange)="visibleChange($event)">
  <c-modal-header>
    <h5 cModalTitle>{{ 'shareForm.title' | translate }}</h5>
    <button [cModalToggle]="shareForm.id" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    <form [formGroup]="formShare">
      <ng-multiselect-dropdown
        placeholder="{{ 'groups.invite.select.placeholder' |translate }}"
        [settings]="dropdownSettings"
        [data]="groups"
        [(ngModel)]="selectedGroups"
        formControlName="groupIds"
        (ngModelChange)="checkEmptySelectedGroup($event)"
      ></ng-multiselect-dropdown>
    </form>
  </c-modal-body>
  <c-modal-footer>
    <button [cModalToggle]="shareForm.id" class="btn-second">
      {{ 'shareForm.close' | translate }}
    </button>
    <button
      cLoadingButton
      [disabledOnLoading]="isLoading"
      [loading]="isLoading"
      class="btn-common btn-share"
      [ngClass]="{
        'btn-disable': isEmptySelectedGroup
      }"
      [disabled]="isEmptySelectedGroup"
      (click)="submit()">{{ 'shareForm.share' | translate }}</button>
  </c-modal-footer>
</c-modal>
