<input
  cFormControl
  type="text"
  [value]="dataZipcode"
  autocomplete="off"
  [readOnly]="readonly"
  [class]="{'readonly-data': readonly}"
  (input)="searchAddressByZipcode($event)"
  (focus)="searchAddressByZipcode($event)"
  [placeholder]="placeholder ?? ''"
/>
<ul cListGroup *ngIf="!!dataAddress && showList && isShowList" [ngClass]="{ 'address-list': dataAddress.length >= ADDRESS_ZIPCODE_SHOW_LIMIT }">
  <li cListGroupItem *ngFor="let address of dataAddress" (click)="choseAddress(address)">{{address}}</li>
</ul>
