<button class="btn-common" [cModalToggle]="createGroup.id">
  <img src="assets/svg/flight-record/icon-add.svg" alt="">
  <span>{{ 'groups.create.create' | translate }}</span>
</button>

<c-modal class="block-modal" #createGroup alignment="center" id="createGroupModal" (visibleChange)="visibleChange($event)">
  <c-modal-header>
    <h5 cModalTitle>{{ 'groups.create.title' | translate }}</h5>
    <button [cModalToggle]="createGroup.id" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    <form [formGroup]="formCreateGroup">
      <div [cFormFloating]="true" [class]="{'mb-3': true, 'error-create': formCreateGroup.invalid && submitted}">
        <input cFormControl id="floatingInput" formControlName="name" placeholder="{{ 'groups.create.groupName' | translate }}" type="text" />
        <label cLabel for="floatingInput">{{ 'groups.create.placeHolder' | translate }}</label>
        <div class="d-flex mt-2">
          <c-form-check class="text-danger" *ngIf="formCreateGroup?.controls?.name?.errors && submitted">
            {{ "errorMessages.groups.name." + getFirstError(formCreateGroup!.controls!.name!.errors) | translate: {maxlength: ruleValidations.maxLength64} }}
          </c-form-check>
        </div>
      </div>
    </form>
  </c-modal-body>
  <c-modal-footer>
    <button [cModalToggle]="createGroup.id" class="btn-second">
      {{ 'groups.close' | translate }}
    </button>
    <button class="btn-common" (click)="create()">{{ 'groups.create.create' | translate }}</button>
  </c-modal-footer>
</c-modal>
