import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ACTION_LIST, EVENT_LOG_TYPE, RuleValidations } from "@app/constants";
import { AlertService, FirebaseAnalyticService, TemplateService, UserService } from "@app/services";
import { ActivatedRoute, Router } from "@angular/router";
import { deepClone } from "@app/helpers";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidatorsService } from '@app/services/custom-validators.service';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-template-setting',
  templateUrl: './template-setting.component.html',
  styleUrls: ['./template-setting.component.scss']
})
export class TemplateSettingComponent implements OnInit, OnDestroy {
  formTemplateSetting = new FormGroup({
    title: new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]),
    name: new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]),
    version: new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]),
    author: new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]),
    copyRight: new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]),
    color: new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255)]),
    style: new FormControl(null, [Validators.required]),
    unit: new FormGroup({
      en: new FormArray([new FormControl(null)]),
      ja: new FormArray([new FormControl(null)])
    }),
    publish: new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255)]),
    prefix: new FormControl(null),
    data: new FormArray<FormControl>([]),
    search: new FormArray<FormControl>([]),
  });
  template: any;
  submitted: boolean = false
  nodeMeta: any;
  nodeObjects: any;
  queryParams: any = {
    query_search: '',
    color: ''
  }
  isClickLoading: boolean = false;
  subscription: { [key: string]: Subscription | null } = {
    ownerTemplateById: null
  }

  constructor(
    private templateService: TemplateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private alertServices: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private userService: UserService
  ) {
    this.queryParams = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit(): void {
    this.getCurrentUser().then()
  }

  ngOnDestroy(): void {
    this.subscription['ownerTemplateById']?.unsubscribe();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) this.getDataTemplate(authUser.uid);
  }

  getDataTemplate(userId: string) {
    const templateId = this.activatedRoute.snapshot.params['templateId'];
    this.subscription['ownerTemplateById'] = this.templateService.getValueOwnerTemplateById(userId, templateId).subscribe({
      next: (data: any) => {
        this.template = { ...data, nodes: JSON.parse(data.nodes) };
        this.nodeMeta = this.template.nodes.meta;
        this.nodeObjects = this.template.nodes.objects;
        this.generateFormTemplate();
      }
    })
  }

  generateFormTemplate() {
    this.formTemplateSettingControls.title.setValue(this.nodeMeta.title);
    this.formTemplateSettingControls.name.setValue(this.nodeMeta.name);
    this.formTemplateSettingControls.version.setValue(this.nodeMeta.version);
    this.formTemplateSettingControls.author.setValue(this.nodeMeta.author ?? this.nodeMeta.auther);
    this.formTemplateSettingControls.copyRight.setValue(this.nodeMeta.copyRight);
    this.formTemplateSettingControls.color.setValue(this.nodeMeta.color);
    this.formTemplateSettingControls.style.setValue(this.nodeMeta.style);
    this.formTemplateSettingControls.unit.controls.ja.removeAt(0);
    this.formTemplateSettingControls.unit.controls.ja.insert(0, new FormControl(this.nodeMeta.unit.ja[0]));
    this.formTemplateSettingControls.unit.controls.en.removeAt(0);
    this.formTemplateSettingControls.unit.controls.en.insert(0, new FormControl(this.nodeMeta.unit.en[0]));
    this.formTemplateSettingControls.publish.setValue(this.nodeMeta.publish);
    this.formTemplateSettingControls.prefix.setValue(this.nodeMeta.prefix);
    if (this.nodeMeta.data.length) {
      for (const [index, node] of this.nodeMeta.data.entries()) {
        this.formTemplateSettingControls.data.setControl(index, new FormControl(node));
      }
    }
    if (this.nodeMeta.search.length) {
      for (const [index, nodeSearch] of this.nodeMeta.search.entries()) {
        this.formTemplateSettingControls.search.setControl(index, new FormControl(nodeSearch));
      }
    }
  }

  get formTemplateSettingControls() {
    return this.formTemplateSetting.controls;
  }

  async submit() {
    try {
      this.submitted = true;
      this.isClickLoading = true;
      if (this.formTemplateSetting.valid) {
        const dataTemplate = deepClone(this.template);
        dataTemplate.nodes.meta = { ...dataTemplate.nodes.meta, ...this.formTemplateSetting.value }
        const dataUpdate = {
          ...dataTemplate,
          nodes: JSON.stringify(dataTemplate.nodes),
          createdAt: this.template.createdAt,
          title: this.formTemplateSetting.value.name
        };
        await this.templateService.update(dataUpdate.docId, dataUpdate);
        this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.UPDATE_BASIC_SETTING, EVENT_LOG_TYPE.SUCCESS);
        this.router.navigate(['list-template'], { queryParams: this.queryParams }).then(() => {
          this.alertServices.success(this.translateService.instant('alertMessages.template.update.success'));
        })
      } else {
        this.isClickLoading = false;
      }
    } catch (e) {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.UPDATE_BASIC_SETTING, EVENT_LOG_TYPE.ERROR);
      this.alertServices.error(this.translateService.instant('alertMessages.template.update.fail'));
    }

  }

  backToListTemplate() {
    this.router.navigate(['list-template'], { queryParams: this.queryParams }).then();
  }
}
