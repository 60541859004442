<c-input-group>
  <input
    cFormControl
    class="input-link"
    [placeholder]="placeholder ?? ''"
    [value]="link"
    [disabled]="disabled"
    (input)="changeLink($event)"
  />
  <button class="btn-common button-link" [ngClass]="{'button-link__disabled' : !checkLinkValid}" type="button" variant="outline">
    <a class="btn-link-icon" [href]="link" target="_blank">
      <img src="/assets/svg/common/link-without-circle.svg" width="25" height="25" alt="">
    </a>
  </button>
</c-input-group>
