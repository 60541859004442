export const MESSAGES_NOTIFICATION = {
  group: {
    invite: {
      title: {
        ja: "={groupName}=グループ",
        en: "={groupName}= Group"
      },
      body: {
        ja: "={groupName}=グルー プへの参加依頼が届きました",
        en: "You received a request to join the ={groupName}= group"
      },
    },
    deleteUser: {
      title: {
        ja: "={groupName}=グループ",
        en: "={groupName}= Group"
      },
      body: {
        ja: "={groupName}=グルー プから退出しました",
        en: "You left group ={groupName}="
      },
    },
    deleteGroup: {
      title: {
        ja: "={groupName}=グループ",
        en: "={groupName}= Group"
      },
      body: {
        ja: "={groupName}=グループはオーナーにより削除されました",
        en: "={groupName}= group has been deleted by the owner"
      },
    },
    joinToGroup: {
      title: {
        ja: "={groupName}=グループ",
        en: "={groupName}= Group"
      },
      body: {
        ja: "={groupName}=グルー プに={email}=さんが参加しました",
        en: "={email}= joined the ={groupName}= group"
      },
    },
    shareForm: {
      title: {
        ja: "={groupName}=グループ",
        en: "={groupName}= Group"
      },
      body: {
        ja: "={groupName}=グルー プの={formName}=フォームが共有されました",
        en: "={formName}= form in ={groupName}= group has been shared"
      },
    },
    unShareForm: {
      title: {
        ja: "={groupName}=グループ",
        en: "={groupName}= Group"
      },
      body: {
        ja: "={groupName}=グルー プの={formName}=フォームの共有が解除されました",
        en: "={formName}= form in ={groupName}= group has been unshared"
      },
    }
  },
  account: {
    changePassword: {
      title: {
        ja: "パスワードが変更されました",
        en: "Your password changed"
      },
      body: {
        ja: "あなたのパスワードが変更されました。新しいパスワードでログインしてください",
        en: "Your password has been changed. Use your new password to login again"
      },
    },
    deleteAccount: {
      title: {
        ja: "カウントが削除されました",
        en: "Your account deleted"
      },
      body: {
        ja: "あなたのアカウントが削除されました",
        en: "Your account has been deleted"
      },
    },
    forgotPassword: {
      title: {
        ja: "パスワードが変更されました",
        en: "Your password changed"
      },
      body: {
        ja: "あなたのパスワードが変更されました。新しいパスワードでログインしてください",
        en: "Your password has been changed. Use your new password to login again"
      },
    },
  },
  childAccount: {
    deleteAccount: {
      title: {
        ja: "アカウントは削除されました",
        en: "Account Deleted"
      },
      body: {
        ja: "お使いのアカウントは削除されました",
        en: "Your account has been deleted"
      },
    },
    inactiveAccount: {
      title: {
        ja: "アカウントは現在非アクティブ",
        en: "Account inactive"
      },
      body: {
        ja: "お使いのアカウントは現在非アクティブになっています",
        en: "Your account is currently inactive"
      }
    }
  }
}
