import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
  @Input() formControlName: string = ''
  @Input() titleInput: string = ''
  @Input() isRequired: boolean = false
  @Input() form: FormGroup = new FormGroup<any>({})
  @Input() errorMessage: string = ''
  @Input() showErrorMessages: boolean = false
  @ViewChild('colorPicker') colorPicker: any;
  constructor() { }

  ngOnInit(): void {
  }

  openColorPicker() {
    const element = this.colorPicker.triggerDirective.triggerRef as ElementRef
    element.nativeElement.click()
  }
}
