import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, Validators } from "@angular/forms";
import { getFirstError, setValueOfDeepData, synchronizeValuesOfDeepData } from "@app/helpers";
import { RuleValidations } from "@app/constants";

@Component({
  selector: 'app-single-multiple-select',
  templateUrl: './single-multiple-select.component.html',
  styleUrls: ['./single-multiple-select.component.scss']
})
export class SingleMultipleSelectComponent implements OnChanges {
  @Input() form: any;
  @Input() submitted: boolean = false;
  showErrors: {[key: string] : any} = {}
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.showErrors = this.setValuesToFalse(this.form.value);
  }

  changeShowErrors(...keys: any[]) {
    this.showErrors = setValueOfDeepData(this.showErrors, keys, true)
  }

  setValuesToFalse(obj: any): any {
    return synchronizeValuesOfDeepData(obj, false)
  }

  get formArrayValue() {
    return this.form.get('value') as FormArray
  }
  get formArrayCandidateJa() {
    return this.form.get('candidateData').get('ja') as FormArray;
  }

  get formArrayCandidateEn() {
    return this.form.get('candidateData').get('en') as FormArray;
  }

  addCandidate() {
    this.formArrayValue.push(new FormControl(null, [Validators.required]))
    this.formArrayCandidateJa.push(new FormControl(null, [Validators.required]))
    this.formArrayCandidateEn.push(new FormControl(null, [Validators.required]))
  }

  removeCandidate(index: number) {
    this.formArrayValue.removeAt(index);
    this.formArrayCandidateJa.removeAt(index);
    this.formArrayCandidateEn.removeAt(index);
  }

  protected readonly getFirstError = getFirstError;
  protected readonly RuleValidations = RuleValidations;
}
