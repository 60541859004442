import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderGuestRoutingName, HideHeaderUserRoutingName } from '@app/constants';
import { TranslateService } from "@ngx-translate/core";
import { text } from "@app/constants";
import { environment } from "@environments/environment";
import { checkColorBg } from '@app/helpers';

@Component({
  selector: 'app-layout-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  @Input() isGuest: boolean = true;
  @Input() backgroundColor: string = "";
  @Input() stringColor: string = "";
  checkColorBg = checkColorBg
  hideHeaderUserRoutingName = HideHeaderUserRoutingName
  text = text
  language: string = '';
  supportLanguage = ['en', 'ja'];
  listRouteNonAccount = [...HeaderGuestRoutingName];
  currentRouteName = this?.route?.snapshot?.routeConfig?.path;
  isRouteNonAccount = this.listRouteNonAccount.includes(this.currentRouteName!)
  errorWithEnvSetting: boolean = (!environment.sing_up_link && this.currentRouteName === 'register') || (!environment.no_login_link && this.currentRouteName === 'non-account')

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
  ) {
    this.translateService.addLangs(this.supportLanguage);
    if (localStorage.getItem('lang') != null) {
      this.language = <string>localStorage.getItem('lang');
      this.translateService.use(<string>localStorage.getItem('lang'));
    } else {
      this.language = 'ja'
      this.translateService.use('ja');
      localStorage.setItem('lang', 'ja')
    }
  }

  ngOnInit(): void {
    this.language = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((e) => {
      this.language = e.lang
    })
  }

  onChange(event: any) {
    const lang = event.target.dataset.lang;
    this.translateService.use(lang);
    localStorage.setItem('lang', lang)
    this.language = lang;
  }

  hideHeader() {
    return Boolean(this.isRouteNonAccount && !HideHeaderUserRoutingName.includes(this.route?.routeConfig?.path!));
  }

  protected readonly environment = environment;
}
