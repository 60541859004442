import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import { environment } from "@environments/environment";
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ZIP_CODE_ADDRESS_LIMIT } from '@app/constants';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AddressSearchService {
  currentMessage = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private translateService: TranslateService,
    private apiService: ApiService
  ) { }

  async getLocale(searchMapValue: string) {
    const config = {
      header: { params: { q: searchMapValue } },
      errorMessageKey: 'alertMessages.formData.getAddressList.fail',
      isThirdParty: true
    }
    return this.apiService.get(environment.api_search_map, config);
  }

  async getAddressByZipcode(zipcode: string) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.http.get(environment.api_search_zip_code, { params: { zipcode: zipcode, limit: ZIP_CODE_ADDRESS_LIMIT } }).subscribe((data: any) => {
          if (data.status == 200) {
            resolve(data.results);
          }
        });
      } catch (e) {
        this.alertService.error(this.translateService.instant('alertMessages.formData.getAddressList.fail'));
      }
    });
  }
}
