<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mb-5">
      <h3>{{ "template.basicSetting.title" | translate }}</h3>
    </c-col>
    <c-nav variant="underline">
      <c-nav-item>
        <a [active]="true" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="0" cNavLink>
          {{ "template.basicSetting.basicTab.title" | translate }}
        </a>
      </c-nav-item>
      <c-nav-item>
        <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="1" cNavLink>
          {{ "template.basicSetting.displayListTab.title" | translate }}
        </a>
      </c-nav-item>
    </c-nav>
    <c-card class="mt-3">
      <c-card-body>
        <form [formGroup]="formTemplateSetting" (ngSubmit)="submit()">
          <c-tab-content #tabContent="cTabContent" [activeTabPaneIdx]="1">
            <c-tab-pane class="pt-3 pb-3">
              <app-basic-template-setting-tab [submitted]="submitted" [formTemplateSetting]="formTemplateSetting"></app-basic-template-setting-tab>
            </c-tab-pane>
            <c-tab-pane class="pt-3 pb-3">
              <app-display-list-tab
                *ngIf="template?.nodes"
                [formTemplateSetting]="formTemplateSetting"
                [templateNode]="template.nodes"
                [submitted]="submitted"
              ></app-display-list-tab>
            </c-tab-pane>
          </c-tab-content>
        </form>
        <div class="d-flex justify-content-end">
          <button class="btn-back me-3" (click)="backToListTemplate()">{{ 'template.basicSetting.btnLabel.back' | translate }}</button>
          <button cLoadingButton [disabledOnLoading]="true" [loading]="isClickLoading" class="btn-save" (click)="submit()">{{ 'template.basicSetting.btnLabel.save' | translate }}</button>
        </div>
      </c-card-body>
    </c-card>
  </div>
</c-container>
