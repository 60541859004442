<div id="notifications" [ngClass]="{ 'd-none': !isShow }">
  <c-col *ngFor="let notification of notifications; index as i" class="notification">
    <c-col class="d-flex align-items-center align-items-center">
      <c-col [md]="2" [xs]="2" class="d-flex justify-content-around">
        <img *ngIf="!notification.isRead" class="notification__logo__dot" cImg src="assets/svg/notification/dot.svg" alt="red dot" />
        <div *ngIf="notification.isRead" class="notification__logo__dot"></div>
        <img cImg src="assets/svg/bell.svg" alt="password reset image" />
      </c-col>
      <c-col [md]="8" [xs]="8" class="px-3">
        <h5 class="mb-1 long-text notification__title" [class]="{'notification__title--unread': !notification.isRead}">
          {{ getDataNotice(notification.messageTitle, currentLang) }}
        </h5>
        <p class="mb-0 long-text notification__body">
          {{ getDataNotice(notification.messageBody, currentLang) }}
        </p>
      </c-col>
      <c-col [md]="2" [xs]="2" class="d-flex justify-content-end">
        <p class="mb-0 notification__time" [ngClass]="{'notification__time--now': notification.timeAgo === textNow}">
          {{ notification.timeAgo }}
        </p>
      </c-col>
    </c-col>
  </c-col>
  <c-col class="text-center mt-3">
    <a routerLink="/notification" class="btn-link text-orange" (click)="handleNavigate()">
      {{ 'notification.viewAll'|translate }}
    </a>
  </c-col>
</div>
