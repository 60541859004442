import { Component, OnInit, ViewChild, Renderer2, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AccountStatus, EXTEND_ROUTE_ACTIVE, RouterNameList } from '@app/constants';
import { TranslateService } from '@ngx-translate/core';
import { NotifyPopupComponent } from '../notify-popup/notify-popup.component';
import { environment } from "@environments/environment";
import { UserService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss']
})
export class HeaderUserComponent implements OnInit, OnDestroy {
  @ViewChild('notifyPopupComponent') notifyPopupComponent!: NotifyPopupComponent;
  @HostListener('window:mousedown')
  onMousedown() {
    this.checkUserActive().then();
  }
  @HostListener('window:touchstart')
  onTouchStart() {
    this.checkUserActive().then();
  }
  protected readonly environment = environment;
  protected readonly EXTEND_ROUTE_ACTIVE = EXTEND_ROUTE_ACTIVE;
  lang = localStorage.getItem('lang');
  formCreateRouterName = RouterNameList.formCreate;
  user: any;
  isCallUserData: boolean = false;
  currentLanguage: string = '';
  subscription: {[key: string]: Subscription| null} = {
    currentUser: null,
    user: null
  }

  constructor(
    public router: Router,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const body = document.querySelector('body');
        if (body) {
          body.classList.remove('open-menu');
        }
      }
    });
  }

  ngOnInit(): void {
    this.currentLanguage = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLanguage = e.lang
    })
    this.getCurrentUser().then();
  }

  ngOnDestroy(): void {
    this.subscription['currentUser']?.unsubscribe();
    this.subscription['user']?.unsubscribe();
  }

  async getCurrentUser() {
    this.subscription['currentUser'] = this.userService.getCurrentUser().subscribe({
      next: (data: any) => {
        if (data) {
          this.subscription['user'] = this.userService.getValueUserByUid(data.uid).subscribe({
            next: (response) => {
              this.user = response;
              this.isCallUserData = true;
            }
          })
        }
      }
    })
  }

  async checkUserActive() {
    if (this.user && !this.user?.active) {
      await this.userService.signOutAuthGuard(AccountStatus.inactiveByParent);
    } else if (!this.user && this.isCallUserData) {
      await this.userService.signOutAuthGuard(AccountStatus.isDeletedByParent);
    }
  }

  toggleNotifications() {
    this.notifyPopupComponent.toggle();
  }

  onChange(event: any) {
    const lang = event.target.dataset.lang;
    this.translateService.use(lang);
    localStorage.setItem('lang', lang)
    this.lang = lang;
  }

  onClickMenu(event: any) {
    const checked = event.target.checked;
    if (checked) {
      this.renderer.addClass(document.body, 'open-menu');
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    } else {
      this.renderer.removeClass(document.body, 'open-menu');
      this.renderer.setStyle(document.body, 'overflow', 'auto');
    }
  }

  customMatcher(regExp: RegExp): boolean {
    return regExp.test(window.location.href);
  }
}
