import {Component, Input, OnInit} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {AlertService, FirebaseAnalyticService} from "@app/services";
import * as moment from 'moment';
import { EVENT_LOG_TYPE, formatFileName } from '@app/constants';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent implements OnInit {
  @Input('dataExport') dataExport: any;
  @Input('columns') columns: Array<string> = []
  @Input('fileName') fileName: string = '';
  @Input('messageExportSuccess') messageExportSuccess: string = '';
  @Input('messageExportFail') messageExportFail: string = '';
  @Input('btnTitle') btnTitle: string = '';
  @Input('isDisabledExportButton') isDisabledExportButton: boolean = true;
  @Input('isDisplayIconExport') isDisplayIconExport: boolean = false;
  @Input('firebaseActionLogName') firebaseActionLogName: string = '';
  private config = {
    delimiter: ',',
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: function (header: any) {
      return header.trim();
    },
    columns: ['']
  };
  constructor(
    private papa: Papa,
    private alertService: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService
  ) { }

  ngOnInit(): void { }

  export() {
    try {
      this.config = {...this.config, columns: this.columns}
      const dataCsv = this.papa.unparse(this.dataExport, this.config);
      this.downloadCsv(dataCsv);
      this.alertService.success(this.messageExportSuccess);
      this.firebaseAnalyticService.logEvent(this.firebaseActionLogName, EVENT_LOG_TYPE.SUCCESS);
    } catch (e) {
      this.alertService.error(this.messageExportFail);
      this.firebaseAnalyticService.logEvent(this.firebaseActionLogName, EVENT_LOG_TYPE.ERROR)
    }
  }

  downloadCsv(dataCsv: string) {
    const blob = new Blob(["\ufeff" + dataCsv], {type: 'text/csv;charset=utf-8'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const current = moment().format(formatFileName);
    link.download = `${this.fileName}${current}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
