import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

export function moveUp(item: any, list: Array<any> = []) {
  if (list) {
    const currentIndex = list.indexOf(item);
    if (currentIndex > 0) {
      moveItemInArray(list, currentIndex, currentIndex - 1);
    }
  }
}

export function moveDown(item: any, list: Array<any> = []) {
  if (list) {
    const currentIndex = list.indexOf(item);
    if (currentIndex < list.length) {
      moveItemInArray(list, currentIndex, currentIndex + 1);
    }
  }
}

export function drop(event: CdkDragDrop<string[]>, list: Array<any> = []) {
  moveItemInArray(list, event.previousIndex, event.currentIndex);
}
