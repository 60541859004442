import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { formatDateChartEn, formatDateChartJa, LimitTextDisplay } from '@app/constants';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-images-chart-form-data',
  templateUrl: './images-chart-form-data.component.html',
  styleUrls: ['./images-chart-form-data.component.scss']
})
export class ImagesChartFormDataComponent implements OnChanges, OnInit {
  @Input() listFormData: any;
  dataImageChart: Array<any> = [];
  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.generateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataImageChart = this.listFormData;
  }

  generateChartData() {
    const currentLang = this.translateService.currentLang;
    this.formatDateTimeByCurrentLang(currentLang);
    this.translateService.onLangChange.subscribe((e: LangChangeEvent) => {
      this.formatDateTimeByCurrentLang(e.lang);
    })
  }

  formatDateTimeByCurrentLang(currentLang: string) {
    this.dataImageChart = this.listFormData;
    if (currentLang)
    this.dataImageChart = this.dataImageChart.map((formData: any) => {
      const data = {
        ...formData,
        date: moment(formData.date).format(currentLang === 'en' ? formatDateChartEn : formatDateChartJa)
      };
      return data;
    });
  }

  protected readonly LimitTextDisplay = LimitTextDisplay;
}
