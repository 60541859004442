<c-modal #editName alignment="center" class="block-modal" [id]="'editName' + path" (visibleChange)="visibleChange($event)" [visible]="visible">
  <c-modal-header>
    <h5 cModalTitle>{{ 'groups.edit.title' | translate }}</h5>
    <button [cModalToggle]="editName.id" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    <form [formGroup]="formEditName">
      <div [cFormFloating]="true" [class]="{'mb-3': true, 'error-create': formEditName.invalid && submitted}">
        <input class="input-name" cFormControl id="floatingInput" formControlName="name" placeholder="{{ 'groups.edit.groupName' | translate }}" type="text" />
        <label cLabel for="floatingInput">{{ 'groups.edit.placeHolder' | translate }}</label>
        <div class="d-flex mt-2">
          <c-form-check class="text-danger" *ngIf="formEditName?.controls?.name?.errors && submitted">
            {{ "errorMessages.groups.name." + getFirstError(formEditName!.controls!.name!.errors) | translate: {maxlength: ruleValidations.maxLength64} }}
          </c-form-check>
        </div>
      </div>
      <div class="mt-2">
        <c-form-check class="hidden-option">
          <input cFormCheckInput id="checkOne" type="checkbox" formControlName="hidden"/>
          <label cFormCheckLabel for="checkOne" (click)="toggleChecked()">{{ 'groups.edit.hidden' | translate }}</label>
        </c-form-check>
      </div>
    </form>
  </c-modal-body>
  <c-modal-footer>
    <button [cModalToggle]="editName.id" class="btn-second">
      {{ 'groups.close' | translate }}
    </button>
    <button (click)="submit()" class="btn-common">{{ 'groups.edit.save' | translate }}</button>
  </c-modal-footer>
</c-modal>
