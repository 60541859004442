import { FieldValue, Timestamp } from "firebase/firestore"

export interface IUser {
  accountType: string,
  accountTypeName: string,
  accountTypePredict: string | null,
  active: boolean,
  appUserId: string | null,
  createAt: FieldValue | Timestamp,
  displayName: string | null,
  email: string | null,
  lastLoginAt: FieldValue | Timestamp | null,
  linked_ui: string | null,
  listSubscriptionAwaitBuy: Array<any> | null,
  listSubscriptionAwaitCancel: Array<any> | null,
  listSubscriptionBuying: Array<any> | null,
  memberCode: string | null,
  name: string | null,
  organizationName: string,
  parentUserId: string | null,
  payment_method: string | null,
  photoURL: string | null,
  purchaseDate: Timestamp | null,
  secretKey: string | null,
  tokens: Array<string> | null,
  uid: string,
  updateAt: FieldValue | Timestamp | null,
  disabled: boolean,
  password: string
}

export interface IAccountRestrict {
  isDisabledCreateForm: boolean,
  isCreateGroup: boolean,
  isDisplayTemplateList: boolean,
  isDisplayChildAccountList: boolean,
  isDisplayCameraSetting: boolean,
  isDisabledCreateChildAccount: boolean
}

export enum AccountType {
  Free = 'free'
}
