import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class FormCachesService {

  constructor(
    private db: AngularFirestore,
  ) { }

  async deleteFormCaches(userId: string = '', formId: string = '') {
    await this.db.collection('users').doc(userId).collection('formCaches').doc(formId).delete();
  }

  getDetailFormCache(formId: string) {
    return this.db.collectionGroup('formCaches', ref => ref.where('formid', '==', formId)).valueChanges();
  }

  getFormCache(formId: string) {
    return new Promise((resolve, reject) => {
      this.db.collectionGroup('formCaches', ref => ref.where('formid', '==', formId).limit(1)).valueChanges().subscribe({
        next: (value) => {
          resolve(value);
        }
      })
    })
  }

  getValueOwnerFormCaches(userId: string = '', formId: string = '') {
    return this.db.collection('users').doc(userId).collection('formCaches').doc(formId).valueChanges();
  }
}
