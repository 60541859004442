import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { INIT_EDITOR, RuleValidations } from "@app/constants";
import { getFirstError, setValueOfDeepData, synchronizeValuesOfDeepData } from "@app/helpers";
import { Validators } from "@angular/forms";

@Component({
  selector: 'app-editor-line',
  templateUrl: './editor-line.component.html',
  styleUrls: ['./editor-line.component.scss']
})
export class EditorLineComponent implements OnChanges {
  @Input() form: any;
  @Input() formIndex: number = 0;
  @Input() submitted: boolean = false;
  @ViewChild('placeholderJa') placeholderJaEle: any
  @ViewChild('placeholderEn') placeholderEnEle: any
  showErrors: {[key: string | number]: any} = {}
  constructor() { }

  ngOnInit(): void {
  }

  click(ele: any) {
    ele.editor.focus()
  }

  changeEditor(ele: any, key: string) {
    this.changeShowErrors(key)
    const value = ele.editor.getContent({ format: 'text' });
    this.form.get(key).setValidators(null);
    if (value?.length > RuleValidations.maxLength4096) this.form.get(key).addValidators(Validators.maxLength(RuleValidations.maxLength4096))
    this.form.get(key).updateValueAndValidity()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.showErrors = this.setValuesToFalse(this.form.value);
  }

  setValuesToFalse(obj: any): any {
    return synchronizeValuesOfDeepData(obj, false)
  }

  changeShowErrors(...keys: any[]) {
    this.showErrors = setValueOfDeepData(this.showErrors, keys, true)
  }

  protected readonly RuleValidations = RuleValidations;
  protected readonly getFirstError = getFirstError;
  protected readonly INIT_EDITOR = INIT_EDITOR;
}
