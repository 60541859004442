import { Component, OnDestroy, OnInit } from '@angular/core';
import { moveItemInArray, copyArrayItem } from '@angular/cdk/drag-drop';
import {
  ACTION_LIST,
  ELEMENT_TYPE_LIST,
  EVENT_LOG_TYPE,
  INPUT_TYPE,
  INPUT_TYPE_FILE,
  MIN_MAX_VALUE_DATA_TYPE_LIST,
  RuleValidations,
  text,
  UNIQUE_LINE_NAME,
} from '@app/constants';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService, FirebaseAnalyticService, TemplateService, UserService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { dayFormat, deepClone, deepCloneFormGroup, getDuplicateIndexItemInArray, getLocaleDatePicker, removeItem, scrollToBottom } from '@app/helpers';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ITemplateElement } from '@app/models';
import { CustomValidatorsService } from '@app/services/custom-validators.service';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-template-items-edit',
  templateUrl: './template-items-edit.component.html',
  styleUrls: ['./template-items-edit.component.scss']
})
export class TemplateItemsEditComponent implements OnInit, OnDestroy {
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'すべて',
    unSelectAllText: 'すべて',
    itemsShowLimit: 3,
    allowSearchFilter: false
  };
  form: FormGroup = new FormGroup({
    objects: new FormArray<any>([])
  });
  elementList: Array<ITemplateElement> = [];
  template: any;
  stylesButton = {
    transform: 'rotate(-180deg)'
  }
  currentDatepickerLocale: string = "";
  elementData: any;
  visibleTemplateDetail: boolean = false;
  templateElementDetail: any;
  submitted: boolean = false
  currentLang: string = '';
  previousData: any;
  queryParams: any = {
    query_search: '',
    color: ''
  }
  isClickLoading: boolean = false;
  listNameInput: string[] = [];
  formIndex: number = 0;
  subscription: { [key: string]: Subscription | null } = {
    ownerTemplateById: null
  }

  constructor(
    private templateService: TemplateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private alertService: AlertService,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private userService: UserService
  ) {
    this.previousData = this.router.getCurrentNavigation()?.extras.state?.['previousData'];
    this.queryParams = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit(): void {
    this.getCurrentUser().then();
    this.currentLang = this.translateService.currentLang
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLang);
    this.listenLangChange();
  }

  ngOnDestroy(): void {
    this.subscription['ownerTemplateById']?.unsubscribe();
  }

  async getCurrentUser() {
    const authUser = await firstValueFrom(this.userService.getCurrentUser());
    if (authUser && authUser.uid.length) this.getDataTemplate(authUser.uid);
  }

  getDataTemplate(userId: string) {
    const templateId = this.activatedRoute.snapshot.params['templateId'];
    this.subscription['ownerTemplateById'] = this.templateService.getValueOwnerTemplateById(userId, templateId).subscribe({
      next: (data: any) => {
        this.template = { ...data, nodes: JSON.parse(data.nodes) };
        const dataNodesObjects = (this.previousData ?? this.template.nodes.objects).entries();
        for (const [index, node] of dataNodesObjects) {
          const initDataFormGroup = this.switchInputForm(node, true);
          this.formObjects.insert(index, initDataFormGroup);
          this.elementList[index] = { type: node.type, datatype: node.datatype, name: node.name };
        }
      }
    })
  }

  listenLangChange() {
    this.translateService.onLangChange.subscribe((e) => {
      this.currentLang = e.lang
      this.currentDatepickerLocale = getLocaleDatePicker(this.currentLang);
    })
  }

  get formObjects() {
    return this.form.controls['objects'] as FormArray;
  }

  getTitleInput(input: any, index: number) {
    return this.formObjects.controls[index]?.value[this.currentLang === 'ja' ? 'title_ja' : 'title_eng']?.length
      ? this.formObjects.controls[index]?.value[this.currentLang === 'ja' ? 'title_ja' : 'title_eng']
      : ('template.formItemsEdit.inputType.' + input.type + input.datatype) + (input.hasOwnProperty('name') && !!input.name ? input['name'] : '')
  }

  setFormGroupInputType(data: any, shiftIndex: number) {
    const initDataFormGroup = this.switchInputForm(data);
    this.formObjects.insert(shiftIndex, initDataFormGroup);
  }

  createInitDataFormGroup(data: any, isHtmlInput: boolean = false): FormGroup<any> {
    return new FormGroup({
      title_ja: new FormControl(null, [Validators.maxLength(RuleValidations.maxLength255), Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
      title_eng: new FormControl(null, [Validators.maxLength(RuleValidations.maxLength255), Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
      placeholder_ja: new FormControl(null, isHtmlInput ? [Validators.maxLength(RuleValidations.maxLength255)] : []),
      placeholder_eng: new FormControl(null, isHtmlInput ? [Validators.maxLength(RuleValidations.maxLength255)] : []),
      datatype: new FormControl(data.datatype),
      name: new FormControl(null, [Validators.maxLength(RuleValidations.maxLength255), Validators.required, CustomValidatorsService.notAllowedOnlySpace]),
      type: new FormControl(data.type),
      required: new FormControl(false),
      readonly: new FormControl(false),
      hidden: new FormControl(false),
    });
  }

  initializeInitDataFormGroup(initDataFormGroup: FormGroup<any>, data: any): void {
    let dataReadonly = data.readonly ?? null;
    if (UNIQUE_LINE_NAME.includes(data.name)) dataReadonly = true;
    initDataFormGroup.patchValue({
      title_ja: data.title_ja ?? null,
      title_eng: data.title_eng ?? null,
      placeholder_ja: data.placeholder_ja ?? null,
      placeholder_eng: data.placeholder_eng ?? null,
      datatype: data.datatype ?? null,
      name: data.name ?? null,
      type: data.type ?? null,
      required: data.required ?? null,
      readonly: dataReadonly,
      hidden: data.hidden ?? null,
    });
  }

  addCandidateControls(initDataFormGroup: FormGroup<any>, data: any): void {
    const candidateValue = data.value;
    const candidateTitle = data.candidateData;
    const candidateData = new FormGroup({
      en: new FormArray<any>([new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace])]),
      ja: new FormArray<any>([new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace])]),
    });
    const value = new FormArray<any>([new FormControl(null, [Validators.required, Validators.maxLength(RuleValidations.maxLength255)])]);

    if (candidateValue?.length) {
      const candidateEn = candidateData.controls.en as FormArray;
      const candidateJa = candidateData.controls.ja as FormArray;
      candidateJa.clear();
      candidateEn.clear();
      value.clear();
      candidateValue.forEach((item: string, index: number) => {
        candidateEn.push(new FormControl(candidateTitle['en'][index], [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]));
        candidateJa.push(new FormControl(candidateTitle['ja'][index], [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]));
        value.push(new FormControl(item, [Validators.required, Validators.maxLength(RuleValidations.maxLength255), CustomValidatorsService.notAllowedOnlySpace]));
      });
    }

    initDataFormGroup.addControl('candidateData', candidateData);
    initDataFormGroup.addControl('value', value);
  }

  setMinMaxValueValidation(formMinValue: FormControl, formMaxValue: FormControl, formMinLength: FormControl, formMaxLength: FormControl) {
    formMinLength.setValue(-1);
    formMaxLength.setValue(-1);

    formMinValue.setValidators(Validators.required);
    formMaxValue.setValidators(Validators.required);
    formMinValue.updateValueAndValidity();
    formMaxValue.updateValueAndValidity();
  }

  setMinMaxLengthValidation(formMinValue: FormControl, formMaxValue: FormControl, formMinLength: FormControl, formMaxLength: FormControl) {
    formMinValue.setValue(-1);
    formMaxValue.setValue(-1);

    formMinLength.setValidators(Validators.required);
    formMaxLength.setValidators(Validators.required);
    formMinLength.updateValueAndValidity();
    formMaxLength.updateValueAndValidity();
  }

  addValidationControls(initDataFormGroup: FormGroup<any>, data: any, initData: boolean): void {
    let formMinValue = new FormControl<number | null>(null);
    let formMaxValue = new FormControl<number | null>(null);
    let formMinLength = new FormControl<number | null>(null);
    let formMaxLength = new FormControl<number | null>(null);
    if (initData && data.validation && data.validation.length) {
      const dataValidation = data.validation;
      const [minValue, maxValue, minLength, maxLength] = dataValidation;
      if (MIN_MAX_VALUE_DATA_TYPE_LIST.includes(data.datatype)) {
        formMinValue.setValue(minValue);
        formMaxValue.setValue(maxValue);
        this.setMinMaxValueValidation(formMinValue, formMaxValue, formMinLength, formMaxLength);
      } else {
        formMinLength.setValue(minLength);
        formMaxLength.setValue(maxLength);
        this.setMinMaxLengthValidation(formMinValue, formMaxValue, formMinLength, formMaxLength);
      }
    } else if (MIN_MAX_VALUE_DATA_TYPE_LIST.includes(initDataFormGroup.controls['datatype'].value)) {
      this.setMinMaxValueValidation(formMinValue, formMaxValue, formMinLength, formMaxLength);
    } else {
      this.setMinMaxLengthValidation(formMinValue, formMaxValue, formMinLength, formMaxLength);
    }
    let validationArray: FormControl[] = [];
    if (!UNIQUE_LINE_NAME.includes(data.name)) {
      validationArray = [formMinValue, formMaxValue, formMinLength, formMaxLength];
    }
    initDataFormGroup.addControl('validation', new FormArray(validationArray));
    initDataFormGroup.addControl('validationCondition', new FormControl(initData && data.validationCondition?.length ? data.validationCondition : null));
    initDataFormGroup.addControl('validationType', new FormControl(initData ? data.validationType ?? null : null));
  }

  addConditionControls(initDataFormGroup: FormGroup<any>, data: any, initData: boolean): void {
    if (data.datatype === INPUT_TYPE.zip) {
      initDataFormGroup.addControl('validationCondition', new FormControl('zip'));
    }
    if (initData) {
      if (data.datatype === INPUT_TYPE.zip && data.validationCondition?.length) {
        initDataFormGroup.get('validationCondition')?.setValue(data.validationCondition);
      }
    }
  }

  switchInputForm(data: any, initData: boolean = false): FormGroup<any> {
    const initDataFormGroup = this.createInitDataFormGroup(data, data.datatype === INPUT_TYPE.html);

    this.initializeInitDataFormGroup(initDataFormGroup, data);

    switch (data.datatype) {
      case INPUT_TYPE.area:
      case INPUT_TYPE.number:
      case INPUT_TYPE.float:
      case INPUT_TYPE.slider:
      case INPUT_TYPE.email:
      case INPUT_TYPE.html:
      case INPUT_TYPE.link:
      case INPUT_TYPE.uid:
      case INPUT_TYPE.text: {
        this.addValidationControls(initDataFormGroup, data, initData);
        break;
      }
      case INPUT_TYPE.radio:
      case INPUT_TYPE.check: {
        this.addCandidateControls(initDataFormGroup, data);
        break;
      }
      case INPUT_TYPE.zip:
      case INPUT_TYPE.location: {
        this.addConditionControls(initDataFormGroup, data, initData);
        this.addValidationControls(initDataFormGroup, data, initData);
        break;
      }
    }

    return initDataFormGroup;
  }

  addItemInputToForm(item: ITemplateElement, index: number | boolean = false) {
    const initDataFormGroup = this.switchInputForm(item)
    if (typeof index === 'boolean' && !index) {
      this.elementList.push(item);
      this.formObjects.push(initDataFormGroup);
      scrollToBottom();
    } else if (typeof index === "number") {
      this.elementList = [
        ...this.elementList.slice(0, index + 1),
        item,
        ...this.elementList.slice(index + 1)
      ];
      const newElementValue = deepClone(this.formObjects.controls.at(index as number)?.value);
      const formGroup = new FormGroup({});
      for (const key in newElementValue) {
        formGroup.addControl(key, deepCloneFormGroup(newElementValue[key]));
      }
      this.formObjects.insert(index + 1, this.formObjects.controls.at(index as number)?.value ? formGroup : initDataFormGroup)
    }
  }

  removeItemInputFromForm(index: number) {
    this.elementList = removeItem(this.elementList, index, true);
    this.formObjects.removeAt(index)
  }

  drop(event: any, isMovingItemInArray: boolean = true) {
    if (event.previousContainer === event.container) {
      if (isMovingItemInArray) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.moveDataFormArray(event.previousIndex, event.currentIndex);
      }
    } else {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      const data = event.container.data[event.currentIndex];
      this.setFormGroupInputType(data, event.currentIndex);
    }
  }

  moveUp(item: any) {
    if (this.elementList) {
      const currentIndex = this.elementList.indexOf(item);
      if (currentIndex > 0 && !item.isDisable) {
        moveItemInArray(this.elementList, currentIndex, currentIndex - 1);
        this.moveDataFormArray(currentIndex, currentIndex - 1)
      }
    }
  }

  moveDown(item: any) {
    if (this.elementList) {
      const currentIndex = this.elementList.indexOf(item);
      if (currentIndex < this.elementList.length && !item.isDisable) {
        moveItemInArray(this.elementList, currentIndex, currentIndex + 1);
        this.moveDataFormArray(currentIndex, currentIndex + 1)
      }
    }
  }

  moveDataFormArray(currentIndex: number, shift: number) {
    const currentGroup = this.formObjects.at(currentIndex);
    this.formObjects.removeAt(currentIndex);
    this.formObjects.insert(shift, currentGroup)
  }

  setElementData(index: any) {
    this.formIndex = index;
    this.templateElementDetail = this.formObjects.at(index);
    this.visibleTemplateDetail = !this.visibleTemplateDetail;
  }

  receiveVisibleChange(event: any) {
    this.visibleTemplateDetail = event;
    if (!event) this.elementData = null;
  }

  async submit() {
    try {
      this.submitted = true
      this.isClickLoading = true;
      this.listNameInput = this.formObjects.value.map((item: any) => item.name);
      const duplicateIndexNameInputList = getDuplicateIndexItemInArray(this.listNameInput);
      if (this.listNameInput && duplicateIndexNameInputList.length) {
        this.alertService.error(this.translateService.instant('errorMessages.template.objectValidate.itemInputDuplicate', { listInput: duplicateIndexNameInputList.join(', ') }))
        this.isClickLoading = false;
        return;
      }
      if (this.formObjects.invalid) {
        const indexOfInvalidForms = [];
        for (const index in this.formObjects.controls) {
          const formItem = this.formObjects.get(index);
          if (formItem?.invalid && !formItem?.get('name')?.getError('isDuplicate')) indexOfInvalidForms.push(Number(index) + 1)
        }
        this.alertService.error(this.translateService.instant('errorMessages.template.objectValidate.itemInputDrag', { listInput: indexOfInvalidForms.join(', ') }))
        this.isClickLoading = false;
        return;
      }
      const templateId = this.activatedRoute.snapshot.params['templateId'];
      const dataTemplate = {
        ...this.template.nodes,
        objects: this.formObjects.value
      };
      dataTemplate.meta.data = dataTemplate.meta.data.filter((data: any) => this.formObjects.value.some((node: any) => node.name === data.key));
      dataTemplate.meta.search = dataTemplate.meta.search.filter((dataSearch: string) => this.formObjects.value.some((node: any) => node.name === dataSearch));

      await this.templateService.update(templateId, {
        nodes: JSON.stringify(dataTemplate)
      })
      this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.UPDATE_FORM_ITEMS_EDIT, EVENT_LOG_TYPE.SUCCESS);
      this.router.navigate(['list-template'], { queryParams: this.queryParams }).then(() => {
        this.alertService.success(this.translateService.instant('alertMessages.template.update.success'));
      });
    } catch (e) {
      this.firebaseAnalyticService.logEvent(ACTION_LIST.TEMPLATE.UPDATE_FORM_ITEMS_EDIT, EVENT_LOG_TYPE.ERROR);
      this.alertService.error(this.translateService.instant('alertMessages.template.update.fail'))
    }
  }

  backToListTemplate() {
    this.router.navigate(['list-template'], { queryParams: this.queryParams }).then();
  }

  protected readonly dayFormat = dayFormat;
  protected readonly INPUT_TYPE = INPUT_TYPE;
  protected readonly text = text;
  protected readonly INPUT_TYPE_FILE = INPUT_TYPE_FILE;
  protected readonly inputTypeList = ELEMENT_TYPE_LIST;
}
