<div>
  <input
    cFormControl
    [readOnly]="readonly"
    placeholder="{{placeholder[currentLang]}}"
    (input)="changeInputLabel($event)"
    type="text"
    [value]="value"
  />
</div>
